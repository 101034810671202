export default editor => {
    const bm = editor.BlockManager;

    const pn = editor.Panels;

    let aux;

    // Configuring Containers
    bm.get("blocks-advanced-header").set({
        attributes: { class: "fa fa-level-up", style: "order: 1" },
        label: "Header",
        category: "Containers",
    });

    bm.get("blocks-advanced-footer").set({
        attributes: { class: "fa fa-level-down", style: "order: 1" },
        label: "Footer",
        category: "Containers",
    });

    bm.get("blocks-advanced-section").set({
        attributes: { class: "fa fa-crop", style: "order: 2" },
        label: "Section",
        category: "Containers",
    });

    aux = bm.get("container-block");
    aux.set({ attributes: { ...aux.get("attributes"), style: "order: 2" } });

    bm.get("blocks-advanced-iframe").set({
        attributes: { class: "fa fa-globe", style: "order: 3" },
        label: "Iframe",
        category: "Containers",
    });

    bm.get("column1").set({ label: "Block", category: "Containers" });

    bm.get("column2").set({ label: "2 Blocks", category: "Containers" });

    bm.get("column3").set({ label: "3 Blocks", category: "Containers" });

    bm.get("column3-7").set({ label: "3/7 Blocks", category: "Containers" });

    // Configuring Basic
    bm.get("header").set({
        attributes: { class: "fa fa-h-square", style: "order: 0" },
    });

    bm.get("text").set({
        attributes: { class: "fa fa-align-left", style: "order: 0" },
    });

    bm.get("link").set({
        attributes: { class: "fa fa-link", style: "order: 1" },
    });

    bm.get("link-block").set({
        attributes: { class: "fa fa-link", style: "order: 1" },
    });

    bm.get("quote").set({
        attributes: { class: "fa fa-quote-right", style: "order: 1" },
    });

    bm.get("image").set({
        attributes: { class: "fa fa-image", style: "order: 2" },
    });

    bm.get("video").set({
        attributes: { class: "fa fa-youtube-play", style: "order: 2" },
    });

    bm.get("map").set({
        attributes: { class: "fa fa-map-marker", style: "order: 2" },
    });

    // Configuring Forms
    bm.get("form").set({
        attributes: { class: "fa fa-file-text", style: "order: 0" },
        label: "Form",
    });

    bm.get("button").set({
        attributes: { class: "fa fa-square", style: "order: 0" },
        label: "Button",
    });

    bm.get("select").set({
        attributes: { class: "fa fa-list-ul", style: "order: 1" },
        label: "Select",
    });

    bm.get("label").set({
        attributes: { class: "fa fa-ticket", style: "order: 1" },
        label: "Label",
    });

    bm.get("input").set({
        attributes: { class: "fa fa-pencil-square-o", style: "order: 2" },
        label: "Input",
    });

    bm.get("textarea").set({
        attributes: { class: "fa fa-pencil-square-o", style: "order: 2" },
        label: "Textarea",
    });

    bm.get("radio").set({
        attributes: { class: "fa fa-dot-circle-o", style: "order: 3" },
        label: "Radio",
    });

    bm.get("checkbox").set({
        attributes: { class: "fa fa-check-square", style: "order: 4" },
    });

    // Configuring Sections
    aux = bm.get("section-hero");
    aux.set({ attributes: { ...aux.get("attributes"), style: "order: -1" } });

    bm.get("text-basic").set({ label: "Content", category: "Sections" });

    bm.get("blocks-advanced-list-items").set({ category: "Sections" });

    bm.get("blocks-advanced-grid-items").set({
        attributes: { class: "fa fa-th-large" },
        label: "Items",
        category: "Sections",
    });

    // Configuring Extra
    bm.get("tooltip").set({
        attributes: { class: "fa fa-commenting" },
        label: "Tooltip",
    });

    bm.get("tabs").set({
        attributes: { class: "fa fa-folder" },
        label: "Tabs",
    });

    bm.get("typed").set({
        attributes: { class: "fa fa-text-width" },
        media: "",
    });

    bm.get("lory-slider").set({
        attributes: { class: "fa fa-arrows-h" },
        label: "Slider",
    });

    bm.get("h-navbar").set({
        attributes: { class: "fa fa-bars" },
        label: "Navbar",
    });

    // bm.get("countdown").set({ attributes: { class: "fa fa-history" }, label: "Countdown" });

    // Configuring Socials Blocks
    bm.get("social-google").set({
        attributes: { class: "fa fa-google" },
        label: "Google+",
    });

    bm.get("social-link").set({
        attributes: { class: "fa fa-circle-o" },
        label: "Generic",
    });

    bm.get("social-link-block").set({
        attributes: { class: "fa fa-ellipsis-h" },
        label: "Socials",
    });

    // Open in fullscreen by default
    // pn.getButton("options", "fullscreen").set("active", 1);
    // editor.current.runCommand("core:fullscreen");

    // Show borders by default
    pn.getButton("options", "sw-visibility").set("active", 1);

    // Sorting the blocks categories
    const order = [
        "Basic",
        "Forms",
        "Containers",
        "Sections",
        "Ecommerce",
        "Social",
        "Extra",
    ];

    bm.getCategories().models.forEach(c => {
        c.set(
            "order",
            order.findIndex(o => o === c.get("label")),
        );
    });
};
