import KanbanCard from "./KanbanCard";
import { useState } from "react";
import { Badge } from "@/components";
import KanbanModal from "./KanbanModal";
import Icon from "base/components/Icon";
import { useConfigure, useHits } from "react-instantsearch-hooks-web";
import { AlgoliaOrder } from "@/types";
import { getAlgoliaClient } from "algolia";
import { KANBAN_LANES } from "config/constant";

const KanbanSection = () => {
    const { hits: orders } = useHits<AlgoliaOrder>();
    useConfigure({ hitsPerPage: 1000 });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [algoliaOrder, setAlgoliaOrder] = useState<AlgoliaOrder>();

    const handleModalToggle = (order: AlgoliaOrder) => {
        setIsModalOpen(prevIsModalOpen => !prevIsModalOpen);

        setAlgoliaOrder(order);

        const client = getAlgoliaClient();
        client.clearCache();
    };

    return (
        <div className="kanban__applied_filters">
            {Object.entries(KANBAN_LANES).map(([laneName, laneProps]) => {
                const laneOrders = orders.filter(order =>
                    laneProps.status.includes(order.status),
                );

                return (
                    <div className="kanban__lane" key={laneName}>
                        <h3>
                            <Icon icon="dots" />
                            <Badge
                                className={`status__${laneProps.color}`}
                                label={laneName}
                            />
                            <span className="kanban_amount">
                                {laneOrders.length}
                            </span>
                        </h3>
                        <div className="kanban_container">
                            {laneOrders.map((order: AlgoliaOrder, cardKey) => (
                                <KanbanCard
                                    accentClass={laneProps.color}
                                    handleModalToggle={handleModalToggle}
                                    key={cardKey}
                                    order={order}
                                />
                            ))}
                        </div>
                    </div>
                );
            })}
            {algoliaOrder ? (
                <KanbanModal
                    algoliaOrder={algoliaOrder}
                    handleModalToggle={handleModalToggle}
                    isModalOpen={isModalOpen}
                />
            ) : null}
        </div>
    );
};

export default KanbanSection;
