import { useState } from "react";
import _ from "lodash";

const useForm = initialState => {
    const [inputs, setInputs] = useState(initialState ?? {});

    const setInput = (name, value) => {
        setInputs(state => {
            const _state = _.cloneDeep(state);

            _.set(_state, name, value);

            return _state;
        });
    };

    const inputChange = e => {
        const { name, value, type, checked } = e.target;

        const isCheck = ["radio", "checkbox"].includes(type);

        setInput(name, isCheck ? checked : value);
    };

    const datepickerChange = (picker, name) => {
        const startDate = picker.startDate;
        const endDate = picker.endDate;

        setInput(
            name,
            picker.singleDatePicker
                ? startDate
                : {
                      startDate,
                      endDate,
                  },
        );
    };

    const genericChange = (v, name) => {
        setInput(name, v);
    };

    const selectChange = (o, name) => {
        setInput(name, o.value);
    };

    const selectValue = (value, options) => {
        const object = _.find(options, o => o.value === value);

        return object || null;
    };

    return {
        inputs,
        setInputs,
        inputChange,
        genericChange,
        datepickerChange,
        selectChange,
        selectValue,
    };
};

export default useForm;
