import { useEffect, useState } from "react";

import { LineItem } from "@/types";

const useLineItems = (initialValues: LineItem[]) => {
    const [lineItems, setLineItems] = useState<LineItem[]>(initialValues);

    useEffect(() => {
        setLineItems(initialValues);
    }, [initialValues]);

    const updateItemQuantity = (itemIndex: number, op: "add" | "sub") => {
        setLineItems(items =>
            items
                .map((item, index) => {
                    if (index === itemIndex) {
                        const quantity = item.quantity + (op == "add" ? 1 : -1);
                        return { ...item, quantity };
                    }
                    return item;
                })
                .filter(item => item.quantity > 0),
        );
    };

    const addLineItemQuantity = (itemIndex: number) =>
        updateItemQuantity(itemIndex, "add");

    const subLineItemQuantity = (itemIndex: number) =>
        updateItemQuantity(itemIndex, "sub");

    const removeLineItems = (itemIndexes: number[]) => {
        setLineItems(items =>
            items.filter((_, index) => !itemIndexes.includes(index)),
        );
    };

    const addLineItem = (lineItem: LineItem) => {
        setLineItems(items => [...items, lineItem]);
    };

    return {
        lineItems,
        setLineItems,
        addLineItemQuantity,
        subLineItemQuantity,
        removeLineItems,
        addLineItem,
    };
};

export { useLineItems };
