import { Button } from "reactstrap";
import { FC } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";

interface INumericMenuProps {
    items: IItemRange[];
    refine: any;
    dateRange?: any;
    element: string | "date" | "radio" | "checkbox";
}

interface IItemRange {
    start?: number;
    end?: number;
    label?: string;
    isRefined?: boolean;
    value?: string;
}

export const NumericMenu: FC<INumericMenuProps> = ({
    refine,
    items,
    element = "radio",
    dateRange,
}) => {
    return (
        <div className="filters__filter">
            {element === "radio" && (
                <ul className="ais-NumericMenu-list">
                    {items.map(item => (
                        <li className={"form-check"} key={item.value}>
                            <input
                                checked={item.isRefined}
                                //className={"k-radio--brand"}
                                id={`radio-${item.value}`}
                                onChange={() => refine(item.value)}
                                type="radio"
                            />
                            <label
                                className="ais-NumericMenu-labelText"
                                htmlFor={`radio-${item.value}`}
                            >
                                {item.label}
                            </label>
                        </li>
                    ))}
                </ul>
            )}
            {element === "date" &&
                items.map(({ label, isRefined, value }, id) => {
                    if (label.includes("Range") || label.includes("-")) {
                        return (
                            <DateRangePicker
                                autoApply
                                key={id}
                                onApply={(e, dates) => {
                                    dateRange(dates);
                                }}
                                singleDatePicker={false}
                            >
                                <Button
                                    active={isRefined}
                                    className="filters_options-filter_button"
                                >
                                    {label}
                                </Button>
                            </DateRangePicker>
                        );
                    }

                    return (
                        <Button
                            active={isRefined}
                            className="filters_options-filter_button"
                            id={id}
                            key={id}
                            onClick={() => {
                                refine(value);
                            }}
                        >
                            {label}
                        </Button>
                    );
                })}
        </div>
    );
};
