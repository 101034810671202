import React, { useEffect, useState } from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { Portlet, Section } from "../../base/components/portlet";
import Form from "../../base/components/form/Form";
import useMeta from "../../base/hooks/useMeta";
import useForm from "../../base/hooks/form/useForm";
import useFormActions from "../../base/hooks/form/useFormActions";
import {
    MUTATION_UPDATE_MIN_ZIP_BY_CODE,
    QUERY_LIST_STORE_APPS,
} from "../../apollo/module-operations/app";
import _ from "lodash";
import useValidator from "../../base/hooks/form/useValidator";
import { FormGroup, Col, Label, FormFeedback, Input } from "reactstrap";
import omit from "lodash/omit";

const MinZipByCodeForm = ({ app }) => {
    const { user } = useMeta();

    const { inputs, setInputs, inputChange } = useForm();

    useEffect(() => {
        const locations = app.locations
            ? app.locations.map(location => omit(location, "__typename"))
            : [];

        setInputs({
            private_key: app.private_key,
            locations: JSON.stringify(locations, null, 2),
        });
    }, [app]);

    const getLocations = () => {
        try {
            return JSON.parse(inputs?.locations);
        } catch (error) {
            return [];
        }
    };

    let options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: {
                ...app,
                ...inputs,
                locations: getLocations(),
            },
        },
    };

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "locations",
                method: "isJSON",
                validWhen: true,
                args: [],
                message: "Invalid locations structure.",
            },
        ],
        {
            ...inputs,
        },
    );

    const { createOrUpdateButton } = useFormActions(
        "id",
        "MinZipByCode",
        "",
        MUTATION_UPDATE_MIN_ZIP_BY_CODE,
        [
            {
                query: QUERY_LIST_STORE_APPS,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        "",
        isValid,
        isSubmited,
    );

    return (
        <Portlet
            className="business__settings"
            header={{
                title: "Min By Code",
                subtitle: "Min By Code Information",
            }}
            sticky
            toolbar={<>{createOrUpdateButton}</>}
        >
            {!user.loading ? (
                <Form>
                    <Section>
                        <FormGroup row>
                            <Col>
                                <Label>Locations</Label>
                                <Input
                                    invalid={validator.locations.isInvalid}
                                    name="locations"
                                    onChange={inputChange}
                                    rows={40}
                                    type="textarea"
                                    value={inputs?.locations || "[]"}
                                />
                                <FormFeedback>
                                    {validator.locations.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                    </Section>
                </Form>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
        </Portlet>
    );
};

export default MinZipByCodeForm;
