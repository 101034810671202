import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Col, Row, FormFeedback, FormGroup, Label, Button } from "reactstrap";
import { useForm, useValidator } from "@/hooks";
import {
    Form,
    Input,
    Section,
    SectionTitle,
    Select,
    Spinner,
} from "@/components";
import { EVENT_TYPE } from "../../config/constant";

const SettingForm = ({
    app,
    handleCancel,
    handlerUpdatingOrCreate,
    isCreate,
    isUpdating,
    selectSettings,
    setSettings,
}) => {
    const [isFormReset, resetFormInputs] = useState(isCreate);
    const { inputs, inputChange, setInputs, selectChange, selectValue } =
        useForm();

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "campaign_id",
                method: "isEmpty",
                validWhen: false,
                message: "This Campaign is required.",
            },
            {
                field: "event_type",
                method: "isEmpty",
                validWhen: false,
                message: "This Event type is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    useEffect(() => {
        if (!isCreate) {
            const objSelect = app?.settings[selectSettings];
            setInputs({
                campaign_id: objSelect?.campaign_id,
                event_type: objSelect?.event_type,
                event_name: objSelect?.event_name,
                schedule_send_email_hours: objSelect?.schedule_send_email_hours,
            });
        }
    }, []);

    if (!isCreate && !isFormReset) {
        resetFormInputs(true);
    }

    const createOrUpdate = async () => {
        if (isSubmited) {
            isSubmited(true);
        }

        if (isValid()) {
            let newSettings = _.cloneDeep(app.settings);

            if (isCreate) {
                newSettings.push({
                    campaign_id: parseInt(inputs.campaign_id),
                    event_type: inputs.event_type,
                    event_name: inputs.event_name,
                    schedule_send_email_hours: parseInt(
                        inputs.shedule_send_email_hours || 0,
                    ),
                });
            } else {
                newSettings[selectSettings] = {
                    ...inputs,
                    schedule_send_email_hours: parseInt(
                        inputs.schedule_send_email_hours || 0,
                    ),
                };
            }
            setSettings(newSettings);
            handlerUpdatingOrCreate(newSettings);
        }
    };

    const handleClose = () => {
        handleCancel();
        resetFormInputs(false);
    };

    return (
        <Form>
            <Section className="mt-5">
                <Row>
                    <Col>
                        <SectionTitle md>
                            {isCreate ? "Add Setting" : "Update Setting"}
                        </SectionTitle>
                    </Col>
                </Row>
                <Row>
                    <Col lg={3} md={6}>
                        <FormGroup>
                            <Label>Campaign number</Label>
                            <Input
                                invalid={validator.campaign_id.isInvalid}
                                name="campaign_id"
                                onChange={inputChange}
                                placeholder="Campaign number"
                                type="number"
                                value={inputs.campaign_id}
                            />
                            <FormFeedback>
                                {validator.campaign_id.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg={3} md={6}>
                        <FormGroup invalid={validator.event_type.isInvalid}>
                            <Label>Event type</Label>
                            <Select
                                controlName="event_type"
                                invalid={validator.event_type.isInvalid}
                                onChange={selectChange}
                                options={EVENT_TYPE}
                                placeholder="Event type"
                                value={selectValue(
                                    inputs.event_type,
                                    EVENT_TYPE,
                                )}
                            />
                            <FormFeedback>
                                {validator.event_type.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg={3} md={6}>
                        <FormGroup>
                            <Label>Event name</Label>
                            <Input
                                name="event_name"
                                onChange={inputChange}
                                placeholder="Event name"
                                value={inputs.event_name}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={3} md={6}>
                        <FormGroup>
                            <Label>Schedule send email hours</Label>
                            <Input
                                name="schedule_send_email_hours"
                                onChange={inputChange}
                                placeholder="Schedule send email hours"
                                type="number"
                                value={inputs.schedule_send_email_hours}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="justify-content-end pr-3">
                    <Button
                        className="btn__options"
                        color="metal"
                        disabled={isUpdating}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="ml-3"
                        color="primary"
                        onClick={createOrUpdate}
                        style={{ minWidth: "80px" }}
                    >
                        {isUpdating && <Spinner color="metal" show sm />}
                        <span>Save</span>
                    </Button>
                </Row>
            </Section>
        </Form>
    );
};

export default SettingForm;
