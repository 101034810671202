import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";

import { useMeta } from "@/hooks";
import { Product } from "@/types";
import { SEARCH_TREEZ_HEADLESS_PRODUCT_QUERY } from "@/data";

const useSearchProducts = () => {
    const { user } = useMeta();
    const [products, setProducts] = useState<Product[]>([]);

    const [search, { data, loading }] = useLazyQuery(
        SEARCH_TREEZ_HEADLESS_PRODUCT_QUERY,
    );

    const searchProducts = (
        nameFilter: string,
        outOfStock: boolean,
        providerStoreID?: string,
    ) => {
        if (!user?.storeId && !user?.accountId) {
            return;
        }

        search({
            variables: {
                account_id: user?.accountId,
                store_id: user?.storeId,
                first: 20,
                provider_id: providerStoreID,
                search_term: nameFilter,
                showOutOfStock: outOfStock,
                strictMedicalCustomerType: false,
            },
        });
    };

    useEffect(() => {
        setProducts(data?.searchTreezHeadlessProducts ?? []);
    }, [data]);

    return { searchProducts, isSearching: loading, products };
};

export { useSearchProducts };
