import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Col, Row, FormFeedback, FormGroup, Label, Button } from "reactstrap";
import { useForm, useValidator } from "@/hooks";
import { Form, Input, Section, SectionTitle, Spinner } from "@/components";

const AccountDutchieForm = ({
    isCreate,
    handleCancel,
    app,
    selectCredential,
    setCredentials,
    isUpdating,
    handlerUpdatingOrCreate,
}) => {
    const [isFormReset, resetFormInputs] = useState(isCreate);
    const { inputs, inputChange, setInputs } = useForm();

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "api_key",
                method: "isEmpty",
                validWhen: false,
                message: "This Api key is required.",
            },
            {
                field: "secret_api_key",
                method: "isEmpty",
                validWhen: false,
                message: "This Secret Api Key is required.",
            },
            {
                field: "retailer_id",
                method: "isEmpty",
                validWhen: false,
                message: "This Retailer Id is required.",
            },
            {
                field: "store_name",
                method: "isEmpty",
                validWhen: false,
                message: "This Store Name is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    useEffect(() => {
        if (!isCreate) {
            const objSelect = app?.credentials[selectCredential];
            setInputs({
                api_key: objSelect?.api_key,
                secret_api_key: objSelect?.secret_api_key,
                retailer_id: objSelect?.retailer_id,
                store_name: objSelect?.store_name,
            });
        }
    }, []);

    if (!isCreate && !isFormReset) {
        resetFormInputs(true);
    }

    const createOrUpdate = async () => {
        if (isSubmited) {
            isSubmited(true);
        }

        if (isValid()) {
            let accounts = _.cloneDeep(app.credentials);

            if (isCreate) {
                accounts.push({
                    api_key: inputs?.api_key,
                    secret_api_key: inputs?.secret_api_key,
                    retailer_id: inputs?.retailer_id,
                    store_name: inputs?.store_name,
                });
            } else {
                accounts[selectCredential] = inputs;
            }
            setCredentials(accounts);
            handlerUpdatingOrCreate(accounts);
        }
    };

    const handleClose = () => {
        handleCancel();
        resetFormInputs(false);
    };

    return (
        <Form>
            <Section className="mt-5">
                <Row>
                    <Col>
                        <SectionTitle md>
                            {isCreate ? "Add Credential" : "Update Credential"}
                        </SectionTitle>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Store Name</Label>
                            <Input
                                invalid={validator.store_name.isInvalid}
                                name="store_name"
                                onChange={inputChange}
                                placeholder="Store Name"
                                value={inputs.store_name}
                            />
                            <FormFeedback>
                                {validator.store_name.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Api Key</Label>
                            <Input
                                invalid={validator.api_key.isInvalid}
                                name="api_key"
                                onChange={inputChange}
                                placeholder="Api key"
                                value={inputs.api_key}
                            />
                            <FormFeedback>
                                {validator.api_key.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Secret Api Key</Label>
                            <Input
                                invalid={validator.secret_api_key.isInvalid}
                                name="secret_api_key"
                                onChange={inputChange}
                                placeholder="Secret api key"
                                value={inputs.secret_api_key || ""}
                            />
                            <FormFeedback>
                                {validator.secret_api_key.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Retailer Id</Label>
                            <Input
                                invalid={validator.retailer_id.isInvalid}
                                name="retailer_id"
                                onChange={inputChange}
                                placeholder="Retailer id"
                                value={inputs.retailer_id}
                            />
                            <FormFeedback>
                                {validator.retailer_id.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="justify-content-end pr-3">
                    <Button
                        className="btn__options"
                        color="metal"
                        disabled={isUpdating}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="ml-3"
                        color="primary"
                        onClick={createOrUpdate}
                        style={{ with: "100px !important" }}
                    >
                        {isUpdating && <Spinner color="metal" show sm />}
                        <span>Save</span>
                    </Button>
                </Row>
            </Section>
        </Form>
    );
};

export default AccountDutchieForm;
