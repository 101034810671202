const convertTZ = (date: Date | string, timeZone: string) => {
    return new Date(
        (typeof date === "string" ? new Date(date) : date)?.toLocaleString(
            "en-US",
            { timeZone },
        ),
    );
};

export { convertTZ };
