import React, { useState } from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { InputGroupText, Portlet, Section, SectionTitle } from "@/components";
import { FormGroup, Col, Row, Table, Collapse } from "reactstrap";
import Input from "../../base/components/form/input/Input";
import Form from "../../base/components/form/Form";
import classNames from "classnames";
import _ from "lodash";
import { currencyMask } from "utils/maskInput";

const initialState = {
    // 1. Global Customizations
    wishlist_active: false,
    is_med_and_rec_selection_active: false,
    treez_filter_sort:
        "classification,price,thc,effect,flavor,subType,brand,general",
    treez_filters_title_mapping:
        '{\n  "productTypeName": "Categories",\n  "brand": "Brand",\n  "subType": "SubType",\n  "classification": "Classification",\n  "generals": "Other",\n  "effects": "Effects",\n  "flavors": "Flavors"\n}',
    display_out_of_stock_products: false,

    // 2. Checkout Customizations
    is_grlimit_active: false,
    allow_guest_checkout: false,

    // 3. Product Detail Customizations
    keep_shopping_active: false,
    show_product_warning: false,
    disable_product_reviews: false,
    show_striketrough_price: false,

    // 4. Kiosk Customizations
    is_add_to_cart_in_kiosk_disabled: false,
};

const CustomizationsForm = ({
    inputs,
    loading,
    inputChange,
    setInputs,
    toolbar,
}) => {
    const [isCollapsed, setIsCollapsed] = useState({
        global: true,
        checkout: true,
        validation: true,
        product_detail: true,
        kios: true,
        signup: true,
    });

    if (!_.get(inputs, "feature_flags", null)) {
        setInputs({
            ...inputs,
            feature_flags: {
                ...initialState,
            },
        });
    }

    const toogle = (list, collapse) => {
        setIsCollapsed({
            ...isCollapsed,
            [list]: collapse,
        });
    };

    const handleChangeTreezFiltersTitleMapping = e => {
        const value = e.target.value;

        setInputs({
            ...inputs,
            feature_flags: {
                ...inputs.feature_flags,
                treez_filters_title_mapping: value,
            },
        });
        return;
    };

    const getValueTreezFiltersTitleMapping = () => {
        return inputs.feature_flags.treez_filters_title_mapping;
    };

    return (
        <Portlet
            className="business__settings"
            header={{
                title: "Customizations",
                subtitle: "Customizations Information",
            }}
            sticky
            toolbar={toolbar}
        >
            {!loading ? (
                <Form>
                    <Section>
                        <Row
                            className={classNames("list-collapse", {
                                "list-expanded": isCollapsed.global,
                            })}
                            onClick={() =>
                                toogle("global", !isCollapsed.global)
                            }
                        >
                            <Col>
                                <SectionTitle
                                    md
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <span>1. Global </span>
                                    <i
                                        className={`fas fa-angle-${
                                            isCollapsed.global ? "up" : "down"
                                        } ml-3 mr-5`}
                                        style={{
                                            fontSize: "18px",
                                        }}
                                    />
                                </SectionTitle>
                            </Col>
                        </Row>
                        <Collapse isOpen={isCollapsed.global}>
                            <FormGroup row>
                                <Col lg={10}>
                                    <Table
                                        bordered
                                        className="table-customizations"
                                    >
                                        <thead>
                                            <tr>
                                                <th>
                                                    Customization & Description
                                                </th>
                                                <th className="table-cell">
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <span>Toggle</span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        Strike trough price
                                                    </strong>
                                                    <br />
                                                    <span>
                                                        If on, discounted
                                                        products will show
                                                        strike through pricing
                                                        and original price.
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="k-switch k-switch--md k-switch--icon">
                                                        <label>
                                                            <Input
                                                                checked={
                                                                    inputs
                                                                        .feature_flags
                                                                        ?.show_striketrough_price
                                                                        ? "checked"
                                                                        : ""
                                                                }
                                                                name="feature_flags.show_striketrough_price"
                                                                onChange={
                                                                    inputChange
                                                                }
                                                                type="checkbox"
                                                            />
                                                            <span />
                                                        </label>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        Out of stock products
                                                    </strong>
                                                    <br />
                                                    <span>
                                                        Shows all products, even
                                                        if they are temporarily
                                                        out of stock.
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="k-switch k-switch--md k-switch--icon">
                                                        <label>
                                                            <Input
                                                                checked={
                                                                    inputs
                                                                        .feature_flags
                                                                        ?.display_out_of_stock_products
                                                                        ? "checked"
                                                                        : ""
                                                                }
                                                                name="feature_flags.display_out_of_stock_products"
                                                                onChange={
                                                                    inputChange
                                                                }
                                                                type="checkbox"
                                                            />
                                                            <span />
                                                        </label>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Med & Rec</strong>
                                                    <br />
                                                    <span>
                                                        If on, the toggle
                                                        component will appear
                                                        for Med v Rec on the
                                                        header of the website.
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="k-switch k-switch--md k-switch--icon">
                                                        <label>
                                                            <Input
                                                                checked={
                                                                    inputs
                                                                        .feature_flags
                                                                        ?.is_med_and_rec_selection_active
                                                                        ? "checked"
                                                                        : ""
                                                                }
                                                                name="feature_flags.is_med_and_rec_selection_active"
                                                                onChange={
                                                                    inputChange
                                                                }
                                                                type="checkbox"
                                                            />
                                                            <span />
                                                        </label>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Wishlist</strong>
                                                    <br />
                                                    <span>
                                                        If on, enables all
                                                        functions related to the
                                                        wish list, whether on
                                                        the header, the product
                                                        card, or product
                                                        details.
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="k-switch k-switch--md k-switch--icon">
                                                        <label>
                                                            <Input
                                                                checked={
                                                                    inputs
                                                                        .feature_flags
                                                                        ?.wishlist_active
                                                                        ? "checked"
                                                                        : ""
                                                                }
                                                                name="feature_flags.wishlist_active"
                                                                onChange={
                                                                    inputChange
                                                                }
                                                                type="checkbox"
                                                            />
                                                            <span />
                                                        </label>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        Cancel Order
                                                    </strong>
                                                    <br />
                                                    <span>
                                                        If on, the option to
                                                        cancel an order will
                                                        appear on the
                                                        confirmation or order
                                                        details page, meaning
                                                        the customer can only
                                                        cancel the order by
                                                        contacting the store
                                                        directly.
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="k-switch k-switch--md k-switch--icon">
                                                        <label>
                                                            <Input
                                                                checked={
                                                                    inputs
                                                                        .feature_flags
                                                                        ?.allow_cancel_order_from_confirmation_page
                                                                        ? "checked"
                                                                        : ""
                                                                }
                                                                name="feature_flags.allow_cancel_order_from_confirmation_page"
                                                                onChange={
                                                                    inputChange
                                                                }
                                                                type="checkbox"
                                                            />
                                                            <span />
                                                        </label>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <Table
                                        bordered
                                        className="table-customizations table-borderles-top"
                                    >
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <strong>Promo units</strong>
                                                    <br />
                                                    <span>
                                                        Promotional products
                                                        priced below the defined
                                                        threshold will be hidden
                                                        from the regular product
                                                        listing page and will
                                                        only be displayed on
                                                        special pages where
                                                        these products are part
                                                        of a BOGO deal.
                                                    </span>
                                                </td>
                                                <td className="table-cell-note">
                                                    <Input
                                                        className="form-control"
                                                        defaultValue={
                                                            inputs.feature_flags
                                                                ?.discount_minimum_threshold_value
                                                        }
                                                        mask={currencyMask(
                                                            inputs.feature_flags
                                                                ?.discount_minimum_threshold_value,
                                                        )}
                                                        name="feature_flags.discount_minimum_threshold_value"
                                                        onChange={inputChange}
                                                        placeholder="Value"
                                                        prepend={
                                                            <InputGroupText>
                                                                $
                                                            </InputGroupText>
                                                        }
                                                        typeInput={"masked"}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Filter sort</strong>
                                                    <br />
                                                    <span>
                                                        If defined, it will
                                                        present the filter
                                                        sorting. The value must
                                                        be specified in the
                                                        comma-separated list.{" "}
                                                        <strong>
                                                            Posible values:{" "}
                                                        </strong>
                                                        category,classification,
                                                        price, thc, effect,
                                                        flavor, subType, brand,
                                                        general
                                                    </span>
                                                </td>
                                                <td className="table-cell-note">
                                                    <Input
                                                        name="feature_flags.treez_filter_sort"
                                                        onChange={inputChange}
                                                        placeholder="classification,price,thc,effect,flavor,subType,brand,general"
                                                        rows={2}
                                                        type="textarea"
                                                        value={
                                                            inputs.feature_flags
                                                                ?.treez_filter_sort ||
                                                            ""
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        Filters title mapping
                                                    </strong>
                                                    <br />
                                                    <span>
                                                        If defined, it will
                                                        present the filter title
                                                        mapping. The value must
                                                        be specified in the JSON
                                                        format.
                                                    </span>
                                                </td>
                                                <td className="table-cell-note">
                                                    <Input
                                                        name="treez_filters_title_mapping"
                                                        onChange={e =>
                                                            handleChangeTreezFiltersTitleMapping(
                                                                e,
                                                            )
                                                        }
                                                        placeholder=""
                                                        rows={10}
                                                        type="textarea"
                                                        value={
                                                            inputs
                                                                ?.feature_flags
                                                                ?.treez_filters_title_mapping ||
                                                            ""
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </FormGroup>
                        </Collapse>
                    </Section>
                    <Section>
                        <Row
                            className={classNames("list-collapse", {
                                "list-expanded": isCollapsed.checkout,
                            })}
                            onClick={() =>
                                toogle("checkout", !isCollapsed.checkout)
                            }
                        >
                            <Col>
                                <SectionTitle
                                    md
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <span>2. Checkout </span>
                                    <i
                                        className={`fas fa-angle-${
                                            isCollapsed.checkout ? "up" : "down"
                                        } ml-3 mr-5`}
                                        style={{
                                            fontSize: "18px",
                                        }}
                                    />
                                </SectionTitle>
                            </Col>
                        </Row>
                        <Collapse isOpen={isCollapsed.checkout}>
                            <FormGroup row>
                                <Col lg={10}>
                                    <Table
                                        bordered
                                        className="table-customizations"
                                    >
                                        <thead>
                                            <tr>
                                                <th>
                                                    Customization & Description
                                                </th>
                                                <th className="table-cell">
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {/*   <span
                                                        style={{
                                                            marginRight: "8px",
                                                        }}
                                                    >
                                                        ⇅
                                                    </span> */}
                                                        <span>Toggle</span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        Allow guest checkout
                                                    </strong>
                                                    <br />
                                                    <span>
                                                        If on, enables guest
                                                        checkout.
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="k-switch k-switch--md k-switch--icon">
                                                        <label>
                                                            <Input
                                                                checked={
                                                                    inputs
                                                                        .feature_flags
                                                                        ?.allow_guest_checkout
                                                                        ? "checked"
                                                                        : ""
                                                                }
                                                                name="feature_flags.allow_guest_checkout"
                                                                onChange={
                                                                    inputChange
                                                                }
                                                                type="checkbox"
                                                            />
                                                            <span />
                                                        </label>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </FormGroup>
                        </Collapse>
                    </Section>
                    <Section>
                        <Row
                            className={classNames("list-collapse", {
                                "list-expanded": isCollapsed.validation,
                            })}
                            onClick={() =>
                                toogle("validation", !isCollapsed.validation)
                            }
                        >
                            <Col>
                                <SectionTitle
                                    md
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <span>3. DL Validation </span>
                                    <i
                                        className={`fas fa-angle-${
                                            isCollapsed.validation
                                                ? "up"
                                                : "down"
                                        } ml-3 mr-5`}
                                        style={{
                                            fontSize: "18px",
                                        }}
                                    />
                                </SectionTitle>
                            </Col>
                        </Row>
                        <Collapse isOpen={isCollapsed.validation}>
                            <FormGroup row>
                                <Col lg={10}>
                                    <Table
                                        bordered
                                        className="table-customizations"
                                    >
                                        <thead>
                                            <tr>
                                                <th>
                                                    Customization & Description
                                                </th>
                                                <th className="table-cell">
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {/*   <span
                                                        style={{
                                                            marginRight: "8px",
                                                        }}
                                                    >
                                                        ⇅
                                                    </span> */}
                                                        <span>Toggle</span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        Verify on Delivery
                                                    </strong>
                                                    <br />
                                                    <span>
                                                        {`If on, the checkout
                                                        process will prompt the
                                                        customer to upload a
                                                        valid driver's license
                                                        picture for verification
                                                        when customer is placing
                                                        a delivery order.`}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="k-switch k-switch--md k-switch--icon">
                                                        <label>
                                                            <Input
                                                                checked={
                                                                    inputs
                                                                        .feature_flags
                                                                        ?.allow_id_verification_for_delivery
                                                                        ? "checked"
                                                                        : ""
                                                                }
                                                                name="feature_flags.allow_id_verification_for_delivery"
                                                                onChange={
                                                                    inputChange
                                                                }
                                                                type="checkbox"
                                                            />
                                                            <span />
                                                        </label>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        Verify on Pickup
                                                    </strong>
                                                    <br />
                                                    <span>
                                                        {`If on, the checkout process will prompt the customer to upload a valid driver's license picture for verification when customer is placing a pickup order.`}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="k-switch k-switch--md k-switch--icon">
                                                        <label>
                                                            <Input
                                                                checked={
                                                                    inputs
                                                                        .feature_flags
                                                                        ?.allow_id_verification_for_pickup
                                                                        ? "checked"
                                                                        : ""
                                                                }
                                                                name="feature_flags.allow_id_verification_for_pickup"
                                                                onChange={
                                                                    inputChange
                                                                }
                                                                type="checkbox"
                                                            />
                                                            <span />
                                                        </label>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <Table
                                        bordered
                                        className="table-customizations table-borderles-top"
                                    >
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <strong>Title</strong>
                                                    <br />
                                                    <span>
                                                        {`The description to be displayed on the driver's license upload screen.`}
                                                    </span>
                                                </td>
                                                <td className="table-cell-note">
                                                    <Input
                                                        className="form-control"
                                                        name="feature_flags.id_verification_title"
                                                        onChange={inputChange}
                                                        placeholder="Title"
                                                        value={
                                                            inputs.feature_flags
                                                                ?.id_verification_title ??
                                                            ""
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>Description</strong>
                                                    <br />
                                                    <span>
                                                        {`The title to be displayed on the driver's license upload screen.`}
                                                    </span>
                                                </td>
                                                <td className="table-cell-note">
                                                    <Input
                                                        className="form-control"
                                                        name="feature_flags.id_verification_description"
                                                        onChange={inputChange}
                                                        placeholder="Description"
                                                        rows={4}
                                                        type="textarea"
                                                        value={
                                                            inputs.feature_flags
                                                                ?.id_verification_description ??
                                                            ""
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </FormGroup>
                        </Collapse>
                    </Section>
                    <Section>
                        <Row
                            className={classNames("list-collapse", {
                                "list-expanded": isCollapsed.product_detail,
                            })}
                            onClick={() =>
                                toogle(
                                    "product_detail",
                                    !isCollapsed.product_detail,
                                )
                            }
                        >
                            <Col>
                                <SectionTitle
                                    md
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <span>4. Product Detail</span>
                                    <i
                                        className={`fas fa-angle-${
                                            isCollapsed.product_detail
                                                ? "up"
                                                : "down"
                                        } ml-3 mr-5`}
                                        style={{
                                            fontSize: "18px",
                                        }}
                                    />
                                </SectionTitle>
                            </Col>
                        </Row>
                        <Collapse isOpen={isCollapsed.product_detail}>
                            <FormGroup row>
                                <Col lg={10}>
                                    <Table
                                        bordered
                                        className="table-customizations"
                                    >
                                        <thead>
                                            <tr>
                                                <th>
                                                    Customization & Description
                                                </th>
                                                <th className="table-cell">
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {/*   <span
                                                        style={{
                                                            marginRight: "8px",
                                                        }}
                                                    >
                                                        ⇅
                                                    </span> */}
                                                        <span>Toggle</span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        Continue shopping
                                                    </strong>
                                                    <br />
                                                    <span>
                                                        If on, “Continue
                                                        Shopping” shows on
                                                        product detail.
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="k-switch k-switch--md k-switch--icon">
                                                        <label>
                                                            <Input
                                                                checked={
                                                                    inputs
                                                                        .feature_flags
                                                                        ?.keep_shopping_active
                                                                        ? "checked"
                                                                        : ""
                                                                }
                                                                name="feature_flags.keep_shopping_active"
                                                                onChange={
                                                                    inputChange
                                                                }
                                                                type="checkbox"
                                                            />
                                                            <span />
                                                        </label>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        Product warning
                                                    </strong>
                                                    <br />
                                                    <span>
                                                        If on, product health
                                                        warnings for Proposition
                                                        65 Warning for
                                                        California, will show on
                                                        product details.
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="k-switch k-switch--md k-switch--icon">
                                                        <label>
                                                            <Input
                                                                checked={
                                                                    inputs
                                                                        .feature_flags
                                                                        ?.show_product_warning
                                                                        ? "checked"
                                                                        : ""
                                                                }
                                                                name="feature_flags.show_product_warning"
                                                                onChange={
                                                                    inputChange
                                                                }
                                                                type="checkbox"
                                                            />
                                                            <span />
                                                        </label>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        Products review
                                                    </strong>
                                                    <br />
                                                    <span>
                                                        If on, product reviews
                                                        do NOT show.
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="k-switch k-switch--md k-switch--icon">
                                                        <label>
                                                            <Input
                                                                checked={
                                                                    inputs
                                                                        .feature_flags
                                                                        ?.disable_product_reviews
                                                                        ? "checked"
                                                                        : ""
                                                                }
                                                                name="feature_flags.disable_product_reviews"
                                                                onChange={
                                                                    inputChange
                                                                }
                                                                type="checkbox"
                                                            />
                                                            <span />
                                                        </label>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </FormGroup>
                        </Collapse>
                    </Section>
                    <Section>
                        <Row
                            className={classNames("list-collapse", {
                                "list-expanded": isCollapsed.kios,
                            })}
                            onClick={() => toogle("kios", !isCollapsed.kios)}
                        >
                            <Col>
                                <SectionTitle
                                    md
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <span>5. Kiosk </span>
                                    <i
                                        className={`fas fa-angle-${
                                            isCollapsed.kios ? "up" : "down"
                                        } ml-3 mr-5`}
                                        style={{
                                            fontSize: "18px",
                                        }}
                                    />
                                </SectionTitle>
                            </Col>
                        </Row>
                        <Collapse isOpen={isCollapsed.kios}>
                            <FormGroup row>
                                <Col lg={10}>
                                    <Table
                                        bordered
                                        className="table-customizations"
                                    >
                                        <thead>
                                            <tr>
                                                <th>
                                                    Customization & Description
                                                </th>
                                                <th className="table-cell">
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {/*   <span
                                                        style={{
                                                            marginRight: "8px",
                                                        }}
                                                    >
                                                        ⇅
                                                    </span> */}
                                                        <span>Toggle</span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        Add to cart in kiosk
                                                    </strong>
                                                    <br />
                                                    <span>
                                                        If on, adding to cart is
                                                        NOT available in kiosk.
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="k-switch k-switch--md k-switch--icon">
                                                        <label>
                                                            <Input
                                                                checked={
                                                                    inputs
                                                                        .feature_flags
                                                                        ?.is_add_to_cart_in_kiosk_disabled
                                                                        ? "checked"
                                                                        : ""
                                                                }
                                                                name="feature_flags.is_add_to_cart_in_kiosk_disabled"
                                                                onChange={
                                                                    inputChange
                                                                }
                                                                type="checkbox"
                                                            />
                                                            <span />
                                                        </label>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        Checkout - First Name
                                                        required
                                                    </strong>
                                                    <br />
                                                    <span>
                                                        In on, the
                                                        customer&apos;s first
                                                        name will be required at
                                                        checkout.
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="k-switch k-switch--md k-switch--icon">
                                                        <label>
                                                            <Input
                                                                checked={
                                                                    inputs
                                                                        .feature_flags
                                                                        ?.kiosk_first_name_required
                                                                        ? "checked"
                                                                        : ""
                                                                }
                                                                name="feature_flags.kiosk_first_name_required"
                                                                onChange={
                                                                    inputChange
                                                                }
                                                                type="checkbox"
                                                            />
                                                            <span />
                                                        </label>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        Checkout - Last Name
                                                        required
                                                    </strong>
                                                    <br />
                                                    <span>
                                                        In on the
                                                        customer&apos;s last
                                                        name will be required at
                                                        checkout.
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="k-switch k-switch--md k-switch--icon">
                                                        <label>
                                                            <Input
                                                                checked={
                                                                    inputs
                                                                        .feature_flags
                                                                        ?.kiosk_last_name_required
                                                                        ? "checked"
                                                                        : ""
                                                                }
                                                                name="feature_flags.kiosk_last_name_required"
                                                                onChange={
                                                                    inputChange
                                                                }
                                                                type="checkbox"
                                                            />
                                                            <span />
                                                        </label>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        Checkout - Email
                                                        required
                                                    </strong>
                                                    <br />
                                                    <span>
                                                        In on the
                                                        customer&apos;s email
                                                        will be required at
                                                        checkout.
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="k-switch k-switch--md k-switch--icon">
                                                        <label>
                                                            <Input
                                                                checked={
                                                                    inputs
                                                                        .feature_flags
                                                                        ?.kiosk_email_required
                                                                        ? "checked"
                                                                        : ""
                                                                }
                                                                name="feature_flags.kiosk_email_required"
                                                                onChange={
                                                                    inputChange
                                                                }
                                                                type="checkbox"
                                                            />
                                                            <span />
                                                        </label>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        Checkout - Phone
                                                        required
                                                    </strong>
                                                    <br />
                                                    <span>
                                                        In on the
                                                        customer&apos;s phone
                                                        will be required at
                                                        checkout.
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="k-switch k-switch--md k-switch--icon">
                                                        <label>
                                                            <Input
                                                                checked={
                                                                    inputs
                                                                        .feature_flags
                                                                        ?.kiosk_phone_required
                                                                        ? "checked"
                                                                        : ""
                                                                }
                                                                name="feature_flags.kiosk_phone_required"
                                                                onChange={
                                                                    inputChange
                                                                }
                                                                type="checkbox"
                                                            />
                                                            <span />
                                                        </label>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        Checkout - Date of birth
                                                        required
                                                    </strong>
                                                    <br />
                                                    <span>
                                                        In on the
                                                        customer&apos;s date of
                                                        birth will be required
                                                        at checkout.
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="k-switch k-switch--md k-switch--icon">
                                                        <label>
                                                            <Input
                                                                checked={
                                                                    inputs
                                                                        .feature_flags
                                                                        ?.kiosk_dob_required
                                                                        ? "checked"
                                                                        : ""
                                                                }
                                                                name="feature_flags.kiosk_dob_required"
                                                                onChange={
                                                                    inputChange
                                                                }
                                                                type="checkbox"
                                                            />
                                                            <span />
                                                        </label>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <Table
                                        bordered
                                        className="table-customizations table-borderles-top"
                                    >
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        Kiosk idle timeout
                                                    </strong>
                                                    <br />
                                                    <span>
                                                        The kiosk idle timeout
                                                        setting determines how
                                                        long the system will
                                                        remain inactive before
                                                        automatically resetting
                                                        or returning to the home
                                                        screen.
                                                    </span>
                                                </td>
                                                <td className="table-cell-note">
                                                    <Input
                                                        className="form-control"
                                                        name="feature_flags.kiosk_timeout_seconds"
                                                        onChange={inputChange}
                                                        placeholder="Value in seconds"
                                                        prepend={
                                                            <InputGroupText>
                                                                sec
                                                            </InputGroupText>
                                                        }
                                                        type={"number"}
                                                        value={
                                                            inputs.feature_flags
                                                                ?.kiosk_timeout_seconds
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        Kiosk countdown
                                                    </strong>
                                                    <br />
                                                    <span>
                                                        The kiosk countdown
                                                        feature displays a
                                                        visible timer indicating
                                                        how much time is left
                                                        before the kiosk resets
                                                        due to inactivity.
                                                    </span>
                                                </td>
                                                <td className="table-cell-note">
                                                    <Input
                                                        className="form-control"
                                                        name="feature_flags.kiosk_countdown_time"
                                                        onChange={inputChange}
                                                        placeholder="Value in seconds"
                                                        prepend={
                                                            <InputGroupText>
                                                                sec
                                                            </InputGroupText>
                                                        }
                                                        type={"number"}
                                                        value={
                                                            inputs.feature_flags
                                                                ?.kiosk_countdown_time
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        Kiosk Thank You Page
                                                        timeout seconds
                                                    </strong>
                                                    <br />
                                                    <span>
                                                        This setting defines how
                                                        long the Thank You Page
                                                        or Confirmation Page is
                                                        displayed after a user
                                                        completes a transaction
                                                        or interaction at the
                                                        kiosk.
                                                    </span>
                                                </td>
                                                <td className="table-cell-note">
                                                    <Input
                                                        className="form-control"
                                                        name="feature_flags.kiosk_thank_you_timeout_seconds"
                                                        onChange={inputChange}
                                                        placeholder="Value in seconds"
                                                        prepend={
                                                            <InputGroupText>
                                                                sec
                                                            </InputGroupText>
                                                        }
                                                        type={"number"}
                                                        value={
                                                            inputs.feature_flags
                                                                ?.kiosk_thank_you_timeout_seconds
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </FormGroup>
                        </Collapse>
                    </Section>
                </Form>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
        </Portlet>
    );
};

export default CustomizationsForm;
