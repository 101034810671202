import React from "react";
import { Link } from "react-router-dom";
import { navigation } from "../../../config/layout/footer";

const FooterMenu = () => {
    return (
        <div className="k-footer__menu">
            {navigation.map(l => (
                <Link
                    className="k-footer__menu-link k-link"
                    key={l.route}
                    to={l.route}
                >
                    {l.label}
                </Link>
            ))}
        </div>
    );
};

export default FooterMenu;
