// import routes from "../routes/routes";

// const { APP_TERMS, APP_ABOUT_US, APP_CONTACT } = routes;

export const navigation = [
    // {
    //     label: APP_TERMS.label,
    //     route: APP_TERMS.route
    // },
    // {
    //     label: APP_ABOUT_US.label,
    //     route: APP_ABOUT_US.route
    // },
    // {
    //     label: APP_CONTACT.label,
    //     route: APP_CONTACT.route
    // }
];
