import React, { useState, useEffect } from "react";
import { Dropdown, DropdownToggle } from "reactstrap";
import { useQuery } from "@apollo/react-hooks";
import _ from "lodash";
import StoresList from "./StoresList";
import useMeta from "../../../hooks/useMeta.js";
import { QUERY_STORES_MENU_LIST } from "../../../../apollo/queries/stores";
import { Icon } from "@/components";

const Stores = () => {
    const [loadingStore, setLoadingStore] = useState(false);

    const [open, isOpen] = useState(false);
    const [store, setStore] = useState({});
    const [activeStore, setActiveStore] = useState(null);
    const { user } = useMeta();
    const { loading, data } = useQuery(QUERY_STORES_MENU_LIST);

    useEffect(() => {
        if (!user.loading && !loading && user.storeId) {
            let store = _.find(
                data ? data.getStoreMenu : {},
                value => value.store_id === user.storeId,
            );

            setStore(store);
            setActiveStore(user.storeId);
        }

        // eslint-disable-next-line
    }, [user.loading, loading, user.storeId]);

    const toggle = () => {
        isOpen(open => !open);
    };

    return (
        <div className="k-header__topbar-item k-header__topbar-item--user">
            <Dropdown
                className="k-header__topbar-wrapper k-header__topbar-wrapper--stores"
                isOpen={open}
                toggle={toggle}
            >
                <DropdownToggle
                    className="k-header__topbar-user k-header__topbar-user-store"
                    role="button"
                    tag="div"
                >
                    <span className="k-header__topbar-icon k-header__topbar-icon-store">
                        {/* <i className="fas fa-store" /> */}
                        <Icon color="#242939" icon="store" />
                    </span>
                    <div className="k-header__topbar-welcome-store">
                        {loadingStore || loading ? (
                            <>
                                {/* <Spinner color="light" sm show color="metal" />&nbsp; */}
                                Loading
                            </>
                        ) : (
                            <span className="k-header__topbar-tag">
                                {data ? "Current store " : "No stores"}
                            </span>
                        )}
                        {!loadingStore && store && (
                            <span className="k-header__topbar-tag k-header__topbar-tag-store">
                                {store.name}
                            </span>
                        )}
                    </div>
                    <i className="k-menu__ver-arrow k-menu__ver-arrow-store fas fa-chevron-down" />
                </DropdownToggle>
                {!loadingStore && data && !user.loading && (
                    <StoresList
                        activeStore={activeStore}
                        isOpen={open}
                        setLoadingStore={setLoadingStore}
                        stores={data.getStoreMenu}
                        toggle={toggle}
                    />
                )}
            </Dropdown>
        </div>
    );
};

export default Stores;
