import React from "react";
import Breadcrumb from "./Breadcrumb";
import ToolBar from "./ToolBar";
import { useLocation, matchPath } from "react-router-dom";
import _ from "lodash";
import routes from "../../../config/routes/routes";
import classNames from "classnames";

const SubHeader = () => {
    const location = useLocation();

    const route = _.find(routes, r => {
        const match = matchPath(location.pathname, {
            path: r.route,
            exact: true,
        });

        return match;
    });

    return (
        <div
            className={classNames("k-content__head k-grid__item", {
                ["k-content-dasboard"]: route.root,
            })}
        >
            <Breadcrumb route={route} />
            <ToolBar />
        </div>
    );
};

export default SubHeader;
