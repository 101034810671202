import React from "react";

import { Input } from "base/components";

const TextFilter = ({ onFilter, column, setValue }) => {
    return (
        <Input
            onChange={e => {
                const value = e.target.value;

                onFilter(e.target.value);

                if (!value) {
                    setValue("");
                }
            }}
            placeholder={`Enter ${column.text}...`}
        />
    );
};

export default TextFilter;
