import React from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { Portlet, Section } from "../../base/components/portlet";
import { FormGroup, Col, Label, FormFeedback } from "reactstrap";
import Input from "../../base/components/form/input/Input";
import Form from "../../base/components/form/Form";
import useMeta from "../../base/hooks/useMeta";
import useForm from "../../base/hooks/form/useForm";
import useFormActions from "../../base/hooks/form/useFormActions";
import {
    MUTATION_UPDATE_FEEFO,
    QUERY_LIST_STORE_APPS,
} from "../../apollo/module-operations/app";

import _ from "lodash";
import useValidator from "../../base/hooks/form/useValidator";

const FeefoForm = ({ app }) => {
    const { user } = useMeta();
    const { inputs, setInputs, inputChange } = useForm();

    if (!_.get(inputs, "id", null)) {
        setInputs(app);
    }

    let options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: {
                ...inputs,
            },
        },
    };

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "merchant_identifier",
                method: "isEmpty",
                validWhen: false,
                message: "This merchant identifier is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    const { createOrUpdateButton } = useFormActions(
        "id",
        "Feefo",
        "",
        MUTATION_UPDATE_FEEFO,
        [
            {
                query: QUERY_LIST_STORE_APPS,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        "",
        isValid,
        isSubmited,
        "primary",
    );

    return (
        <Portlet
            className="business__settings"
            header={{
                title: "Feefo",
                subtitle: "Feefo Information",
            }}
            sticky
            toolbar={<>{createOrUpdateButton}</>}
        >
            {!user.loading ? (
                <Form>
                    <Section>
                        <FormGroup row>
                            <Label className="row-label">
                                Used for subscription
                            </Label>
                            <Col>
                                <span className="k-switch k-switch--md k-switch--icon">
                                    <label>
                                        <Input
                                            checked={
                                                inputs.used_for_subscription
                                                    ? "checked"
                                                    : ""
                                            }
                                            name="used_for_subscription"
                                            onChange={inputChange}
                                            type="checkbox"
                                        />
                                        <span />
                                    </label>
                                </span>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col lg={4}>
                                <Label>Merchant Identifier</Label>
                                <Input
                                    invalid={
                                        validator.merchant_identifier.isInvalid
                                    }
                                    name="merchant_identifier"
                                    onChange={inputChange}
                                    value={inputs.merchant_identifier}
                                />
                                <FormFeedback>
                                    {validator.merchant_identifier.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                    </Section>
                </Form>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
        </Portlet>
    );
};

export default FeefoForm;
