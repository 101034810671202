import { FC } from "react";
import { Col, Row } from "reactstrap";
import { Badge, Section } from "@/components";
import { IDataPaneProps } from "@/types";
import moment from "moment";
import classNames from "classnames";
import { NOT_AVAILABLE } from "@/config";

const AdditionalDataPane: FC<IDataPaneProps> = ({ order, loading }) => {
    if (loading) {
        return (
            <Section>
                <Row>
                    <Col className="order__status">
                        <div className="content-placeholder">
                            <div className="cp-w-lg cp-h-md" />
                        </div>
                        <div className="content-placeholder">
                            <div className="cp-w-lg cp-h-md" />
                        </div>
                    </Col>
                </Row>
            </Section>
        );
    }

    return (
        <Section>
            <Row>
                <Col>
                    <span className="order__type">
                        {`Device (${order?.client_detail?.device})`}
                    </span>
                </Col>
            </Row>
            <Row className="mt-4 max-lg">
                <Col className="my-4 my-md-0 pr-5" lg={6}>
                    <div className={"order__detail_info_content"}>
                        <div className="order__detail_info">
                            <div className="order__detail_info_item">
                                <strong>Created At:</strong>
                                <span className="d-block">
                                    {moment(order?.invoice_date).format(
                                        "ddd, MMM Do, YYYY - h:mm:ss a",
                                    ) || NOT_AVAILABLE}
                                </span>
                            </div>
                            <div className="order__detail_info_item">
                                <strong>Device:</strong>
                                <span className="d-block">
                                    {order?.client_detail?.device ||
                                        NOT_AVAILABLE}
                                </span>
                            </div>

                            <div className="order__detail_info_item">
                                <strong>User Agent:</strong>
                                <span className="d-block">
                                    {order?.client_detail?.user_agent ||
                                        NOT_AVAILABLE}
                                </span>
                            </div>

                            <div className="order__detail_info_item">
                                <strong>IP:</strong>
                                <span className="d-block">
                                    {order?.client_detail?.x_forwarded_for ||
                                        NOT_AVAILABLE}
                                </span>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className="my-4 my-md-0 p-0" lg={6}>
                    <div className="order__detail_info_content content_delivery_separator">
                        <div className="order__detail_info">
                            <h5>Jane Information</h5>
                            <div className="order__detail_info_item">
                                <strong>Shop:</strong>
                                <span className="d-block">
                                    {order?.jane_store_name || NOT_AVAILABLE}
                                </span>
                            </div>
                            <div className="order__detail_info_item">
                                <strong>Cart ID:</strong>
                                <span className="d-block">
                                    {order?.jane_cart_id || NOT_AVAILABLE}
                                </span>
                            </div>
                            <div className="order__detail_info_item">
                                <strong>Order ID:</strong>
                                <span className="d-block">
                                    {order?.jane_order_id || NOT_AVAILABLE}
                                </span>
                            </div>
                            <div className="order__detail_info_item">
                                <strong>Store ID:</strong>
                                <span className="d-block">
                                    {order?.jane_store_id || NOT_AVAILABLE}
                                </span>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            {/* TODO: Fix Align */}
            {(order.delivery_details?.on_fleet || order.treez_order_id) && (
                <Row className="mt-5 max-lg">
                    {order.treez_order_id && (
                        <Col className="my-4 my-md-0 pr-5" lg={6}>
                            <div className={"order__detail_info_content"}>
                                <div className="order__detail_info">
                                    <h5>Treez Information</h5>

                                    <div className="order__detail_info_item">
                                        <strong>Order ID:</strong>
                                        <span className="d-block">
                                            {order?.treez_order_id ||
                                                NOT_AVAILABLE}
                                        </span>
                                    </div>
                                    <div className="order__detail_info_item">
                                        <strong>Order Number:</strong>
                                        <span className="d-block">
                                            {order?.treez_order_number ||
                                                NOT_AVAILABLE}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    )}

                    {order.delivery_details?.on_fleet && (
                        <Col className="my-4 my-md-0 p-0" lg={6}>
                            <div className="order__detail_info_content content_delivery_separator">
                                <div className="order__detail_info">
                                    <h5>OnFleet Information</h5>

                                    <div className="order__detail_info_item">
                                        <strong>Order ID:</strong>
                                        <span className="d-block">
                                            {order.delivery_details?.on_fleet
                                                ?.id || NOT_AVAILABLE}
                                        </span>
                                    </div>
                                    <div className="order__detail_info_item">
                                        <strong>Tracking URL:</strong>
                                        <a
                                            className="d-block"
                                            href={
                                                order.delivery_details?.on_fleet
                                                    ?.tracking_url
                                            }
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            {order.delivery_details?.on_fleet
                                                ?.tracking_url || NOT_AVAILABLE}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    )}
                </Row>
            )}
        </Section>
    );
};

export default AdditionalDataPane;
