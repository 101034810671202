import React, { useState, useRef, useEffect } from "react";
import { DropdownMenu, DropdownItem, FormGroup, Input } from "reactstrap";
import _ from "lodash";
import StoresItems from "./StoresItems";
import { Icon } from "@/components";

const StoresList = ({
    isOpen,
    toggle,
    stores,
    activeStore,
    setLoadingStore,
}) => {
    const [filter, setFilter] = useState("");

    const input = useRef(null);

    useEffect(
        () => {
            if (isOpen) {
                input.current.focus();
            }
        },

        // eslint-disable-next-line
        [isOpen],
    );

    const _stores = _.filter(stores, s =>
        _.includes(_.toLower(s.name), _.toLower(filter)),
    );

    const handleFilter = e => {
        setFilter(e.target.value);
    };

    return (
        <DropdownMenu center>
            <FormGroup>
                <div className="k-input-icon k-input-icon--left">
                    <Input
                        bsSize="sm"
                        innerRef={input}
                        onChange={handleFilter}
                        value={filter}
                    />
                    <span className="k-input-icon__icon k-input-icon__icon--left">
                        <span>
                            {/* <i className="fas fa-search" /> */}
                            <Icon color="#727A94" icon="search" />
                        </span>
                    </span>
                </div>
            </FormGroup>
            <DropdownItem divider />
            {!_stores.length ? (
                <DropdownItem className="dropdown-item--no-stores" tag="div">
                    No stores
                </DropdownItem>
            ) : (
                <StoresItems
                    activeStore={activeStore}
                    setLoadingStore={setLoadingStore}
                    stores={_stores}
                    toggle={toggle}
                />
            )}
        </DropdownMenu>
    );
};

export default StoresList;
