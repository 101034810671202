import React, { useState } from "react";
import {
    Badge,
    Portlet,
    Section,
    SectionTitle,
    Table,
    Input,
    Spinner,
} from "@/components";
import { Col, Row, FormFeedback, FormGroup, Label, Button } from "reactstrap";
import { useMutation } from "@apollo/react-hooks";
import { MUTATION_UPDATE_ITERABLE } from "../../apollo/module-operations/app";
import _ from "lodash";
import {
    useConfirm,
    useForm,
    useMeta,
    useNotification,
    useValidator,
} from "@/hooks";
import SettingForm from "./SettingForm";
import { EVENT_TYPE } from "config/constant";

const IterableForm = ({ app, loading }) => {
    const notify = useNotification();
    const { user } = useMeta();
    const [showForm, setShowForm] = useState(false);
    const [isCreate, setIsCreate] = useState(true);
    const [rowSelect, setRowSelect] = useState(null);
    const [settings, setSettings] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [isloading, setIsLoading] = useState(false);

    const { inputs, inputChange, setInputs } = useForm();

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "api_key",
                method: "isEmpty",
                validWhen: false,
                message: "This Api key is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    if (!_.get(inputs, "id", null)) {
        setInputs(app);
    }

    const [mutate] = useMutation(MUTATION_UPDATE_ITERABLE, {
        refetchQueries: [],
        onCompleted: resp => {
            handleCancel();
            setIsUpdating(false);
            setIsDelete(false);
            setInputs({ ...inputs, settings });
            setIsLoading(false);
            notify(
                `Setting Was ${
                    isCreate ? "Created" : !isDelete ? "Updated" : "Removed"
                } Successfully"`,
                "success",
            );
        },
        onError: () => {
            setIsUpdating(false);
            notify(
                `Error ${
                    isCreate ? "Updating" : !isDelete ? "Creating" : "Removing"
                }  The Setting, If The Error Persist Please Contact Support.`,
                "error",
            );
        },
    });

    const [confirm, launch] = useConfirm(() => {
        mutate({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: {
                    ...inputs,
                    settings: settings,
                },
            },
        });
    }, `Are You Sure You Want To Remove The Setting ?`);

    const columns = [
        {
            dataField: "campaign_id",
            text: "Campaign Number",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.campaign_id || ""}`}</div>;
            },
        },
        {
            dataField: "event_type",
            text: "Event type",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <div>{`${
                        _.filter(EVENT_TYPE, e => e.value === row.event_type)[0]
                            .label
                    }`}</div>
                );
            },
        },
        {
            dataField: "event_name",
            text: "Event name",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.event_name || ""}`}</div>;
            },
        },
        {
            dataField: "schedule_send_email_hours",
            text: "Schedule send email hours",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Badge
                        className={
                            row.schedule_send_email_hours
                                ? `status__success`
                                : `status__metalic`
                        }
                        label={
                            row.schedule_send_email_hours
                                ? `scheduled ${row.schedule_send_email_hours} hrs after`
                                : "Not scheduled"
                        }
                    />
                );
            },
        },
    ];

    const actions = [
        {
            label: "Update",
            action: async (cell, row, rowIndex, formatExtraData) => {
                setIsCreate(false);
                setRowSelect(rowIndex);
                setShowForm(true);
            },
        },
        {
            label: "Delete",
            action: async (cell, row, rowIndex, formatExtraData) => {
                setIsDelete(true);
                setIsCreate(false);
                setSettings(
                    _.filter(inputs.settings, (i, index) => index !== rowIndex),
                );
                launch();
            },
        },
    ];

    const handlerUpdatingOrCreate = array => {
        setIsUpdating(true);
        mutate({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: {
                    ...inputs,
                    api_key: inputs.api_key,
                    settings: array,
                },
            },
        });
    };

    const handleCancel = () => {
        setIsUpdating(false);
        setShowForm(false);
    };

    const createOrUpdate = () => {
        if (isSubmited) {
            isSubmited(true);
        }

        if (isValid()) {
            setIsLoading(true);
            setSettings(inputs.settings);
            handlerUpdatingOrCreate(inputs.settings);
        }
    };

    const _settings = (inputs && inputs.settings) || [];

    return (
        <>
            <Portlet
                header={{
                    title: "Iterable",
                    subtitle: "Iterable Information",
                }}
                sticky
                toolbar={
                    <>
                        <Button
                            className="ml-3"
                            color="metal"
                            onClick={() => {
                                setIsCreate(true);
                                setShowForm(true);
                            }}
                        >
                            <i className="fas fa-plus" />
                            Add Setting
                        </Button>
                        <Button
                            className="ml-3"
                            color="primary"
                            disabled={isloading || showForm}
                            onClick={createOrUpdate}
                            style={{ minWidth: "128px" }}
                        >
                            <Spinner show={isloading} sm />
                            {!isloading && <i className="fas fa-save" />}
                            Update
                        </Button>
                    </>
                }
            >
                <Section>
                    <Row>
                        <Col>
                            <SectionTitle md>Information</SectionTitle>
                        </Col>
                    </Row>
                    <Row>
                        <FormGroup row>
                            <Label className="row-label">
                                Used for subscription
                            </Label>
                            <Col>
                                <span className="k-switch k-switch--md k-switch--icon">
                                    <label>
                                        <Input
                                            checked={
                                                inputs.used_for_subscription
                                                    ? "checked"
                                                    : ""
                                            }
                                            name="used_for_subscription"
                                            onChange={inputChange}
                                            type="checkbox"
                                        />
                                        <span />
                                    </label>
                                </span>
                            </Col>
                        </FormGroup>
                    </Row>
                    <Row>
                        <Col md={6} xl={4}>
                            <FormGroup>
                                <Label>Api key</Label>
                                <Input
                                    invalid={validator.api_key.isInvalid}
                                    name="api_key"
                                    onChange={inputChange}
                                    placeholder="Api key"
                                    value={inputs.api_key}
                                />
                                <FormFeedback>
                                    {validator.api_key.message}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SectionTitle md>Settings</SectionTitle>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table
                                actions={actions}
                                columns={columns}
                                data={_settings}
                                keyField="key"
                                loading={loading}
                            />
                        </Col>
                    </Row>
                </Section>
                {showForm && (
                    <SettingForm
                        app={inputs}
                        handleCancel={handleCancel}
                        handlerUpdatingOrCreate={handlerUpdatingOrCreate}
                        isCreate={isCreate}
                        isUpdating={isUpdating}
                        selectSettings={rowSelect}
                        setSettings={setSettings}
                    />
                )}
            </Portlet>
            {confirm}
        </>
    );
};

export default IterableForm;
