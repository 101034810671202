import React, { useEffect } from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    FormGroup,
    Button,
    Col,
} from "reactstrap";
import Input from "../../base/components/form/input/Input";
import useForm from "../../base/hooks/form/useForm";
import templates, { templateEmpty } from "./templates/templates";

const LandingSelector = ({ isOpen, toggle, empty, onSelect }) => {
    const { inputs, genericChange } = useForm();

    useEffect(
        () => genericChange(empty ? "0" : templates[0].id, "template"),
        // eslint-disable-next-line
        [empty],
    );

    const handleSelect = e => {
        let template = templateEmpty;

        if (inputs.template !== "0") {
            template = templates.find(t => t.id === inputs.template);
        }

        onSelect(template.project);

        toggle();
    };

    const option = (template, col) => {
        const radio = (
            <label className="k-option" key={!col ? template.id : undefined}>
                <span className="k-option__control">
                    <span className="k-radio k-radio--bold k-radio--brand k-radio--check-bold">
                        <Input
                            checked={inputs.template === template.id}
                            name="template"
                            onChange={e => {
                                genericChange(template.id, "template");
                            }}
                            type="radio"
                        />
                        <span />
                    </span>
                </span>
                <span className="k-option__label">
                    <span className="k-option__head">
                        <span className="k-option__title">
                            {template.title}
                        </span>
                    </span>
                    <span className="k-option__body">
                        {template.thumbnail && (
                            <>
                                <img
                                    alt={template.title}
                                    className="rounded mb-3 border shadow-sm"
                                    src={template.thumbnail}
                                    style={{
                                        width: 100,
                                        height: 100,
                                        objectFit: "cover",
                                    }}
                                />
                                <br />
                            </>
                        )}
                        {template.description}
                    </span>
                </span>
            </label>
        );

        if (col)
            return (
                <Col key={template.id} sm={6}>
                    {radio}
                </Col>
            );

        return radio;
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader>Select template</ModalHeader>
            <ModalBody>
                <FormGroup className="mb-0" row>
                    {empty && option(templateEmpty, true)}
                    {templates.map(t => option(t, true))}
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" disabled={false} onClick={handleSelect}>
                    Select
                </Button>
                <Button className="ml-3" color="metal" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default LandingSelector;
