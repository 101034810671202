import React, { useState } from "react";
import { Portlet, Section, SectionTitle, Table } from "@/components";
import { Button, Col, Row } from "reactstrap";
import { useMutation } from "@apollo/react-hooks";
import {
    MUTATION_UPDATE_DUTCHIE_APP,
    QUERY_LIST_STORE_APPS,
} from "../../apollo/module-operations/app";
import _ from "lodash";
import {
    useConfirm,
    useForm,
    useMeta,
    useFormActions,
    useNotification,
} from "@/hooks";
import AccountDutchieForm from "./AccountDutchieForm";

const DutchieForm = ({ app, loading }) => {
    const notify = useNotification();
    const { user } = useMeta();
    const [showForm, setShowForm] = useState(false);
    const [isCreate, setIsCreate] = useState(true);
    const [rowSelect, setRowSelect] = useState(null);
    const [credentials, setCredentials] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const { inputs, setInputs } = useForm();

    if (!_.get(inputs, "id", null)) {
        setInputs(app);
    }

    const [mutate] = useMutation(MUTATION_UPDATE_DUTCHIE_APP, {
        refetchQueries: [],
        onCompleted: resp => {
            handleCancel();
            setIsUpdating(false);
            setIsDelete(false);
            setInputs({ ...inputs, credentials });
            notify(
                `Credential Dutchie Was ${
                    isCreate ? "Created" : !isDelete ? "Updated" : "Removed"
                } Successfully"`,
                "success",
            );
        },
        onError: () => {
            setIsUpdating(false);
            notify(
                `Error ${
                    isCreate ? "Updating" : !isDelete ? "Creating" : "Removing"
                }  The Credential, If The Error Persist Please Contact Support.`,
                "error",
            );
        },
    });

    const [confirm, launch] = useConfirm(() => {
        mutate({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: {
                    ...inputs,
                    credentials: credentials,
                },
            },
        });
    }, `Are You Sure You Want To Remove The Credential ?`);

    let options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: {
                ...inputs,
                type: "Dutchie",
            },
        },
    };

    const { createOrUpdateButton } = useFormActions(
        "id",
        "Dutchie",
        "",
        MUTATION_UPDATE_DUTCHIE_APP,
        [
            {
                query: QUERY_LIST_STORE_APPS,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        "",
        () => {
            return true;
        }, // isValid
        () => {
            return true;
        }, // isSubmited func
        "primary",
    );

    const columns = [
        {
            dataField: "store_name",
            text: "Store Name",
            headerStyle: (colum, colIndex) => {
                return { width: "30%" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.store_name || ""}`}</div>;
            },
        },
        {
            dataField: "api_key",
            text: "Api Key",
            headerStyle: (colum, colIndex) => {
                return { width: "30%" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.api_key || ""}`}</div>;
            },
        },
        {
            dataField: "secret_api_key",
            text: "Secret Api Key",
            headerStyle: (colum, colIndex) => {
                return { width: "30%" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.secret_api_key || ""}`}</div>;
            },
        },
        {
            dataField: "retailer_id",
            text: "Retailer Id",
            headerStyle: (colum, colIndex) => {
                return { width: "30%" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.retailer_id || ""}`}</div>;
            },
        },
    ];

    const actions = [
        {
            label: "Update",
            action: async (cell, row, rowIndex, formatExtraData) => {
                setIsCreate(false);
                setRowSelect(rowIndex);
                setShowForm(true);
            },
        },
        {
            label: "Delete",
            action: async (cell, row, rowIndex, formatExtraData) => {
                setIsDelete(true);
                setIsCreate(false);
                setCredentials(
                    _.filter(
                        inputs.credentials,
                        (i, index) => index !== rowIndex,
                    ),
                );
                launch();
            },
        },
    ];

    const handlerUpdatingOrCreate = array => {
        setIsUpdating(true);
        mutate({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: {
                    ...inputs,
                    credentials: array,
                },
            },
        });
    };

    const handleCancel = () => {
        setIsUpdating(false);
        setShowForm(false);
    };

    const _credentials = (inputs && inputs.credentials) || [];

    return (
        <>
            <Portlet
                header={{
                    title: "Dutchie",
                    subtitle: "Dutchie Information",
                }}
                sticky
                toolbar={[
                    createOrUpdateButton,
                    // eslint-disable-next-line react/jsx-key
                    <Button
                        className="ml-3"
                        color="secondary"
                        onClick={() => {
                            setIsCreate(true);
                            setShowForm(true);
                        }}
                    >
                        <i className="fas fa-plus" />
                        Add Credential
                    </Button>,
                ]}
            >
                <Section>
                    <Row>
                        <Col>
                            <SectionTitle md>Credentials</SectionTitle>
                        </Col>
                    </Row>
                    <Table
                        actions={actions}
                        columns={columns}
                        data={_credentials}
                        keyField="id"
                        loading={loading}
                    />
                </Section>
                {showForm && (
                    <AccountDutchieForm
                        app={inputs}
                        credentials={credentials}
                        handleCancel={handleCancel}
                        handlerUpdatingOrCreate={handlerUpdatingOrCreate}
                        isCreate={isCreate}
                        isUpdating={isUpdating}
                        selectCredential={rowSelect}
                        setCredentials={setCredentials}
                    />
                )}
            </Portlet>
            {confirm}
        </>
    );
};

export default DutchieForm;
