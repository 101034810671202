import React from "react";
import classNames from "classnames";

const Content = ({ children, className = "" }) => {
    return (
        <div
            className={classNames(
                "k-content__body k-grid__item k-grid__item--fluid",
                className,
            )}
        >
            {children}
        </div>
    );
};

export default Content;
