import React from "react";
import { Button } from "reactstrap";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import Spinner from "../../components/spinner/Spinner";
import useMeta from "../useMeta";
import useNotification from "../components/useNotification";
import _ from "lodash";

const useFormActions = (
    keyField,
    resource,
    createMutation,
    updateMutation,
    refetchQueries,
    options,
    route,
    isValid,
    isSubmited = null,
    color = "primary",
    className,
    textaction,
) => {
    const { isCreate } = useMeta();

    const history = useHistory();

    const notify = useNotification();

    const [mutate, { loading }] = useMutation(
        isCreate ? createMutation : updateMutation,
        {
            refetchQueries,
            onCompleted: resp => {
                notify(
                    `${resource} ${
                        isCreate ? "Created" : "Updated"
                    } Successfully.`,
                    "success",
                );

                const keys = _.keys(resp);
                const entity = resp[keys[0]];

                if (isCreate) {
                    let url = _.replace(route, ":id", entity[keyField]);

                    history.replace(url);
                }
            },
            onError: () => {
                notify(
                    `Error ${
                        isCreate ? "Creating" : "Updating"
                    } The ${resource}.`,
                    "error",
                );
            },
        },
    );

    const createOrUpdate = () => {
        if (isSubmited) {
            isSubmited(true);
        }

        if (isValid()) {
            if (options.variables.input) {
                if (options.variables.input.menu_items) {
                    options.variables.input.menu_items = _.map(
                        options.variables.input.menu_items,
                        v => {
                            return _.omit(v, ["__typename"]);
                        },
                    );
                }
                if (options.variables.input.store_feature) {
                    options.variables.input.store_feature = _.map(
                        options.variables.input.store_feature,
                        v => {
                            return _.omit(v, ["__typename"]);
                        },
                    );
                }
                if (
                    options.variables.input.shipping_setting &&
                    options.variables.input.shipping_setting
                        .free_shipping_method
                ) {
                    options.variables.input.shipping_setting.free_shipping_method.regions =
                        _.map(
                            options.variables.input.shipping_setting
                                .free_shipping_method.regions,
                            v => {
                                return _.omit(v, ["__typename"]);
                            },
                        );
                }
                if (
                    options.variables.input.shipping_setting &&
                    options.variables.input.shipping_setting.flat_rate_method
                ) {
                    options.variables.input.shipping_setting.flat_rate_method.regions =
                        _.map(
                            options.variables.input.shipping_setting
                                .flat_rate_method.regions,
                            v => {
                                return _.omit(v, ["__typename"]);
                            },
                        );
                }

                if (options.variables.input.material) {
                    if (options.variables.input.material.price_table) {
                        options.variables.input.material.price_table = _.map(
                            options.variables.input.material.price_table,
                            p => _.omit(p, ["__typename", "value", "label"]),
                        );
                    }

                    if (options.variables.input.material.colors) {
                        options.variables.input.material.colors = _.map(
                            options.variables.input.material.colors,
                            p => _.omit(p, ["__typename", "value", "label"]),
                        );
                    }
                }

                if (options.variables.input.colors) {
                    options.variables.input.colors = _.map(
                        options.variables.input.colors,
                        p => _.omit(p, ["__typename", "value", "label"]),
                    );
                }
            }

            options = _.omit(options, [
                "variables.input.__typename",
                "variables.input.delivery_address.__typename",
                //"variables.input.address.__typename",
                "variables.input.seo.__typename",
                "variables.input.ignore_whitespace",
                "variables.input.shipping_setting.free_shipping_method.__typename",
                "variables.input.shipping_setting.flat_rate_method.__typename",
                "variables.input.account_setting.__typename",
                "variables.input.shipping_setting.fulfilment_center.__typename",
                "variables.input.shipping_setting.local_delivery_method.__typename",
                "variables.input.shipping_setting.local_pickup_method.__typename",
                "variables.input.aws_pinpoint_analytics.__typename",
                "variables.input.material.__typename",
                "variables.input.max_height",
                "variables.input.max_width",
            ]);

            mutate(options);
        }
    };

    const btnUpdateButton = (
        <Button
            className={className}
            color={color}
            disabled={loading}
            onClick={createOrUpdate}
        >
            {textaction}
        </Button>
    );

    const createOrUpdateButton = (
        <Button
            className="ml-3"
            color={color}
            disabled={loading}
            onClick={createOrUpdate}
        >
            <Spinner show={loading} sm />
            {!loading && <i className="fas fa-save" />}
            {isCreate ? "Create" : "Update"}
        </Button>
    );

    return { createOrUpdateButton, btnUpdateButton };
};

export default useFormActions;
