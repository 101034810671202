import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import axios from "axios";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
    QUERY_GET_STORE,
    MUTATION_UPDATE_STORE,
} from "../../apollo/module-operations/store";
import { MUTATION_GET_IMAGEURL } from "../../apollo/module-operations/file_upload";
import useMeta from "../../base/hooks/useMeta.js";
import useConfirm from "../../base/hooks/components/useConfirm";
import useNotification from "../../base/hooks/components/useNotification";
import Spinner from "./spinner/Spinner";
import { Separator } from "../../base/components/portlet";

import { Input, InputGroupText } from "base/components";
import { formatFileUploadFileName } from "utils";

export function Medias({ isOpen, toggle, defaultSelected, onChange }) {
    const { user } = useMeta();
    const [medias, setMedias] = useState([]);
    const [query, setQuery] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [selectedMedia, setSelectedMedia] = useState(null);
    const notify = useNotification();

    const { data } = useQuery(QUERY_GET_STORE, {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
        },
        skip: user.loading,
        onCompleted: res => {
            setMedias(res.getStore.medias || []);
        },
    });

    const [updateStore] = useMutation(MUTATION_UPDATE_STORE, {
        onCompleted: () => {
            notify(
                selectedMedia
                    ? "Media deleted successfully"
                    : "Media uploaded successfully",
                "success",
            );
            setSelectedMedia(null);
        },
        onError: () => {
            notify("Some error ocurred. Please, try again later.", "error");
        },
        refetchQueries: [
            {
                query: QUERY_GET_STORE,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
            "getStore",
        ],
    });

    const updateMedias = newMedias => {
        setIsLoading(true);

        setMedias(newMedias);
        updateStore({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: {
                    ...data.getStore,
                    medias: newMedias,
                },
            },
        }).finally(() => setIsLoading(false));
    };

    const [confirm, launch] = useConfirm(() => {
        updateMedias(
            data.getStore.medias.filter(media => media !== selectedMedia),
        );

        if (onChange) {
            onChange("");
        }
    }, "Are you sure you want delete this media?");

    let searchNumber = null;
    const handleSearch = e => {
        setQuery(e.target.value);

        if (searchNumber) {
            clearTimeout(searchNumber);
        }

        searchNumber = setTimeout(() => {
            searchNumber = null;

            const filteredMedias = data.getStore.medias.filter(media =>
                media.toLowerCase().includes(e.target.value.toLowerCase()),
            );

            setMedias(filteredMedias);
        }, 800);
    };

    const copyUrl = (e, url) => {
        navigator.clipboard.writeText(url);

        const parent = e.currentTarget.parentNode;

        const btn = parent.querySelector(".btn");
        const tooltip = parent.querySelector(".btn-tooltip");

        btn.style.opacity = 0.5;
        tooltip.style.display = "block";

        setTimeout(() => {
            btn.style.opacity = 1;
            tooltip.style.display = "none";
        }, 1000);
    };

    const [generatePresignedUrl] = useMutation(MUTATION_GET_IMAGEURL);

    const handleUpload = e => {
        if (e.target.files.length === 0) {
            return;
        }

        setIsLoading(true);

        const file = e.target.files[0];
        const nameFormat = formatFileUploadFileName(
            file.name,
            user.accountId,
            user.storeId,
        );

        generatePresignedUrl({
            variables: {
                input: {
                    file_name: nameFormat,
                    file_type: file.type,
                },
            },
        })
            .then(async resp => {
                const { url, headers } = resp.data.uploadFile;
                const config = {
                    headers: {
                        "Content-Type": file.type,
                        ...headers.reduce(
                            (prev, { key, value }) => ({
                                ...prev,
                                [key]: value,
                            }),
                            {},
                        ),
                    },
                };
                const upload = await axios.put(url, file, config);

                if (upload.status === 200) {
                    const media = `${process.env.REACT_APP_STATIC_CONTENT_BASE_URL}${nameFormat}`;
                    const newMedias = [media, ...medias];

                    setQuery("");
                    updateMedias(newMedias);

                    if (onChange) {
                        onChange(media);
                    }
                } else {
                    notify(
                        "Error uploading media. Please, try again later.",
                        "error",
                    );
                }
            })
            .catch(e => {
                console.error(e);

                notify(
                    "Error preparing media. Please, try again later.",
                    "error",
                );
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (data) {
            setIsLoading(false);
        }
    }, [data]);

    return (
        <Modal isOpen={isOpen} size="lg" toggle={toggle}>
            <ModalHeader toggle={toggle}>Medias</ModalHeader>
            <ModalBody className="gallery__body">
                <div className="search__gallery">
                    <div className="k-input-icon k-input-icon--left">
                        <Input
                            disabled={isLoading}
                            name="search"
                            onChange={handleSearch}
                            placeholder="Search..."
                            type="search"
                            value={query}
                        />
                        <span className="k-input-icon__icon k-input-icon__icon--left">
                            <span>
                                <i className="fas fa-search" />
                            </span>
                        </span>
                    </div>
                    <div className="position-relative ml-3 mb-0">
                        <button className="btn btn-primary">
                            <i className="fas fa-plus" />
                            Add new
                        </button>
                        <input
                            accept="image/*"
                            className="gallery__input"
                            disabled={isLoading}
                            multiple={false}
                            onChange={handleUpload}
                            type="file"
                        />
                    </div>
                </div>
                <Separator />
                {isLoading && (
                    <>
                        <Spinner color="metal" show sm />
                        &nbsp;&nbsp;Loading medias
                    </>
                )}
                {!isLoading &&
                    !medias &&
                    !medias?.length &&
                    "There aren't medias."}
                {!isLoading && medias?.length > 0 && (
                    <div className="gallery" role="radiogroup">
                        {medias.map((media, i) => {
                            // const id = `card_id_${i}`;
                            const name = media.split("/").reverse()[0];

                            return (
                                <figure
                                    aria-checked={defaultSelected === media}
                                    className={`gallery__card rounded ${
                                        onChange
                                            ? "gallery__card_selectable"
                                            : ""
                                    }`}
                                    key={media}
                                    onClick={() => {
                                        if (onChange) {
                                            onChange(media);
                                            toggle();
                                        }
                                    }}
                                    role="radio"
                                >
                                    <img
                                        alt=""
                                        className="rounded"
                                        src={media}
                                    />
                                    <figcaption className="image__title">
                                        {name}
                                        <div>
                                            <Button
                                                className="btn-icon"
                                                color="light"
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    copyUrl(e, media);
                                                }}
                                                size="sm"
                                            >
                                                <i className="fas fa-copy" />
                                            </Button>
                                            <Button
                                                className="btn-icon"
                                                color="light"
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    setSelectedMedia(media);
                                                    launch();
                                                }}
                                                size="sm"
                                            >
                                                <i className="fas fa-trash text-danger" />
                                            </Button>
                                            <small
                                                className="btn-tooltip p-2 position-absolute shadow rounded bg-dark text-white"
                                                style={{
                                                    display: "none",
                                                    right: 4,
                                                    top: 4,
                                                }}
                                            >
                                                Copied!
                                            </small>
                                        </div>
                                    </figcaption>
                                </figure>
                            );
                        })}
                    </div>
                )}
            </ModalBody>
            {confirm}
        </Modal>
    );
}

function MediaInput({ name, value, onChange, invalid, ...props }) {
    const [isOpen, setIsOpen] = useState(false);

    const handleChangeMedia = url => {
        onChange({
            target: {
                name,
                value: url,
            },
        });
    };

    return (
        <>
            <Input
                append={
                    <Button
                        className="ml-0"
                        color="metal"
                        onClick={() => setIsOpen(true)}
                    >
                        Browse
                    </Button>
                }
                invalid={invalid}
                name={name}
                onChange={onChange}
                prepend={
                    <InputGroupText>
                        <i className="fas fa-globe" />
                    </InputGroupText>
                }
                value={value}
                {...props}
            />
            {value && (
                <img
                    alt=""
                    className="image-preview rounded"
                    key={value}
                    onLoad={e => (e.target.style.display = "block")}
                    src={value}
                    style={{ display: "none" }}
                />
            )}
            <Medias
                defaultSelected={value}
                isOpen={isOpen}
                onChange={handleChangeMedia}
                toggle={() => setIsOpen(false)}
            />
        </>
    );
}

export default MediaInput;
