import React, { FC } from "react";
import classNames from "classnames";

interface IBadgeProps {
    label: string;
    className?: string;
    md?: boolean;
}

const Badge: FC<IBadgeProps> = ({ label, className, md }) => {
    return (
        <span
            className={classNames(
                "badge__status",
                { "badge__status-md": md },
                className,
            )}
        >
            {label}
        </span>
    );
};

export default Badge;
