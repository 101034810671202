import React, { useState } from "react";
import { Portlet, Section, SectionTitle, Table, Badge } from "@/components";
import { Button, Col, Row } from "reactstrap";
import { useMutation } from "@apollo/react-hooks";
import { MUTATION_UPDATE_STORE } from "../../apollo/module-operations/store";
import _ from "lodash";
import omit from "lodash/omit";
import { useConfirm, useMeta, useNotification } from "@/hooks";
import ScheduleForm from "./ScheduleForm";
import SchedulesFormJSON from "./SchedulesFormJSON";
import { formatSetOrderInput, formatUpdateOrderInput } from "utils/utils";

const PickupSchedulesForm = ({ inputs, setInputs, loading, stores }) => {
    const notify = useNotification();
    const { user } = useMeta();
    const [showForm, setShowForm] = useState(false);
    const [showFormJson, setShowFormJson] = useState(false);
    const [isCreate, setIsCreate] = useState(true);
    const [rowSelect, setRowSelect] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const [mutate] = useMutation(MUTATION_UPDATE_STORE, {
        refetchQueries: [],
        onCompleted: resp => {
            let input = _.cloneDeep(resp.updateStore);

            setInputs(input);
            handleCancel();
            setRowSelect(null);
            setIsDelete(false);
            notify(
                `Pickup Schedule Was ${
                    isCreate ? "Created" : !isDelete ? "Updated" : "Removed"
                } Successfully"`,
                "success",
            );
        },
        onError: () => {
            setIsUpdating(false);
            notify(
                `Error ${
                    isCreate ? "Updating" : !isDelete ? "Creating" : "Removing"
                }  Pickup Schedule, If The Error Persist Please Contact Support.`,
                "error",
            );
        },
    });

    const [confirm, launch] = useConfirm(() => {
        const newShedules = _.filter(
            inputs.pickup_schedules,
            (i, index) => index !== rowSelect,
        );

        const updateInputs = {
            ...omit(
                {
                    ...inputs,
                    pickup_schedules: newShedules,
                },
                "ignore_whitespace",
            ),
        };

        const { errors } = mutate({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: formatUpdateOrderInput(updateInputs),
            },
        });

        if (errors) {
            setInputs(formatSetOrderInput(updateInputs));
        }
    }, `Are You Sure You Want To Remove The Pickup Schedule ?`);

    const columns = [
        {
            dataField: "name",
            text: "Name",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.name || ""}`}</div>;
            },
        },
        {
            dataField: "note",
            text: "Note",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{cell}</div>;
            },
        },
        {
            dataField: "stores_provider_id",
            text: "Store Provider",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <Badge className={"status__success"} label={cell} />;
            },
        },
    ];

    const actions = [
        {
            label: "Update",
            action: async (cell, row, rowIndex, formatExtraData) => {
                setIsCreate(false);
                setRowSelect(rowIndex);
                setShowForm(true);
            },
        },
        {
            label: "Delete",
            action: async (cell, row, rowIndex) => {
                setIsDelete(true);
                setIsCreate(false);
                setRowSelect(rowIndex);
                launch();
            },
        },
    ];

    const handlerUpdatingOrCreate = array => {
        setIsUpdating(true);

        mutate({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: formatUpdateOrderInput({
                    ...omit(
                        {
                            ...inputs,
                            pickup_schedules: array,
                        },
                        "ignore_whitespace",
                    ),
                }),
            },
        });
    };

    const handlerUpdatingOrCreateJSON = inputs => {
        setIsUpdating(true);

        mutate({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: formatUpdateOrderInput(inputs),
            },
        });
    };

    const handleCancel = () => {
        setIsUpdating(false);
        setShowForm(false);
        setShowFormJson(false);
    };

    const _pickupSchedule = (inputs && inputs.pickup_schedules) || [];

    return (
        <>
            <Portlet
                className="business__settings"
                header={{
                    title: "Pickup Schedules",
                    subtitle: "Pickup Schedules Information",
                }}
                sticky
                toolbar={[
                    <Button
                        className="ml-3"
                        color="secondary"
                        disabled={isUpdating || showFormJson || showForm}
                        key="addPickup"
                        onClick={() => {
                            setIsCreate(true);
                            setShowForm(true);
                        }}
                    >
                        <i className="fas fa-plus" />
                        Add Pickup Schedule
                    </Button>,
                    <Button
                        className="ml-3"
                        color="primary"
                        disabled={isUpdating || showFormJson || showForm}
                        key="updateFromJSON"
                        onClick={() => {
                            setIsCreate(true);
                            setShowFormJson(true);
                        }}
                    >
                        <i className="fas fa-edit" />
                        Update Form JSON
                    </Button>,
                ]}
            >
                {showForm && (
                    <ScheduleForm
                        context="Pickup"
                        handleCancel={handleCancel}
                        handlerUpdatingOrCreate={handlerUpdatingOrCreate}
                        isCreate={isCreate}
                        isUpdating={isUpdating}
                        schedules={inputs.pickup_schedules}
                        selectSchedule={rowSelect}
                        stores={stores}
                    />
                )}
                {showFormJson && (
                    <SchedulesFormJSON
                        context="Pickup"
                        handleCancel={handleCancel}
                        handlerUpdatingOrCreate={handlerUpdatingOrCreateJSON}
                        inputs={inputs}
                        isUpdating={isUpdating}
                        setInputs={setInputs}
                    />
                )}
                <Section className="pb-4">
                    <Row>
                        <Col>
                            <SectionTitle md>Pickup Schedule</SectionTitle>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table
                                actions={actions}
                                columns={columns}
                                data={_pickupSchedule}
                                //expandRow={expandRow}
                                keyField="name"
                                loading={loading}
                            />
                        </Col>
                    </Row>
                </Section>
            </Portlet>
            {confirm}
        </>
    );
};

export default PickupSchedulesForm;
