import gql from "graphql-tag";

let Vendor = {};
Vendor.fragments = {
    attributes: gql`
        fragment VendorFragment on Vendor {
            id
            name
            description
            type
            enabled
            company_type
            email
            phone_number
            first_name
            last_name
            license_number
            website
            license_expiration_date
        }
    `,
};

export const QUERY_VENDOR_LIST = gql`
    query listVendors($account_id: String!, $store_id: String!) {
        listVendors(account_id: $account_id, store_id: $store_id) {
            ...VendorFragment
        }
    }
    ${Vendor.fragments.attributes}
`;
