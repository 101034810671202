import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Col, Row, FormFeedback, FormGroup, Label, Button } from "reactstrap";
import { useForm, useValidator } from "@/hooks";
import {
    Form,
    Input,
    Section,
    SectionTitle,
    Select,
    Spinner,
} from "@/components";
import {
    RESERVATION_MODE_OPTIONS,
    PAYMENTS_TYPES_OPTIONS,
} from "../../config/constant";

const CheckoutFormAddItem = ({
    store,
    handleCancel,
    handlerUpdatingOrCreate,
    isCreate,
    isLoadingUpdating,
    idSelected,
    form,
    stores,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const { inputs, inputChange, setInputs, selectChange, selectValue } =
        useForm();

    if (!_.get(inputs, "reservation_mode", null)) {
        if (!isCreate) {
            let itemUpdate = null;

            if (form === "Agreements") {
                itemUpdate = store.agreements.find(
                    (_, index) => index === idSelected,
                );
            }

            if (form === "Payments") {
                itemUpdate = store.payment_settings.find(
                    (_, index) => index === idSelected,
                );
            }

            setInputs(itemUpdate);
        }
    }

    useEffect(() => {
        setIsLoading(isLoadingUpdating);
    }, [isLoadingUpdating]);

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "reservation_mode",
                method: "isEmpty",
                validWhen: false,
                message: "This reservation mode is required.",
            },
            {
                field: "stores_provider_ids",
                method: "isEmpty",
                validWhen: false,
                message: "This stores provider is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    const {
        validator: validatorPayments,
        isValid: isValidPayments,
        isSubmited: isSubmitedPayments,
    } = useValidator(
        [
            {
                field: "reservation_mode",
                method: "isEmpty",
                validWhen: false,
                message: "This reservation mode is required.",
            },
            {
                field: "payment_type",
                method: "isEmpty",
                validWhen: false,
                message: "This payment type is required.",
            },
            {
                field: "stores_provider_ids",
                method: "isEmpty",
                validWhen: false,
                message: "This stores provider is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    const createOrUpdate = () => {
        if (isSubmited) {
            isSubmited(true);
        }

        if (isSubmitedPayments) {
            isSubmitedPayments(true);
        }

        if (isValid() || isValidPayments()) {
            setIsLoading(true);
            let newList = [];

            if (form === "Agreements") {
                newList = _.cloneDeep(store.agreements || []);
            }

            if (form === "Payments") {
                newList = _.cloneDeep(store.payment_settings || []);
            }

            if (isCreate) {
                if (form === "Agreements") {
                    newList.push({
                        reservation_mode: inputs.reservation_mode,
                        content: inputs.content,
                        check_by_default: inputs.check_by_default,
                        required: inputs.required,
                        stores_provider_ids: inputs.stores_provider_ids,
                        __typename: "StoreAgreement",
                    });
                }

                if (form === "Payments") {
                    newList.push({
                        reservation_mode: inputs.reservation_mode,
                        payment_type: inputs.payment_type,
                        stores_provider_ids: inputs.stores_provider_ids,
                        __typename: "StorePaymentSettings",
                    });
                }
            } else {
                if (form === "Agreements") {
                    newList[idSelected] = {
                        ...inputs,
                        reservation_mode: inputs.reservation_mode,
                        content: inputs.content,
                        check_by_default: inputs.check_by_default,
                        required: inputs.required,
                        stores_provider_ids: inputs.stores_provider_ids,
                    };
                }
                if (form === "Payments") {
                    newList[idSelected] = {
                        ...inputs,
                        reservation_mode: inputs.reservation_mode,
                        payment_type: inputs.payment_type,
                        stores_provider_ids: inputs.stores_provider_ids,
                    };
                }
            }

            handlerUpdatingOrCreate(newList);
        }
    };

    const handleClose = () => {
        handleCancel();
    };

    // On change store logic
    const onChangeStore = options => {
        const optionsSelect = options?.map(op => op.value);
        setInputs({ ...inputs, stores_provider_ids: optionsSelect });
    };

    const getRenderValues = idsList => {
        return stores?.filter(op =>
            idsList?.some(id => op.value?.includes(id)),
        );
    };

    return (
        <Form>
            <Section className="section-separator" />
            <Section className="p-4 bg-light">
                <Row>
                    <Col>
                        <SectionTitle md>
                            {isCreate ? "Add " : "Update "}
                            {`${form}`}
                        </SectionTitle>
                    </Col>
                </Row>
                {form === "Agreements" && (
                    <>
                        <Row>
                            <FormGroup row>
                                <Label className="row-label">
                                    Check By Default
                                </Label>
                                <Col lg={4}>
                                    <span className="k-switch k-switch--md k-switch--icon">
                                        <label>
                                            <Input
                                                checked={
                                                    inputs.check_by_default
                                                        ? "checked"
                                                        : ""
                                                }
                                                name="check_by_default"
                                                onChange={inputChange}
                                                type="checkbox"
                                            />
                                            <span />
                                        </label>
                                    </span>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label className="row-label">Required</Label>
                                <Col lg={4}>
                                    <span className="k-switch k-switch--md k-switch--icon">
                                        <label>
                                            <Input
                                                checked={
                                                    inputs.required
                                                        ? "checked"
                                                        : ""
                                                }
                                                name="required"
                                                onChange={inputChange}
                                                type="checkbox"
                                            />
                                            <span />
                                        </label>
                                    </span>
                                </Col>
                            </FormGroup>
                        </Row>
                        <Row style={{ flexWrap: "nowrap" }}>
                            <Col lg={4} md={6}>
                                <FormGroup>
                                    <Label>Reservation Mode</Label>
                                    <Select
                                        controlName="reservation_mode"
                                        invalid={
                                            validator.reservation_mode.isInvalid
                                        }
                                        onChange={selectChange}
                                        options={RESERVATION_MODE_OPTIONS}
                                        value={selectValue(
                                            inputs.reservation_mode,
                                            RESERVATION_MODE_OPTIONS,
                                        )}
                                    />
                                    <FormFeedback>
                                        {validator.reservation_mode.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col
                                lg={"auto"}
                                md={6}
                                style={{
                                    minWidth: "33%",
                                    maxWidth: "66%",
                                }}
                            >
                                <FormGroup>
                                    <Label>Stores Provider</Label>
                                    <Select
                                        closeMenuOnSelect={false}
                                        controlName="stores_provider_ids"
                                        invalid={
                                            validator.stores_provider_ids
                                                .isInvalid
                                        }
                                        isClearable={false}
                                        isMulti
                                        isSearchable={true}
                                        onChange={onChangeStore}
                                        options={stores}
                                        value={getRenderValues(
                                            inputs.stores_provider_ids,
                                        )}
                                    />
                                    <FormFeedback>
                                        {validator.stores_provider_ids.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Col lg={8} md={6}>
                            <FormGroup>
                                <Label>Content</Label>
                                <Input
                                    name="content"
                                    onChange={inputChange}
                                    placeholder="Write content..."
                                    rows={6}
                                    type="textarea"
                                    value={
                                        inputs.content !== ""
                                            ? inputs.content
                                            : ""
                                    }
                                />
                            </FormGroup>
                        </Col>
                    </>
                )}
                {form === "Payments" && (
                    <>
                        <Row style={{ flexWrap: "nowrap" }}>
                            <Col lg={4} md={6}>
                                <FormGroup>
                                    <Label>Reservation Mode</Label>
                                    <Select
                                        controlName="reservation_mode"
                                        invalid={
                                            validatorPayments.reservation_mode
                                                .isInvalid
                                        }
                                        onChange={selectChange}
                                        options={RESERVATION_MODE_OPTIONS}
                                        value={selectValue(
                                            inputs.reservation_mode,
                                            RESERVATION_MODE_OPTIONS,
                                        )}
                                    />
                                    <FormFeedback>
                                        {
                                            validatorPayments.reservation_mode
                                                .message
                                        }
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={6}>
                                <FormGroup>
                                    <Label>Payment Type</Label>
                                    <Select
                                        controlName="payment_type"
                                        invalid={
                                            validatorPayments.payment_type
                                                .isInvalid
                                        }
                                        onChange={selectChange}
                                        options={PAYMENTS_TYPES_OPTIONS}
                                        value={selectValue(
                                            inputs.payment_type,
                                            PAYMENTS_TYPES_OPTIONS,
                                        )}
                                    />
                                    <FormFeedback>
                                        {validatorPayments.payment_type.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ flexWrap: "nowrap" }}>
                            <Col
                                lg={"auto"}
                                md={6}
                                style={{
                                    minWidth: "33%",
                                    maxWidth: "100%",
                                }}
                            >
                                <FormGroup>
                                    <Label>Stores Provider</Label>
                                    <Select
                                        closeMenuOnSelect={false}
                                        controlName="stores_provider_ids"
                                        invalid={
                                            validatorPayments
                                                .stores_provider_ids.isInvalid
                                        }
                                        isClearable={false}
                                        isMulti
                                        isSearchable={true}
                                        onChange={onChangeStore}
                                        options={stores}
                                        value={getRenderValues(
                                            inputs.stores_provider_ids,
                                        )}
                                    />
                                    <FormFeedback>
                                        {
                                            validatorPayments
                                                .stores_provider_ids.message
                                        }
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                    </>
                )}
                <Row className="justify-content-end py-4 pr-3">
                    <Button
                        className="btn__options"
                        color="metal"
                        disabled={isLoading}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="ml-3"
                        color="primary"
                        onClick={createOrUpdate}
                        style={{ minWidth: "120px" }}
                    >
                        {isLoading && <Spinner color="metal" show sm />}
                        <span>Save</span>
                    </Button>
                </Row>
            </Section>
        </Form>
    );
};

export default CheckoutFormAddItem;
