import gql from "graphql-tag";

const fragments = {
    createStoreShippoToken: gql`
        fragment CreateStoreShippoToken on ShippoResponse {
            status
            error
            error_description
        }
    `,
    createShipmentLabel: gql`
        fragment CreateShipmentLabel on ShippingLabelResponse {
            status
            error
            label
        }
    `,
    shipmentDetail: gql`
        fragment ShipmentDetail on ShipmentDetailResponse {
            entity_id
            key
            created_at
            updated_at
            refunded
            refund_id
            rate_id
            provider
            service_level
            estimated_days
            rate
            shipment_id
            label
            tracking_number
            tracking_url
            fixed_shipping
        }
    `,
};

export const MUTATION_CREATE_STORE_SHIPPO_TOKEN = gql`
    mutation createStoreShippoToken(
        $account_id: String!
        $store_id: String!
        $auth_code: String!
    ) {
        createApp(
            account_id: $account_id
            store_id: $store_id
            auth_code: $auth_code
        ) {
            ...CreateStoreShippoToken
        }
    }
    ${fragments.createStoreShippoToken}
`;

export const MUTATION_CREATE_SHIPMENT_LABEL = gql`
    mutation createShipmentLabel(
        $account_id: String!
        $store_id: String!
        $order_id: String!
    ) {
        createShipmentLabel(
            account_id: $account_id
            store_id: $store_id
            order_id: $order_id
        ) {
            ...CreateShipmentLabel
        }
    }
    ${fragments.createShipmentLabel}
`;

export const QUERY_SHIPMENT_DETAILS = gql`
    query shipmentDetail(
        $account_id: String!
        $store_id: String!
        $shipment_detail_id: String!
        $order_id: String!
    ) {
        shipmentDetail(
            account_id: $account_id
            store_id: $store_id
            shipment_detail_id: $shipment_detail_id
            order_id: $order_id
        ) {
            ...ShipmentDetail
        }
    }
    ${fragments.shipmentDetail}
`;
