import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import Content from "../../base/layout/content/Content";
import { Portlet } from "../../base/components/portlet";

import FiltersToggle from "../../base/components/table/FiltersToggle";
import Filters from "../../base/components/table/Filters";
import Table from "../../base/components/table/Table";
import { FILTER_TYPES } from "react-bootstrap-table2-filter";
import useFilter from "../../base/hooks/table/useFilter";

import classNames from "classnames";
import useMeta from "../../base/hooks/useMeta.js";
import useTableActions from "../../base/hooks/table/useTableActions";
import useRowActions from "../../base/hooks/table/useRowActions";
import { QUERY_NAVIGATION_LIST } from "../../apollo/module-operations/navigation";
import { MUTATION_REMOVE_NAVIGATION } from "../../apollo/module-operations/navigation";
import routes from "../../config/routes/routes";
import paginationFactory from "react-bootstrap-table2-paginator";
import { paginationOptions } from "../../config/components/table";

const Navigations = () => {
    const { user } = useMeta();

    //---------------------------Defining filters-----------------------------
    const { TEXT } = FILTER_TYPES;

    const [showFilters, setShowFilters] = useState(false);

    const filterTitle = useFilter("title", TEXT, "Title", "");

    const filterSlug = useFilter("slug", TEXT, "Slug", "");

    const filters = [filterTitle, filterSlug];
    // -----------------------------------------------------------------------

    const { loading, data, refetch, networkStatus } = useQuery(
        QUERY_NAVIGATION_LIST,
        {
            skip: user.loading,
            //fetchPolicy: "cache-and-network",
            notifyOnNetworkStatusChange: true,
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
            },
        },
    );

    const { refreshButton, addNewButton } = useTableActions(
        refetch,
        routes.NAVIGATION_CREATE.route,
    );

    const { confirm, remove, update } = useRowActions(
        [
            {
                query: QUERY_NAVIGATION_LIST,
                skip: user.loading,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        MUTATION_REMOVE_NAVIGATION,
        routes.NAVIGATION_DETAIL.route,
        "Navigation",
    );

    const columns = [
        {
            dataField: "id",
            text: "Id",
            hidden: true,
        },
        {
            dataField: "title",
            text: "Title",
            sort: true,
        },
        {
            dataField: "slug",
            text: "Slug",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "250px" };
            },
        },
        {
            dataField: "status",
            text: "Active",
            headerStyle: (colum, colIndex) => {
                return { width: "100px" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <span
                        className={classNames(
                            "badge badge-pill",
                            `badge-${cell ? "success" : "secondary"}`,
                        )}
                    >
                        {cell ? "Active" : "No Active"}
                    </span>
                );
            },
            sort: true,
        },
    ];

    const actions = [
        {
            label: "Update",
            icon: "fas fa-pencil-alt",
            action: (cell, row, rowIndex, formatExtraData) => {
                update(row.id);
            },
        },
        {
            label: "Remove",
            icon: "fas fa-trash",
            action: (cell, row, rowIndex, formatExtraData) => {
                remove({
                    variables: {
                        account_id: user.accountId,
                        store_id: user.storeId,
                        input: {
                            id: row.id,
                        },
                    },
                });
            },
        },
    ];

    const _loading = loading || networkStatus === 4;

    const _data = data && data.listNavigations;

    return (
        <>
            <Content>
                <Portlet
                    header={{
                        title: "Navigations",
                        subtitle: "All navigations availables in the system",
                    }}
                    sticky
                    toolbar={
                        <>
                            <FiltersToggle setShowFilters={setShowFilters} />
                            {refreshButton}
                            {addNewButton}
                        </>
                    }
                >
                    <Filters filters={filters} />
                    <Table
                        actions={actions}
                        columns={columns}
                        data={_data}
                        filters={filters}
                        keyField="id"
                        loading={_loading}
                        pagination={paginationFactory(paginationOptions)}
                        showFilters={showFilters}
                    />
                </Portlet>
            </Content>
            {confirm}
        </>
    );
};

export default Navigations;
