import grapejsTemplate from "./example-grape-template";
import thumbnail from "../assets/scratch.jpg";

export const templateEmpty = {
    id: "0",
    title: "Build from scratch",
    description: "Start with a blank template and create your own design.",
    thumbnail,
    project: '{"wrapper":{"attributes":{}},"components":[],"style":[]}',
};

export default [grapejsTemplate];
