import { FC, useEffect, useMemo, useState } from "react";
import { Col, Row, Label } from "reactstrap";

import { CheckBox, FilterSelect, Icon } from "@/components";
import { LineItem, Product } from "@/types";
import { mapProductToLineItem } from "@/utils";

import { useSearchProducts } from "./hooks";

interface Props {
    lineItems: LineItem[];
    addLineItem: (item: LineItem) => void;
    hideForm: () => void;
    providerStoreID?: string;
}
const AddLineItemForm: FC<Props> = ({
    lineItems,
    addLineItem,
    hideForm,
    providerStoreID,
}) => {
    const [filter, setFilter] = useState("");
    const [outOfStock, setOutOfStock] = useState(false);
    const { searchProducts, products, isSearching } = useSearchProducts();
    const lineItemIds = useMemo(
        () => lineItems?.map(({ id }) => id),
        [lineItems],
    );
    const filterOptions = useMemo(
        () =>
            products
                .map(product => ({
                    label: product.name,
                    value: product,
                }))
                .filter(({ value: { id } }) => !lineItemIds.includes(id)),
        [products],
    );

    useEffect(() => {
        searchProducts(filter, outOfStock, providerStoreID);
    }, [filter, outOfStock, providerStoreID]);

    const handleFilterChange = (filter: string) => {
        setFilter(filter);
    };

    const handleCheckboxChange = () => {
        setOutOfStock(value => !value);
    };

    const handleAddLineItem = (product: Product) => {
        hideForm();
        addLineItem({ ...mapProductToLineItem(product), quantity: 1 });
    };

    const handleFocus = () => {
        searchProducts(filter, outOfStock, providerStoreID);
    };

    return (
        <div className="order__form_add_item">
            <div className="add__item_form_headder">
                <button onClick={hideForm}>
                    <Icon icon="cross" />
                </button>
                <span>Add product</span>
            </div>
            <Row className="add__item_form_body">
                <Col md={2}>
                    <Label>Include</Label>
                    <CheckBox
                        checked={outOfStock}
                        label="Out-of-stock items"
                        onChange={handleCheckboxChange}
                    />
                </Col>
                <Col md={5}>
                    <Label>Product</Label>
                    <FilterSelect
                        className="products_select"
                        loading={isSearching}
                        onChange={handleAddLineItem}
                        onFilterChange={handleFilterChange}
                        onFocus={handleFocus}
                        options={filterOptions}
                    />
                </Col>
            </Row>
        </div>
    );
};

export { AddLineItemForm };
