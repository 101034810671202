import React from "react";

const Balloon = ({ filter }) => {
    return (
        <div
            className="filter-ballon"
            style={{
                width: "max-content",
                borderRadius: "2rem",
            }}
        >
            {/* <span
                style={{
                    opacity: ".3",
                }}
            >
                {filter.label.current}
            </span>
            :  */}
            {filter.value}
            <i
                className="fas fa-times"
                onClick={e => {
                    if (filter.filter.current) {
                        filter.filter.current(filter.defaultValue);
                    }
                    filter.setValue(null);
                }}
                style={{
                    cursor: "pointer",
                }}
            />
        </div>
    );
};

export default Balloon;
