import React, { useState } from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { Portlet, Section, SectionTitle } from "../../base/components/portlet";
import { FormGroup, Col, Label, Row, Button } from "reactstrap";
import Input from "../../base/components/form/input/Input";
import Form from "../../base/components/form/Form";
import useMeta from "../../base/hooks/useMeta";
import useForm from "../../base/hooks/form/useForm";
import {
    MUTATION_UPDATE_FACEBOOKCATALOG_APP,
    MUTATION_CREATE_FACEBOOKCATALOG_APP,
    QUERY_LIST_STORE_APPS,
} from "../../apollo/module-operations/app";
import _ from "lodash";
import { useMutation } from "@apollo/react-hooks";
import useNotification from "../../base/hooks/components/useNotification";

const FacebookCatalogForm = ({ app }) => {
    const { user } = useMeta();

    const { inputs, setInputs, inputChange } = useForm();

    const [loading, setLoading] = useState(false);

    const notify = useNotification();

    if (!_.get(inputs, "id", null)) {
        setInputs(app);
    }

    const [updateFacebookCatalog] = useMutation(
        MUTATION_UPDATE_FACEBOOKCATALOG_APP,
        {
            skip: user.loading,
            refetchQueries: [
                {
                    query: QUERY_LIST_STORE_APPS,
                    variables: {
                        account_id: user.accountId,
                        store_id: user.storeId,
                    },
                },
            ],
            onCompleted: resp => {
                setLoading(false);
                notify(
                    `${
                        inputs.facebook_catalog_url ? "Update" : "Generate"
                    } Facebook Catalog Successfully`,
                    "success",
                );
            },
            onError: e => {
                setLoading(false);
                notify(
                    `Error ${
                        inputs.facebook_catalog_url ? "Creating" : "Updating"
                    } Facebook Catalog`,
                    "error",
                );
            },
        },
    );

    const [createFacebookCatalog] = useMutation(
        MUTATION_CREATE_FACEBOOKCATALOG_APP,
        {
            skip: user.loading,
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
            },
            onCompleted: resp => {
                setInputs(_inputs => {
                    return {
                        ..._inputs,
                        facebook_catalog_url:
                            resp.createFacebookCatalogProducts,
                    };
                });

                let options = {
                    variables: {
                        account_id: user.accountId,
                        store_id: user.storeId,
                        input: {
                            ...inputs,
                            facebook_catalog_url:
                                resp.createFacebookCatalogProducts,
                            type: "FacebookCatalog",
                        },
                    },
                };

                updateFacebookCatalog(
                    _.omit(options, ["variables.input.__typename"]),
                );
            },
            onError: e => {
                setLoading(false);
            },
        },
    );

    return (
        <Portlet
            centered
            header={{
                title: "Facebook Catalog",
                subtitle: "Facebook Catalog Information",
            }}
            sticky
        >
            {!user.loading ? (
                <Form centered>
                    <Section>
                        <Row>
                            <Label lg={4} xl={3} />
                            <Col>
                                <SectionTitle md>Information</SectionTitle>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Facebook Catalog Url
                            </Label>
                            <Col>
                                <Input
                                    defaultValue={
                                        inputs.facebook_catalog_url
                                            ? inputs.facebook_catalog_url
                                            : ""
                                    }
                                    name="facebook_catalog_url"
                                    onChange={inputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={4} xl={3} />
                            <Col>
                                <Button
                                    color={"primary"}
                                    disabled={loading}
                                    onClick={() => {
                                        setLoading(true);
                                        createFacebookCatalog();
                                    }}
                                >
                                    <Spinner show={loading} sm />
                                    {!user.loading && (
                                        <i className="fas fa-download" />
                                    )}
                                    {inputs.facebook_catalog_url
                                        ? "Update"
                                        : "Generate"}
                                </Button>
                            </Col>
                        </FormGroup>
                    </Section>
                </Form>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
        </Portlet>
    );
};

export default FacebookCatalogForm;
