import React from "react";
import { DropdownMenu } from "reactstrap";
import VerticalNav from "../../../components/navs/vertical/VerticalNav";
import VerticalNavItem from "../../../components/navs/vertical/VerticalNavItem";
import { userMenu } from "../../../../config/layout/header";
import routes from "../../../../config/routes/routes";
import { Auth } from "aws-amplify";
import usePermissions from "../../../hooks/auth/usePermissions";

const UserMenuItems = ({ toggle, user }) => {
    const isAllow = usePermissions();
    const username = user.email ? user.email.split("@") : "";

    return (
        <DropdownMenu onClick={toggle} right>
            <VerticalNav sm>
                <VerticalNavItem key="username">
                    <span>
                        <b>Hi,</b> {username?.[0] ?? ""}
                    </span>
                </VerticalNavItem>
            </VerticalNav>
            <VerticalNav sm>
                {userMenu.map(
                    l =>
                        isAllow(l.permissions) && (
                            <VerticalNavItem
                                action={l.route}
                                icon={l.icon}
                                key={l.route}
                            >
                                {l.label}
                            </VerticalNavItem>
                        ),
                )}
                {userMenu.length && <VerticalNavItem separator />}
                <VerticalNavItem
                    action={() => {
                        Auth.signOut();
                    }}
                    icon={"fas fa-sign-out-alt"}
                >
                    {routes.SING_OUT.label}
                </VerticalNavItem>
            </VerticalNav>
        </DropdownMenu>
    );
};

export default UserMenuItems;
