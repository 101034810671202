import gql from "graphql-tag";

let Product = {};
Product.fragments = {
    attributes: gql`
        fragment ProductFragment on Product {
            id
            sku
            name
            enabled
            description
            category
            category_id
            cannabis_type
            product_type
            product_sale_type
            flower_type
            in_stock
            created_at
            updated_at
            qty
            unit_price
            brand_name
        }
    `,
};

let ListBlazeInventory = {};
ListBlazeInventory.fragments = {
    attributes: gql`
        fragment ListBlazeInventoryFragment on BlazeProductInventory {
            id
            name
        }
    `,
};

let TerminalInventory = {};
TerminalInventory.fragments = {
    attributes: gql`
        fragment TerminalInventoryFragment on BlazeTerminalInventory {
            id
            thc
            type
            qty
            strain
            brand
            name
        }
    `,
};

let ListTreezProductsResponse = {};
ListTreezProductsResponse.fragments = {
    attributes: gql`
        fragment ListTreezProductsResponseFragment on ListTreezProductsResponse {
            total
            products {
                id
                sku
                name
                enabled
                description
                category
                category_id
                cannabis_type
                product_type
                product_sale_type
                flower_type
                in_stock
                created_at
                updated_at
                qty
                unit_price
                brand_name
            }
        }
    `,
};

export const QUERY_BLAZE_INVENTORY_LIST = gql`
    query listInventories($account_id: String!, $store_id: String!) {
        listInventories(account_id: $account_id, store_id: $store_id) {
            ...ListBlazeInventoryFragment
        }
    }
    ${ListBlazeInventory.fragments.attributes}
`;

export const QUERY_BLAZE_TERMINAL_INVENTORY_LIST = gql`
    query listTerminalInventory(
        $account_id: String!
        $store_id: String!
        $inventory_id: String!
    ) {
        listTerminalInventory(
            account_id: $account_id
            store_id: $store_id
            inventory_id: $inventory_id
        ) {
            ...TerminalInventoryFragment
        }
    }
    ${TerminalInventory.fragments.attributes}
`;

export const QUERY_PRODUCT_LIST = gql`
    query listProducts($account_id: String!, $store_id: String!) {
        listProducts(account_id: $account_id, store_id: $store_id) {
            ...ProductFragment
        }
    }
    ${Product.fragments.attributes}
`;

export const QUERY_GET_PRODUCT = gql`
    query getProduct(
        $account_id: String!
        $product_id: String!
        $store_id: String!
    ) {
        getProduct(
            account_id: $account_id
            store_id: $store_id
            product_id: $product_id
        ) {
            ...ProductFragment
        }
    }
    ${Product.fragments.attributes}
`;

export const MUTATION_CREATE_PRODUCT = gql`
    mutation createProduct(
        $account_id: String!
        $store_id: String!
        $input: ProductInput!
    ) {
        createProduct(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            ...ProductFragment
        }
    }
    ${Product.fragments.attributes}
`;

export const MUTATION_UPDATE_PRODUCT = gql`
    mutation updateProduct(
        $account_id: String!
        $store_id: String!
        $input: ProductInput!
    ) {
        updateProduct(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            ...ProductFragment
        }
    }
    ${Product.fragments.attributes}
`;

export const MUTATION_REMOVE_PRODUCT = gql`
    mutation deleteProduct(
        $account_id: String!
        $store_id: String!
        $input: ProductInput!
    ) {
        deleteProduct(
            account_id: $account_id
            store_id: $store_id
            input: $input
        )
    }
`;

export const MUTATION_SYNC_PRODUCT_FROM_TREEZ = gql`
    mutation syncFromTreez($account_id: String!, $store_id: String!) {
        syncFromTreez(account_id: $account_id, store_id: $store_id)
    }
`;

export const SEARCH_PRODUCT_QUERY = gql`
    query searchProduct(
        $account_id: String!
        $store_id: String!
        $after: String
        $before: String
        $first: Int
        $last: Int
        $query: String
        $sortKey: ProductSortKeys
        $provider_store_id: String
    ) {
        searchProduct(
            account_id: $account_id
            store_id: $store_id
            after: $after
            before: $before
            first: $first
            last: $last
            query: $query
            sortKey: $sortKey
            provider_store_id: $provider_store_id
        ) {
            totalCount
            edges {
                cursor
                node {
                    id
                    sku
                    name
                    enabled
                    description
                    category
                    category_id
                    flower_type
                    in_stock
                    brand_id
                    brand_name
                    related_products
                    slug
                    thc {
                        percentage
                    }
                    cbd {
                        percentage
                    }
                    variants {
                        id
                        name
                        price
                        salePrice
                        type
                        pos_id
                    }
                    asset {
                        thumbnail
                        image
                        image_medium_size
                    }
                    barcodes
                    qty
                    unit_price
                    sales_price
                }
            }
            pageInfo {
                hasNextPage
            }
        }
    }
`;

export const SEARCH_PRODUCT_NAMES_QUERY = gql`
    query searchProduct(
        $account_id: String!
        $store_id: String!
        $first: Int
        $query: String
        $provider_store_id: String
    ) {
        searchProduct(
            account_id: $account_id
            store_id: $store_id
            first: $first
            query: $query
            provider_store_id: $provider_store_id
        ) {
            totalCount
            edges {
                cursor
                node {
                    id
                    name
                }
            }
        }
    }
`;

export const SEARCH_TREEZ_HEADLESS_PRODUCT_QUERY = gql`
    query searchTreezHeadlessProducts(
        $provider_id: String!
        $account_id: String!
        $store_id: String!
        $search_term: String
        $pageSize: Int
        $showOutOfStock: Boolean
        $strictMedicalCustomerType: Boolean
    ) {
        searchTreezHeadlessProducts(
            provider_id: $provider_id
            account_id: $account_id
            store_id: $store_id
            search_term: $search_term
            pageSize: $pageSize
            showOutOfStock: $showOutOfStock
            strictMedicalCustomerType: $strictMedicalCustomerType
        ) {
            id
            sku
            name
            enabled
            description
            category
            category_id
            flower_type
            in_stock
            brand_id
            brand_name
            related_products
            slug
            thc {
                percentage
            }
            cbd {
                percentage
            }
            variants {
                id
                name
                price
                salePrice
                type
                pos_id
            }
            asset {
                thumbnail
                image
                image_medium_size
            }
            barcodes
            qty
            unit_price
            sales_price
        }
    }
`;
