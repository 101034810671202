import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Col, Row, FormFeedback, FormGroup, Label, Button } from "reactstrap";
import { useForm, useValidator } from "@/hooks";
import { Form, Input, Section, SectionTitle, Spinner } from "@/components";

const UserAccountTreezForm = ({
    isCreate,
    handleCancel,
    app,
    selectCredential,
    setCredentials,
    isUpdating,
    handlerUpdatingOrCreate,
}) => {
    const [isFormReset, resetFormInputs] = useState(isCreate);
    const { inputs, inputChange, setInputs } = useForm();

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "dispensary_name",
                method: "isEmpty",
                validWhen: false,
                message: "Dispensary name is required.",
            },
            {
                field: "store_id",
                method: "isEmpty",
                validWhen: false,
                message: "This Store id is required.",
            },
            {
                field: "store_name",
                method: "isEmpty",
                validWhen: false,
                message: "This Store name is required.",
            },
            {
                field: "client_id",
                method: "isEmpty",
                validWhen: false,
                message: "This Client id is required.",
            },
            {
                field: "api_key",
                method: "isEmpty",
                validWhen: false,
                message: "This Api key is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    useEffect(() => {
        if (!isCreate) {
            const objSelect = app?.credentials[selectCredential];
            setInputs({
                active: objSelect?.active,
                default_store: objSelect?.default_store,
                dispensary_name: objSelect?.dispensary_name,
                store_id: objSelect?.store_id,
                store_name: objSelect?.store_name,
                client_id: objSelect?.client_id,
                api_key: objSelect?.api_key,
                headless_client_id: objSelect?.headless_client_id,
                headless_client_secret: objSelect?.headless_client_secret,
            });
        }
    }, []);

    if (!isCreate && !isFormReset) {
        resetFormInputs(true);
    }

    const createOrUpdate = async () => {
        if (isSubmited) {
            isSubmited(true);
        }

        if (isValid()) {
            let accounts = _.cloneDeep(app.credentials);

            if (inputs?.default_store) {
                accounts = accounts.map(account => ({
                    ...account,
                    default_store: false,
                }));
            }

            if (isCreate) {
                accounts.push({
                    active: inputs?.active,
                    default_store: inputs?.default_store,
                    dispensary_name: inputs.dispensary_name,
                    store_id: inputs.store_id,
                    store_name: inputs.store_name,
                    client_id: inputs.client_id,
                    api_key: inputs.api_key,
                    headless_client_id: inputs.headless_client_id,
                    headless_client_secret: inputs.headless_client_secret,
                });
            } else {
                accounts[selectCredential] = inputs;
            }
            setCredentials(accounts);
            handlerUpdatingOrCreate(accounts);
        }
    };

    const handleClose = () => {
        handleCancel();
        resetFormInputs(false);
    };

    const handleToggleActive = e => {
        const isChecked = e.target.checked;

        setInputs({
            ...inputs,
            active: isChecked,
            default_store: isChecked ? inputs.default_store : false,
        });
    };

    return (
        <Form>
            <Section className="p-4 bg-light mt-5">
                <Row>
                    <Col>
                        <SectionTitle md>
                            {isCreate ? "Add Credential" : "Update Credential"}
                        </SectionTitle>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <SectionTitle md>Store Informations</SectionTitle>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col lg={4} md={6}>
                        <FormGroup row>
                            <Label className="row-label mr-3 pl-0">
                                Active
                            </Label>

                            <span className="k-switch k-switch--md k-switch--icon">
                                <label>
                                    <Input
                                        checked={inputs.active ? "checked" : ""}
                                        name="active"
                                        onChange={e => handleToggleActive(e)}
                                        type="checkbox"
                                    />
                                    <span />
                                </label>
                            </span>
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup row>
                            <Label className="row-label mr-3 pl-0">
                                Default Select Store
                            </Label>

                            <span className="k-switch k-switch--md k-switch--icon">
                                <label>
                                    <Input
                                        checked={
                                            inputs.default_store
                                                ? "checked"
                                                : ""
                                        }
                                        disabled={!inputs.active}
                                        name="default_store"
                                        onChange={inputChange}
                                        type="checkbox"
                                    />
                                    <span />
                                </label>
                            </span>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Store id</Label>
                            <Input
                                invalid={validator.store_id.isInvalid}
                                name="store_id"
                                onChange={inputChange}
                                placeholder="Store id"
                                value={inputs.store_id}
                            />
                            <FormFeedback>
                                {validator.store_id.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Store name</Label>
                            <Input
                                invalid={validator.store_name.isInvalid}
                                name="store_name"
                                onChange={inputChange}
                                placeholder="Store name"
                                value={inputs.store_name}
                            />
                            <FormFeedback>
                                {validator.store_name.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <SectionTitle md>Treez Credentials</SectionTitle>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Dispensary Name</Label>
                            <Input
                                invalid={validator.dispensary_name.isInvalid}
                                name="dispensary_name"
                                onChange={inputChange}
                                placeholder="Dispensary name"
                                value={inputs.dispensary_name || ""}
                            />
                            <FormFeedback>
                                {validator.dispensary_name.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Client Id</Label>
                            <Input
                                invalid={validator.client_id.isInvalid}
                                name="client_id"
                                onChange={inputChange}
                                placeholder="Client id"
                                value={inputs.client_id}
                            />
                            <FormFeedback>
                                {validator.client_id.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Api Key</Label>
                            <Input
                                invalid={validator.api_key.isInvalid}
                                name="api_key"
                                onChange={inputChange}
                                placeholder="Api key"
                                value={inputs.api_key}
                            />
                            <FormFeedback>
                                {validator.api_key.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <SectionTitle md>Treez Headless</SectionTitle>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Headless Client Id</Label>
                            <Input
                                name="headless_client_id"
                                onChange={inputChange}
                                placeholder="Headless client id"
                                value={inputs.headless_client_id || ""}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Headless Client Secret</Label>
                            <Input
                                name="headless_client_secret"
                                onChange={inputChange}
                                placeholder="Headless client secret"
                                value={inputs.headless_client_secret}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="justify-content-end pr-3">
                    <Button
                        className="btn__options"
                        color="metal"
                        disabled={isUpdating}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="ml-3"
                        color="primary"
                        onClick={createOrUpdate}
                        style={{ with: "100px !important" }}
                    >
                        {isUpdating && <Spinner color="metal" show sm />}
                        <span>Save</span>
                    </Button>
                </Row>
            </Section>
        </Form>
    );
};

export default UserAccountTreezForm;
