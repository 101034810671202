import algoliasearch, { SearchClient } from "algoliasearch";

let client: SearchClient | null;

// singleton
const getAlgoliaClient = (): SearchClient => {
    if (client) {
        return client;
    }

    client = algoliasearch(
        process.env.REACT_APP_ALGOLIA_APP_ID,
        process.env.REACT_APP_ALGOLIA_API_KEY,
    );

    return client;
};

export { getAlgoliaClient };
