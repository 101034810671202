import { createContext, FC, PropsWithChildren, useContext } from "react";

import { Store } from "@/types";
import { useStore } from "@/hooks";

type StoreContextProps = {
    store: Store;
};

const StoreContext = createContext<StoreContextProps | undefined>(undefined);
StoreContext.displayName = "StoreContext";

const StoreProvider: FC<PropsWithChildren> = ({ children }) => {
    const { store } = useStore();

    return (
        <StoreContext.Provider value={{ store }}>
            {children}
        </StoreContext.Provider>
    );
};

const useStoreContext = () => {
    const context = useContext(StoreContext);

    if (!context) {
        throw new Error("useStoreContext must be used withing StoreProvider");
    }

    return context;
};

export { StoreProvider, useStoreContext };
