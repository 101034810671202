import { FC } from "react";
import classNames from "classnames";

import { Icon } from "@/components";

interface IMessageProps {
    type: MessageType;
    text: string;
    closable?: boolean;
    onClose?: () => void;
}

const Message: FC<IMessageProps> = ({
    type = "info",
    text,
    closable,
    onClose,
}) => {
    if (!text) {
        return null;
    }

    return (
        <div className={classNames("message", type)}>
            <div className="message_wrapper">
                <span>{text}</span>
                {closable && (
                    <button className="close_button" onClick={onClose}>
                        <Icon color={getColorByType(type)} icon={"cross"} />
                    </button>
                )}
            </div>
        </div>
    );
};

type MessageType = "info" | "warning" | "success" | "error";

const getColorByType = (type: MessageType) =>
    ({
        success: "#1ea97c",
        error: "#ff5757",
        info: "#696cff",
        warning: "#cc8925",
    }[type]);

export { Message };
