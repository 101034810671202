import { useState, useRef } from "react";

const useFilter = (dataField, type, label, defaultValue) => {
    const [value, setValue] = useState(null);

    const _label = useRef(label);

    const filter = useRef(null);

    return {
        dataField,
        type,
        label: _label,
        filter,
        defaultValue,
        value,
        setValue,
    };
};

export default useFilter;
