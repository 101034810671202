import pluginAvance from "grapesjs-blocks-avance";
import avanceOptions from "../options/avanceOptions";

import pluginTabs from "grapesjs-tabs";
import tabsOptions from "../options/tabsOptions";

import pluginTyped from "grapesjs-typed";
import typedOptions from "../options/typedOptions";

import pluginCkeditor from "grapesjs-plugin-ckeditor";
import ckeditorOptions from "../options/ckeditorOptions";

import pluginImage from "grapesjs-tui-image-editor";
import imageOptions from "../options/imageOptions";

import pluginSlider from "grapesjs-lory-slider";
import sliderOptions from "../options/sliderOptions";

import pluginPresetWebpage from "grapesjs-preset-webpage";
import presetOptions from "../options/presetOptions";

import pluginAssets from "../plugins/grapesjs-gc-assets";
import assetsOptions from "../options/assetsOptions";

import pluginStorage from "../plugins/grapesjs-gc-storage";
import storageOptions from "../options/storageOptions";

import pluginEcommerce from "../plugins/grapesjs-gc-ecommerce";
import ecommerceOptions from "../options/ecommerceOptions";

export default meta => {
    return {
        [pluginAvance]: avanceOptions,
        [pluginTabs]: tabsOptions,
        [pluginTyped]: typedOptions,
        [pluginCkeditor]: ckeditorOptions,
        [pluginImage]: imageOptions,
        [pluginSlider]: sliderOptions,
        [pluginPresetWebpage]: presetOptions,

        [pluginAssets]: assetsOptions(meta),
        [pluginStorage]: storageOptions(meta),
        [pluginEcommerce]: ecommerceOptions(meta),
    };
};
