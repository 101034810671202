import { FC, useState } from "react";
import { Configure, InstantSearch } from "react-instantsearch-hooks-web";
import { getAlgoliaClient } from "algolia";

import { Content } from "@/layout";
import { Portlet, Filters } from "@/components";
import { useMeta } from "@/hooks";
import { useStoreContext } from "context";

import { Kanban } from "./kanban";
import { RefreshButton } from "./components/RefreshButton";

const Orders: FC = () => {
    const algoliaClient = getAlgoliaClient();
    const { store } = useStoreContext();
    const [selectedOptions, setSelectedOptions] = useState({});
    const [restrictedParams, setRestrictedParams] = useState([]);
    const [searchValue, setSearchValue] = useState(null);
    const { user } = useMeta();

    const handleOptionSelect = (filterTitle, selectedOption) => {
        if (selectedOptions[filterTitle] === selectedOption) {
            return setSelectedOptions({
                ...selectedOptions,
                [filterTitle]: null,
            });
        }
        setSelectedOptions({
            ...selectedOptions,
            [filterTitle]: selectedOption,
        });
    };

    return (
        <>
            <Content>
                {store?.algolia_order_index ? (
                    <InstantSearch
                        indexName={store?.algolia_order_index}
                        searchClient={algoliaClient}
                    >
                        <Configure
                            disableTypoToleranceOnAttributes={["order_number"]}
                            filters={`store_id:"${user.storeId}" AND account_id:"${user.accountId}"`}
                            //hitsPerPage={30}
                            restrictSearchableAttributes={
                                searchValue ? restrictedParams : []
                            }
                        />
                        <Portlet
                            className="orders"
                            fit={undefined}
                            fluidHeight={undefined}
                            footer={undefined}
                            header={{
                                title: "Orders",
                                subtitle: "All orders availables in the system",
                            }}
                            sticky
                            toolbar={<RefreshButton />}
                        >
                            <Filters />
                            <Kanban
                                filters={null}
                                handleOptionSelect={handleOptionSelect}
                                selectedOptions={selectedOptions}
                                setRestrictedParams={setRestrictedParams}
                                setSearchValue={setSearchValue}
                            />
                        </Portlet>
                    </InstantSearch>
                ) : null}
            </Content>
        </>
    );
};

export default Orders;
