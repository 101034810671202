import { Icon, Portlet } from "@/components";
import classNames from "classnames";
import { FC } from "react";
import { Col } from "reactstrap";
import { currencyFormat } from "utils/utils";

interface IStatCardProps {
    loading: boolean;
    stat: { total?: number; revenue?: number; percentage: number };
    title: string;
    type: "count" | "currency";
}

const StatCard: FC<IStatCardProps> = ({ loading, stat, title, type }) => {
    return (
        <Portlet className="stat_card">
            <div className="stat_card-container">
                <div className="stat_card-stats">
                    <h3 className="stat_card-title">
                        {title.replaceAll("_", " ")}
                    </h3>
                    {loading ? (
                        <div className="content-placeholder">
                            <div className="cp-w-sm cp-h-sm mt-3 mb-4" />
                            <div className="cp-w-md cp-h-sm" />
                        </div>
                    ) : (
                        <div>
                            <p className="stat_card-main_stat">
                                {type === "count"
                                    ? (stat && stat.total) || 0
                                    : type === "currency"
                                    ? (stat && currencyFormat(stat.revenue)) ||
                                      "$0"
                                    : ""}
                            </p>
                            <p className="stat_card-percent_stat">
                                <span
                                    className={classNames({
                                        "percent_stat-success":
                                            stat && stat.percentage >= 0,
                                        "percent_stat-error":
                                            stat && stat.percentage < 0,
                                    })}
                                >
                                    <Icon color="#3B9415" icon="stats_arrow" />
                                    {(stat && stat.percentage) || 0}%
                                    <span className="stat_desc">
                                        Since last month
                                    </span>
                                </span>
                            </p>
                        </div>
                    )}
                </div>
                <div className="stat_card-icon_badge">
                    <Icon color="#3B9415" icon={`stats_${title}`} />
                </div>
            </div>
        </Portlet>
    );
};

export default StatCard;
