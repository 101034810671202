import { FC, HTMLAttributes } from "react";
import { Input } from "reactstrap";
import MaskedInput from "react-text-mask";

interface IInputProps extends HTMLAttributes<HTMLInputElement> {
    prepend?: any;
    append?: any;
    typeInput?: any;
    highlightWords?: any;
    type?: any;
    defaultValue?: any;
    value?: any;
    invalid?: any;
    bsSize?: any;
    className?: any;
    innerRef?: any;
    onChange?: any;
    placeholder?: any;
    disabled?: any;
    name?: any;
    checked?: any;
    defaultChecked?: any;
    rows?: number;
}

const _Input: FC<IInputProps> = ({
    prepend,
    append,
    typeInput,
    highlightWords,
    ...props
}) => {
    let input = <Input {...props} />;

    if (typeInput === "masked") {
        input = (
            <MaskedInput
                {...props}
                render={(ref, props) => <Input innerRef={ref} {...props} />}
            />
        );
    }

    if (highlightWords) {
        input = (
            <div
                className={`input-highlight-container ${
                    prepend ? "input-highlight-container_prepend" : ""
                } ${append ? "input-highlight-container_append" : ""}`}
            >
                <div
                    className="form-control input-highlight"
                    data-type={props.type === "textarea" ? "textarea" : true}
                >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: (props.defaultValue || props.value)
                                .replace(/\n$/g, "\n\n")
                                .replace(highlightWords, "<mark>$1</mark>"),
                        }}
                    />
                </div>
                <Input
                    data-input
                    onScroll={e => {
                        const highlight =
                            e.target.parentNode.querySelector(
                                ".input-highlight",
                            );

                        highlight.scrollLeft = e.target.scrollLeft;
                        highlight.scrollTop = e.target.scrollTop;
                    }}
                    {...props}
                />
            </div>
        );
    }

    if (prepend || append) {
        return (
            <div className={`input-group ${props.invalid ? "is-invalid" : ""}`}>
                {prepend && (
                    <div className="input-group-prepend">{prepend}</div>
                )}
                {input}
                {append && <div className="input-group-append">{append}</div>}
            </div>
        );
    }

    return input;
};

export default _Input;
