import { Storage } from "aws-amplify";
import moment from "moment";

export default (editor, opts = {}) => {
    const options = {
        loading: "Uploading",
        formats: ["jpg", "jpeg", "png", "gif", "bmp"],
        label: "Add images",
        ...opts,
    };

    Storage.configure({
        bucket: options.bucket,
        region: options.region,
        customPrefix: { public: "" },
    });

    loadAssets(editor, options);
};

const loadAssets = (editor, opts) => {
    Storage.list(opts.prefix)
        .then(res => {
            let urls = res.map(i => ({
                src: `${opts.url}${i.key}`,
                name: i.key.split("/").reverse()[0],
            }));

            urls = urls.filter(u =>
                opts.formats.includes(
                    u.name.toLowerCase().split(".").reverse()[0],
                ),
            );

            editor.AssetManager.add(urls);
        })
        .catch(e => {
            console.error(e);
        })
        .finally(() => {
            loadAsset(editor, opts);
        });
};

const loadAsset = (editor, opts) => {
    editor.on("asset:add", payload => {
        if (payload.get("modified") || !payload.get("name")) return;

        const src = payload.get("src");

        const name = `${moment().unix()}-${payload.get("name").toLowerCase()}`;

        const url = `${opts.prefix}/${name}`;

        const contentType = src.toLowerCase().split(";")[0].split(":")[1];

        let content = atob(src.split(",")[1]);

        let length = content.length;

        const newContent = new Uint8Array(length);

        while (length--) newContent[length] = content.charCodeAt(length);

        content = new File([newContent], name, { type: contentType });

        remove(editor, payload);

        if (!opts.formats.includes(contentType.split("/")[1])) return;

        loading(true, opts);

        Storage.put(url, content, { contentType })
            .then(() => {
                editor.AssetManager.add({
                    src: `${opts.url}${url}`,
                    name,
                    modified: true,
                });
            })
            .catch(e => {
                console.error(e);
            })
            .finally(() => {
                loading(false);
            });
    });
};

const remove = (editor, asset) => {
    editor.AssetManager.remove(asset.id);
};

const loading = (state, opts) => {
    const LOADER_CLASS = "gjs-gc-loader";

    let el = document.getElementsByClassName(LOADER_CLASS)[0];

    if (el && !state) el.remove();

    if (!el && state) {
        el = document.createElement("div");

        el.setAttribute("data-message", opts.loading);

        el.classList.add(LOADER_CLASS);

        document.body.append(el);
    }
};
