import React, { useState } from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { Portlet, Section } from "../../base/components/portlet";
import { FormGroup, FormFeedback, Button, Col, Label, Input } from "reactstrap";
import Form from "../../base/components/form/Form";
import useMeta from "../../base/hooks/useMeta";
import useForm from "../../base/hooks/form/useForm";
import useFormActions from "../../base/hooks/form/useFormActions";
import {
    MUTATION_UPDATE_REDIRECT_APP,
    QUERY_LIST_STORE_APPS,
} from "../../apollo/module-operations/app";
import _ from "lodash";
import useValidator from "../../base/hooks/form/useValidator";
import useNotification from "../../base/hooks/components/useNotification";
import JsonEditor from "../../base/components/form/rich-editor/JsonEditor";
import isJSON from "validator/lib/isJSON";

const RedirectForm = ({ app }) => {
    const { user } = useMeta();

    const { inputs, setInputs, genericChange } = useForm();

    const [free, setFree] = useState(false);

    const forceUpdate = useState(0)[1];

    const notify = useNotification();

    const validate = () => {
        const json = document.getElementsByName("links")[0].value;

        if (isJSON(json)) return true;

        notify("Invalid Redirect structure.", "error");

        return false;
    };

    const switchMode = () => {
        const valid = free ? validate() : true;

        if (!valid) return;

        if (!free)
            document.getElementsByName("links")[0].value = JSON.stringify(
                inputs.links,
                null,
                1,
            );

        setFree(free => !free);
    };

    const changeOnFree = e => {
        const value = e.target.value;

        if (isJSON(value)) {
            genericChange(JSON.parse(value), e.target.name);
        } else {
            forceUpdate(update => update + 1);
        }
    };

    if (!_.get(inputs, "id", null)) {
        setInputs({
            ...app,
            links: JSON.parse(app.links || "[{}]"),
        });
    }

    let options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: {
                ...inputs,
                links: JSON.stringify(inputs.links),
                type: "Redirect",
            },
        },
    };

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "links",
                method: "isJSON",
                validWhen: true,
                args: [],
                message: "Invalid Redirect structure.",
            },
        ],
        {
            ...inputs,
            links: free
                ? document.getElementsByName("links")[0].value
                : JSON.stringify(inputs.links),
        },
    );

    const { createOrUpdateButton } = useFormActions(
        "id",
        "Redirect",
        "",
        MUTATION_UPDATE_REDIRECT_APP,
        [
            {
                query: QUERY_LIST_STORE_APPS,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        "",
        isValid,
        isSubmited,
        "primary",
    );

    return (
        <Portlet
            className="business__settings"
            header={{
                title: "Redirect",
                subtitle: "Redirect Information",
            }}
            sticky
            toolbar={
                <>
                    <Button className="ml-3" color="metal" onClick={switchMode}>
                        <i
                            className={`fas fa-${
                                !free ? "pen" : "project-diagram"
                            }`}
                        />
                        {!free ? "Free" : "Visual"} mode
                    </Button>
                    {createOrUpdateButton}
                </>
            }
        >
            {!user.loading ? (
                <Form>
                    <Section>
                        <FormGroup row>
                            <Col>
                                <Label>Redirect rule</Label>
                                {!free && (
                                    <JsonEditor
                                        onChange={json => {
                                            genericChange(json, "links");
                                        }}
                                        rootName="links"
                                        value={inputs.links ? inputs.links : {}}
                                    />
                                )}
                                <Input
                                    className={!free ? "d-none" : ""}
                                    invalid={validator.links.isInvalid}
                                    name="links"
                                    onChange={changeOnFree}
                                    rows={40}
                                    type="textarea"
                                />
                                <FormFeedback>
                                    {validator.links.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                    </Section>
                </Form>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
        </Portlet>
    );
};

export default RedirectForm;
