import gql from "graphql-tag";

let Promotion = {};
Promotion.fragments = {
    attributes: gql`
        fragment PromotionFragment on Promotion {
            id
            name
            label
            never_expire
            start_date
            expiration_date
            priority
            broadcast
            broadcast_type
            broadcast_color
            discountType
            discount_rate_type
            rate
            discount_apply_type
            promo_code
            minimum_order_required
            minimum_order_amount
            maximum_order_required
            maximum_order_amount
            products_id
            blaze_id
            status
            first_time_patient
        }
    `,
};

export const QUERY_PROMOTION_LIST = gql`
    query listPromotions($account_id: String!, $store_id: String!) {
        listPromotions(account_id: $account_id, store_id: $store_id) {
            ...PromotionFragment
        }
    }
    ${Promotion.fragments.attributes}
`;

export const QUERY_GET_PROMOTION = gql`
    query getPromotion(
        $account_id: String!
        $promotion_id: String!
        $store_id: String!
    ) {
        getPromotion(
            account_id: $account_id
            promotion_id: $promotion_id
            store_id: $store_id
        ) {
            ...PromotionFragment
        }
    }
    ${Promotion.fragments.attributes}
`;

export const MUTATION_CREATE_PROMOTION = gql`
    mutation createPromotion(
        $account_id: String!
        $store_id: String!
        $input: PromotionInput!
    ) {
        createPromotion(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            ...PromotionFragment
        }
    }
    ${Promotion.fragments.attributes}
`;

export const MUTATION_UPDATE_PROMOTION = gql`
    mutation updatePromotion(
        $account_id: String!
        $store_id: String!
        $input: PromotionInput!
    ) {
        updatePromotion(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            ...PromotionFragment
        }
    }
    ${Promotion.fragments.attributes}
`;

export const MUTATION_REMOVE_PROMOTION = gql`
    mutation deletePromotion(
        $account_id: String!
        $store_id: String!
        $id: String!
    ) {
        deletePromotion(account_id: $account_id, store_id: $store_id, id: $id)
    }
`;
