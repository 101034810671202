import React from "react";
import Content from "../../base/layout/content/Content";

const E404 = () => {
    return (
        <Content>
            <div
                style={{
                    position: "relative",
                    top: "calc(50% - 28px)",
                    textAlign: "center",
                }}
            >
                <h3>
                    <i className="fas fa-cogs mr-3" />
                    404:
                </h3>
                Page not found.
            </div>
        </Content>
    );
};

export default E404;
