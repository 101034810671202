import {
    Dispatch,
    SetStateAction,
    FC,
    useMemo,
    useEffect,
    useState,
} from "react";
import { SearchBox, useSearchBox } from "react-instantsearch-hooks-web";
import { debounce } from "lodash";
import { Row } from "reactstrap";

interface ICustomSearchBoxProps {
    initialQuery: string;
    setInitialQuery: Dispatch<SetStateAction<string>>;
}

const CustomSearchBox: FC<ICustomSearchBoxProps> = ({
    initialQuery,
    setInitialQuery,
}) => {
    const { refine } = useSearchBox();

    const debouncedQueryHook = useMemo(
        () =>
            debounce(query => {
                setInitialQuery("");
                refine(query);
            }, 300),
        [],
    );

    useEffect(() => {
        refine(initialQuery);
    }, [initialQuery]);

    return (
        <div className="k-input-icon k-input-icon--left search-form">
            <SearchBox
                classNames={{ root: "order__search" }}
                placeholder="Search by customer email"
                queryHook={debouncedQueryHook}
            />
            <span className="k-input-icon__icon k-input-icon__icon--left">
                <span>
                    <i className="fas fa-search" />
                </span>
            </span>
        </div>
    );
};

const SearchBar = () => {
    const [query, setQuery] = useState("");

    return (
        <Row className="mb-4 search__content">
            <CustomSearchBox initialQuery={query} setInitialQuery={setQuery} />
        </Row>
    );
};

export default SearchBar;
