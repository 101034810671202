import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import {
    Button,
    ButtonDropdown,
    DropdownToggle,
    //  DropdownMenu,
    //  DropdownItem,
} from "reactstrap";
import { CSVLink } from "react-csv";
import moment from "moment";
import _ from "lodash";

import { QUERY_CUSTOMER_LIST } from "../../apollo/module-operations/customer";
import routes from "../../config/routes/routes";

import { headers_customers } from "../../config/constant";

import { Content } from "@/layout";
import { useTableActions, useMeta, useRowActions } from "@/hooks";
import { Portlet, Table, Spinner, Icon, Badge, Input } from "@/components";

const FilterOptions = [
    { value: "email", label: "Email" },
    /*   { value: "first_name", label: "First Name" },
    { value: "last_name", label: "Last Name" }, */
];

const Customers = () => {
    const { user } = useMeta();
    const [key, setKey] = useState(true);
    const [fetchingMore, setFetchingMore] = useState(false);
    const [selection, setSelection] = useState([]);
    const [searchValue, setSearchValue] = useState(null);
    const [filterToggle, setFilterToggle] = useState(false);
    const [currentFilter, setCurrentFilter] = useState({
        value: "email",
        label: "Email",
    });

    function handleFilterToggle() {
        setFilterToggle(filterToggle => !filterToggle);
    }

    const handleSearch = () => {
        listAccounts(
            searchValue && {
                variables: {
                    account_filter: {
                        [`${currentFilter.value}`]: searchValue,
                    },
                },
            },
        );
    };

    const [
        listAccounts,
        {
            loading: loadingaccounts,
            data: accounts_data,
            networkStatus,
            refetch,
            fetchMore,
        },
    ] = useLazyQuery(QUERY_CUSTOMER_LIST, {
        notifyOnNetworkStatusChange: true,
        skip: user.loading,
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            /*  account_filter: {
                [`${currentFilter.value}`]: searchValue,
            }, */
        },
    });

    const { update } = useRowActions(
        [
            {
                query: QUERY_CUSTOMER_LIST,
                skip: user.loading,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        "",
        routes.CUSTOMER_DETAIL.route,
        "Customers",
    );

    useEffect(() => {
        !user.loading && listAccounts();
    }, [user.loading, searchValue]);

    const next = cursor => {
        setFetchingMore(true);

        fetchMore({
            query: QUERY_CUSTOMER_LIST,
            variables: searchValue
                ? {
                      cursor: cursor,
                      account_id: user.accountId,
                      store_id: user.storeId,
                      account_filter: { email: searchValue },
                  }
                : {
                      cursor: cursor,
                      account_id: user.accountId,
                      store_id: user.storeId,
                  },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                const previousEntry = previousResult.listAccounts;
                const newCustomers = fetchMoreResult.listAccounts.accounts;
                const newCursor = fetchMoreResult.listAccounts.cursor;

                setFetchingMore(false);
                return {
                    listAccounts: {
                        cursor: newCursor,
                        accounts: [...previousEntry.accounts, ...newCustomers],
                        __typename: previousEntry.__typename,
                    },
                };
            },
        });
    };

    const { refreshButton } = useTableActions(refetch, "");

    const columns = [
        {
            dataField: "entity_id",
            text: "Key",
            hidden: true,
        },
        {
            dataField: "entity_id",
            text: "entity_id",
            hidden: true,
        },
        {
            dataField: "email",
            text: "Customer",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "25%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                let first = "";
                if (row) {
                    first = `${row.first_name ?? ""} ${row.last_name ?? ""}`;
                }

                return (
                    <>
                        <div className="costumers__table_row_primary">
                            {first}
                        </div>
                        <div className="costumers__table_row_secondary">
                            {row.email}
                        </div>
                    </>
                );
            },
        },
        {
            dataField: "phone",
            text: "Phone",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "10%" };
            },
        },
        {
            dataField: "address1",
            text: "Address",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "20%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <>
                        <div className="costumers__table_row_primary">
                            {row.address1} {row.address2}
                        </div>
                        <div className="costumers__table_row_secondary">
                            {row.city && `${row.city} ,`} {row.zip}
                        </div>
                    </>
                );
            },
        },
        {
            dataField: "accept_marketing",
            text: "Opt In",
            headerStyle: (colum, colIndex) => {
                return { width: "10%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Badge
                        className={
                            row.has_account ? "status__info" : "status__metalic"
                        }
                        label={row.has_account ? "Yes" : "No"}
                    />
                );
            },
            sort: true,
        },
        {
            dataField: "has_account",
            text: "Have Account",
            headerStyle: (colum, colIndex) => {
                return { width: "10%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Badge
                        className={
                            row.has_account ? "status__info" : "status__metalic"
                        }
                        label={row.has_account ? "Active" : "Inactive"}
                    />
                );
            },
            sort: true,
        },
        {
            dataField: "created_at",
            text: "Created At",
            headerStyle: (colum, colIndex) => {
                return { width: "10%" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                let date = cell ? moment(cell).format("MMMM Do YYYY") : "";
                let time = cell ? moment(cell).format("HH:mm:ss") : "";
                return (
                    <>
                        <div className="costumers__table_row_primary">
                            {date}
                        </div>
                        <div className="costumers__table_row_secondary">
                            {time}
                        </div>
                    </>
                );
            },
        },
    ];

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            update(row.entity_id);
        },
    };

    const selectRow = {
        mode: "checkbox",
        classes: "table-row--selected",
        clickToSelect: false,
        onSelect: (row, isSelect, rowIndex, e) => {
            setKey(!key);

            if (isSelect) {
                setSelection([...selection, row.entity_id]);
                return;
            }

            setSelection(selection.filter(s => s !== row.entity_id));
        },
        onSelectAll: (isSelect, rows, e) => {
            setKey(!key);

            if (isSelect) {
                setSelection(rows.map(r => r.entity_id));
                return;
            }

            setSelection([]);
        },
        selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
            <label className="k-checkbox k-checkbox--brand">
                <Input type="checkbox" {...rest} readOnly />
                <span />
            </label>
        ),
        selectionRenderer: ({ mode, rowIndex, ...rest }) => (
            <label className="k-checkbox k-checkbox--brand">
                <Input type={mode} {...rest} readOnly />
                <span />
            </label>
        ),
    };

    const actions = [
        {
            label: "Edit",
            action: (cell, row, rowIndex, formatExtraData) => {
                update(row.entity_id);
            },
        },
    ];

    const _loading = (loadingaccounts || networkStatus === 4) && !fetchingMore;
    const _customers =
        (accounts_data && accounts_data.listAccounts.accounts) || [];
    const _cursor = accounts_data && accounts_data.listAccounts.cursor;

    const getCustomersToExport = () => {
        let data = [];
        let selectedCustomers = [];

        if (!selection.length) {
            return [];
        }

        selectedCustomers = _.filter(_customers, o =>
            _.includes(selection, o.entity_id),
        );

        _.forEach(selectedCustomers, o => {
            o.created_at = moment(o.created_at).format("MM/DD/YYYY HH:mm");
            o.update_at = moment(o.update_at).format("MM/DD/YYYY HH:mm");

            data.push({
                email: o.email,
                first_name: `${o.first_name} ${o.last_name}`,
                created_at: o.created_at,
                update_at: o.update_at,
            });
        });
        return data;
    };

    const exportBtn = (
        <CSVLink
            className="btn btn-primary"
            data={getCustomersToExport()}
            filename={"customers.csv"}
            headers={headers_customers}
            key={key}
        >
            <span className="mr-2">
                <Icon icon="export" />
            </span>
            Export
        </CSVLink>
    );

    return (
        <>
            <Content>
                <Portlet
                    className="customers__content"
                    header={{
                        title: "Customers",
                        subtitle: "All customers availables in the system",
                    }}
                    sticky
                    toolbar={[refreshButton, exportBtn]}
                >
                    <div className="k-portlet__head-toolbar mb-4">
                        <div className="k-input-icon">
                            <ButtonDropdown
                                isOpen={filterToggle}
                                toggle={handleFilterToggle}
                            >
                                <DropdownToggle caret>
                                    {currentFilter.label}
                                </DropdownToggle>
                                {/*  <DropdownMenu>
                                    {FilterOptions.map((f, index) => (
                                        <DropdownItem
                                            key={index}
                                            onClick={() => {
                                                setCurrentFilter(f);
                                                setSearchValue("");
                                            }}
                                        >
                                            {f.label}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu> */}
                            </ButtonDropdown>
                            {/*  <span className="k-input-icon__icon k-input-icon__icon--left">
                                <span>
                                    <Icon icon="search" />
                                </span>
                            </span> */}
                            <Input
                                bsSize="sm"
                                name="search"
                                onChange={e => {
                                    setSearchValue(e.target.value);
                                }}
                                placeholder={`Search by customer ${currentFilter.label}`}
                                value={searchValue}
                            />
                            <Button
                                className="ml-2"
                                color="metal"
                                onClick={() => {
                                    handleSearch();
                                }}
                            >
                                <span className="mr-2">
                                    <Icon icon={"search"} />
                                </span>
                                Search
                            </Button>
                        </div>
                    </div>
                    <Table
                        actions={actions}
                        className="table table-hover"
                        columns={columns}
                        data={_customers}
                        keyField="entity_id"
                        loading={_loading}
                        remote={{ filter: true }}
                        rowEvents={rowEvents}
                        selectRow={selectRow}
                    />
                    {_customers.length > 1 ? (
                        <Button
                            className="mt-3 float-right"
                            color="metal"
                            disabled={!_cursor}
                            onClick={() => next(_cursor)}
                        >
                            <Spinner show={fetchingMore} sm />
                            {!fetchingMore && <Icon icon={"refresh"} />}
                            Show more
                        </Button>
                    ) : null}
                </Portlet>
            </Content>
        </>
    );
};

export default Customers;
