import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Badge, Portlet, Icon, Input, Table } from "@/components";
import { CSVLink } from "react-csv";
import moment from "moment";
import useMeta from "../../base/hooks/useMeta.js";
import { QUERY_USER_LIST } from "../../apollo/module-operations/user";
import { Button } from "reactstrap";
import UserFormModal from "./UserFormModal";
import paginationFactory from "react-bootstrap-table2-paginator";
import { paginationOptions } from "../../config/components/table";
import _ from "lodash";

import { headers_users } from "../../config/constant";

const StoreUsers = () => {
    const [key, setKey] = useState(true);
    const [selection, setSelection] = useState([]);
    const { user } = useMeta();
    const [userId, setUserId] = useState(null);
    const [showUserForm, setShowUserForm] = useState(false);

    const {
        data: stores,
        loading: storeLoading,
        networkStatus,
    } = useQuery(QUERY_USER_LIST, {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
        },
        skip: user.loading,
    });

    const columns = [
        {
            dataField: "id",
            text: "Id",
            hidden: true,
        },
        {
            dataField: "first_name",
            text: "Name",
            headerStyle: (colum, colIndex) => {
                return { width: "30%" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <div>{`${row.first_name || ""} ${
                        row.last_name || ""
                    }`}</div>
                );
            },
        },
        {
            dataField: "email",
            text: "Email",
            headerStyle: (colum, colIndex) => {
                return { width: "30%" };
            },
        },
        {
            dataField: "enabled",
            text: "Status",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "20%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Badge
                        className={
                            row.enabled ? "status__success" : "status__error"
                        }
                        label={row.enabled ? "Active" : "No Active"}
                    />
                );
            },
        },
    ];

    const selectRow = {
        mode: "checkbox",
        classes: "table-row--selected",
        clickToSelect: false,
        onSelect: (row, isSelect, rowIndex, e) => {
            setKey(!key);

            if (isSelect) {
                setSelection([...selection, row.entity_id]);
                return;
            }

            setSelection(selection.filter(s => s !== row.entity_id));
        },
        onSelectAll: (isSelect, rows, e) => {
            setKey(!key);

            if (isSelect) {
                setSelection(rows.map(r => r.entity_id));
                return;
            }

            setSelection([]);
        },
        selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
            <label className="k-checkbox k-checkbox--brand">
                <Input type="checkbox" {...rest} readOnly />
                <span />
            </label>
        ),
        selectionRenderer: ({ mode, rowIndex, ...rest }) => (
            <label className="k-checkbox k-checkbox--brand">
                <Input type={mode} {...rest} readOnly />
                <span />
            </label>
        ),
    };

    const actions = [
        {
            label: "Update",
            icon: "fas fa-pencil-alt",
            action: async (cell, row, rowIndex, formatExtraData) => {
                setUserId(row.id);
                setShowUserForm(true);
            },
        },
    ];

    const createUser = () => {
        setUserId(null);
        setShowUserForm(true);
    };

    const _storeLoading = storeLoading || networkStatus === 4;

    const _users = stores && stores.listUsers;

    const getUserToExport = () => {
        let data = [];
        let selectedUsers = [];

        if (!selection.length) {
            return [];
        }

        selectedUsers = _.filter(_users, o =>
            _.includes(selection, o.entity_id),
        );

        _.forEach(selectedUsers, (user, index) => {
            const created_at = moment(user.created_at).format(
                "MM/DD/YYYY HH:mm",
            );
            const update_at = moment(user.update_at).format("MM/DD/YYYY HH:mm");

            data.push({
                id: index + 1,
                email: user.email,
                first_name: `${user.first_name} ${user.last_name}`,
                active: user.enabled ? "Active" : "Disabled",
                role: user.role || "No data",
                status: user.status || "No data",
                created_at: created_at,
                update_at: update_at,
            });
        });
        return data;
    };

    const exportBtn = (
        <CSVLink
            className="btn btn-primary"
            data={getUserToExport()}
            filename={`users_${new Date().toISOString()}.csv`}
            headers={headers_users}
            key={key}
        >
            <span className="mr-2">
                <Icon icon="export" />
            </span>
            Export
        </CSVLink>
    );

    const createUserBtn = (
        <Button
            className="float-right ml-2"
            color="primary"
            onClick={createUser}
        >
            <i className="fas fa-plus" />
            Create User
        </Button>
    );

    return (
        <>
            <Portlet
                header={{
                    title: "Users",
                    subtitle: "All Business Users",
                }}
                sticky
                toolbar={[exportBtn, createUserBtn]}
            >
                <Table
                    actions={actions}
                    columns={columns}
                    data={_users}
                    keyField="id"
                    loading={_storeLoading}
                    pagination={paginationFactory(paginationOptions)}
                    selectRow={selectRow}
                />

                <UserFormModal
                    isOpen={showUserForm}
                    storeId={user.storeId}
                    toggle={setShowUserForm}
                    userId={userId}
                />
            </Portlet>
        </>
    );
};

export default StoreUsers;
