import tuiIconA from "../assets/tui-icon-a.svg";
import tuiIconB from "../assets/tui-icon-b.svg";
import tuiIconC from "../assets/tui-icon-c.svg";
import tuiIconD from "../assets/tui-icon-d.svg";

export default {
    // config: {
    //     includeUI: {// initMenu: "filter"}
    // },
    icons: {
        "menu.normalIcon.path": tuiIconD,
        "menu.activeIcon.path": tuiIconB,
        "menu.disabledIcon.path": tuiIconA,
        "menu.hoverIcon.path": tuiIconC,
        "submenu.normalIcon.path": tuiIconD,
        "submenu.activeIcon.path": tuiIconC,
    },
};
