import React from "react";
import { Link, useLocation, matchPath } from "react-router-dom";
import classNames from "classnames";
import { Icon } from "@/components";

const AsideItem = ({ label, icon, route }) => {
    const location = useLocation();

    const match = matchPath(location.pathname, {
        path: route,
        exact: true,
    });

    return (
        <li
            className={classNames("k-menu__item", {
                "k-menu__item--active": match,
            })}
        >
            <Link className="k-menu__link" to={route}>
                {icon ? (
                    /* <i className={`k-menu__link-icon ${icon}`} /> */
                    <i className={`k-menu__link-icon`}>
                        <Icon icon={icon} />
                    </i>
                ) : (
                    <i className="k-menu__link-bullet k-menu__link-bullet--dot">
                        <span />
                    </i>
                )}
                <span className="k-menu__link-text">{label}</span>
            </Link>
        </li>
    );
};

export default AsideItem;
