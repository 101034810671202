import gql from "graphql-tag";

let Brand = {};
Brand.fragments = {
    attributes: gql`
        fragment BrandFragment on Brand {
            id
            name
            phone_number
            asset {
                image
            }
            website
            vendors
            enabled
            created_at
            updated_at
        }
    `,
};

export const QUERY_BRAND_LIST = gql`
    query listBrands($account_id: String!, $store_id: String!) {
        listBrands(account_id: $account_id, store_id: $store_id) {
            ...BrandFragment
        }
    }
    ${Brand.fragments.attributes}
`;

export const QUERY_GET_BRAND = gql`
    query getBrand(
        $account_id: String!
        $brand_id: String!
        $store_id: String!
    ) {
        getBrand(
            account_id: $account_id
            brand_id: $brand_id
            store_id: $store_id
        ) {
            ...BrandFragment
        }
    }
    ${Brand.fragments.attributes}
`;

export const MUTATION_UPDATE_BRAND = gql`
    mutation updateBrand(
        $account_id: String!
        $store_id: String!
        $input: BrandInput!
    ) {
        updateBrand(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            ...BrandFragment
        }
    }
    ${Brand.fragments.attributes}
`;
