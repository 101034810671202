export const modules = {
    ACCOUNTS: "ACCOUNTS",
    APPS: "APPS",
    SYSTEMAPPS: "SYSTEMAPPS",
    ADMIN_SETTINGS: "ADMIN_SETTINGS",
};

export const operation = {
    CREATE: "create",
    READ: "read",
    UPDATE: "update",
    DELETE: "delete",
};

export const roles = {
    SUPER_ADMIN: "SUPER_ADMIN",
    ADMIN: "ADMIN",
    USER: "USER",
};

export const permission = {
    [modules.ACCOUNTS]: {
        [operation.CREATE]: [],
        [operation.READ]: [roles.SUPER_ADMIN],
        [operation.UPDATE]: [],
        [operation.DELETE]: [],
    },
    [modules.ADMIN_SETTINGS]: {
        [operation.CREATE]: [],
        [operation.READ]: [roles.SUPER_ADMIN],
        [operation.UPDATE]: [],
        [operation.DELETE]: [],
    },
    [modules.SYSTEMAPPS]: {
        [operation.CREATE]: [],
        [operation.READ]: [roles.SUPER_ADMIN, roles.ADMIN],
        [operation.UPDATE]: [],
        [operation.DELETE]: [],
    },
    [modules.APPS]: {
        [operation.CREATE]: [],
        [operation.READ]: [roles.SUPER_ADMIN],
        [operation.UPDATE]: [],
        [operation.DELETE]: [],
    },
};

// If you want to Allow everybody for reading:
// read: undefined | null | false,

// If you want to Disallow everybody for reading:
// read: [],

// If you want to Allow only Users for reading:
// read: ["User"],
