import { useRef } from "react";
import { useMutation } from "@apollo/react-hooks";

import { MUTATION_CHECK_ORDER_LINE_ITEMS_BARCODES } from "apollo/module-operations/order";
import { useMeta } from "@/hooks";

interface Props {
    onCompleted?: (itemId: number) => void;
    onError?: (itemId: number, errorMessage: string) => void;
}

const useCheckBarcodes = ({ onCompleted, onError }: Props) => {
    const { user } = useMeta();
    const checkedLineItemIndex = useRef<number>();

    const [checkOrderLineItemsBarCodes, { loading }] = useMutation(
        MUTATION_CHECK_ORDER_LINE_ITEMS_BARCODES,
        {
            onCompleted: () => {
                onCompleted?.(checkedLineItemIndex.current);
            },
            onError: error => {
                onError?.(checkedLineItemIndex.current, error.message);
            },
        },
    );

    const checkLineItemBarcodes = (
        orderId: string,
        lineItemId: string,
        lineItemIndex: number,
        barcodes: string[],
        weight: string,
    ) => {
        checkedLineItemIndex.current = lineItemIndex;
        checkOrderLineItemsBarCodes({
            variables: {
                account_id: user?.accountId,
                store_id: user?.storeId,
                input: {
                    id: orderId,
                    line_items: [
                        { id: lineItemId, bar_codes: barcodes, weight: weight },
                    ],
                },
            },
        });
    };

    return { checkLineItemBarcodes, isChecking: loading };
};

export { useCheckBarcodes };
