import React, { useState, useEffect } from "react";
import Content from "../../base/layout/content/Content";
import { useQuery, useMutation } from "@apollo/react-hooks";
import useMeta from "../../base/hooks/useMeta.js";
import Navigation from "../../base/layout/content/Navigation";
import _ from "lodash";
import { Portlet } from "../../base/components/portlet";
import Spinner from "../../base/components/spinner/Spinner";
import { Button } from "reactstrap";
import { QUERY_GET_STORE } from "../../apollo/module-operations/store";
import { QUERY_GET_ORDER } from "../../apollo/module-operations/order";
import { printSlip } from "./print";
import useNotification from "../../base/hooks/components/useNotification";
import {
    QUERY_SHIPMENT_DETAILS,
    MUTATION_CREATE_SHIPMENT_LABEL,
} from "../../apollo/module-operations/shipping";

const STEPS_KEYS = {
    SHIPPING_LABEL: "SHIPPING_LABEL",
    PACKAGE_SLIPS: "PACKAGE_SLIPS",
};

const OrderFulfillment = () => {
    const { resourceId, user } = useMeta();
    const [loading, setLoading] = useState(true);
    const [activeSection, setActiveSection] = useState(null);

    const notify = useNotification();

    const variables = {
        account_id: user.accountId,
        store_id: user.storeId,
    };

    const { data: dataStore, loading: loadingStore } = useQuery(
        QUERY_GET_STORE,
        {
            variables,
            skip: user.loading,
        },
    );

    const store = _.get(dataStore, "getStore") || {};

    const { data, loading: loadingOrder } = useQuery(QUERY_GET_ORDER, {
        variables: { entity_id: resourceId },
        skip: user.loading,
    });

    const order = _.get(data, "getOrder") || {};

    const shipmentDetailVariables = {
        ...variables,
        shipment_detail_id: order.shipment_detail_id,
        order_id: resourceId,
    };

    const { data: shipmentDetail, loading: loadingShipment } = useQuery(
        QUERY_SHIPMENT_DETAILS,
        {
            skip: user.loading || loadingOrder,
            variables: shipmentDetailVariables,
        },
    );

    const shipment = shipmentDetail ? shipmentDetail.shipmentDetail : {};
    const hideShippingLabel = [
        "Flat rate",
        "Free",
        "Local delivery",
        "Local pickup",
    ].includes(shipment.provider); //Ensures hide until all the data is ready.

    useEffect(() => {
        switch (activeSection) {
            case STEPS_KEYS.SHIPPING_LABEL:
                shippingLabelStep();
                break;

            case STEPS_KEYS.PACKAGE_SLIPS:
                packageSlipsStep();
                break;

            default:
                break;
        }

        // eslint-disable-next-line
    }, [activeSection]);

    useEffect(() => {
        !loadingStore &&
            !loadingOrder &&
            !loadingShipment &&
            setActiveSection(
                STEPS_KEYS[
                    hideShippingLabel ? "PACKAGE_SLIPS" : "SHIPPING_LABEL"
                ],
            );

        // eslint-disable-next-line
    }, [loadingStore, loadingOrder, loadingShipment, hideShippingLabel]);

    // Shipping label step
    const [createShipmentLabel] = useMutation(MUTATION_CREATE_SHIPMENT_LABEL, {
        variables: { ...variables, order_id: resourceId },
        refetchQueries: [
            {
                query: QUERY_SHIPMENT_DETAILS,
                variables: shipmentDetailVariables,
            },
        ],
        onCompleted: res => {
            !res.status &&
                notify("Unable to create the shipping label.", "error");

            res.status &&
                notify("Shipping label created successfully.", "success");

            setCreatingLabel(false);
        },
    });

    const [creatingLabel, setCreatingLabel] = useState(false);

    const onCreateShipmentLabel = () => {
        setCreatingLabel(true);

        createShipmentLabel();
    };

    const shippingLabelStep = () => setLoading(false);

    // Package slips step
    const [info, setInfo] = useState({});

    const packageSlipsStep = () => {
        if (_.get(info, "packageSlips.src")) return;

        const _order = _.cloneDeep(order);

        _order.shippingPackages = _.map(order.line_items, p => ({
            id: p.id,
            title: p.name,
            qtyInPackage: p.quantity,
        }));

        const pdf = printSlip([_order], store);

        pdf.getDataUrl(src => {
            setInfo(info => ({ ...info, packageSlips: { src } }));
        });
    };

    const iframeProps = {
        frameBorder: "0",
        marginHeight: "0",
        marginWidth: "0",
        style: {
            width: "100%",
            height: "900px",
        },
    };

    const loadingSpinner = (
        <>
            <Spinner color="metal" show sm />
            &nbsp;&nbsp;Loading
        </>
    );

    return (
        <Content>
            <Navigation
                activeKey={activeSection}
                items={[
                    ...(hideShippingLabel
                        ? []
                        : [
                              {
                                  key: STEPS_KEYS.SHIPPING_LABEL,
                                  label: "Shipping label",
                                  icon: "fas fa-file-alt",
                                  render: (
                                      <Portlet
                                          fit={!loading && shipment.label}
                                          header={{ title: "Shipping label" }}
                                          sticky
                                      >
                                          {/* toolbar={
                                                    <Button
                                                        color="metal"
                                                        onClick={() =>
                                                            setActiveSection(
                                                                STEPS_KEYS.PACKAGE_SLIPS,
                                                            )
                                                        }
                                                    >
                                                        <i className="fas fa-angle-right" />
                                                        Next
                                                    </Button>
                                                    }
                                                }
                                            */}
                                          {!loading && !!shipmentDetail ? (
                                              !shipment.label ? (
                                                  <Button
                                                      color="metal"
                                                      disabled={creatingLabel}
                                                      onClick={
                                                          onCreateShipmentLabel
                                                      }
                                                  >
                                                      {creatingLabel ? (
                                                          <Spinner
                                                              color="dark"
                                                              show
                                                              sm
                                                          />
                                                      ) : (
                                                          <i className="fas fa-file-alt" />
                                                      )}
                                                      Create shipping label
                                                  </Button>
                                              ) : (
                                                  <iframe
                                                      src={shipment.label}
                                                      title="Shipping label"
                                                      {...iframeProps}
                                                  />
                                              )
                                          ) : (
                                              loadingSpinner
                                          )}
                                      </Portlet>
                                  ),
                              },
                          ]),
                    {
                        key: STEPS_KEYS.PACKAGE_SLIPS,
                        label: "Package slips",
                        icon: "fas fa-file-alt",
                        render: (
                            <Portlet
                                fit={
                                    !loading && _.get(info, "packageSlips.src")
                                }
                                header={{ title: "Package slips" }}
                                sticky
                            >
                                {/*
                                toolbar={
                                    <Link
                                        to={_.replace(
                                            routes.ORDER_DETAIL.route,
                                            ":id",
                                            resourceId,
                                        )}
                                        className="btn btn-metal"
                                    >
                                        <i className="fas fa-angle-right" />
                                        Finish
                                    </Link>
                                }
                                */}
                                {!loading && _.get(info, "packageSlips.src") ? (
                                    <iframe
                                        src={_.get(
                                            info,
                                            "packageSlips.src",
                                            "/",
                                        )}
                                        title="Package slips"
                                        {...iframeProps}
                                    />
                                ) : (
                                    loadingSpinner
                                )}
                            </Portlet>
                        ),
                    },
                ]}
                setActiveKey={setActiveSection}
            />
        </Content>
    );
};

export default OrderFulfillment;
