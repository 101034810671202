import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Col, Row, FormFeedback, FormGroup, Label, Button } from "reactstrap";
import { useForm, useValidator } from "@/hooks";
import {
    Form,
    Input,
    Section,
    SectionTitle,
    Select,
    Spinner,
} from "@/components";

const SwifterCredentialForm = ({
    isCreate,
    handleCancel,
    app,
    stores,
    selectCredential,
    setCredentials,
    isUpdating,
    handlerUpdatingOrCreate,
}) => {
    const [isFormReset, resetFormInputs] = useState(isCreate);
    const { inputs, inputChange, setInputs, selectChange, selectValue } =
        useForm();

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "swifter_store_provider_id",
                method: "isEmpty",
                validWhen: false,
                message: "This Store provider id is required.",
            },
            {
                field: "swifter_client_id",
                method: "isEmpty",
                validWhen: false,
                message: "This Client Id is required.",
            },
            {
                field: "swifter_client_secret",
                method: "isEmpty",
                validWhen: false,
                message: "This Client Secret is required.",
            },
            {
                field: "swifter_auth_host",
                method: "isEmpty",
                validWhen: false,
                message: "This Auth Host is required.",
            },
            {
                field: "swifter_api_host",
                method: "isEmpty",
                validWhen: false,
                message: "This Api Host is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    useEffect(() => {
        if (!isCreate) {
            const objSelect = app?.credentials[selectCredential];
            setInputs({
                swifter_sandbox: objSelect?.swifter_sandbox,
                swifter_store_provider_id: objSelect?.swifter_store_provider_id,
                swifter_client_id: objSelect?.swifter_client_id,
                swifter_client_secret: objSelect?.swifter_client_secret,
                swifter_auth_host: objSelect?.swifter_auth_host,
                swifter_api_host: objSelect?.swifter_api_host,
            });
        }
    }, []);

    if (!isCreate && !isFormReset) {
        resetFormInputs(true);
    }

    const createOrUpdate = async () => {
        if (isSubmited) {
            isSubmited(true);
        }

        if (isValid()) {
            let accounts = _.cloneDeep(app.credentials ?? []);

            if (isCreate) {
                accounts.push({
                    swifter_sandbox: inputs?.swifter_sandbox,
                    swifter_store_provider_id:
                        inputs?.swifter_store_provider_id,
                    swifter_client_id: inputs?.swifter_client_id,
                    swifter_client_secret: inputs?.swifter_client_secret,
                    swifter_auth_host: inputs?.swifter_auth_host,
                    swifter_api_host: inputs?.swifter_api_host,
                });
            } else {
                accounts[selectCredential] = inputs;
            }
            setCredentials(accounts);
            handlerUpdatingOrCreate(accounts);
        }
    };

    const handleClose = () => {
        handleCancel();
        resetFormInputs(false);
    };

    return (
        <Form>
            <Section className="p-4 bg-light">
                <Row>
                    <Col>
                        <SectionTitle md>
                            {isCreate ? "Add Credential" : "Update Credential"}
                        </SectionTitle>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <SectionTitle md>Credential Informations</SectionTitle>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col lg={4} md={6}>
                        <FormGroup row>
                            <Label className="row-label mr-3 pl-0">
                                Sandbox
                            </Label>

                            <span className="k-switch k-switch--md k-switch--icon">
                                <label>
                                    <Input
                                        checked={
                                            inputs.swifter_sandbox
                                                ? "checked"
                                                : ""
                                        }
                                        name="swifter_sandbox"
                                        onChange={inputChange}
                                        type="checkbox"
                                    />
                                    <span />
                                </label>
                            </span>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Store Provider Id</Label>
                            <Select
                                controlName="swifter_store_provider_id"
                                invalid={
                                    validator.swifter_store_provider_id
                                        .isInvalid
                                }
                                onChange={selectChange}
                                options={stores}
                                value={selectValue(
                                    inputs.swifter_store_provider_id,
                                    stores,
                                )}
                            />
                            <FormFeedback>
                                {validator.swifter_store_provider_id.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Client id</Label>
                            <Input
                                invalid={validator.swifter_client_id.isInvalid}
                                name="swifter_client_id"
                                onChange={inputChange}
                                placeholder="Client Id"
                                value={inputs.swifter_client_id}
                            />
                            <FormFeedback>
                                {validator.swifter_client_id.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Client secret</Label>
                            <Input
                                invalid={
                                    validator.swifter_client_secret.isInvalid
                                }
                                name="swifter_client_secret"
                                onChange={inputChange}
                                placeholder="Client Secret"
                                value={inputs.swifter_client_secret}
                            />
                            <FormFeedback>
                                {validator.swifter_client_secret.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Api host</Label>
                            <Input
                                invalid={validator.swifter_api_host.isInvalid}
                                name="swifter_api_host"
                                onChange={inputChange}
                                placeholder="Api Host"
                                value={inputs.swifter_api_host}
                            />
                            <FormFeedback>
                                {validator.swifter_api_host.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Auth host</Label>
                            <Input
                                invalid={validator.swifter_auth_host.isInvalid}
                                name="swifter_auth_host"
                                onChange={inputChange}
                                placeholder="Auth Host"
                                value={inputs.swifter_auth_host}
                            />
                            <FormFeedback>
                                {validator.swifter_auth_host.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="justify-content-end pr-3">
                    <Button
                        className="btn__options"
                        color="metal"
                        disabled={isUpdating}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="ml-3"
                        color="primary"
                        onClick={createOrUpdate}
                        style={{ with: "100px !important" }}
                    >
                        {isUpdating && <Spinner color="metal" show sm />}
                        <span>Save</span>
                    </Button>
                </Row>
            </Section>
        </Form>
    );
};

export default SwifterCredentialForm;
