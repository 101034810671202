import gql from "graphql-tag";

let Accounts = {};
let Account = {};
Accounts.fragments = {
    attributes: gql`
        fragment AccountsFragments on AccountCursorPagination {
            cursor
            accounts {
                entity_id
                key
                first_name
                last_name
                email
                has_account
                phone
                active
                leaf_logic_id
                driver_license_id
                latitude
                longitude
                address1
                address2
                city
                city_code
                country
                country_code
                province
                province_code
                zip
                created_at
                updated_at
                total_order_expense
                tags
                accept_marketing
            }
        }
    `,
};

Account.fragments = {
    attributes: gql`
        fragment AccountFragments on Account {
            entity_id
            key
            first_name
            last_name
            email
            has_account
            phone
            active
            leaf_logic_id
            driver_license_id
            latitude
            longitude
            address1
            address2
            city
            city_code
            country
            country_code
            province
            province_code
            zip
            created_at
            updated_at
            total_order_expense
            tags
            accept_marketing
        }
    `,
};

export const QUERY_ACCOUNT_LIST = gql`
    query listAccounts(
        $account_id: String!
        $store_id: String!
        $cursor: String
        $account_filter: AccountFilter
    ) {
        listAccounts(
            account_id: $account_id
            store_id: $store_id
            cursor: $cursor
            account_filter: $account_filter
        ) {
            ...AccountsFragments
        }
    }
    ${Accounts.fragments.attributes}
`;

export const QUERY_GET_ACCOUNT = gql`
    query getAccount($account_id: String!, $store_id: String!, $id: String!) {
        getAccount(account_id: $account_id, store_id: $store_id, id: $id) {
            ...AccountFragments
        }
    }
    ${Account.fragments.attributes}
`;

export const MUTATION_CREATE_ACCOUNT = gql`
    mutation createAccount($input: AccountInput!) {
        createAccount(input: $input) {
            ...AccountFragments
        }
    }
    ${Account.fragments.attributes}
`;

export const MUTATION_UPDATE_ACCOUNT = gql`
    mutation updateAccount(
        $account_id: String!
        $store_id: String!
        $input: UpdateAccountInput!
    ) {
        updateAccount(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            ...AccountFragments
        }
    }
    ${Account.fragments.attributes}
`;

export const MUTATION_REMOVE_ACCOUNT = gql`
    mutation removeAccount($id: String!) {
        removeAccount(id: $id)
    }
`;

export const MUTATION_ADD_NOTES_TO_ACCOUNT = gql`
    mutation addNotesToAccount(
        $account_id: String!
        $store_id: String!
        $input: AddAccountNotesInput!
    ) {
        addNotesToAccount(
            account_id: $account_id
            store_id: $store_id
            input: $input
        )
    }
`;

export const MUTATION_RESET_USER_PASSWORD = gql`
    mutation resetUserPassword(
        $account_id: String!
        $store_id: String!
        $input: ResetUserPasswordInput!
    ) {
        resetUserPassword(
            account_id: $account_id
            store_id: $store_id
            input: $input
        )
    }
`;

export const MUTATION_FORGOT_USER_PASSWORD = gql`
    mutation sendForgotUserPassword(
        $account_id: String!
        $store_id: String!
        $email: String!
    ) {
        sendForgotUserPassword(
            account_id: $account_id
            store_id: $store_id
            email: $email
        )
    }
`;

export const MUTATION_UPDATE_ACCOUNT_BERBIX_VERIFICATION_STATUS = gql`
    mutation updateAccountBerbixVerification(
        $account_id: String!
        $store_id: String!
        $input: UpdateBerbixVerificationStatus!
    ) {
        updateAccountBerbixVerification(
            account_id: $account_id
            store_id: $store_id
            input: $input
        )
    }
`;
