import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import classNames from "classnames";
import _ from "lodash";
import Portlet from "../../components/portlet/Portlet";
import VerticalNav from "../../components/navs/vertical/VerticalNav";
import VerticalNavItem from "../../components/navs/vertical/VerticalNavItem";
import usePermissions from "../../hooks/auth/usePermissions";

const Navigation = ({ items, activeItem, activeKey, setActiveKey }) => {
    const isAllow = usePermissions();

    const [active, setActive] = useState(() =>
        _.findIndex(items, i => i.render),
    );

    useEffect(() => {
        if (!activeKey) return;

        const index = _.findIndex(items, i => i.key === activeKey);

        action(index);

        // eslint-disable-next-line
    }, [activeKey]);

    const action = index => {
        window.scrollTo(0, 0);

        setActive(index);

        if (setActiveKey) {
            setActiveKey(items[index].key);
        }

        if (activeItem) {
            activeItem(items[index]);
        }
    };

    return (
        <Row className="content-navigation">
            <Col className="content-navigation__items" lg="auto" sm={4}>
                <Portlet fit>
                    <VerticalNav>
                        {items.map(
                            (e, i) =>
                                isAllow(e.permissions) &&
                                !e.hidden && (
                                    <VerticalNavItem
                                        action={() => action(i)}
                                        active={i === active}
                                        badge={
                                            e.invalid && (
                                                <i className="fas fa-exclamation text-danger" />
                                            )
                                        }
                                        icon={e.icon}
                                        key={i}
                                        section={e.label && !e.render}
                                        separator={!e.label}
                                        // first={i === 0}
                                    >
                                        {e.label}
                                    </VerticalNavItem>
                                ),
                        )}
                    </VerticalNav>
                </Portlet>
            </Col>
            {items.map((e, i) =>
                e.render ? (
                    <Col
                        className={classNames({ "k-hidden": i !== active })}
                        key={i}
                        lg
                        sm={8}
                    >
                        {e.render}
                    </Col>
                ) : null,
            )}
        </Row>
    );
};

export default Navigation;
