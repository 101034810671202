/**
 * Created by: Pablo David Gago Ballester
 * Twitter: @pedegago
 */

const loadBlocks = (editor, options) => {
    const bm = editor.BlockManager;

    bm.add("container-block", {
        label: "Container",
        attributes: { class: "fa fa-inbox" },
        category: "Containers",
        content: `<div class="container-wrapper">
            <div class="container"></div>
        </div>
        <style>
            .container {
                min-height: 100px;
                margin: 0 auto;
                padding: 0 20px;
            }

            @media (min-width: 576px) {
                .container { max-width: 540px; }
            }

            @media (min-width: 768px) {
                .container { max-width: 720px; }
            }

            @media (min-width: 992px) {
                .container { max-width: 960px; }
            }

            @media (min-width: 1200px) {
                .container { max-width: 1140px; }
            }
        </style>`,
    });

    bm.add("divider-block", {
        label: "Divider",
        attributes: { class: "fa fa-minus" },
        category: "Basic",
        content: `<hr class="divider" />
        <style>
            .divider {
                margin: 2rem 0;
            }
        </style>`,
    });

    bm.add("section-hero", {
        label: "Hero",
        attributes: { class: "gjs-fonts gjs-f-hero" },
        category: "Sections",
        content: `<header class="header-banner"><div class="container-width">
            <div class="logo-container"><div class="logo">Logo</div></div>
            <nav class="navbar">
            <div class="menu-item">BUILDER</div><div class="menu-item">TEMPLATE</div><div class="menu-item">WEB</div>
            </nav><div class="clearfix"></div>
            <h1 class="lead-title">Lorem ipsum dolor si amet contour</h1>
            <div class="sub-lead-title">All text blocks could be edited easily with double clicking on it. You can create new text blocks with the command from the left panel</div>
            <a href="#" class="lead-btn">Discover Now!</a></div></header>
            <style>
                .clearfix{ clear:both}

                .header-banner{
                    padding-top: 35px;
                    padding-bottom: 100px;
                    font-family: Helvetica, serif;
                    font-weight: 100;
                    background-image: -webkit-linear-gradient(265deg, rgb(231, 235, 248) 0%, rgb(108, 128, 177) 100%);
                }

                .container-width{
                    width: 90%;
                    max-width: 1150px;
                    margin: 0 auto;
                }

                .logo-container{
                    float: left;
                    width: 50%;
                }

                .logo{
                    background-color: var(--white);
                    border-radius: 5px;
                    width: 130px;
                    padding: 10px;
                    min-height: 30px;
                    text-align: center;
                    line-height: 30px;
                    font-size: 23px;
                }

                .navbar{
                    float: right;
                    width: 50%;
                }

                .menu-item{
                    float:right;
                    font-size: 15px;
                    width: 130px;
                    padding: 10px;
                    min-height: 50px;
                    text-align: center;
                    line-height: 30px;
                    font-weight: 400;
                }

                .lead-title{
                    margin: 150px 0 30px 0;
                    font-size: 40px;
                    width: 50%;
                }

                .sub-lead-title{
                    max-width: 650px;
                    line-height:30px;
                    margin-bottom:50px;
                    color: white;
                }

                .lead-btn{
                    padding:10px 27px;
                    width:190px;
                    font-size:20px;
                    text-align:center;
                    background-color: var(--primary500);
                    color: white;
                    border-radius:5px;
                    text-decoration: none;
                }
            </style>`,
    });
};

export default (editor, opts = {}) => {
    const options = {
        ...opts,
    };

    loadBlocks(editor, options);
};
