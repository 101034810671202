import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import classNames from "classnames";
import logo from "../../../assets/images/logo.png";
import { QUERY_UI } from "../../../apollo/queries/local";
import { MUTATION_UI } from "../../../apollo/mutations/local";

const HeaderMobile = () => {
    const { data } = useQuery(QUERY_UI);

    const { asideToggle, headerToggle } = data.ui;

    const [updateUi] = useMutation(MUTATION_UI);

    return (
        <header className="k-header-mobile k-header-mobile--fixed ">
            <div className="k-header-mobile__logo">
                <Link to="/">{/* <img alt="GapCommerce" src={logo} /> */}</Link>
            </div>
            <div className="k-header-mobile__toolbar">
                <button
                    className={classNames(
                        "k-header-mobile__toolbar-toggler",
                        "k-header-mobile__toolbar-toggler--left",
                        {
                            "k-header-mobile__toolbar-toggler--active":
                                asideToggle,
                        },
                    )}
                    onClick={() => {
                        updateUi({
                            variables: {
                                ui: {
                                    asideHover: false,
                                    asideMinimize: false,
                                    asideToggle: !asideToggle,
                                },
                            },
                        });
                    }}
                >
                    <span />
                </button>
                <button
                    className={classNames(
                        "k-header-mobile__toolbar-topbar-toggler",
                        {
                            "k-header-mobile__toolbar-topbar-toggler--active":
                                headerToggle,
                        },
                    )}
                    onClick={() => {
                        updateUi({
                            variables: {
                                ui: {
                                    headerToggle: !headerToggle,
                                },
                            },
                        });
                    }}
                >
                    <i className="fas fa-ellipsis-v" />
                </button>
            </div>
        </header>
    );
};

export default HeaderMobile;
