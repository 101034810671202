export default `
    * {
        box-sizing: border-box;
    }

    html,
    body,
    [data-gjs-type="wrapper"] {
        min-height: 100%;
    }

    body {
        margin: 0;
        height: 100%;
        background-color: var(--white);
    }

    [data-gjs-type="wrapper"] {
        overflow: auto;
        overflow-x: hidden;
    }

    * ::-webkit-scrollbar-track {
        background: #f9f9fc !important;
    }

    * ::-webkit-scrollbar-thumb {
        background: #e3e5f2 !important;
    }

    * ::-webkit-scrollbar {
        width: 8px;
    }
`;
