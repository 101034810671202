import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Col, Row, FormFeedback, FormGroup, Label, Button } from "reactstrap";
import { useForm, useValidator } from "@/hooks";
import {
    Form,
    Input,
    Section,
    SectionTitle,
    Select,
    Spinner,
} from "@/components";
import {
    EVENT_TYPE_LISTTRACK,
    SUBSCRIPTION_SOURCE_TYPE,
} from "../../config/constant";

const ListTrackAddItem = ({
    app,
    handleCancel,
    handlerUpdatingOrCreate,
    isCreate,
    isUpdating,
    state,
    setState,
}) => {
    const [isFormReset, resetFormInputs] = useState(isCreate);
    const { inputs, inputChange, setInputs, selectChange, selectValue } =
        useForm();

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "id",
                method: "isEmpty",
                validWhen: false,
                message: "This Id is required.",
            },
            {
                field: "name",
                method: "isEmpty",
                validWhen: false,
                message: "This Name is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    const {
        validator: validatorSources,
        isValid: isValidSources,
        isSubmited: isSubmitedSources,
    } = useValidator(
        [
            {
                field: "id",
                method: "isEmpty",
                validWhen: false,
                message: "This Id is required.",
            },
            {
                field: "event_id",
                method: "isEmpty",
                validWhen: false,
                message: "This Event Id is required.",
            },
            {
                field: "type",
                method: "isEmpty",
                validWhen: false,
                message: "This Type is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    const {
        validator: validatorMessages,
        isValid: isValidMessages,
        isSubmited: isSubmitedMessages,
    } = useValidator(
        [
            {
                field: "message_id",
                method: "isEmpty",
                validWhen: false,
                message: "This Message Id is required.",
            },
            {
                field: "event_type",
                method: "isEmpty",
                validWhen: false,
                message: "This Event Type is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    if (!isCreate && !isFormReset) {
        resetFormInputs(true);
    }

    useEffect(() => {
        if (isCreate) {
            resetForm();
        }
        if (!isCreate) {
            let itemUpdate = null;
            if (state.form === "Order Field") {
                itemUpdate = app.order_fields[state.selected];
            }

            if (state.form === "Password Field") {
                itemUpdate = app.password_fields[state.selected];
            }

            if (state.form === "Store Field") {
                itemUpdate = app.store_fields[state.selected];
            }

            if (state.form === "Message") {
                itemUpdate = app.messages[state.selected];
            }

            if (state.form === "Subscribe Lists") {
                itemUpdate = app.subscribe_lists[state.selected];
            }

            if (state.form === "Subscribe Fields") {
                itemUpdate = app.subscribe_fields[state.selected];
            }

            if (state.form === "Sources") {
                itemUpdate = app.sources[state.selected];
            }

            setInputs(itemUpdate);
        }
    }, [state.form, state.selected]);

    const createOrUpdate = () => {
        if (isSubmitedMessages) {
            isSubmitedMessages(true);
        }

        if (isSubmitedSources) {
            isSubmitedSources(true);
        }

        if (isSubmited) {
            isSubmited(true);
        }

        if (isValidMessages() || isValid() || isValidSources()) {
            let newList = [];

            if (state.form === "Order Field") {
                newList = _.cloneDeep(app.order_fields || []);
            }

            if (state.form === "Password Field") {
                newList = _.cloneDeep(app.password_fields || []);
            }

            if (state.form === "Store Field") {
                newList = _.cloneDeep(app.store_fields || []);
            }

            if (state.form === "Message") {
                newList = _.cloneDeep(app.messages || []);
            }

            if (state.form === "Subscribe Lists") {
                newList = _.cloneDeep(app.subscribe_lists || []);
            }

            if (state.form === "Subscribe Fields") {
                newList = _.cloneDeep(app.subscribe_fields || []);
            }

            if (state.form === "Sources") {
                newList = _.cloneDeep(app.sources || []);
            }

            if (isCreate) {
                if (state.form === "Message") {
                    newList.push({
                        message_id: inputs.message_id,
                        event_type: inputs.event_type,
                        __typename: "ListTrackMessage",
                    });
                }
                if (state.form === "Sources") {
                    newList.push({
                        id: parseInt(inputs.id),
                        type: inputs.type,
                        event_id: inputs.event_id,
                        __typename: "ListTrackSourceFields",
                    });
                }
                if (state.form !== "Message" && state.form !== "Sources") {
                    newList.push({
                        id: parseInt(inputs.id),
                        name: inputs.name,
                        __typename: "ListTrackField",
                    });
                }
            } else {
                if (state.form === "Message") {
                    newList[state.selected] = {
                        ...inputs,
                        message_id: inputs.message_id,
                        event_type: inputs.event_type,
                    };
                }
                if (state.form === "Sources") {
                    newList[state.selected] = {
                        ...inputs,
                        id: parseInt(inputs.id),
                        type: inputs.type,
                        event_id: inputs.event_id,
                    };
                }
                if (state.form !== "Message" && state.form !== "Sources") {
                    newList[state.selected] = {
                        ...inputs,
                        id: parseInt(inputs.id),
                        name: inputs.name,
                    };
                }
            }

            setState({ ...state, updateList: newList });
            handlerUpdatingOrCreate(newList);
        }
    };

    const handleClose = () => {
        handleCancel();
        resetFormInputs(false);
    };

    const resetForm = () => {
        setInputs({
            id: null,
            name: "",
            message_id: "",
            event_type: "",
            event_id: "",
            type: "",
        });
        resetFormInputs(false);
    };

    return (
        <Form>
            <Section className="section-separator" />
            <Section className="pt-4 bg-light">
                <Row>
                    <Col>
                        <SectionTitle md>
                            {isCreate ? "Add " : "Update "}
                            {`${state.form}`}
                        </SectionTitle>
                    </Col>
                </Row>
                <Row>
                    {state.form === "Sources" && (
                        <>
                            <Col lg={4} md={6}>
                                <FormGroup>
                                    <Label>Id</Label>
                                    <Input
                                        invalid={validatorSources.id.isInvalid}
                                        name="id"
                                        onChange={inputChange}
                                        placeholder="Id"
                                        value={inputs.id}
                                    />
                                    <FormFeedback>
                                        {validatorSources.id.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={6}>
                                <FormGroup
                                    invalid={validatorSources.type.isInvalid}
                                >
                                    <Label>Type</Label>
                                    <Select
                                        controlName="type"
                                        invalid={
                                            validatorSources.type.isInvalid
                                        }
                                        onChange={selectChange}
                                        options={SUBSCRIPTION_SOURCE_TYPE}
                                        placeholder="type"
                                        value={selectValue(
                                            inputs.type,
                                            SUBSCRIPTION_SOURCE_TYPE,
                                        )}
                                    />
                                    <FormFeedback>
                                        {validatorSources.type.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={6}>
                                <FormGroup>
                                    <Label>Event Id</Label>
                                    <Input
                                        invalid={
                                            validatorSources.event_id.isInvalid
                                        }
                                        name="event_id"
                                        onChange={inputChange}
                                        placeholder="Event_Id"
                                        value={inputs.event_id}
                                    />
                                    <FormFeedback>
                                        {validatorSources.event_id.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </>
                    )}
                    {state.form === "Message" && (
                        <>
                            <Col lg={4} md={6}>
                                <FormGroup>
                                    <Label>Message Id</Label>
                                    <Input
                                        invalid={
                                            validatorMessages.message_id
                                                .isInvalid
                                        }
                                        name="message_id"
                                        onChange={inputChange}
                                        placeholder="Message Id"
                                        value={inputs.message_id}
                                    />
                                    <FormFeedback>
                                        {validatorMessages.message_id.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={6}>
                                <FormGroup
                                    invalid={
                                        validatorMessages.event_type.isInvalid
                                    }
                                >
                                    <Label>Event type</Label>
                                    <Select
                                        controlName="event_type"
                                        invalid={
                                            validatorMessages.event_type
                                                .isInvalid
                                        }
                                        onChange={selectChange}
                                        options={EVENT_TYPE_LISTTRACK}
                                        placeholder="Event type"
                                        value={selectValue(
                                            inputs.event_type,
                                            EVENT_TYPE_LISTTRACK,
                                        )}
                                    />
                                    <FormFeedback>
                                        {validatorMessages.event_type.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </>
                    )}
                    {state.form !== "Message" && state.form !== "Sources" && (
                        <>
                            <Col lg={4} md={6}>
                                <FormGroup>
                                    <Label>Id</Label>
                                    <Input
                                        invalid={validator.id.isInvalid}
                                        name="id"
                                        onChange={inputChange}
                                        placeholder="Id"
                                        type="number"
                                        value={inputs.id}
                                    />
                                    <FormFeedback>
                                        {validator.id.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={6}>
                                <FormGroup>
                                    <Label>Name</Label>
                                    <Input
                                        invalid={validator.name.isInvalid}
                                        name="name"
                                        onChange={inputChange}
                                        placeholder="Name"
                                        type="text"
                                        value={inputs.name}
                                    />
                                    <FormFeedback>
                                        {validator.name.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </>
                    )}
                </Row>
                <Row
                    className="justify-content-end pb-4"
                    style={{ paddingRight: "24px" }}
                >
                    <Button
                        className="btn__options"
                        color="metal"
                        disabled={isUpdating}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="ml-3"
                        color="primary"
                        onClick={createOrUpdate}
                        style={{ minWidth: "120px" }}
                    >
                        {isUpdating && <Spinner color="metal" show sm />}
                        <span>Save</span>
                    </Button>
                </Row>
            </Section>
        </Form>
    );
};

export default ListTrackAddItem;
