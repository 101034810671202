import React, { useState, useEffect } from "react";
import { Button, Row, Col } from "reactstrap";
import Content from "../../base/layout/content/Content";
import Table from "../../base/components/table/Table";
import { useLazyQuery } from "@apollo/react-hooks";
import {
    QUERY_LIST_STORE_ORDER,
    QUERY_GET_ORDER,
    MUTATION_UPDATE_ORDER,
} from "../../apollo/module-operations/order";
import { Portlet } from "../../base/components/portlet";
import useMeta from "../../base/hooks/useMeta.js";
import useRowActions from "../../base/hooks/table/useRowActions";
import routes from "../../config/routes/routes";
import _ from "lodash";
import Input from "../../base/components/form/input/Input";
import moment from "moment";
import { FILTER_TYPES } from "react-bootstrap-table2-filter";
import useFilter from "../../base/hooks/table/useFilter";
import FiltersToggle from "../../base/components/table/FiltersToggle";
import Filters from "../../base/components/table/Filters";
import { formatAddress, currencyFormat } from "../../utils/utils";
import { textFilter } from "react-bootstrap-table2-filter";
import { CSVLink } from "react-csv";
import { headers_orders } from "../../config/constant";

const AbandonedOrders = () => {
    const { user } = useMeta();
    const [totalCount, setTotalCount] = useState(undefined);
    const [key, setKey] = useState(true);
    const [selection, setSelection] = useState([]);
    const [_orders, setOrders] = useState([]);
    const [_cursors, setCursors] = useState([]);
    const [pageSize, setPageSize] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);
    const [pages, setPages] = useState(0);

    const limit = 20;

    //---------------------------Defining filters-----------------------------
    const { TEXT, DATE } = FILTER_TYPES;

    const [showFilters, setShowFilters] = useState(false);

    let query = "status == 'ABANDONED_CART'";

    const filterCustomerFirstName = useFilter(
        "customer_first_name",
        TEXT,
        "Customer First Name",
        "",
    );

    const filterCustomerLastName = useFilter(
        "customer_last_name",
        TEXT,
        "Customer Last Name",
        "",
    );

    const filterDate = useFilter("created_at", DATE, "Created", "");

    const filters = [
        filterCustomerFirstName,
        filterCustomerLastName,
        filterDate,
    ];

    const [
        listStoreOrders,
        {
            loading: loadingorders,
            data: order_data,
            networkStatus,
            refetch,
            fetchMore,
        },
    ] = useLazyQuery(QUERY_LIST_STORE_ORDER, {
        notifyOnNetworkStatusChange: true,
        skip: user.loading,
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            query: query,
            last: limit,
        },
        onCompleted: resp => {
            if (!totalCount) {
                setTotalCount(order_data.listStoreOrders.totalCount);

                setPageSize(
                    Math.ceil(order_data.listStoreOrders.totalCount / limit),
                );
            }
            setOrders(resp.listStoreOrders.edges.map(e => e.node));

            setCursors(resp.listStoreOrders.edges.map(e => e.cursor));
        },
    });

    const getQuery = (firstName, lastName, createdAt) => {
        query = "status == 'ABANDONED_CART'";

        if (firstName !== "") {
            query = `${query} && customer_first_name == '${firstName}'`;
        }

        if (lastName !== "") {
            query = `${query} && customer_last_name == '${lastName}'`;
        }

        const date = moment(createdAt);

        if (date.isValid()) {
            query = `${query} && created_at >= ${date.unix()}`;
        }

        return query;
    };

    const handleRemoteFilters = (type, { filters: changes }) => {
        _.forEach(filters, f => {
            const current = changes[f.dataField];

            f.setValue(current ? current.filterVal : "");
        });

        const firstName = _.get(changes, "customer_first_name.filterVal", "");

        const lastName = _.get(changes, "customer_last_name.filterVal", "");

        const createdAt = _.get(changes, "created_at.filterVal", "");

        listStoreOrders({
            variables: {
                query: getQuery(firstName, lastName, createdAt),
            },
        });
    };
    // -----------------------------------------------------------------------

    useEffect(() => {
        !user.loading && listStoreOrders();

        // eslint-disable-next-line
    }, [user.loading]);

    const next = cursor => {
        if (pages + 1 < pageSize) {
            setPages(pages + 1);
        }

        if (pageIndex < pageSize) {
            setPageIndex(pageIndex + 1);
        }

        if (pages + 1 === pageSize) {
            return;
        }

        fetchMore({
            query: QUERY_LIST_STORE_ORDER,
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                query: query,
                last: limit,
                before: cursor,
            },
            updateQuery: (_, { fetchMoreResult }) => {
                setOrders([
                    ..._orders,
                    ...fetchMoreResult.listStoreOrders.edges.map(e => e.node),
                ]);

                setCursors([
                    ..._cursors,
                    ...fetchMoreResult.listStoreOrders.edges.map(e => e.cursor),
                ]);

                return {
                    listStoreOrders: fetchMoreResult.listStoreOrders,
                };
            },
        });
    };

    const back = () => {
        if (pageIndex > 0) {
            setPageIndex(pageIndex - 1);
        }
    };

    const getOrdersToExport = () => {
        let orders_export = [];
        let get_selected_orders = [];

        if (selection.length) {
            get_selected_orders = _.filter(_orders, o =>
                _.includes(selection, o.entity_id),
            );
        }

        _.forEach(get_selected_orders, o => {
            /*  o.invoice_date = moment(o.invoice_date).format("MM/DD/YYYY HH:mm");
            o.subtotal = subtotal(o.line_items); */

            orders_export.push({
                order_number: o.order_number,
                billing_address_first_name: `${o.delivery_address.first_name} ${o.delivery_address.last_name}`,
                shipping_address_address1: formatAddress(o.delivery_address),
                subtotal_price: `$${currencyFormat(o.subtotal_price)}`,
                total_tax: `$${currencyFormat(o.total_tax)}`,
                total: `$${currencyFormat(o.total_price)}`,
                created_at: moment(o.created_at).format("MM/DD/YYYY HH:mm"),
                status: o.status,
            });
        });
        return orders_export;
    };

    const { update } = useRowActions(
        [
            {
                query: QUERY_GET_ORDER,
            },
        ],
        MUTATION_UPDATE_ORDER,
        routes.ORDER_DETAIL.route,
        "Order",
    );

    const actions = [
        {
            label: "Detail",
            icon: "fas fa-pencil-alt",
            action: (cell, row, rowIndex, formatExtraData) => {
                update(row.entity_id);
            },
        },
    ];

    const defaultSorted = [
        {
            dataField: "order_number",
            order: "desc",
        },
    ];

    const columns = [
        {
            dataField: "key",
            text: "Key",
            hidden: true,
        },
        {
            dataField: "entity_id",
            text: "entity_id",
            hidden: true,
        },
        {
            dataField: "customer_first_name",
            text: "Customer Name",
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                let first = "";
                if (row) {
                    first = `${row.delivery_address.first_name} ${row.delivery_address.last_name}`;
                }

                return <div>{first}</div>;
            },
            headerStyle: (colum, colIndex) => {
                return { width: "250px" };
            },
            filter: textFilter({
                getFilter: filter =>
                    (filterCustomerFirstName.filter.current = filter),
            }),
        },
        {
            dataField: "delivery_address",
            text: "Delivery Address",
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                let address = "";
                if (row) {
                    address = formatAddress(row.delivery_address);
                }

                return <div>{address}</div>;
            },
            headerStyle: (colum, colIndex) => {
                return { width: "350px" };
            },
        },
        {
            dataField: "created_at",
            text: "Date",
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <span>{moment(cell).format("MM/DD/YYYY HH:mm")}</span>;
            },
            headerStyle: (colum, colIndex) => {
                return { width: "150px" };
            },
            filter: textFilter({
                getFilter: filter => (filterDate.filter.current = filter),
            }),
        },
        {
            dataField: "total_price",
            text: "Total Price",
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return row.total_price && row.total_price > 0
                    ? `${currencyFormat(row.total_price)}`
                    : "$0";
            },
            headerStyle: (colum, colIndex) => {
                return { width: "100px" };
            },
        },
    ];

    const selectRow = {
        mode: "checkbox",
        classes: "table-row--selected",
        clickToSelect: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            let selected_orders = [];
            setKey(!key);

            if (isSelect) {
                selected_orders = [...selection, row.entity_id];
            } else {
                selected_orders = selection.filter(s => s !== row.entity_id);
            }
            setSelection(selected_orders);
        },
        onSelectAll: (isSelect, rows, e) => {
            let selected_orders = [];
            setKey(!key);
            if (isSelect) {
                rows.forEach((r, index) => {
                    selected_orders.push(r.entity_id);
                });
            }
            setSelection(selected_orders);
        },
        selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
            <label className="k-checkbox k-checkbox--brand">
                <Input type="checkbox" {...rest} readOnly />
                <span />
            </label>
        ),
        selectionRenderer: ({ mode, rowIndex, ...rest }) => (
            <label className="k-checkbox k-checkbox--brand">
                <Input type={mode} {...rest} readOnly />
                <span />
            </label>
        ),
    };

    const _loadingorders = loadingorders || networkStatus === 4;

    return (
        <>
            <Content>
                <Portlet
                    header={{
                        title: "Information",
                        subtitle: "Select any Order for more details",
                    }}
                    sticky
                    toolbar={
                        <>
                            <FiltersToggle setShowFilters={setShowFilters} />
                            <Button
                                className="ml-3"
                                color="metal"
                                onClick={() => refetch()}
                            >
                                <i className="fas fa-sync-alt" />
                                Refresh
                            </Button>
                            <CSVLink
                                className="btn btn-primary ml-3"
                                data={getOrdersToExport()}
                                filename={"orders.csv"}
                                headers={headers_orders}
                                key={key}
                            >
                                <i className="fas fa-download" />
                                Export
                            </CSVLink>
                        </>
                    }
                >
                    <Filters filters={filters} />
                    <Table
                        actions={actions}
                        columns={columns}
                        data={_orders.slice(
                            pageIndex * limit,
                            pageIndex * limit + limit,
                        )}
                        defaultSorted={defaultSorted}
                        keyField="entity_id"
                        loading={_loadingorders}
                        onTableChange={handleRemoteFilters}
                        remote={{ filter: true }}
                        selectRow={selectRow}
                        showFilters={showFilters}
                    />
                    <Row className="react-bootstrap-table-pagination">
                        <Col xs={6}>
                            {!_loadingorders && totalCount && (
                                <span>
                                    Showing rows {pageIndex * limit + 1} to{" "}
                                    {pageIndex * limit + limit > totalCount
                                        ? totalCount
                                        : pageIndex * limit + limit}{" "}
                                    of {totalCount}
                                </span>
                            )}
                        </Col>
                        <Col
                            className="react-bootstrap-table-pagination-list"
                            xs={6}
                        >
                            {!_loadingorders &&
                                totalCount &&
                                totalCount > 0 && (
                                    <ul className="pagination react-bootstrap-table-page-btns-ul">
                                        <li className="page-item">
                                            <a
                                                className={`${
                                                    pageIndex === 0
                                                        ? "k-opacity-5"
                                                        : ""
                                                } page-link`}
                                                href="#"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    back();
                                                }}
                                                style={{
                                                    pointerEvents:
                                                        pageIndex === 0
                                                            ? "none"
                                                            : "unset",
                                                }}
                                            >
                                                <i className="fas fa-arrow-left mr-3" />
                                                Previous
                                            </a>
                                        </li>
                                        <li className="page-item">
                                            <a
                                                className={`${
                                                    pageIndex >= pageSize - 1
                                                        ? "k-opacity-5"
                                                        : ""
                                                } page-link`}
                                                href="#"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    next(
                                                        _cursors[
                                                            _cursors.length - 1
                                                        ],
                                                    );
                                                }}
                                                style={{
                                                    pointerEvents:
                                                        pageIndex >=
                                                        pageSize - 1
                                                            ? "none"
                                                            : "unset",
                                                }}
                                            >
                                                Next
                                                <i className="fas fa-arrow-right ml-3" />
                                            </a>
                                        </li>
                                    </ul>
                                )}
                        </Col>
                    </Row>
                </Portlet>
            </Content>
        </>
    );
};

export default AbandonedOrders;
