import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { MUTATION_UPDATE_STORE } from "../../apollo/module-operations/store";
import Spinner from "../../base/components/spinner/Spinner";
import { Portlet, Section, SectionTitle } from "../../base/components/portlet";
import {
    FormGroup,
    Label,
    Collapse,
    Col,
    Row,
    Button,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
} from "reactstrap";
import Input from "../../base/components/form/input/Input";
import Form from "../../base/components/form/Form";
import { currencyMask } from "utils/maskInput";
import { Badge, InputGroupText, Table } from "@/components";
import { useConfirm, useMeta, useNotification } from "@/hooks";
import _ from "lodash";
import MediaInput from "base/components/Medias";
import classNames from "classnames";
import CheckoutFormAddItem from "./CheckoutFormAddItem";
import { omit, formatUpdateOrderInput } from "utils/utils";

const ChekoutForm = ({ inputs, setInputs, inputChange, loading, stores }) => {
    const notify = useNotification();
    const { user } = useMeta();
    const [form, setForm] = useState("");
    const [idSelected, setIdSelected] = useState(null);
    const [isCreate, setIsCreate] = useState(true);
    const [isLoadingUpdating, setIsLoadingUpdating] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [isloading, setIsLoading] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState({
        agreements: true,
        payments: true,
    });

    const getColorReservationMode = mode => {
        if (mode === "PICKUP") {
            return "info";
        }

        if (mode === "DELIVERY") {
            return "success";
        }

        return "metalic";
    };

    const getColorPayment = mode => {
        if (mode === "ACH") {
            return "info";
        }

        if (mode === "CASH") {
            return "success";
        }

        return "metalic";
    };

    const toogle = (list, collapse) => {
        setIsCollapsed({
            ...isCollapsed,
            [list]: collapse,
        });
    };

    const [mutate] = useMutation(MUTATION_UPDATE_STORE, {
        refetchQueries: [],
        onCompleted: resp => {
            let input = _.cloneDeep(resp.updateStore);

            setInputs(input);
            notify(
                `${form} Was ${
                    isCreate ? "Created" : !isDelete ? "Updated" : "Removed"
                } Successfully`,
                "success",
            );
            handleClose();
        },
        onError: () => {
            setIsLoadingUpdating(false);
            notify(
                `Error ${
                    isCreate ? "Updating" : !isDelete ? "Creating" : "Removing"
                }  The ${form}, If The Error Persist Please Contact Support.`,
                "error",
            );
        },
    });

    const handlerUpdatingOrCreate = newArray => {
        setIsLoadingUpdating(true);
        let _inputs = {
            ...inputs,
            agreements: form === "Agreements" ? newArray : inputs.agreements,
            payment_settings:
                form === "Payments" ? newArray : inputs.payment_settings,
        };

        mutate({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: formatUpdateOrderInput(_inputs),
            },
        });
    };

    const createOrUpdate = () => {
        setIsLoading(true);
        handlerUpdatingOrCreate();
    };

    const renderFormCreate = form => {
        setForm(form);
        setIsCreate(true);
        window.scrollTo(0, 0);
    };

    const handleClose = () => {
        setIsLoadingUpdating(false);
        setIsDelete(false);
        setIsLoading(false);
        setForm("");
    };

    const handleCancel = () => {
        setInputs(inputs);
        handleClose();
    };

    const handleDelete = list => {
        return list.filter((_, idx) => idx !== idSelected);
    };

    const [confirm, launch] = useConfirm(() => {
        let _inputs = {
            ...inputs,
            agreements:
                form === "Agreements"
                    ? handleDelete(inputs.agreements)
                    : inputs.agreements,
            payment_settings:
                form === "Payments"
                    ? handleDelete(inputs.payment_settings)
                    : inputs.payment_settings,
        };

        setIsLoadingUpdating(true);

        mutate({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: formatUpdateOrderInput({
                    ...omit(_inputs, [
                        "display_post_tax",
                        "show_reward_at_checkout",
                        "debit_card_fee",
                        "ach_payment_banner_url",
                        "warning_setting",
                        "ignore_whitespace",
                    ]),
                }),
            },
        });
    }, `Are you sure you want to remove the item from ${form} ?`);

    //agreements
    const columnsDefault = [
        {
            dataField: "reservation_mode",
            text: "Reservation Mode",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Badge
                        className={`status__${
                            getColorReservationMode(row.reservation_mode) ||
                            "info"
                        }`}
                        label={row.reservation_mode}
                    />
                );
            },
        },
        {
            dataField: "content",
            text: "Content",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.content || ""}`}</div>;
            },
        },
        {
            dataField: "check_by_default",
            text: "Check By Default",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Badge
                        className={cell ? "status__success" : "status__error"}
                        label={cell ? "Yes" : "No"}
                    />
                );
            },
        },
        {
            dataField: "required",
            text: "Required",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Badge
                        className={cell ? "status__success" : "status__error"}
                        label={cell ? "Yes" : "No"}
                    />
                );
            },
        },
        {
            dataField: "stores_provider_ids",
            text: "Stores Provider",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.stores_provider_ids || ""}`}</div>;
            },
        },
    ];

    //payments
    const columnsPayments = [
        {
            dataField: "reservation_mode",
            text: "Reservation Mode",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Badge
                        className={`status__${
                            getColorReservationMode(row.reservation_mode) ||
                            "info"
                        }`}
                        label={row.reservation_mode}
                    />
                );
            },
        },
        {
            dataField: "payment_type",
            text: "Payment Type",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Badge
                        className={`status__${
                            getColorPayment(row.payment_type) || "info"
                        }`}
                        label={row.payment_type}
                    />
                );
            },
        },
        {
            dataField: "stores_provider_ids",
            text: "Stores Provider",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: cell => {
                return <div>{`${cell || ""}`}</div>;
            },
        },
    ];

    const renderAction = context => {
        return [
            {
                label: "Update",
                action: async (cell, row, rowIndex) => {
                    setIsCreate(false);
                    setIdSelected(rowIndex);
                    setForm(context);
                    window.scrollTo(0, 0);
                },
            },
            {
                label: "Delete",
                action: async (cell, row, rowIndex) => {
                    setIdSelected(rowIndex);
                    setForm(context);
                    setIsDelete(true);
                    setIsCreate(false);
                    launch();
                },
            },
        ];
    };

    const _agreements = (inputs && inputs.agreements) || [];
    const _payments = (inputs && inputs.payment_settings) || [];

    return (
        <>
            <Portlet
                className="business__settings"
                header={{
                    title: "Checkout",
                    subtitle: "Checkout Information",
                }}
                sticky
                toolbar={
                    <>
                        <UncontrolledDropdown className="ml-3 text-dark">
                            <DropdownToggle
                                caret
                                color="metal"
                                disabled={form !== "" || isLoadingUpdating}
                            >
                                <i className="fas fa-plus" /> Add Item
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    onClick={() =>
                                        renderFormCreate("Agreements")
                                    }
                                >
                                    Agreement
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() => renderFormCreate("Payments")}
                                >
                                    Payment
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <Button
                            className="ml-3"
                            color="primary"
                            disabled={isloading || form !== ""}
                            onClick={createOrUpdate}
                            style={{ minWidth: "128px" }}
                        >
                            <Spinner show={isloading} sm />
                            {!isloading && <i className="fas fa-save" />}
                            Update
                        </Button>
                    </>
                }
            >
                {!loading ? (
                    <Form>
                        <Section>
                            <Row>
                                <Col>
                                    <SectionTitle md>Information</SectionTitle>
                                </Col>
                            </Row>
                            <FormGroup row>
                                <Label className="row-label">
                                    Display Post Tax
                                </Label>
                                <Col lg={4}>
                                    <span className="k-switch k-switch--md k-switch--icon">
                                        <label>
                                            <Input
                                                checked={
                                                    inputs.display_post_tax
                                                        ? "checked"
                                                        : ""
                                                }
                                                name="display_post_tax"
                                                onChange={inputChange}
                                                type="checkbox"
                                            />
                                            <span />
                                        </label>
                                    </span>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label className="row-label">
                                    Show Reward At Checkout
                                </Label>
                                <Col lg={4}>
                                    <span className="k-switch k-switch--md k-switch--icon">
                                        <label>
                                            <Input
                                                checked={
                                                    inputs.show_reward_at_checkout
                                                        ? "checked"
                                                        : ""
                                                }
                                                name="show_reward_at_checkout"
                                                onChange={inputChange}
                                                type="checkbox"
                                            />
                                            <span />
                                        </label>
                                    </span>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label className="row-label">
                                    Disabled Time Window
                                </Label>
                                <Col lg={4}>
                                    <span className="k-switch k-switch--md k-switch--icon">
                                        <label>
                                            <Input
                                                checked={
                                                    inputs.disabled_time_window
                                                        ? "checked"
                                                        : ""
                                                }
                                                name="disabled_time_window"
                                                onChange={inputChange}
                                                type="checkbox"
                                            />
                                            <span />
                                        </label>
                                    </span>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col lg={4}>
                                    <Label>
                                        Allow number of days in advance
                                    </Label>
                                    <Input
                                        defaultValue={
                                            inputs.number_of_days_in_advance
                                                ? inputs.number_of_days_in_advance
                                                : ""
                                        }
                                        name="number_of_days_in_advance"
                                        onChange={inputChange}
                                        type={"number"}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col lg={4}>
                                    <Label>Debit Card Fee</Label>
                                    <Input
                                        className="form-control"
                                        defaultValue={
                                            inputs.debit_card_fee
                                                ? inputs.debit_card_fee
                                                : ""
                                        }
                                        mask={currencyMask(
                                            inputs.debit_card_fee,
                                        )}
                                        name="debit_card_fee"
                                        onChange={inputChange}
                                        prepend={
                                            <InputGroupText>$</InputGroupText>
                                        }
                                        typeInput={"masked"}
                                    />
                                </Col>
                                <Col lg={4}>
                                    <Label>ACH Payment Banner Url</Label>
                                    <MediaInput
                                        name="ach_payment_banner_url"
                                        onChange={inputChange}
                                        typeInput="highlight"
                                        value={
                                            inputs.ach_payment_banner_url ?? ""
                                        }
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col lg={4}>
                                    <Label>Warnings</Label>
                                    <Input
                                        name="warning_setting"
                                        onChange={inputChange}
                                        placeholder="Write the warnings..."
                                        rows={12}
                                        type="textarea"
                                        value={
                                            inputs.warning_setting !== ""
                                                ? inputs.warning_setting
                                                : ""
                                        }
                                    />
                                </Col>
                                {inputs.warning_setting && (
                                    <Col lg={4}>
                                        <Label>Preview</Label>
                                        <iframe
                                            srcDoc={
                                                inputs.warning_setting !== ""
                                                    ? inputs.warning_setting
                                                    : `<style>
                                                    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
                                                    p {
                                                        color: #9CA1B4;
                                                        font-size: 14px;
                                                        line-height: 1.5;
                                                        padding-left: 14px;
                                                        font-family: 'Poppins', sans-serif;
                                                    }
                                                </style> 
                                                <p>
                                                  There is no template available to view...
                                                </p>`
                                            }
                                            style={{
                                                width: "100%",
                                                height: "fit-content",
                                                minHeight: "272.5px",
                                                border: "1px solid #d5d9e5",
                                                borderRadius: "0.25rem",
                                            }}
                                            title="Warnings Preview"
                                        />
                                    </Col>
                                )}
                            </FormGroup>
                        </Section>
                        {form !== "" && !isDelete && (
                            <CheckoutFormAddItem
                                form={form}
                                handleCancel={handleCancel}
                                handlerUpdatingOrCreate={
                                    handlerUpdatingOrCreate
                                }
                                idSelected={idSelected}
                                isCreate={isCreate}
                                IsLoadingUpdating={isLoadingUpdating}
                                store={inputs}
                                stores={stores}
                            />
                        )}
                        <Section className="section-separator" />
                        <Section>
                            <Row
                                className={classNames("list-collapse", {
                                    "list-expanded": isCollapsed.agreements,
                                })}
                                onClick={() =>
                                    toogle(
                                        "agreements",
                                        !isCollapsed.agreements,
                                    )
                                }
                            >
                                <Col>
                                    <SectionTitle md>
                                        <span>Agreements</span>
                                        <i
                                            className={`fas fa-caret-${
                                                isCollapsed.agreements
                                                    ? "up"
                                                    : "down"
                                            } ml-3`}
                                        />
                                    </SectionTitle>
                                </Col>
                            </Row>
                            <Collapse isOpen={isCollapsed.agreements}>
                                <Col>
                                    <Table
                                        actions={renderAction("Agreements")}
                                        columns={columnsDefault}
                                        data={_agreements}
                                        keyField="key"
                                        loading={
                                            loading ||
                                            (isLoadingUpdating &&
                                                form === "Agreements")
                                        }
                                    />
                                </Col>
                                <Section className="section-separator" />
                            </Collapse>
                        </Section>
                        <Section>
                            <Row
                                className={classNames("list-collapse", {
                                    "list-expanded": isCollapsed.payments,
                                })}
                                onClick={() =>
                                    toogle("payments", !isCollapsed.payments)
                                }
                            >
                                <Col>
                                    <SectionTitle md>
                                        <span>Payment Setting</span>
                                        <i
                                            className={`fas fa-caret-${
                                                isCollapsed.payments
                                                    ? "up"
                                                    : "down"
                                            } ml-3`}
                                        />
                                    </SectionTitle>
                                </Col>
                            </Row>
                            <Collapse isOpen={isCollapsed.payments}>
                                <Col>
                                    <Table
                                        actions={renderAction("Payments")}
                                        columns={columnsPayments}
                                        data={_payments}
                                        keyField="key"
                                        loading={
                                            loading ||
                                            (isLoadingUpdating &&
                                                form === "Payments")
                                        }
                                    />
                                </Col>
                                <Section className="section-separator" />
                            </Collapse>
                        </Section>
                    </Form>
                ) : (
                    <>
                        <Spinner color="metal" show sm />
                        &nbsp;&nbsp;Loading
                    </>
                )}
            </Portlet>
            {confirm}
        </>
    );
};

export default ChekoutForm;
