export default (editor, opts = {}) => {
    const options = {
        configId: "config",
        configClassName: "fas fa-cog",
        configCommand: "config",
        ...opts,
    };

    loadTraits(editor, options);

    loadOptions(editor, options);

    loadCommands(editor, options);
};

const loadTraits = (editor, opts) => {
    editor
        .getWrapper()
        .set("traits", ["url", "title", "description", "keywords"]);
};

const loadOptions = (editor, opts) => {
    const { configId, configClassName, configCommand } = opts;

    editor.Panels.addButton("options", {
        id: configId,
        className: configClassName,
        command: configCommand,
        attributes: { title: "Settings" },
        active: false,
    });
};

const loadCommands = (editor, opts) => {
    const { configId, configCommand } = opts;

    editor.Commands.add(configCommand, {
        run: (editor, sender) => {
            editor.select(editor.getWrapper());

            const tm = editor.Panels.getButton("views", "open-tm");

            tm.set("active", 1);

            document.querySelectorAll(".gjs-trt-traits input")[1].focus();

            editor.Commands.get(configCommand).stop(editor);
        },
        stop: (editor, sender) => {
            editor.Panels.getButton("options", configId).set("active", false);
        },
    });
};
