import gql from "graphql-tag";

let DeliveryHub = {};
DeliveryHub.fragments = {
    attributes: gql`
        fragment DeliveryHubFragment on DeliveryHub {
            id
            name
            location
            address {
                name
                number
                street
                apartment
                city
                state
                zip
                country
            }
        }
    `,
};

let DeliveryWorker = {};
DeliveryWorker.fragments = {
    attributes: gql`
        fragment DeliveryWorkerFragment on DeliveryWorker {
            id
            name
            on_duty
            phone
            location
            is_responding
            image
        }
    `,
};

export const QUERY_LIST_DELIVERY_HUBS = gql`
    query listHubs($account_id: String!, $store_id: String!) {
        listHubs(account_id: $account_id, store_id: $store_id) {
            ...DeliveryHubFragment
        }
    }
    ${DeliveryHub.fragments.attributes}
`;

export const QUERY_LIST_DELIVERY_WORKERS_IN_LOCATION = gql`
    query listWorkersInLocation(
        $account_id: String!
        $store_id: String!
        $location: [Float]
        $radius: Float
    ) {
        listWorkersInLocation(
            account_id: $account_id
            store_id: $store_id
            location: $location
            radius: $radius
        ) {
            ...DeliveryWorkerFragment
        }
    }
    ${DeliveryWorker.fragments.attributes}
`;
