export const paginationOptions = {
    showTotal: true,
    sizePerPageList: [
        {
            text: "30",
            value: 30,
        },
        {
            text: "200",
            value: 200,
        },
        {
            text: "300",
            value: 300,
        },
        {
            text: "All",
            value: 100,
        },
    ],
};
