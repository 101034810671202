import { useState } from "react";
import FormValidator from "../../../utils/FormValidator";

const useValidator = (rules, inputs) => {
    const [submited, isSubmited] = useState(false);

    const formValidator = new FormValidator(rules);

    const validator = submited
        ? formValidator.validate(inputs)
        : formValidator.valid();

    const isValid = () => {
        return formValidator.validate(inputs).isValid;
    };

    return { submited, validator, isValid, isSubmited };
};

export default useValidator;
