import React, { useState } from "react";
import {
    Portlet,
    Section,
    SectionTitle,
    Table,
    Input,
    Badge,
} from "@/components";
import { Button, Col, Row, Label, FormGroup } from "reactstrap";
import { useMutation } from "@apollo/react-hooks";
import {
    MUTATION_UPDATE_TREEZ_APP,
    QUERY_LIST_STORE_APPS,
} from "../../apollo/module-operations/app";
import _ from "lodash";
import {
    useConfirm,
    useForm,
    useMeta,
    useFormActions,
    useNotification,
} from "@/hooks";
import AccountTreezForm from "./AccountTreezForm";

const TreezForm = ({ app, loading }) => {
    const notify = useNotification();
    const { user } = useMeta();
    const [showForm, setShowForm] = useState(false);
    const [isCreate, setIsCreate] = useState(true);
    const [rowSelect, setRowSelect] = useState(null);
    const [credentials, setCredentials] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const { inputs, setInputs, inputChange } = useForm();

    if (!_.get(inputs, "id", null)) {
        setInputs(app);
    }

    const [mutate] = useMutation(MUTATION_UPDATE_TREEZ_APP, {
        refetchQueries: [],
        onCompleted: resp => {
            handleCancel();
            setIsUpdating(false);
            setIsDelete(false);
            setInputs({ ...inputs, credentials });
            notify(
                `Credential Treez Was ${
                    isCreate ? "Created" : !isDelete ? "Updated" : "Removed"
                } Successfully"`,
                "success",
            );
        },
        onError: () => {
            setIsUpdating(false);
            notify(
                `Error ${
                    isCreate ? "Updating" : !isDelete ? "Creating" : "Removing"
                }  The Credential, If The Error Persist Please Contact Support.`,
                "error",
            );
        },
    });

    const [confirm, launch] = useConfirm(() => {
        mutate({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: {
                    ...inputs,
                    credentials: credentials,
                },
            },
        });
    }, `Are You Sure You Want To Remove The Credential ?`);

    let options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: {
                ...inputs,
                type: "Treez",
            },
        },
    };

    const { createOrUpdateButton } = useFormActions(
        "id",
        "Treez",
        "",
        MUTATION_UPDATE_TREEZ_APP,
        [
            {
                query: QUERY_LIST_STORE_APPS,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        "",
        () => {
            return true;
        }, // isValid
        () => {
            return true;
        }, // isSubmited func
        "primary",
    );

    const columns = [
        {
            dataField: "store_id",
            text: "Id",
            hidden: true,
        },
        {
            dataField: "active",
            text: "Active",
            headerStyle: (colum, colIndex) => {
                return { width: "120px" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Badge
                        className={
                            row.active ? "status__success" : "status__error"
                        }
                        label={row.active ? "Yes" : "No"}
                    />
                );
            },
            sort: true,
        },
        {
            dataField: "default_store",
            text: "Default",
            headerStyle: (colum, colIndex) => {
                return { width: "100px" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Badge
                        className={
                            row.default_store
                                ? "status__success"
                                : "status__metalic"
                        }
                        label={row.default_store ? "Yes" : "No"}
                    />
                );
            },
            sort: true,
        },
        // {
        //     dataField: "dispensary_name",
        //     text: "Dispensary Name",
        //     headerStyle: (colum, colIndex) => {
        //         return { width: "300px" };
        //     },
        //     sort: true,
        //     formatter: (cell, row, rowIndex, formatExtraData) => {
        //         return <div>{`${row.dispensary_name || ""}`}</div>;
        //     },
        // },
        {
            dataField: "store_id",
            text: "Store Id",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.store_id || ""}`}</div>;
            },
        },
        {
            dataField: "store_name",
            text: "Store Name",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.store_name || ""}`}</div>;
            },
        },
        {
            dataField: "client_id",
            text: "Client Id",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.client_id || ""}`}</div>;
            },
        },
        {
            dataField: "api_key",
            text: "Api Key",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.api_key || ""}`}</div>;
            },
        },
        {
            dataField: "headless_client_id",
            text: "Headless Client Id",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.headless_client_id || ""}`}</div>;
            },
        },
        {
            dataField: "headless_client_secret",
            text: "Headless Client Secret",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.headless_client_secret || ""}`}</div>;
            },
        },
    ];

    const actions = [
        {
            label: "Update",
            action: async (cell, row, rowIndex, formatExtraData) => {
                setIsCreate(false);
                setRowSelect(rowIndex);
                setShowForm(true);
            },
        },
        {
            label: "Delete",
            action: async (cell, row, rowIndex, formatExtraData) => {
                setIsDelete(true);
                setIsCreate(false);
                setCredentials(
                    _.filter(
                        inputs.credentials,
                        (i, index) => index !== rowIndex,
                    ),
                );
                launch();
            },
        },
    ];

    const handlerUpdatingOrCreate = array => {
        setIsUpdating(true);
        mutate({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: {
                    ...inputs,
                    credentials: array,
                },
            },
        });
    };

    const handleCancel = () => {
        setIsUpdating(false);
        setShowForm(false);
    };

    const _credentials = (inputs && inputs.credentials) || [];

    return (
        <>
            <Portlet
                className="business__settings"
                header={{
                    title: "Treez",
                    subtitle: "Treez Information",
                }}
                sticky
                toolbar={[
                    createOrUpdateButton,
                    // eslint-disable-next-line react/jsx-key
                    <Button
                        className="ml-3"
                        color="secondary"
                        onClick={() => {
                            setIsCreate(true);
                            setShowForm(true);
                        }}
                    >
                        <i className="fas fa-plus" />
                        Add Credential
                    </Button>,
                ]}
            >
                <Section>
                    <Row className="mt-3">
                        <Col lg={4}>
                            <FormGroup className="mb-0" row>
                                <Label className="row-label mr-3 pl-0">
                                    Automatic Approval
                                </Label>

                                <span className="k-switch k-switch--md k-switch--icon">
                                    <label>
                                        <Input
                                            checked={
                                                inputs.automatic_approval
                                                    ? "checked"
                                                    : ""
                                            }
                                            name="automatic_approval"
                                            onChange={inputChange}
                                            type="checkbox"
                                        />
                                        <span />
                                    </label>
                                </span>
                            </FormGroup>
                        </Col>
                        <Col lg={4}>
                            <FormGroup className="mb-0" row>
                                <Label className="row-label mr-3 pl-0">
                                    Sandbox
                                </Label>

                                <span className="k-switch k-switch--md k-switch--icon">
                                    <label>
                                        <Input
                                            checked={
                                                inputs.sandbox ? "checked" : ""
                                            }
                                            name="sandbox"
                                            onChange={inputChange}
                                            type="checkbox"
                                        />
                                        <span />
                                    </label>
                                </span>
                            </FormGroup>
                        </Col>
                    </Row>
                    {showForm && (
                        <AccountTreezForm
                            app={inputs}
                            credentials={credentials}
                            handleCancel={handleCancel}
                            handlerUpdatingOrCreate={handlerUpdatingOrCreate}
                            isCreate={isCreate}
                            isUpdating={isUpdating}
                            selectCredential={rowSelect}
                            setCredentials={setCredentials}
                        />
                    )}
                    {/* TODO: note sure if this is the best way to create an space here*/}
                    <br></br>
                    <Row>
                        <Col>
                            <SectionTitle md>Credentials</SectionTitle>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table
                                actions={actions}
                                columns={columns}
                                data={_credentials}
                                keyField="store_id"
                                loading={loading}
                            />
                        </Col>
                    </Row>
                </Section>
            </Portlet>
            {confirm}
        </>
    );
};

export default TreezForm;
