import { useNumericMenu } from "react-instantsearch-hooks-web";
import { FC } from "react";
import { NumericMenu } from "./NumericMenu";

export const TotalPriceFilter: FC = () => {
    const { items, refine } = useNumericMenu({
        attribute: "total_price",
        items: [
            { label: "All" },
            { end: 20, label: "Less than $20" },
            { end: 60, label: "Between $20 - $60", start: 20 },
            { end: 100, label: "Between $60 - $100", start: 60 },
            { label: "More than $100", start: 101 },
        ],
    });

    return <NumericMenu element="radio" items={items} refine={refine} />;
};
