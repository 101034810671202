import React from "react";
import { useHistory } from "react-router-dom";

const ToolBar = () => {
    const history = useHistory();

    return (
        <div className="k-content__head-toolbar">
            <div className="k-content__head-wrapper">
                <button
                    className="btn btn-clean"
                    onClick={() => {
                        history.goBack();
                    }}
                >
                    <i className="fas fa-reply" />
                    &nbsp; Go back
                </button>
            </div>
        </div>
    );
};

export default ToolBar;
