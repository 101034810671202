import React from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { Portlet, Section, SectionTitle } from "../../base/components/portlet";
import { FormGroup, Col, Label, FormFeedback, Row } from "reactstrap";
import Input from "../../base/components/form/input/Input";
import Form from "../../base/components/form/Form";
import useMeta from "../../base/hooks/useMeta";
import useForm from "../../base/hooks/form/useForm";
import useValidator from "../../base/hooks/form/useValidator";
import useFormActions from "../../base/hooks/form/useFormActions";
import {
    MUTATION_UPDATE_PAYPAL_APP,
    QUERY_LIST_STORE_APPS,
} from "../../apollo/module-operations/app";
import _ from "lodash";

const AppPayPalForm = ({ toolbar, app }) => {
    const { user } = useMeta();

    const { inputs, setInputs, inputChange } = useForm();

    if (!_.get(inputs, "id", null)) {
        setInputs(app);
    }

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "credential_id",
                method: "isEmpty",
                validWhen: false,
                message: "This Credential Id is required.",
            },
            {
                field: "credential_secret",
                method: "isEmpty",
                validWhen: false,
                message: "This Credential Secret is required.",
            },
            {
                field: "client_id",
                method: "isEmpty",
                validWhen: false,
                message: "This Client Id is required.",
            },
            {
                field: "test_credential_id",
                method: "isEmpty",
                validWhen: false,
                message: "This Credential Id is required.",
            },
            {
                field: "test_credential_secret",
                method: "isEmpty",
                validWhen: false,
                message: "This Credential Secret is required.",
            },
            {
                field: "test_client_id",
                method: "isEmpty",
                validWhen: false,
                message: "This Client Id is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    let options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: {
                ...inputs,
                type: "Paypal",
                sandbox: inputs.sandbox === "true" || inputs.sandbox === true,
            },
        },
    };

    const { createOrUpdateButton } = useFormActions(
        "id",
        "PayPal",
        "",
        MUTATION_UPDATE_PAYPAL_APP,
        [
            {
                query: QUERY_LIST_STORE_APPS,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        "",
        isValid,
        isSubmited,
        "secondary",
    );

    return (
        <Portlet
            centered
            header={{
                title: "PayPal",
                subtitle: "PayPal Information",
            }}
            sticky
            toolbar={toolbar}
        >
            {!false ? (
                <Form centered>
                    <Section>
                        <Row>
                            <Label lg={4} xl={3} />
                            <Col>
                                <SectionTitle md>Information</SectionTitle>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Sandbox
                            </Label>
                            <Col>
                                <span className="k-switch k-switch--md k-switch--icon">
                                    <label>
                                        <Input
                                            checked={
                                                inputs.sandbox ? "checked" : ""
                                            }
                                            name="sandbox"
                                            onChange={inputChange}
                                            type="checkbox"
                                        />
                                        <span />
                                    </label>
                                </span>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Client id
                            </Label>
                            <Col>
                                <Input
                                    invalid={
                                        inputs.sandbox
                                            ? validator.test_client_id.isInvalid
                                            : validator.client_id.isInvalid
                                    }
                                    name={
                                        inputs.sandbox
                                            ? "test_client_id"
                                            : "client_id"
                                    }
                                    onChange={inputChange}
                                    value={
                                        inputs.sandbox
                                            ? inputs.test_client_id
                                            : inputs.client_id
                                    }
                                />
                                <FormFeedback>
                                    {inputs.sandbox
                                        ? validator.test_client_id.message
                                        : validator.client_id.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Credential id
                            </Label>
                            <Col>
                                <Input
                                    invalid={
                                        inputs.sandbox
                                            ? validator.test_credential_id
                                                  .isInvalid
                                            : validator.credential_id.isInvalid
                                    }
                                    name={
                                        inputs.sandbox
                                            ? "test_credential_id"
                                            : "credential_id"
                                    }
                                    onChange={inputChange}
                                    value={
                                        inputs.sandbox
                                            ? inputs.test_credential_id
                                            : inputs.credential_id
                                    }
                                />
                                <FormFeedback>
                                    {inputs.sandbox
                                        ? validator.test_credential_id.message
                                        : validator.credential_id.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Credential secret
                            </Label>
                            <Col>
                                <Input
                                    invalid={
                                        inputs.sandbox
                                            ? validator.test_credential_secret
                                                  .isInvalid
                                            : validator.credential_secret
                                                  .isInvalid
                                    }
                                    name={
                                        inputs.sandbox
                                            ? "test_credential_secret"
                                            : "credential_secret"
                                    }
                                    onChange={inputChange}
                                    value={
                                        inputs.sandbox
                                            ? inputs.test_credential_secret
                                            : inputs.credential_secret
                                    }
                                />
                                <FormFeedback>
                                    {inputs.sandbox
                                        ? validator.test_credential_secret
                                              .message
                                        : validator.credential_secret.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label lg={4} xl={3} />
                            <Col>{createOrUpdateButton}</Col>
                        </FormGroup>
                    </Section>
                </Form>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
        </Portlet>
    );
};

export default AppPayPalForm;
