import { FC, useEffect, useState } from "react";
import classNames from "classnames";
import Icon from "./Icon";

interface ICheckboxProps {
    label: string;
    checked?: boolean;
    disabled?: boolean;
    onChange?: () => void;
    className?: string;
}

const CheckBox: FC<ICheckboxProps> = ({
    label,
    checked,
    disabled,
    onChange,
    className,
}) => {
    const handleChange = () => {
        if (disabled) return;
        onChange?.();
    };

    return (
        <div
            className={classNames("checkbox", className)}
            onClick={handleChange}
            role="button"
        >
            <button
                className={classNames({
                    ["disabled"]: disabled,
                    ["checked"]: checked,
                    ["unchecked"]: !checked,
                })}
            >
                <Icon icon={checked ? "checkbox_variant" : "checkbox"} />
            </button>
            {label ? <label>{label}</label> : null}
        </div>
    );
};

export default CheckBox;
