import React, { useState } from "react";
import { Row, Col, FormGroup, Label, FormFeedback, Button } from "reactstrap";
import { useQuery } from "@apollo/react-hooks";

import {
    Form,
    Input,
    Spinner,
    Portlet,
    Section,
    SectionTitle,
} from "base/components";
import { useMeta, useForm, useValidator, useFormActions } from "base/hooks";

import Content from "../../base/layout/content/Content";
import Navigation from "../../base/layout/content/Navigation";
import {
    MUTATION_CREATE_ACCOUNT,
    MUTATION_UPDATE_ACCOUNT,
    QUERY_GET_ACCOUNT,
    QUERY_ACCOUNT_LIST,
} from "../../apollo/module-operations/business_account";
import routes from "../../config/routes/routes";
import StoreFormModal from "./StoreFormModal";
import AccountStores from "./AccountStores";
import AccountUsers from "../user/AccountUsers";

const AccountForm = () => {
    const { resourceId, isCreate, user } = useMeta();

    const [loading, setLoading] = useState(!isCreate);

    const [showStoreForm, setShowStoreForm] = useState(false);

    const { inputs, setInputs, inputChange } = useForm();

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "first_name",
                method: "isEmpty",
                validWhen: false,
                message: "This first name is required.",
            },
            {
                field: "last_name",
                method: "isEmpty",
                validWhen: false,
                message: "This last name is required.",
            },
            {
                field: "company",
                method: "isEmpty",
                validWhen: false,
                message: "This company is required.",
            },
            {
                field: "phone",
                method: "isEmpty",
                validWhen: false,
                message: "This phone is required.",
            },
            {
                field: "email",
                method: "isEmpty",
                validWhen: false,
                message: "This email is required.",
            },
            {
                field: "address1",
                method: "isEmpty",
                validWhen: false,
                message: "This address1 is required.",
            },
            {
                field: "city",
                method: "isEmpty",
                validWhen: false,
                message: "This city is required.",
            },
            {
                field: "province_code",
                method: "isEmpty",
                validWhen: false,
                message: "This state code is required.",
            },
            {
                field: "country_code",
                method: "isEmpty",
                validWhen: false,
                message: "This country code is required.",
            },
            {
                field: "zip",
                method: "isEmpty",
                validWhen: false,
                message: "This zip is required.",
            },
        ],
        {
            ...inputs,
            ...inputs.address,
        },
    );

    // Call to a query for getting the information of a specific
    // resource but only when is create mode.
    useQuery(QUERY_GET_ACCOUNT, {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            id: resourceId,
        },
        //fetchPolicy: "cache-and-network",
        skip: isCreate,
        onCompleted: resp => {
            // This function could be used to modify the "inputs"
            // object before of working with it in case of the
            // response of the query does not correspond with
            // input structure.

            setInputs(resp.getAccount);

            setLoading(false);
        },
    });

    // This variable allows you to define options
    // for passing it to update mutation or to create mutation.
    // It can be the payload ("variables") or any other
    // option available for Apollo.
    // You can use this in order to tranform the payload in
    // case of "inputs" object does not correspond with
    // payload structure.
    const options = {
        variables: {
            input: inputs,
        },
    };

    // The "id" passed to this hook defines the key field.
    // In this hook you can omit the last two parameters when
    // the form has not validations.
    const { createOrUpdateButton } = useFormActions(
        "id",
        "Account",
        MUTATION_CREATE_ACCOUNT,
        MUTATION_UPDATE_ACCOUNT,
        [
            {
                query: QUERY_ACCOUNT_LIST,
            },
        ],
        options,
        routes.ACCOUNT_DETAIL.route,
        isValid,
        isSubmited,
    );

    return (
        <>
            <Content>
                <Navigation
                    items={[
                        {
                            label: "Detail",
                            icon: "fab fa-wpforms",
                            render: (
                                <>
                                    <Portlet
                                        header={{
                                            title: "Form",
                                            subtitle:
                                                "Provide all required information.",
                                        }}
                                        sticky
                                        toolbar={
                                            <>
                                                <Button
                                                    className="ml-3"
                                                    color="secondary"
                                                    onClick={() => {
                                                        setShowStoreForm(true);
                                                    }}
                                                >
                                                    <i className="fas fa-plus" />
                                                    Create Store
                                                </Button>
                                                {createOrUpdateButton}
                                            </>
                                        }
                                    >
                                        {!loading ? (
                                            <Form>
                                                <Section>
                                                    <Row>
                                                        <SectionTitle md>
                                                            Information
                                                        </SectionTitle>
                                                    </Row>
                                                    <Col md={4}>
                                                        <FormGroup
                                                            className="align-items-center"
                                                            row
                                                        >
                                                            <Label>
                                                                Active
                                                            </Label>
                                                            <span className="k-switch k-switch--md k-switch--icon ml-5">
                                                                <label>
                                                                    <Input
                                                                        checked={
                                                                            inputs.status
                                                                                ? "checked"
                                                                                : ""
                                                                        }
                                                                        name="status"
                                                                        onChange={
                                                                            inputChange
                                                                        }
                                                                        type="checkbox"
                                                                    />
                                                                    <span />
                                                                </label>
                                                            </span>
                                                        </FormGroup>
                                                    </Col>
                                                    <Row>
                                                        <Col sm={4}>
                                                            <FormGroup>
                                                                <Label>
                                                                    Company *
                                                                </Label>

                                                                <Input
                                                                    defaultValue={
                                                                        inputs.company
                                                                    }
                                                                    invalid={
                                                                        validator
                                                                            .company
                                                                            .isInvalid
                                                                    }
                                                                    name="company"
                                                                    onChange={
                                                                        inputChange
                                                                    }
                                                                    placeholder="Company"
                                                                />
                                                                <FormFeedback>
                                                                    {
                                                                        validator
                                                                            .company
                                                                            .message
                                                                    }
                                                                </FormFeedback>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <FormGroup>
                                                                <Label>
                                                                    First Name *
                                                                </Label>

                                                                <Input
                                                                    defaultValue={
                                                                        inputs.first_name
                                                                    }
                                                                    invalid={
                                                                        validator
                                                                            .first_name
                                                                            .isInvalid
                                                                    }
                                                                    name="first_name"
                                                                    onChange={
                                                                        inputChange
                                                                    }
                                                                    placeholder="First name"
                                                                />
                                                                <FormFeedback>
                                                                    {
                                                                        validator
                                                                            .first_name
                                                                            .message
                                                                    }
                                                                </FormFeedback>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <FormGroup row>
                                                                <Label>
                                                                    Last Name *
                                                                </Label>

                                                                <Input
                                                                    defaultValue={
                                                                        inputs.last_name
                                                                    }
                                                                    invalid={
                                                                        validator
                                                                            .last_name
                                                                            .isInvalid
                                                                    }
                                                                    name="last_name"
                                                                    onChange={
                                                                        inputChange
                                                                    }
                                                                    placeholder="Last name"
                                                                />
                                                                <FormFeedback>
                                                                    {
                                                                        validator
                                                                            .last_name
                                                                            .message
                                                                    }
                                                                </FormFeedback>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <FormGroup>
                                                                <Label>
                                                                    Phone Number
                                                                    *
                                                                </Label>

                                                                <Input
                                                                    defaultValue={
                                                                        inputs.phone
                                                                    }
                                                                    invalid={
                                                                        validator
                                                                            .phone
                                                                            .isInvalid
                                                                    }
                                                                    name="phone"
                                                                    onChange={
                                                                        inputChange
                                                                    }
                                                                    placeholder="Phone number"
                                                                />
                                                                <FormFeedback>
                                                                    {
                                                                        validator
                                                                            .phone
                                                                            .message
                                                                    }
                                                                </FormFeedback>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <FormGroup>
                                                                <Label>
                                                                    Email *
                                                                </Label>

                                                                <Input
                                                                    defaultValue={
                                                                        inputs.email
                                                                    }
                                                                    invalid={
                                                                        validator
                                                                            .email
                                                                            .isInvalid
                                                                    }
                                                                    name="email"
                                                                    onChange={
                                                                        inputChange
                                                                    }
                                                                    placeholder="Email"
                                                                />
                                                                <FormFeedback>
                                                                    {
                                                                        validator
                                                                            .email
                                                                            .message
                                                                    }
                                                                </FormFeedback>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Section className="section-separator" />
                                                    <Row>
                                                        <Col>
                                                            <SectionTitle md>
                                                                Location
                                                            </SectionTitle>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={4}>
                                                            <FormGroup>
                                                                <Label>
                                                                    Address 1 *
                                                                </Label>

                                                                <Input
                                                                    defaultValue={
                                                                        inputs.address
                                                                            ? inputs
                                                                                  .address
                                                                                  .address1
                                                                            : ""
                                                                    }
                                                                    invalid={
                                                                        validator
                                                                            .address1
                                                                            .isInvalid
                                                                    }
                                                                    name="address.address1"
                                                                    onChange={
                                                                        inputChange
                                                                    }
                                                                    placeholder="Address 1"
                                                                />
                                                                <FormFeedback>
                                                                    {
                                                                        validator
                                                                            .address1
                                                                            .message
                                                                    }
                                                                </FormFeedback>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <FormGroup>
                                                                <Label>
                                                                    Address 2
                                                                </Label>

                                                                <Input
                                                                    defaultValue={
                                                                        inputs.address
                                                                            ? inputs
                                                                                  .address
                                                                                  .address2
                                                                            : ""
                                                                    }
                                                                    name="address.address2"
                                                                    onChange={
                                                                        inputChange
                                                                    }
                                                                    placeholder="Address 2"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <FormGroup>
                                                                <Label>
                                                                    City *
                                                                </Label>

                                                                <Input
                                                                    defaultValue={
                                                                        inputs.address
                                                                            ? inputs
                                                                                  .address
                                                                                  .city
                                                                            : ""
                                                                    }
                                                                    invalid={
                                                                        validator
                                                                            .city
                                                                            .isInvalid
                                                                    }
                                                                    name="address.city"
                                                                    onChange={
                                                                        inputChange
                                                                    }
                                                                    placeholder="City"
                                                                />
                                                                <FormFeedback>
                                                                    {
                                                                        validator
                                                                            .city
                                                                            .message
                                                                    }
                                                                </FormFeedback>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <FormGroup>
                                                                <Label>
                                                                    State Code *
                                                                </Label>

                                                                <Input
                                                                    defaultValue={
                                                                        inputs.address
                                                                            ? inputs
                                                                                  .address
                                                                                  .province_code
                                                                            : ""
                                                                    }
                                                                    invalid={
                                                                        validator
                                                                            .province_code
                                                                            .isInvalid
                                                                    }
                                                                    name="address.province_code"
                                                                    onChange={
                                                                        inputChange
                                                                    }
                                                                    placeholder="State Code"
                                                                />
                                                                <FormFeedback>
                                                                    {
                                                                        validator
                                                                            .province_code
                                                                            .message
                                                                    }
                                                                </FormFeedback>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <FormGroup>
                                                                <Label>
                                                                    Zip *
                                                                </Label>

                                                                <Input
                                                                    defaultValue={
                                                                        inputs.address
                                                                            ? inputs
                                                                                  .address
                                                                                  .zip
                                                                            : ""
                                                                    }
                                                                    invalid={
                                                                        validator
                                                                            .zip
                                                                            .isInvalid
                                                                    }
                                                                    name="address.zip"
                                                                    onChange={
                                                                        inputChange
                                                                    }
                                                                    placeholder="Zip"
                                                                />
                                                                <FormFeedback>
                                                                    {
                                                                        validator
                                                                            .zip
                                                                            .message
                                                                    }
                                                                </FormFeedback>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label>
                                                                    Country Code
                                                                    *
                                                                </Label>
                                                                <Input
                                                                    defaultValue={
                                                                        inputs.address
                                                                            ? inputs
                                                                                  .address
                                                                                  .country_code
                                                                            : ""
                                                                    }
                                                                    invalid={
                                                                        validator
                                                                            .country_code
                                                                            .isInvalid
                                                                    }
                                                                    name="address.country_code"
                                                                    onChange={
                                                                        inputChange
                                                                    }
                                                                    placeholder="Country code"
                                                                />
                                                                <FormFeedback>
                                                                    {
                                                                        validator
                                                                            .country_code
                                                                            .message
                                                                    }
                                                                </FormFeedback>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Section>
                                            </Form>
                                        ) : (
                                            <>
                                                <Spinner
                                                    color="metal"
                                                    show
                                                    sm
                                                />
                                                &nbsp;&nbsp;Loading
                                            </>
                                        )}
                                    </Portlet>
                                </>
                            ),
                        },
                        {
                            label: "Stores",
                            icon: "fas fa-store",
                            render: (
                                <AccountStores
                                    toolbar={
                                        <>
                                            <Button
                                                className="ml-3"
                                                color="secondary"
                                                onClick={() => {
                                                    setShowStoreForm(true);
                                                }}
                                            >
                                                <i className="fas fa-plus" />
                                                Create Store
                                            </Button>
                                            {createOrUpdateButton}
                                        </>
                                    }
                                />
                            ),
                        },
                        {
                            label: "Users",
                            icon: "fas fa-users",
                            render: (
                                <AccountUsers
                                    toolbar={
                                        <>
                                            <Button
                                                className="ml-3"
                                                color="secondary"
                                                onClick={() => {
                                                    setShowStoreForm(true);
                                                }}
                                            >
                                                <i className="fas fa-plus" />
                                                Create Store
                                            </Button>
                                            {createOrUpdateButton}
                                        </>
                                    }
                                />
                            ),
                        },
                    ]}
                />
                <StoreFormModal
                    isOpen={showStoreForm}
                    toggle={setShowStoreForm}
                />
            </Content>
        </>
    );
};

export default AccountForm;
