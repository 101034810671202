import React from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { Portlet, Section, SectionTitle } from "../../base/components/portlet";
import { FormGroup, Col, Label, FormFeedback, Row } from "reactstrap";
import Input from "../../base/components/form/input/Input";
import Form from "../../base/components/form/Form";
import useMeta from "../../base/hooks/useMeta";
import useForm from "../../base/hooks/form/useForm";
import useValidator from "../../base/hooks/form/useValidator";
import useFormActions from "../../base/hooks/form/useFormActions";
import {
    MUTATION_UPDATE_QUICKBOOK_APP,
    QUERY_LIST_STORE_APPS,
} from "../../apollo/module-operations/app";
import _ from "lodash";

const AppQuickBookForm = ({ toolbar, app }) => {
    const { user } = useMeta();

    const { inputs, setInputs, inputChange } = useForm();

    if (!_.get(inputs, "id", null)) {
        setInputs(app);
    }

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "access_token",
                method: "isEmpty",
                validWhen: false,
                message: "This access token Id is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    let options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: {
                ...inputs,
                type: "QuickBook",
            },
        },
    };

    const { createOrUpdateButton } = useFormActions(
        "id",
        "QuickBook",
        "",
        MUTATION_UPDATE_QUICKBOOK_APP,
        [
            {
                query: QUERY_LIST_STORE_APPS,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        "",
        isValid,
        isSubmited,
        "secondary",
    );

    return (
        <Portlet
            centered
            header={{
                title: "QuickBook",
                subtitle: "QuickBook Information",
            }}
            sticky
            toolbar={toolbar}
        >
            {!false ? (
                <Form centered>
                    <Section>
                        <Row>
                            <Label lg={4} xl={3} />
                            <Col>
                                <SectionTitle md>Information</SectionTitle>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Access Token
                            </Label>
                            <Col>
                                <Input
                                    defaultValue={
                                        inputs.access_token
                                            ? inputs.access_token
                                            : ""
                                    }
                                    invalid={validator.access_token.isInvalid}
                                    name="access_token"
                                    onChange={inputChange}
                                />
                                <FormFeedback>
                                    {validator.access_token.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Refresh Token
                            </Label>
                            <Col>
                                <Input
                                    defaultValue={
                                        inputs.refresh_token
                                            ? inputs.refresh_token
                                            : ""
                                    }
                                    name="refresh_token"
                                    onChange={inputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Expires In
                            </Label>
                            <Col>
                                <Input
                                    defaultValue={
                                        inputs.expires_in
                                            ? inputs.expires_in
                                            : ""
                                    }
                                    name="expires_in"
                                    onChange={inputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Token Type
                            </Label>
                            <Col>
                                <Input
                                    defaultValue={
                                        inputs.token_type
                                            ? inputs.token_type
                                            : ""
                                    }
                                    name="token_type"
                                    onChange={inputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Realm Id
                            </Label>
                            <Col>
                                <Input
                                    defaultValue={
                                        inputs.realmid ? inputs.realmid : ""
                                    }
                                    name="realmid"
                                    onChange={inputChange}
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label lg={4} xl={3} />
                            <Col>{createOrUpdateButton}</Col>
                        </FormGroup>
                    </Section>
                </Form>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
        </Portlet>
    );
};

export default AppQuickBookForm;
