import { FC } from "react";
import classNames from "classnames";

interface ISpinnerProps {
    show: boolean;
    color?: string;
    sm?: boolean;
}
const Spinner: FC<ISpinnerProps> = ({ show, color, sm }) => {
    return show ? (
        <div
            className={classNames("spinner", `text-${color || "light"}`, {
                "spinner-sm": sm,
            })}
        />
    ) : null;
};

export default Spinner;
