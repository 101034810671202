import { useState } from "react";
import KanbanModal from "./kanban/KanbanModal";
import {
    useInfiniteHits,
    useInstantSearch,
} from "react-instantsearch-hooks-web";
import { AlgoliaOrder } from "@/types";
import { getAlgoliaClient } from "algolia";
import { useHistory } from "react-router-dom";
import routes from "config/routes/routes";
import { replace } from "lodash";
import { Button } from "reactstrap";
import OrderTable from "./OrderTable";
import { Icon, Spinner } from "@/components";

const OrderTableList = () => {
    const { status } = useInstantSearch();
    const {
        hits: orders,
        showMore,
        isLastPage,
    } = useInfiniteHits<AlgoliaOrder>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [algoliaOrder, setAlgoliaOrder] = useState<AlgoliaOrder>();

    function handleModalToggle(order: AlgoliaOrder) {
        setIsModalOpen(prevIsModalOpen => !prevIsModalOpen);
        setAlgoliaOrder(order);
        const client = getAlgoliaClient();
        client.clearCache();
    }

    const history = useHistory();

    function handleDetailOpen(id) {
        const url = replace(routes.ORDER_DETAIL.route, ":id", id);
        history.push(url);
    }

    return (
        <>
            <OrderTable
                handleDetailOpen={handleDetailOpen}
                handleModalToggle={handleModalToggle}
                orders={orders || []}
            />
            <Button
                className="mt-3 float-right load_more__button"
                color="metal"
                disabled={isLastPage}
                onClick={() => showMore()}
            >
                {status === "loading" ? (
                    <Spinner color="primary" show={true} sm />
                ) : (
                    <Icon icon={"refresh"} />
                )}
                Show more
            </Button>
            {algoliaOrder ? (
                <KanbanModal
                    algoliaOrder={algoliaOrder}
                    handleModalToggle={handleModalToggle}
                    isModalOpen={isModalOpen}
                />
            ) : null}
        </>
    );
};

export default OrderTableList;
