import { useTableActions } from "@/hooks";
import { useInstantSearch } from "react-instantsearch-hooks-web";

export const RefreshButton = () => {
    const { status, refresh: refetch } = useInstantSearch();

    const { refreshButton } = useTableActions(
        refetch,
        "",
        status === "loading",
    );

    return refreshButton;
};
