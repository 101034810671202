import React, { useState, useEffect } from "react";
import { Row, Col, FormGroup, Label, FormFeedback, Button } from "reactstrap";
import { useQuery } from "@apollo/react-hooks";
import Content from "../../base/layout/content/Content";
import { Portlet, Section, SectionTitle } from "../../base/components/portlet";
import Form from "../../base/components/form/Form";
import Input from "../../base/components/form/input/Input";
import Spinner from "../../base/components/spinner/Spinner";
import useMeta from "../../base/hooks/useMeta";
import useForm from "../../base/hooks/form/useForm";
import useValidator from "../../base/hooks/form/useValidator";
import useFormActions from "../../base/hooks/form/useFormActions";
import { QUERY_GET_NAVIGATION } from "../../apollo/module-operations/navigation";
import {
    MUTATION_CREATE_NAVIGATION,
    MUTATION_UPDATE_NAVIGATION,
    QUERY_NAVIGATION_LIST,
} from "../../apollo/module-operations/navigation";
import routes from "../../config/routes/routes";
import NavigationItem from "./NavigationItem";
import NavigationItemForm from "./NavigationItemForm";
import Nestable from "react-nestable";
import _ from "lodash";

const NavigationForm = () => {
    const { resourceId, isCreate, user } = useMeta();

    const [loading, setLoading] = useState(!isCreate);

    const [items, setItems] = useState([]);

    const [isOpen, toggle] = useState(false);

    const { inputs, setInputs, inputChange, genericChange } = useForm();

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "title",
                method: "isEmpty",
                validWhen: false,
                message: "This title is required.",
            },
            {
                field: "slug",
                method: "isEmpty",
                validWhen: false,
                message: "This slug is required.",
            },
        ],
        { ...inputs },
    );

    useQuery(QUERY_GET_NAVIGATION, {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            navigation_id: resourceId,
        },
        skip: user.loading || isCreate,
        onCompleted: resp => {
            setInputs(resp.getNavigation);

            setLoading(false);
        },
    });

    let options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: inputs,
        },
    };

    const { createOrUpdateButton } = useFormActions(
        "id",
        "Navigation",
        MUTATION_CREATE_NAVIGATION,
        MUTATION_UPDATE_NAVIGATION,
        [
            {
                query: QUERY_NAVIGATION_LIST,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        routes.NAVIGATION_DETAIL.route,
        isValid,
        isSubmited,
    );

    // Recursive algorithm for nesting navigation items
    useEffect(() => {
        const nesting = (items, item) => {
            const children = _.sortBy(
                _.filter(items, i => i.parent_menu_item_id === item.id),
                "position",
            );

            item.children = children.length
                ? _.map(children, ch => nesting(items, ch))
                : [];

            return item;
        };

        const nested = nesting(_.cloneDeep(inputs.menu_items), { id: null });

        setItems(nested.children);
    }, [inputs.menu_items]);

    // Get the parent item using searching tree algorithm
    const getParent = (result, item) => {
        let found = false;

        const search = parent => {
            let _parent = parent;

            const exist = _.some(parent.children, ch => ch.id === item.id);

            found = exist;

            if (exist) return parent;

            _.forEach(parent.children, i => {
                _parent = search(i);

                if (found) return false;
            });

            return _parent;
        };

        return search({ id: null, children: result });
    };

    const changeItems = (result, item) => {
        const parent = getParent(result, item);

        const menuItems = _.cloneDeep(inputs.menu_items);

        // Setting the new position and parent id
        _.forEach(parent.children, (ch, index) => {
            const realItem = _.find(menuItems, i => i.id === ch.id);

            realItem.parent_menu_item_id = parent.id;

            realItem.position = index;
        });

        genericChange(menuItems, "menu_items");
    };

    return (
        <Content>
            <Portlet
                header={{
                    title: "Form",
                    subtitle: "Provide all required information.",
                }}
                sticky
                toolbar={
                    <>
                        <Button color="metal" onClick={() => toggle(true)}>
                            <i className="fas fa-stream" />
                            Add item
                        </Button>
                        {createOrUpdateButton}
                    </>
                }
            >
                {!loading ? (
                    <Form centered>
                        <Section>
                            <Row>
                                <Label lg={4} xl={3} />
                                <Col>
                                    <SectionTitle md>Navigation</SectionTitle>
                                </Col>
                            </Row>
                            <FormGroup row>
                                <Label lg={4} xl={3}>
                                    Active
                                </Label>
                                <Col>
                                    <span className="k-switch k-switch--md k-switch--icon">
                                        <label>
                                            <Input
                                                checked={
                                                    inputs.status
                                                        ? "checked"
                                                        : ""
                                                }
                                                name="status"
                                                onChange={inputChange}
                                                type="checkbox"
                                            />
                                            <span />
                                        </label>
                                    </span>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label lg={4} xl={3}>
                                    Title
                                </Label>
                                <Col>
                                    <Input
                                        defaultValue={
                                            inputs.title ? inputs.title : ""
                                        }
                                        invalid={validator.title.isInvalid}
                                        name="title"
                                        onChange={inputChange}
                                    />
                                    <FormFeedback>
                                        {validator.title.message}
                                    </FormFeedback>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label lg={4} xl={3}>
                                    Slug
                                </Label>
                                <Col>
                                    <Input
                                        defaultValue={
                                            inputs.slug ? inputs.slug : ""
                                        }
                                        invalid={validator.slug.isInvalid}
                                        name="slug"
                                        onChange={inputChange}
                                    />
                                    <FormFeedback>
                                        {validator.slug.message}
                                    </FormFeedback>
                                </Col>
                            </FormGroup>
                            <Row>
                                <Label lg={4} xl={3} />
                                <Col>
                                    <SectionTitle md>Menu items</SectionTitle>
                                </Col>
                            </Row>
                            <FormGroup row>
                                <Label lg={4} xl={3}>
                                    items
                                </Label>
                                <Col lg={8} xl={9}>
                                    {!(inputs.menu_items || []).length && (
                                        <p className="text-muted col-form-label">
                                            No items added.
                                        </p>
                                    )}
                                    <Nestable
                                        handler={
                                            <i className="fas fa-grip-vertical" />
                                        }
                                        items={items}
                                        onChange={changeItems}
                                        renderCollapseIcon={({
                                            isCollapsed,
                                        }) => (
                                            <i
                                                className={`fas fa-caret-${
                                                    isCollapsed ? "down" : "up"
                                                }`}
                                            />
                                        )}
                                        renderItem={({
                                            item,
                                            handler,
                                            collapseIcon,
                                        }) => (
                                            <NavigationItem
                                                collapseIcon={collapseIcon}
                                                genericChange={genericChange}
                                                handler={handler}
                                                item={item}
                                                items={inputs.menu_items}
                                            />
                                        )}
                                    />
                                </Col>
                            </FormGroup>
                        </Section>
                        <NavigationItemForm
                            genericChange={genericChange}
                            isCreate
                            isOpen={isOpen}
                            items={inputs.menu_items}
                            toggle={toggle}
                        />
                    </Form>
                ) : (
                    <>
                        <Spinner color="metal" show sm />
                        &nbsp;&nbsp;Loading
                    </>
                )}
            </Portlet>
        </Content>
    );
};

export default NavigationForm;
