import React from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import Icon from "base/components/Icon";
import { Spinner } from "@/components";

const useTableActions = (refetch, route, loading = false) => {
    const refreshButton = (
        <Button
            className="ml-2"
            color="metal"
            onClick={() => {
                refetch();
            }}
        >
            {loading ? (
                <Spinner className="mr-2" color="primary" show={true} sm />
            ) : (
                <span className="mr-2">
                    <Icon icon={"refresh"} />
                </span>
            )}
            Refresh
        </Button>
    );

    const addNewButton = (
        <Link className="ml-2 btn btn-primary" to={route}>
            <i className="fas fa-plus" />
            Add New
        </Link>
    );

    return { refreshButton, addNewButton };
};

export default useTableActions;
