import React from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { Portlet, Section, SectionTitle } from "../../base/components/portlet";
import { FormGroup, Col, Label, Row } from "reactstrap";
import Input from "../../base/components/form/input/Input";
import Form from "../../base/components/form/Form";

const GoogleForm = ({ toolbar, inputs, inputChange, loading }) => {
    return (
        <Portlet
            className="business__settings"
            header={{
                title: "Google",
                subtitle: "Google GTM Information",
            }}
            sticky
            toolbar={toolbar}
        >
            {!loading ? (
                <Form>
                    <Section>
                        <Row>
                            <Col>
                                <SectionTitle md>Information</SectionTitle>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Label className="row-label">GTM Active</Label>
                            <Col>
                                <span className="k-switch k-switch--md k-switch--icon">
                                    <label>
                                        <Input
                                            checked={
                                                inputs.gtm_active
                                                    ? "checked"
                                                    : ""
                                            }
                                            name="gtm_active"
                                            onChange={inputChange}
                                            type="checkbox"
                                        />
                                        <span />
                                    </label>
                                </span>
                            </Col>
                        </FormGroup>
                        {inputs.gtm_active && (
                            <FormGroup row>
                                <Col lg={4}>
                                    <Label>GTM ID</Label>
                                    <Input
                                        defaultValue={
                                            inputs.gtm_id ? inputs.gtm_id : ""
                                        }
                                        name="gtm_id"
                                        onChange={inputChange}
                                    />
                                </Col>
                            </FormGroup>
                        )}
                    </Section>
                </Form>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
        </Portlet>
    );
};

export default GoogleForm;
