import React, { useState } from "react";
import { useLocation, matchPath } from "react-router-dom";
import classNames from "classnames";
import AsideItem from "./AsideItem";
import { Icon } from "@/components";
import _ from "lodash";

const AsideSubMenu = ({ label, icon, items, apps }) => {
    const [open, isOpen] = useState(false);
    const location = useLocation();

    const match = _.find(items, i =>
        matchPath(location.pathname, {
            path: i.route,
            exact: true,
        }),
    );

    let totalRenderItems = 0;
    items = items.map(e => {
        if (!e.handler || (e.handler && apps.includes(e.handler))) {
            totalRenderItems++;

            return { ...e, showItem: true };
        }
    });

    if (totalRenderItems == 0) {
        return;
    }

    return (
        <li
            className={classNames(
                "k-menu__item k-menu__item--submenu",
                { "k-menu__item--open": open },
                { "k-menu__item--here": match },
            )}
            onClick={() => {
                isOpen(!open);
            }}
        >
            <div className="k-menu__link k-menu__toggle">
                {/* <i className={`k-menu__link-icon ${icon}`} /> */}
                <i className={`k-menu__link-icon`}>
                    <Icon color="#727A94" icon={icon} />
                </i>
                <span className="k-menu__link-text">{label}</span>
                <i className="k-menu__ver-arrow fas fa-chevron-right" />
            </div>
            <div
                className="k-menu__submenu"
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                <ul className="k-menu__subnav">
                    {items.map(i => {
                        if (!i.showItem) {
                            return;
                        }

                        return <AsideItem key={i.route} {...i} />;
                    })}
                </ul>
            </div>
        </li>
    );
};

export default AsideSubMenu;
