import { FC } from "react";
import { Col, Row } from "reactstrap";
import { Section } from "@/components";
import { IDataPaneProps } from "@/types";
import ActivityLogTable from "./ActivityLogTable";

const ActivityLogPane: FC<IDataPaneProps> = ({ order, loading }) => {
    if (loading) {
        return (
            <Section>
                <Row>
                    <Col className="order__status">
                        <div className="content-placeholder">
                            <div className="cp-w-lg cp-h-md" />
                        </div>
                        <div className="content-placeholder">
                            <div className="cp-w-lg cp-h-md" />
                        </div>
                    </Col>
                </Row>
            </Section>
        );
    }

    return (
        <Section>
            <Row>
                <Col>
                    <h5>Activity logs</h5>
                    <ActivityLogTable
                        activityLogs={order?.activity_logs}
                        loading={loading}
                    />
                </Col>
            </Row>
        </Section>
    );
};

export default ActivityLogPane;
