import React from "react";
import ReactSummernote from "react-summernote";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/tooltip";
import "bootstrap/js/dist/modal";
import { toolbar } from "../../../../config/components/summernote";

const RichEditor = ({ name, placeholder, onChange, ...props }) => {
    return (
        <ReactSummernote
            onChange={o => {
                onChange(o, name);
            }}
            options={{
                toolbar,
                placeholder,
                dialogsFade: true,
                disableDragAndDrop: true,
            }}
            {...props}
        />
    );
};

export default RichEditor;
