import React, { useState } from "react";
import Content from "../../base/layout/content/Content";
import { useQuery } from "@apollo/react-hooks";
import useTableActions from "../../base/hooks/table/useTableActions";
import useRowActions from "../../base/hooks/table/useRowActions";
import routes from "../../config/routes/routes";
import {
    QUERY_SYSTEM_APP_LIST,
    MUTATION_REMOVE_SYSTEM_APP,
} from "../../apollo/module-operations/app";
import paginationFactory from "react-bootstrap-table2-paginator";
import { paginationOptions } from "../../config/components/table";

import { Portlet } from "../../base/components/portlet";

import useMeta from "../../base/hooks/useMeta.js";

import FiltersToggle from "../../base/components/table/FiltersToggle";
import Filters from "../../base/components/table/Filters";
import Table from "../../base/components/table/Table";
import { FILTER_TYPES } from "react-bootstrap-table2-filter";
import useFilter from "../../base/hooks/table/useFilter";
import classNames from "classnames";

const Apps = () => {
    const { user } = useMeta();

    //---------------------------Defining filters-----------------------------
    const { TEXT } = FILTER_TYPES;

    const [showFilters, setShowFilters] = useState(false);

    const filterName = useFilter("name", TEXT, "Name", "");

    const filterHandler = useFilter("handler", TEXT, "Handler", "");

    const filters = [filterName, filterHandler];
    // -----------------------------------------------------------------------

    const { loading, data, refetch, networkStatus } = useQuery(
        QUERY_SYSTEM_APP_LIST,
        {
            skip: user.loading,
            notifyOnNetworkStatusChange: true,
        },
    );

    const { refreshButton, addNewButton } = useTableActions(
        refetch,
        routes.APP_CREATE.route,
    );

    const { confirm, update } = useRowActions(
        [
            {
                query: QUERY_SYSTEM_APP_LIST,
            },
        ],
        MUTATION_REMOVE_SYSTEM_APP,
        routes.APP_DETAIL.route,
        "App",
    );

    const defaultSorted = [
        {
            dataField: "created_at",
            order: "desc",
        },
    ];

    const columns = [
        {
            dataField: "id",
            text: "Id",
            hidden: true,
        },
        {
            dataField: "name",
            text: "Name",
            headerClasses: "align-top",
            sort: true,
        },
        {
            dataField: "handler",
            text: "Handler",
            headerClasses: "align-top",
            sort: true,
        },
        {
            dataField: "category",
            text: "Category",
            headerClasses: "align-top",
            sort: true,
        },
        {
            dataField: "status",
            text: "Status",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "80px" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <span
                        className={classNames(
                            "badge badge-pill",
                            `badge-${cell ? "success" : "secondary"}`,
                        )}
                    >
                        {cell ? "Yes" : "No"}
                    </span>
                );
            },
        },
    ];

    const actions = [
        {
            label: "Update",
            icon: "fas fa-pencil-alt",
            action: (cell, row, rowIndex, formatExtraData) => {
                update(row.id);
            },
        },
    ];

    const _loading = loading || networkStatus === 4;

    const _data = data && data.listApps;

    return (
        <>
            <Content>
                <Portlet
                    header={{
                        title: "Information",
                        subtitle: "Select any App for more details",
                    }}
                    sticky
                    toolbar={
                        <>
                            <FiltersToggle setShowFilters={setShowFilters} />
                            {refreshButton}
                            {addNewButton}
                        </>
                    }
                >
                    <Filters filters={filters} />
                    <Table
                        actions={actions}
                        columns={columns}
                        data={_data}
                        defaultSorted={defaultSorted}
                        filters={filters}
                        keyField="id"
                        loading={_loading}
                        pagination={paginationFactory(paginationOptions)}
                        showFilters={showFilters}
                    />
                </Portlet>
            </Content>
            {confirm}
        </>
    );
};

export default Apps;
