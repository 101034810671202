import React from "react";
import classNames from "classnames";

const PortletFooter = ({ children, sm }) => {
    return (
        <div
            className={classNames("k-portlet__foot", {
                "k-portlet__foot--sm": sm,
            })}
        >
            {children}
        </div>
    );
};

export default PortletFooter;
