import pluginSocial from "grapesjs-plugin-social";
import pluginHeader from "grapesjs-plugin-header";
import pluginAvance from "grapesjs-blocks-avance";
import pluginTooltip from "grapesjs-tooltip";
import pluginTabs from "grapesjs-tabs";
import pluginTyped from "grapesjs-typed";
import pluginFilter from "grapesjs-style-filter";
import pluginBg from "grapesjs-style-bg";
import pluginCkeditor from "grapesjs-plugin-ckeditor";
// import pluginImage from "grapesjs-tui-image-editor";
import pluginSlider from "grapesjs-lory-slider";
import pluginPresetWebpage from "grapesjs-preset-webpage";

import pluginConfig from "../plugins/grapesjs-gc-config";
import pluginGtm from "../plugins/grapesjs-gc-gtm";
import pluginBasic from "../plugins/grapesjs-gc-basic";
import pluginAssets from "../plugins/grapesjs-gc-assets";
import pluginStorage from "../plugins/grapesjs-gc-storage";
import pluginEcommerce from "../plugins/grapesjs-gc-ecommerce";

export default [
    pluginSocial,
    pluginHeader,
    pluginAvance,
    pluginTooltip,
    pluginTabs,
    pluginTyped,
    pluginFilter,
    pluginBg,
    pluginCkeditor, // <script src="https://cdn.ckeditor.com/4.12.1/standard-all/ckeditor.js"></script>
    // pluginImage,
    pluginSlider,
    pluginPresetWebpage,

    pluginConfig,
    pluginGtm,
    pluginBasic,
    pluginAssets,
    pluginStorage,
    pluginEcommerce,
];
