export default (editor, opts = {}) => {
    const options = {
        saveId: "save",
        saveClassName: "fas fa-save",
        saveCommand: "save",
        loadId: "load",
        // loadClassName: "fas fa-load",
        loadCommand: "load",
        publishId: "publish",
        publishClassName: "fa fa-rocket",
        publishCommand: "publish",
        templateId: "template",
        templateClassName: "fa fa-bookmark",
        templateCommand: "template",
        ...opts,
    };

    loadOptions(editor, options);

    loadCommands(editor, options);

    loadStorages(editor, options);
};

const loadOptions = (editor, opts) => {
    const {
        saveId,
        saveClassName,
        saveCommand,
        publishId,
        publishClassName,
        publishCommand,
        templateId,
        templateClassName,
        templateCommand,
    } = opts;

    editor.Panels.addButton("options", {
        id: templateId,
        className: templateClassName,
        command: templateCommand,
        attributes: { title: "Select template" },
        active: false,
    });

    editor.Panels.addButton("options", {
        id: publishId,
        className: publishClassName,
        command: publishCommand,
        attributes: { title: "Publish" },
        active: false,
    });

    editor.Panels.addButton("options", {
        id: saveId,
        className: saveClassName,
        command: saveCommand,
        attributes: { title: "Save" },
        active: false,
    });
};

const loadCommands = (editor, opts) => {
    const {
        saveId,
        saveCommand,
        loadCommand,
        publishId,
        publishCommand,
        templateId,
        templateCommand,
    } = opts;

    editor.Commands.add(saveCommand, {
        run: (editor, sender) => {
            editor.store();

            editor.Commands.get(saveCommand).stop(editor);
        },
        stop: (editor, sender) => {
            editor.Panels.getButton("options", saveId).set("active", false);
        },
    });

    editor.Commands.add(loadCommand, {
        run: (editor, sender, project) => {
            editor.StorageManager.get("s3-storage").project = project;

            editor.load();

            editor.Commands.get(loadCommand).stop(editor);
        },
    });

    editor.Commands.add(templateCommand, {
        run: (editor, sender) => {
            opts.handleTemplate();

            editor.Commands.get(templateCommand).stop(editor);
        },
        stop: (editor, sender) => {
            editor.Panels.getButton("options", templateId).set("active", false);
        },
    });

    editor.Commands.add(publishCommand, {
        run: (editor, sender) => {
            if (validate(editor, opts)) {
                const attrs = attributes(editor);

                const body = `<!doctype html><html lang="en">
                    <head><meta charset="utf-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5, shrink-to-fit=no">
                    <meta name="description" content="${attrs.description}">
                    <meta name="keyworkds" content="${attrs.keywords}">
                    <title>${attrs.title}</title>
                    <style>${editor.getCss()}</style></head>
                    <body>${editor.getHtml()}</body><html>`;

                opts.handlePublish({
                    url: attrs.url,
                    title: attrs.title,
                    project: project(editor),
                    body,
                });
            }

            editor.Commands.get(publishCommand).stop(editor);
        },
        stop: (editor, sender) => {
            editor.Panels.getButton("options", publishId).set("active", false);
        },
    });
};

const loadStorages = (editor, opts) => {
    editor.StorageManager.add("s3-storage", {
        project: "",
        load() {
            const json = JSON.parse(
                editor.StorageManager.get("s3-storage").project,
            );

            editor.getWrapper().set({
                attributes: json.wrapper,
            });

            editor.setComponents(json.components);

            editor.setStyle(json.style);
        },
        store() {
            if (!validate(editor, opts)) return;

            const attrs = attributes(editor);

            opts.handleSave({
                url: attrs.url,
                title: attrs.title,
                project: project(editor),
            });
        },
    });
};

const project = editor => {
    const project = JSON.stringify({
        wrapper: editor.getWrapper().get("attributes"),
        components: editor.getComponents(),
        style: editor.getStyle(),
    });

    return project;
};

const attributes = editor => {
    const attrs = {};

    const traits = editor.getWrapper().get("traits");

    traits.forEach(t => {
        attrs[t.get("name")] = t.get("value");
    });

    return attrs;
};

const validate = (editor, opts) => {
    let valid = true;

    const attrs = attributes(editor);

    if ((attrs.title || "").trim() === "") {
        valid = false;

        opts.notify(opts.validationMessage, "error");

        editor.select(editor.getWrapper());

        const tm = editor.Panels.getButton("views", "open-tm");

        tm.set("active", 1);

        document.querySelectorAll(".gjs-trt-traits input")[1].focus();
    }

    const traits = document.querySelectorAll(".gjs-trt-trait__wrp");

    traits.forEach(t => t.classList[valid ? "remove" : "add"]("is-invalid"));

    return valid;
};
