import React, { useState } from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { Portlet, Section, SectionTitle, Table } from "@/components";
import { Button, FormGroup, Col, Label, Row, FormFeedback } from "reactstrap";
import Input from "../../base/components/form/input/Input";
import Form from "../../base/components/form/Form";
import {
    useFormActions,
    useConfirm,
    useForm,
    useMeta,
    useNotification,
} from "@/hooks";
import { useMutation } from "@apollo/react-hooks";
import Select from "../../base/components/form/select/Select";
import { onfleetAsignModeOptions } from "../../config/constant";
import {
    MUTATION_UPDATE_ONFLEET_APP,
    QUERY_LIST_STORE_APPS,
} from "../../apollo/module-operations/app";
import _ from "lodash";
import MerchantForm from "./MerchantForm ";
import useValidator from "../../base/hooks/form/useValidator";

const OnFleetForm = ({ app, loading }) => {
    const { user } = useMeta();
    const notify = useNotification();
    const [merchants, setMerchants] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [isCreate, setIsCreate] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [rowSelect, setRowSelect] = useState(null);
    const [isDelete, setIsDelete] = useState(false);
    const [isloading, setIsLoading] = useState(false);
    const { inputs, setInputs, selectValue, selectChange, inputChange } =
        useForm();

    if (!_.get(inputs, "id", null)) {
        setInputs(app);
    }

    let options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: {
                ...inputs,
                id: inputs.id,
                user: inputs.user,
                sandbox: inputs.sandbox,
                test_user: inputs.test_user,
                auto_assign: inputs.auto_assign,
                auto_assign_mode: inputs.auto_assign_mode,
                merchants: inputs.merchants,
                type: "OnFleet",
            },
        },
    };

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "id",
                method: "isEmpty",
                validWhen: false,
                message: "This app id is required.",
            },

            {
                field: "user",
                method: "isEmpty",
                validWhen: false,
                message: "This user is required.",
            },

            {
                field: "test_user",
                method: "isEmpty",
                validWhen: false,
                message: "This test user is required.",
            },
            {
                field: "auto_assign_mode",
                method: "isEmpty",
                validWhen: false,
                message: "This auto assign mode is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    const [confirm, launch] = useConfirm(() => {
        mutate({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: {
                    ...inputs,
                    merchants: merchants,
                },
            },
        });
    }, `Are You Sure You Want To Remove The Merchant ?`);

    const [mutate] = useMutation(MUTATION_UPDATE_ONFLEET_APP, {
        refetchQueries: [],
        onCompleted: resp => {
            handleCancel();
            setIsUpdating(false);
            setIsDelete(false);
            setInputs({ ...inputs, merchants });
            setIsLoading(false);
            notify(
                `Setting Was ${
                    isCreate ? "Created" : !isDelete ? "Updated" : "Removed"
                } Successfully"`,
                "success",
            );
        },
        onError: () => {
            setIsUpdating(false);
            notify(
                `Error ${
                    isCreate ? "Updating" : !isDelete ? "Creating" : "Removing"
                }  The Merchant, If The Error Persist Please Contact Support.`,
                "error",
            );
        },
    });

    const { createOrUpdateButton } = useFormActions(
        "id",
        "OnFleet",
        "",
        MUTATION_UPDATE_ONFLEET_APP,
        [
            {
                query: QUERY_LIST_STORE_APPS,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        "",
        isValid,
        isSubmited,
        "primary",
    );

    const columns = [
        {
            dataField: "store_id",
            text: "Store Id",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.store_id || ""}`}</div>;
            },
        },
        {
            dataField: "merchant",
            text: "Merchant",
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{`${row.merchant || ""}`}</div>;
            },
        },
    ];

    const actions = [
        {
            label: "Update",
            action: async (cell, row, rowIndex, formatExtraData) => {
                setIsCreate(false);
                setRowSelect(rowIndex);
                setShowForm(true);
            },
        },
        {
            label: "Delete",
            action: async (cell, row, rowIndex, formatExtraData) => {
                setIsDelete(true);
                setIsCreate(false);
                setMerchants(
                    _.filter(
                        inputs.merchants,
                        (i, index) => index !== rowIndex,
                    ),
                );
                launch();
            },
        },
    ];

    const handlerUpdatingOrCreate = array => {
        setIsUpdating(true);
        mutate({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: {
                    ...inputs,
                    store_id: inputs.store_id,
                    merchants: array,
                },
            },
        });
    };

    const handleCancel = () => {
        setIsUpdating(false);
        setShowForm(false);
    };

    const _merchants = (inputs && inputs.merchants) || [];

    return (
        <>
            <Portlet
                className="business__settings"
                header={{
                    title: "OnFleet",
                    subtitle: "Onfleet Information",
                }}
                sticky
                toolbar={
                    <>
                        <Button
                            className="ml-3"
                            color="metal"
                            onClick={() => {
                                setIsCreate(true);
                                setShowForm(true);
                            }}
                        >
                            <i className="fas fa-plus" />
                            Add Merchant
                        </Button>
                        {createOrUpdateButton}
                    </>
                }
            >
                {!user.loading ? (
                    <Form>
                        <Section>
                            <Row>
                                <Col>
                                    <SectionTitle md>Information</SectionTitle>
                                </Col>
                            </Row>

                            <FormGroup row>
                                <Label className="row-label">Sandbox</Label>
                                <Col>
                                    <span className="k-switch k-switch--md k-switch--icon">
                                        <label>
                                            <Input
                                                checked={
                                                    inputs.sandbox
                                                        ? "checked"
                                                        : ""
                                                }
                                                name="sandbox"
                                                onChange={inputChange}
                                                type="checkbox"
                                            />
                                            <span />
                                        </label>
                                    </span>
                                </Col>
                            </FormGroup>
                        </Section>
                        <Section className="section-separator" />
                        <Section>
                            <Row>
                                <Col>
                                    <SectionTitle md>API Key</SectionTitle>
                                </Col>
                            </Row>
                            <FormGroup row>
                                <Col>
                                    <Label>User</Label>
                                    <Input
                                        invalid={validator.user.isInvalid}
                                        name="user"
                                        onChange={inputChange}
                                        value={inputs.user}
                                    />
                                    <FormFeedback>
                                        {validator.user.message}
                                    </FormFeedback>
                                </Col>
                                <Col>
                                    <Label>Test User</Label>
                                    <Input
                                        invalid={validator.test_user.isInvalid}
                                        name="test_user"
                                        onChange={inputChange}
                                        value={inputs.test_user}
                                    />
                                    <FormFeedback>
                                        {validator.test_user.message}
                                    </FormFeedback>
                                </Col>
                                <Col lg={4}>
                                    <Label>Auto Assign Mode</Label>
                                    <Select
                                        controlName="auto_assign_mode"
                                        defaultValue={selectValue(
                                            inputs.auto_assign_mode,
                                            onfleetAsignModeOptions,
                                        )}
                                        invalid={
                                            validator.auto_assign_mode.isInvalid
                                        }
                                        onChange={selectChange}
                                        options={onfleetAsignModeOptions}
                                    />

                                    <FormFeedback>
                                        {validator.auto_assign_mode.message}
                                    </FormFeedback>
                                </Col>
                            </FormGroup>
                        </Section>
                        <Section>
                            <Row>
                                <Col>
                                    <SectionTitle md>Auto Assing</SectionTitle>
                                </Col>
                            </Row>
                            <FormGroup row>
                                <Label className="row-label">Auto Assign</Label>
                                <Col>
                                    <span className="k-switch k-switch--md k-switch--icon">
                                        <label>
                                            <Input
                                                checked={
                                                    inputs.auto_assign
                                                        ? "checked"
                                                        : ""
                                                }
                                                name="auto_assign"
                                                onChange={inputChange}
                                                type="checkbox"
                                            />
                                            <span />
                                        </label>
                                    </span>
                                </Col>
                            </FormGroup>
                        </Section>
                        <Section className="section-separator" />
                        <Section>
                            <Row>
                                <Col>
                                    <SectionTitle md>Merchants</SectionTitle>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table
                                        actions={actions}
                                        columns={columns}
                                        data={_merchants}
                                        keyField="id"
                                        loading={loading}
                                    />
                                </Col>
                            </Row>
                        </Section>
                        {showForm && (
                            <MerchantForm
                                app={inputs}
                                handleCancel={handleCancel}
                                handlerUpdatingOrCreate={
                                    handlerUpdatingOrCreate
                                }
                                isCreate={isCreate}
                                isUpdating={isUpdating}
                                merchants={merchants}
                                selectMerchant={rowSelect}
                                setMerchants={setMerchants}
                            />
                        )}
                    </Form>
                ) : (
                    <>
                        <Spinner color="metal" show sm />
                        &nbsp;&nbsp;Loading
                    </>
                )}
            </Portlet>
            {confirm}
        </>
    );
};

export default OnFleetForm;
