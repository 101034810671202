import React, { useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import Content from "../../base/layout/content/Content";
import { Portlet } from "../../base/components/portlet";
import useMeta from "../../base/hooks/useMeta.js";
import Spinner from "../../base/components/spinner/Spinner";
import paginationFactory from "react-bootstrap-table2-paginator";
import { paginationOptions } from "../../config/components/table";
import {
    QUERY_BLAZE_INVENTORY_LIST,
    QUERY_BLAZE_TERMINAL_INVENTORY_LIST,
} from "../../apollo/module-operations/product";
import Select from "../../base/components/form/select/Select";
import _ from "lodash";
import useForm from "../../base/hooks/form/useForm";
import FiltersToggle from "../../base/components/table/FiltersToggle";
import Filters from "../../base/components/table/Filters";
import Table from "../../base/components/table/Table";
import { FILTER_TYPES } from "react-bootstrap-table2-filter";
import useFilter from "../../base/hooks/table/useFilter";
import { Col, Row, Button } from "reactstrap";

const BlazeInventory = () => {
    const { user } = useMeta();

    //---------------------------Defining filters-----------------------------
    const { TEXT } = FILTER_TYPES;

    const [showFilters, setShowFilters] = useState(false);

    const filterBrand = useFilter("brand", TEXT, "Brand", "");

    const filterType = useFilter("type", TEXT, "Type", "");

    const filters = [filterBrand, filterType];

    const [inventory, setInventory] = useState(null);

    const [inventories, setInventories] = useState([]);

    const { selectValue } = useForm();

    const [
        getTerminalInventory,
        {
            loading: loadingTerminalInventory,
            data: terminalInventory,
            networkStatus,
            refetch,
        },
    ] = useLazyQuery(QUERY_BLAZE_TERMINAL_INVENTORY_LIST, {
        notifyOnNetworkStatusChange: true,
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            inventory_id: inventory,
        },
    });

    useEffect(() => {
        user.loading && getTerminalInventory();
    }, []);

    // -----------------------------------------------------------------------

    const { loading } = useQuery(QUERY_BLAZE_INVENTORY_LIST, {
        skip: user.loading,
        notifyOnNetworkStatusChange: true,
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
        },
        onCompleted: resp => {
            const ops = _.map(resp.listInventories, v => {
                return { value: v.id, label: v.name };
            });

            setInventories(ops);

            if (ops.length > 0) {
                setInventory(ops[0].value);

                getTerminalInventory({
                    variables: {
                        account_id: user.accountId,
                        store_id: user.storeId,
                        inventory_id: ops[0].value,
                    },
                });
            }
        },
    });

    const columns = [
        {
            dataField: "id",
            text: "Id",
            hidden: true,
        },
        {
            dataField: "brand",
            text: "Brand",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
        },
        {
            dataField: "type",
            text: "Type",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "200px" };
            },
        },
        {
            dataField: "strain",
            text: "Strain",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "150px" };
            },
        },
        {
            dataField: "thc",
            text: "THC",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "150px" };
            },
        },

        {
            dataField: "qty",
            text: "Remain",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "80px" };
            },
        },
    ];

    const _loading = loading || networkStatus === 4;

    const _data = terminalInventory && terminalInventory.listTerminalInventory;

    return (
        <>
            <Content>
                <Portlet
                    header={{
                        title: "Blaze Inventory",
                        subtitle: "All ",
                    }}
                    sticky
                    toolbar={
                        !loading && (
                            <>
                                <div style={{ minWidth: 240 }}>
                                    <Select
                                        controlName="inventories"
                                        onChange={v => {
                                            setInventory(v.value);
                                            getTerminalInventory({
                                                variables: {
                                                    account_id: user.accountId,
                                                    store_id: user.storeId,
                                                    inventory_id: v.value,
                                                },
                                            });
                                        }}
                                        options={inventories}
                                        value={selectValue(
                                            inventory,
                                            inventories,
                                        )}
                                    />
                                </div>
                                <FiltersToggle
                                    setShowFilters={setShowFilters}
                                />
                                <Button
                                    className="ml-3"
                                    color="metal"
                                    onClick={() => {
                                        refetch({
                                            variables: {
                                                account_id: user.accountId,
                                                store_id: user.storeId,
                                                inventory_id: inventory,
                                            },
                                        });
                                    }}
                                >
                                    <i className="fas fa-sync-alt" />
                                    Refresh
                                </Button>
                            </>
                        )
                    }
                >
                    {!loading && !loadingTerminalInventory ? (
                        <>
                            <Filters filters={filters} />
                            <Table
                                columns={columns}
                                data={_data}
                                filters={filters}
                                keyField="id"
                                loading={_loading}
                                pagination={paginationFactory(
                                    paginationOptions,
                                )}
                                showFilters={showFilters}
                            />
                        </>
                    ) : (
                        <>
                            <Spinner color="metal" show sm />
                            &nbsp;&nbsp;Loading
                        </>
                    )}
                </Portlet>
            </Content>
        </>
    );
};

export default BlazeInventory;
