import gql from "graphql-tag";

let Category = {};
Category.fragments = {
    attributes: gql`
        fragment CategoryFragment on Category {
            id
            name
            enabled
            is_cannabis
            created_at
            updated_at
            description
            short_description
            priority
            unit_type
            cannabis_type
            wm_category
            low_threshold
            asset {
                image
            }
        }
    `,
};

export const QUERY_CATEGORY_LIST = gql`
    query listCategory($account_id: String!, $store_id: String!) {
        listCategory(account_id: $account_id, store_id: $store_id) {
            ...CategoryFragment
        }
    }
    ${Category.fragments.attributes}
`;

export const QUERY_GET_CATEGORY = gql`
    query getCategory(
        $account_id: String!
        $category_id: String!
        $store_id: String!
    ) {
        getCategory(
            account_id: $account_id
            category_id: $category_id
            store_id: $store_id
        ) {
            ...CategoryFragment
        }
    }
    ${Category.fragments.attributes}
`;

export const MUTATION_CREATE_CATEGORY = gql`
    mutation createCategory(
        $account_id: String!
        $store_id: String!
        $input: CategoryInput!
    ) {
        createCategory(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            ...CategoryFragment
        }
    }
    ${Category.fragments.attributes}
`;

export const MUTATION_UPDATE_CATEGORY = gql`
    mutation updateCategory(
        $account_id: String!
        $store_id: String!
        $input: CategoryInput!
    ) {
        updateCategory(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            ...CategoryFragment
        }
    }
    ${Category.fragments.attributes}
`;

export const MUTATION_REMOVE_CATEGORY = gql`
    mutation deleteCategory(
        $account_id: String!
        $store_id: String!
        $input: CategoryInput!
    ) {
        deleteCategory(
            account_id: $account_id
            store_id: $store_id
            input: $input
        )
    }
`;
