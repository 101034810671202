import { FC } from "react";
import { Col, Row } from "reactstrap";
import { Section } from "@/components";
import { IDataPaneProps } from "types/tab";

const RawPane: FC<IDataPaneProps> = ({ order, loading }) => {
    if (loading) {
        return (
            <Section>
                <Row>
                    <Col className="order__status">
                        <div className="content-placeholder">
                            <div className="cp-w-lg cp-h-md" />
                        </div>
                        <div className="content-placeholder">
                            <div className="cp-w-lg cp-h-md" />
                        </div>
                    </Col>
                </Row>
            </Section>
        );
    }

    return (
        <Section>
            <Row className="mt-4 max-lg">
                <Col>
                    <pre>{JSON.stringify(order, null, 2)}</pre>
                </Col>
            </Row>
        </Section>
    );
};

export default RawPane;
