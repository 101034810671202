import { FC, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useQuery } from "@apollo/react-hooks";
import { useInstantSearch } from "react-instantsearch-hooks-web";

import { AlgoliaOrder, Order } from "@/types";
import { useFixedBodyOnPopup, useMeta } from "@/hooks";
import { QUERY_GET_ORDER } from "apollo/module-operations/order";
import { QUERY_GET_STORE } from "apollo/module-operations/store";

import OrderForm from "../OrderForm";
import { printInvoice } from "../print";
import { OrderActionButtons } from "../OrderActionButtons";

interface ModalProps {
    isModalOpen: boolean;
    handleModalToggle: (order: AlgoliaOrder) => void;
    algoliaOrder: AlgoliaOrder;
}

const KanbanModal: FC<ModalProps> = ({
    isModalOpen,
    handleModalToggle,
    algoliaOrder,
}) => {
    const { refresh } = useInstantSearch();
    const { user } = useMeta();
    const [order, setOrder] = useState<Order>(null);
    const [storeInformation, setStoreInformation] = useState({});
    useFixedBodyOnPopup(isModalOpen);

    const { loading: loadingOrder, refetch } = useQuery(QUERY_GET_ORDER, {
        skip: user.loading || !algoliaOrder.objectID,
        notifyOnNetworkStatusChange: true,
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            entity_id: algoliaOrder.objectID,
        },
        onCompleted: resp => {
            const order = (resp && resp.getOrder) || {};
            setOrder(order);
        },
    });

    useQuery(QUERY_GET_STORE, {
        variables: { account_id: user.accountId, store_id: user.storeId },
        skip: user.loading,
        onCompleted: resp => {
            setStoreInformation(resp.getStore);
        },
    });

    const handleActionCompleted = () => {
        handleModalToggle(algoliaOrder);
        refetch();
        refresh();
    };

    const handleViewReceipt = () => {
        const pdf = printInvoice([order], storeInformation, "country", "state");
        pdf.open();
    };

    return (
        <Modal
            centered
            className="modal__order_detail"
            fade={false}
            isOpen={isModalOpen}
            toggle={handleModalToggle}
        >
            <ModalHeader toggle={handleModalToggle}>Order details</ModalHeader>
            <ModalBody className="order__details">
                <OrderForm algoliaOrder={algoliaOrder} isModal />
            </ModalBody>
            <ModalFooter>
                {loadingOrder || !order ? (
                    <>
                        <div className="content-placeholder skeleton_btn">
                            <div className="cp-w-lg cp-h-md" />
                        </div>
                        <div className="d-flex gap-3">
                            <div className="content-placeholder skeleton_btn mr-3">
                                <div className="cp-w-lg cp-h-md" />
                            </div>
                            <div className="content-placeholder skeleton_btn">
                                <div className="cp-w-lg cp-h-md" />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <Button
                            color="dark"
                            onClick={() => handleViewReceipt()}
                        >
                            View receipt
                        </Button>
                        <OrderActionButtons
                            onActionCompleted={handleActionCompleted}
                            order={order}
                        />
                    </>
                )}
            </ModalFooter>
        </Modal>
    );
};

export default KanbanModal;
