import React from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import { toolBar } from "../../../config/layout/aside";

const ToolBar = () => {
    return (
        <div className="k-aside__footer k-grid__item">
            <div className="k-aside__footer-nav">
                {toolBar.map((l, i) => (
                    <div className="k-aside__footer-item" key={l.route}>
                        <Link
                            className="btn btn-icon"
                            id={`aside-toolbar-option-${i}`}
                            to={l.route}
                        >
                            <i className="fas fa-cog"></i>
                        </Link>
                        <UncontrolledTooltip
                            target={`aside-toolbar-option-${i}`}
                        >
                            {l.label}
                        </UncontrolledTooltip>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ToolBar;
