import { LineItem, Product } from "types/backend.generated";

export const mapProductToLineItem = (product: Product): LineItem => {
    const variant = product.variants[0];

    return {
        id: product.id.toString(),
        pos_id: variant?.pos_id,
        item_id: product.id.toString(),
        brand: product?.brand_name,
        category: product?.category,
        name: product.name,
        price: variant.price,
        quantity: product.qty ?? 1,
        sale_price: product.sales_price,
        thumbnail: product.asset?.image,
        variants: [
            {
                id: variant.id.toString(),
                name: variant.name,
                price: getVariantPrice(variant.salePrice, variant.price),
                type: variant.type,
            },
        ],
    };
};

export const mapProductsToLineItems = (products: Product[]): LineItem[] =>
    products.map(mapProductToLineItem);

const getVariantPrice = (salePrice: number, price: number): number => {
    if (!salePrice) {
        return price;
    }

    if (salePrice > price) {
        return price;
    }

    return salePrice;
};
