import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

const SubHeaderBreadcrumb = ({ route }) => {
    return (
        <div className="k-content__head-main">
            <div className="k-content__head-breadcrumbs">
                {route && (
                    <>
                        {!route.root && (
                            <>
                                <Link
                                    className="k-content__head-breadcrumb-link"
                                    to="/"
                                >
                                    Dashboard
                                </Link>
                                <span className="k-content__head-breadcrumb-separator" />
                            </>
                        )}
                        {route.breadcrumb &&
                            route.breadcrumb.map(i => (
                                <Fragment key={i.route}>
                                    <Link
                                        className="k-content__head-breadcrumb-link"
                                        to={i.route}
                                    >
                                        {i.label}
                                    </Link>
                                    <span className="k-content__head-breadcrumb-separator" />
                                </Fragment>
                            ))}
                    </>
                )}
                <span className="k-content__head-breadcrumb-link k-content__head-breadcrumb-link--active">
                    {(route && route.label) || "Dashboard"}
                </span>
            </div>
        </div>
    );
};

export default SubHeaderBreadcrumb;
