import { useEffect, useState } from "react";
import {
    SEARCH_TREEZ_HEADLESS_PRODUCT_QUERY,
    QUERY_LIST_STORE_APPS,
} from "@/data";
import { useMeta, useForm, useNotification } from "@/hooks";
import { Content } from "@/layout";
import { Badge, Icon, Input, Portlet, Table } from "@/components";
import { Button } from "reactstrap";
import { TreezObjectModal } from "./components";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import Select from "../../base/components/form/select/Select";
import placeholder from "../../assets/images/placeholder.png";
import { NOT_AVAILABLE } from "@/config";

const TreezPage = () => {
    const { user } = useMeta();
    const notify = useNotification();
    const [products, setProducts] = useState([]);
    const [treezStores, setTreezStores] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { inputs, selectChange, selectValue, setInputs } = useForm();

    const COLUMNS = [
        {
            dataField: "key",
            text: "Key",
            hidden: true,
        },
        {
            dataField: "asset",
            text: "Image",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "100px" };
            },
            formatter: (cell, row) => {
                let imageURL = placeholder;
                if (cell && cell?.image) {
                    imageURL = cell.image;
                }

                return (
                    <img
                        alt={row.name}
                        key={`${row.id}-${imageURL}`}
                        onError={event => {
                            event.currentTarget.src = placeholder;
                        }}
                        src={imageURL}
                        style={{
                            height: "80px",
                            width: "80px",
                            background: "#f9fafc",
                            borderRadius: "4px",
                        }}
                    />
                );
            },
        },
        {
            dataField: "name",
            text: "Name",
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <>
                        <div className="treez__table_row_primary">
                            {cell || NOT_AVAILABLE}
                        </div>
                    </>
                );
            },
        },
        {
            dataField: "brand_name",
            text: "Brand Name",
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <>
                        <div className="treez__table_row_secondary">
                            {cell || NOT_AVAILABLE}
                        </div>
                    </>
                );
            },
        },
        {
            dataField: "category",
            text: "Category",
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <>
                        <div className="treez__table_row_secondary">
                            {cell || NOT_AVAILABLE}
                        </div>
                    </>
                );
            },
        },
        {
            dataField: "in_stock",
            formatter: cell => {
                return (
                    <Badge
                        className={cell ? "status__success" : "status__error"}
                        label={cell ? "Yes" : "No"}
                    />
                );
            },
            text: "In Stock",
            sort: true,
        },
        {
            dataField: "qty",
            text: "Quantity",
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <>
                        <div className="treez__table_row_primary">
                            {cell || 0}
                        </div>
                    </>
                );
            },
        },
        {
            dataField: "id",
            text: "Product Id",
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Button
                        className="order__table_copy_action"
                        onClick={() => clipboardCopy(cell)}
                        style={{ color: "#3b9415" }}
                        title="Copy Product Id"
                    >
                        <span className="mr-2">Copy ID</span>
                        <Icon color="#727a94" icon="copy" />
                    </Button>
                );
            },
        },
    ];

    const v = {
        account_id: user.accountId,
        store_id: user.storeId,
        first: 20,
        search_term: "",
        provider_id: inputs.store,
        showOutOfStock: true,
        strictMedicalCustomerType: false,
    };

    const clipboardCopy = item => {
        navigator.clipboard.writeText(item);
        notify(`Product Id:${item} is copied`, "success");
    };

    const handleSearch = e => {
        listTreezProducts({
            variables: {
                ...v,
                search_term: e.target.value ?? "",
            },
        });
    };

    const { loading: loadingApps } = useQuery(QUERY_LIST_STORE_APPS, {
        skip: user.loading,
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            category: "ERP",
        },
        onCompleted: res => {
            const treezApp = res?.listStoreApps?.find(
                app => app.handler === "treez",
            );

            setTreezStores(
                treezApp?.credentials.map(credential => {
                    return {
                        label: credential.store_name,
                        value: credential.store_id,
                    };
                }) || [],
            );
            setInputs({
                ...inputs,
                store: treezApp?.credentials[0]?.store_id || null,
            });
        },
    });

    const [listTreezProducts, { loading }] = useLazyQuery(
        SEARCH_TREEZ_HEADLESS_PRODUCT_QUERY,
        {
            onCompleted: res => {
                setProducts(res?.searchTreezHeadlessProducts);
            },
            notifyOnNetworkStatusChange: true,
            skip: !inputs.store,
            variables: v,
        },
    );

    useEffect(() => {
        !loadingApps && inputs.store && listTreezProducts();
    }, [loadingApps, inputs.store]);

    const handleRowClick = element => {
        setIsModalOpen(!isModalOpen);
        setSelectedProduct(element);
    };

    const rowEvents = {
        onClick: (e, row) => {
            const cell = e.target.closest("td");
            if (!cell) return;

            const cellIndex = cell.cellIndex;
            if (cellIndex === 6) return;

            handleRowClick(row);
        },
    };

    return (
        <>
            <Content>
                <Portlet
                    className={"treez"}
                    header={{
                        title: "Treez Products",
                        subtitle: "All Treez Products availables in the system",
                    }}
                    sticky
                    toolbar={[
                        <Select
                            className="treez__select-store"
                            controlName="store"
                            key="select_store"
                            onChange={selectChange}
                            options={treezStores}
                            placeholder="Store"
                            style={{ minWidth: "160px" }}
                            value={selectValue(inputs.store, treezStores)}
                        />,
                    ]}
                >
                    <div className="k-portlet__head-toolbar treez__input-search">
                        <div className="k-input-icon k-input-icon--left ">
                            <Input
                                bsSize="sm"
                                className={"treez__search"}
                                defaultValue={inputs.name}
                                name="name"
                                onChange={e => {
                                    handleSearch(e);
                                }}
                                placeholder="Search by product name"
                            />
                            <span className="k-input-icon__icon k-input-icon__icon--left">
                                <span>
                                    <Icon icon="search" />
                                </span>
                            </span>
                        </div>
                    </div>
                    <Table
                        className="table table-hover"
                        columns={COLUMNS}
                        data={products}
                        keyField={"id"}
                        loading={loading || loadingApps}
                        remote={{ filter: true }}
                        rowEvents={rowEvents}
                    />
                </Portlet>
            </Content>
            <TreezObjectModal
                handleModalToggle={() => setIsModalOpen(false)}
                isModalOpen={isModalOpen}
                object={selectedProduct}
            />
        </>
    );
};

export default TreezPage;
