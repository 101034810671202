import gql from "graphql-tag";

export const MUTATION_SYNC_FROM_JANE = gql`
    mutation syncFromJane($account_id: String!, $store_id: String!) {
        syncFromJane(account_id: $account_id, store_id: $store_id)
    }
`;

export const MUTATION_SYNC_JANE_PRODUCT_TO_LISTRACK = gql`
    mutation syncJaneProductToLisTrack(
        $account_id: String!
        $store_id: String!
        $jane_store_id: String!
    ) {
        syncJaneProductToLisTrack(
            account_id: $account_id
            store_id: $store_id
            jane_store_id: $jane_store_id
        )
    }
`;
