import React from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

const VerticalNavItem = ({
    children,
    separator,
    section,
    first,
    icon,
    iconAsImage,
    badge,
    active,
    action,
}) => {
    const history = useHistory();

    const handleClick = () => {
        if (typeof action === "string") {
            history.push(action);
        } else {
            action();
        }
    };

    return (
        <li
            className={classNames(
                { "k-nav__separator": separator },
                { "k-nav__section": section },
                { "k-nav__section--first": section && first },
                { "k-nav__item": !separator && !section },
            )}
        >
            {!separator &&
                (section ? (
                    <div className="k-nav__section-text">{children}</div>
                ) : (
                    <div
                        className={classNames("k-nav__link", {
                            "k-nav__link--active": active,
                        })}
                        onClick={handleClick}
                        role="button"
                    >
                        {icon && (
                            <span className="k-nav__link-icon">
                                {iconAsImage ? (
                                    <img alt="Item" src={icon} />
                                ) : (
                                    <i className={icon} />
                                )}
                            </span>
                        )}
                        <div className="k-nav__link-text">{children}</div>
                        {badge && (
                            <div className="k-nav__link-badge">{badge}</div>
                        )}
                    </div>
                ))}
        </li>
    );
};

export default VerticalNavItem;
