import moment from "moment";
import { Section, SectionTitle, Table, Spinner } from "@/components";
import {
    QUERY_GET_CUSTOMER,
    MUTATION_ADD_NOTES_TO_ACCOUNT,
} from "../../../apollo/module-operations/customer";
import { useForm, useNotification, useValidator } from "@/hooks";
import { useMutation } from "@apollo/react-hooks";
import {
    Row,
    Col,
    FormGroup,
    FormFeedback,
    Label,
    Button,
    TabPane,
    Input,
} from "reactstrap";

const Notes = ({ customers, loading, tabId, setCustomers, user }) => {
    const notify = useNotification();

    const { inputs, inputChange } = useForm();

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "note",
                method: "isEmpty",
                validWhen: false,
                message: "This note is required.",
            },
        ],
        inputs,
    );

    const columns = [
        {
            dataField: "key",
            text: "Key",
            hidden: true,
        },
        {
            dataField: "notes",
            text: "Notes",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "40%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <>
                        <div className="costumers__table_row_primary">
                            {_notes[rowIndex]}
                        </div>
                    </>
                );
            },
        },
    ];

    const [addNote, { loading: loadingAddNotes }] = useMutation(
        MUTATION_ADD_NOTES_TO_ACCOUNT,
        {
            onCompleted: () => {
                notify("Added New Note Successfully", "success");
            },
            onError: () => {
                notify("Error Adding New Note", "error");
            },
        },
    );

    const addNoteCustomer = async () => {
        if (isSubmited) {
            isSubmited(true);
        }

        if (isValid()) {
            _notes.push(inputs.note);

            addNote({
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                    input: {
                        id: customers.entity_id,
                        notes: _notes,
                    },
                },
            });
        }
    };

    const _loading = loading || false;

    const _notes = customers?.notes || [];

    return (
        <TabPane className="tap__panel_notes" tabId={tabId}>
            <Section>
                <Row>
                    <Col>
                        <SectionTitle md>Notes</SectionTitle>
                    </Col>
                </Row>
                <Row className="row-content-table">
                    <Table
                        className="table table-hover"
                        columns={columns}
                        data={_notes}
                        keyField="key"
                        loading={_loading}
                    />
                </Row>
                <Row className="mt-5">
                    <Col lg={4}>
                        <FormGroup>
                            <Label>Add Notes</Label>
                            <p>
                                Enter any notes that you consider important in
                                the process
                            </p>
                            <Input
                                invalid={validator.note.isInvalid}
                                name="note"
                                onChange={inputChange}
                                placeholder="Write the note..."
                                rows={10}
                                type="textarea"
                                value={inputs?.note || ""}
                            />
                            <FormFeedback>
                                {validator.note.message}
                            </FormFeedback>
                        </FormGroup>
                        <Row className="actions mt-3">
                            <Button
                                color="primary"
                                disabled={loadingAddNotes}
                                onClick={addNoteCustomer}
                            >
                                <Spinner show={loadingAddNotes} sm />
                                {!loadingAddNotes && (
                                    <i className="fas fa-plus" />
                                )}
                                Add New Note
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </Section>
        </TabPane>
    );
};

export default Notes;
