import { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import AsideSection from "./AsideSection";
import AsideItem from "./AsideItem";
import AsideSubMenu from "./AsideSubMenu";
import { navigation } from "../../../config/layout/aside";
import { useMeta } from "@/hooks";
import { useQuery } from "@apollo/react-hooks";
import { QUERY_LIST_STORE_APPS } from "apollo/module-operations/app";

const AsideNavigation = () => {
    const { user } = useMeta();
    const [apps, setApps] = useState([]);

    const { loading } = useQuery(QUERY_LIST_STORE_APPS, {
        skip: user.loading,
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
        },
        onCompleted: resp => {
            if (resp.listStoreApps) {
                setApps(
                    resp.listStoreApps
                        .filter(app => app?.status)
                        .map(app => app?.handler),
                );
            }
        },
    });

    return (
        <PerfectScrollbar
            className="k-aside-menu"
            options={{
                suppressScrollX: true,
            }}
        >
            <ul className="k-menu__nav">
                {navigation.map((e, i) => {
                    if (!e.route && !e.items) {
                        return <AsideSection key={i} {...e} />;
                    }

                    if (e.route) {
                        return <AsideItem key={i} {...e} />;
                    }

                    if (e.items) {
                        return <AsideSubMenu key={i} {...e} apps={apps} />;
                    }
                })}
            </ul>
        </PerfectScrollbar>
    );
};

export default AsideNavigation;
