import React, { useState } from "react";
import { Row, Col, FormGroup, Label, FormFeedback } from "reactstrap";
import { useQuery } from "@apollo/react-hooks";
import Content from "../../base/layout/content/Content";
import { Portlet, Section, SectionTitle } from "../../base/components/portlet";
import Form from "../../base/components/form/Form";
import Input from "../../base/components/form/input/Input";
import Spinner from "../../base/components/spinner/Spinner";
import useMeta from "../../base/hooks/useMeta";
import useForm from "../../base/hooks/form/useForm";
import useValidator from "../../base/hooks/form/useValidator";
import useFormActions from "../../base/hooks/form/useFormActions";
import MediaInput from "../../base/components/Medias";
import {
    MUTATION_UPDATE_BRAND,
    QUERY_BRAND_LIST,
    QUERY_GET_BRAND,
} from "../../apollo/module-operations/brand";
import routes from "../../config/routes/routes";
import _ from "lodash";

const BrandForm = () => {
    const { resourceId, isCreate, user } = useMeta();

    const [loading, setLoading] = useState(!isCreate);

    const { inputs, setInputs, inputChange } = useForm();

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "name",
                method: "isEmpty",
                validWhen: false,
                message: "This name is required.",
            },
        ],
        { ...inputs },
    );

    useQuery(QUERY_GET_BRAND, {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            brand_id: resourceId,
        },
        skip: user.loading || !loading,
        onCompleted: resp => {
            let input = _.cloneDeep(resp.getBrand);

            setInputs(input);
            setLoading(false);
        },
    });

    const addProtocol = e => {
        const value = e.target.value;

        e.target.value = _.trimEnd(
            !_.startsWith(value, "http") ? `https://${value}` : value,
            "/",
        );

        if (value !== e.target.value) {
            inputChange(e);
        }
    };

    const options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: inputs,
        },
    };

    const { createOrUpdateButton } = useFormActions(
        "id",
        "Brand",
        "",
        MUTATION_UPDATE_BRAND,
        [
            {
                query: QUERY_BRAND_LIST,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        routes.BRAND_DETAIL.route,
        isValid,
        isSubmited,
    );

    return (
        <Content>
            <Portlet
                header={{
                    title: "Form",
                    subtitle: "Provide all required information.",
                }}
                sticky
                toolbar={createOrUpdateButton}
            >
                {!loading ? (
                    <Form centered>
                        <Section>
                            <Row>
                                <Label lg={4} xl={3} />
                                <Col>
                                    <SectionTitle md>Brand</SectionTitle>
                                </Col>
                            </Row>
                            <FormGroup row>
                                <Label lg={4} xl={3}>
                                    Enable
                                </Label>
                                <Col>
                                    <span className="k-switch k-switch--md k-switch--icon">
                                        <label>
                                            <Input
                                                checked={
                                                    inputs.enabled
                                                        ? "checked"
                                                        : ""
                                                }
                                                disabled
                                                name="enabled"
                                                onChange={inputChange}
                                                type="checkbox"
                                            />
                                            <span />
                                        </label>
                                    </span>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label lg={4} xl={3}>
                                    Name *
                                </Label>
                                <Col>
                                    <Input
                                        defaultValue={
                                            inputs.name ? inputs.name : ""
                                        }
                                        invalid={validator.name.isInvalid}
                                        name="name"
                                        onChange={inputChange}
                                    />
                                    <FormFeedback>
                                        {validator.name.message}
                                    </FormFeedback>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label lg={4} xl={3}>
                                    Image
                                </Label>
                                <Col>
                                    <MediaInput
                                        name="asset.image"
                                        onChange={inputChange}
                                        typeInput="highlight"
                                        value={inputs.asset.image ?? ""}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label lg={4} xl={3}>
                                    Phone Number
                                </Label>
                                <Col>
                                    <Input
                                        defaultValue={
                                            inputs.phone_number
                                                ? inputs.phone_number
                                                : ""
                                        }
                                        name="phone_number"
                                        onChange={inputChange}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label lg={4} xl={3}>
                                    Web Site
                                </Label>
                                <Col>
                                    <Input
                                        name="website"
                                        onBlur={addProtocol}
                                        onChange={inputChange}
                                        value={inputs.website || ""}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label lg={4} xl={3}>
                                    Vendors
                                </Label>
                                <Col>
                                    <Input
                                        defaultValue={
                                            inputs.vendors ? inputs.vendors : ""
                                        }
                                        name="vendors"
                                        onChange={inputChange}
                                    />
                                </Col>
                            </FormGroup>
                        </Section>
                    </Form>
                ) : (
                    <>
                        <Spinner color="metal" show sm />
                        &nbsp;&nbsp;Loading
                    </>
                )}
            </Portlet>
        </Content>
    );
};

export default BrandForm;
