import React from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { Portlet, Section, SectionTitle } from "../../base/components/portlet";
import { FormGroup, Col, Label, Row, FormFeedback } from "reactstrap";
import InputGroupText from "../../base/components/form/input/InputGroupText";
import { currencyMask } from "../../utils/maskInput";
import Input from "../../base/components/form/input/Input";
import Form from "../../base/components/form/Form";
import useMeta from "../../base/hooks/useMeta";
import useForm from "../../base/hooks/form/useForm";
import useFormActions from "../../base/hooks/form/useFormActions";
import { toCurrency } from "../../utils/utils";
import {
    MUTATION_UPDATE_MEASURE_REQUEST_APP,
    QUERY_LIST_STORE_APPS,
} from "../../apollo/module-operations/app";
import _ from "lodash";
import useValidator from "../../base/hooks/form/useValidator";

const MeasureRequestForm = ({ app }) => {
    const { user } = useMeta();
    const { inputs, setInputs, inputChange } = useForm();

    if (!_.get(inputs, "id", null)) {
        setInputs(app);
    }

    let options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: {
                ...inputs,
                measurement_price: toCurrency(inputs.measurement_price),
                type: "MeasureRequest",
            },
        },
    };

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "email",
                method: "isEmpty",
                validWhen: false,
                message: "This email is required.",
            },
            {
                field: "measurement_price",
                method: "isEmpty",
                validWhen: false,
                message: "This price is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    const { createOrUpdateButton } = useFormActions(
        "id",
        "MeasureRequest",
        "",
        MUTATION_UPDATE_MEASURE_REQUEST_APP,
        [
            {
                query: QUERY_LIST_STORE_APPS,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        "",
        isValid,
        isSubmited,
        "primary",
    );

    return (
        <Portlet
            centered
            header={{
                title: "Measure Request",
                subtitle: "Measure Request Information",
            }}
            sticky
        >
            {!user.loading ? (
                <Form centered>
                    <Section>
                        <Row>
                            <Label lg={4} xl={3} />
                            <Col>
                                <SectionTitle md>Information</SectionTitle>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Email
                            </Label>
                            <Col>
                                <Input
                                    invalid={validator.email.isInvalid}
                                    name="email"
                                    onChange={inputChange}
                                    value={inputs.email}
                                />
                                <FormFeedback>
                                    {validator.email.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Measurement price
                            </Label>
                            <Col>
                                <Input
                                    className="form-control"
                                    defaultValue={inputs.measurement_price}
                                    invalid={
                                        validator.measurement_price.isInvalid
                                    }
                                    mask={currencyMask(
                                        inputs.measurement_price,
                                    )}
                                    name="measurement_price"
                                    onChange={inputChange}
                                    prepend={<InputGroupText>$</InputGroupText>}
                                    typeInput={"masked"}
                                />
                                <FormFeedback>
                                    {validator.measurement_price.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={4} xl={3} />
                            <Col>{createOrUpdateButton}</Col>
                        </FormGroup>
                    </Section>
                </Form>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
        </Portlet>
    );
};

export default MeasureRequestForm;
