import { FC } from "react";
import classNames from "classnames";
import PortletHeader from "./PortletHeader";
import PortletBody from "./PortletBody";
import PortletFooter from "./PortletFooter";

interface IPortletProps {
    children: any;
    fluidHeight?: any;
    sticky?: any;
    fit?: any;
    className?: any;
    toolbar?: any;
    header?: any;
    footer?: any;
}

const Portlet: FC<IPortletProps> = ({
    children,
    fluidHeight,
    sticky,
    fit,
    className,
    toolbar,
    header,
    footer,
    ...props
}) => {
    return (
        <section
            {...props}
            className={classNames(
                "k-portlet",
                { "k-portlet--height-fluid": fluidHeight },
                { "k-portlet--sticky": sticky },
                className,
            )}
        >
            {header && <PortletHeader toolbar={toolbar} {...header} />}
            <PortletBody fit={fit}>{children}</PortletBody>
            {footer && <PortletFooter {...footer} />}
        </section>
    );
};

export default Portlet;
