import {
    Dispatch,
    FC,
    SetStateAction,
    useEffect,
    useMemo,
    useState,
} from "react";
import {
    Button,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import {
    SearchBox,
    useInstantSearch,
    useSearchBox,
} from "react-instantsearch-hooks-web";
import FiltersSection from "./FiltersSection";
import { SEARCH_ORDER_FILTER_OPTIONS } from "@/config";
import KanbanSection from "./KanbanSection";
import { Badge, Icon } from "@/components";
import OrderTableList from "./../OrderTableList";
import { debounce } from "lodash";
import classNames from "classnames";

interface ICustomSearchBoxProps {
    setRestrictedParams: (any) => void;
    setSearchValue: (any) => void;
}

const CustomSearchBox: FC<ICustomSearchBoxProps> = ({
    setRestrictedParams,
    setSearchValue,
}) => {
    const { refine } = useSearchBox();
    const [filterToggle, setFilterToggle] = useState(false);
    const [currentFilter, setCurrentFilter] = useState({
        value: null,
        label: "Search",
    });

    const handleFilterToggle = () => {
        setFilterToggle(filterToggle => !filterToggle);
    };

    const debouncedQueryHook = useMemo(
        () =>
            debounce(query => {
                setSearchValue(query);
                refine(query);
            }, 300),
        [],
    );

    return (
        <div className="k-portlet__head-toolbar mb-4">
            <ButtonDropdown isOpen={filterToggle} toggle={handleFilterToggle}>
                <DropdownToggle caret>{currentFilter?.label}</DropdownToggle>
                <DropdownMenu>
                    {SEARCH_ORDER_FILTER_OPTIONS.map((f, index) => (
                        <DropdownItem
                            key={index}
                            onClick={() => {
                                setCurrentFilter(f);
                                if (f.value) {
                                    return setRestrictedParams([f.value]);
                                }
                                return setRestrictedParams([]);
                            }}
                        >
                            {f.label}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </ButtonDropdown>
            <div className="k-input-icon">
                <span className="k-input-icon__icon k-input-icon__icon--left">
                    <span>
                        <Icon color="#727A94" icon="search" />
                    </span>
                </span>
                <SearchBox
                    classNames={{ root: "kanban__search" }}
                    placeholder={`${
                        currentFilter.label !== "Search"
                            ? `Find by ${currentFilter.label?.toLowerCase()}`
                            : "Search"
                    }`}
                    queryHook={debouncedQueryHook}
                />
            </div>
        </div>
    );
};

const Kanban = ({
    filters,
    handleOptionSelect,
    selectedOptions,
    setRestrictedParams,
    setSearchValue,
}) => {
    const [showKanban, setShowKanban] = useState(
        JSON.parse(localStorage.getItem("show-kanban")) ?? true,
    );

    const {
        results: { nbHits },
    } = useInstantSearch();

    const changeDisplayingOrders = () => {
        localStorage.setItem("show-kanban", JSON.stringify(!showKanban));
        setShowKanban(!showKanban);
    };

    return (
        <div className="kanban__content">
            <div className="kanban__header">
                <div
                    className="mb-3"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <h2 className="kanban__subtitle">
                        {nbHits === 0 ? (
                            "No results"
                        ) : (
                            <>
                                <span>Total Orders</span>
                                <Badge
                                    className={`status__metalic`}
                                    label={`${nbHits}`}
                                />
                            </>
                        )}{" "}
                    </h2>
                    <div className="data__presentation_options">
                        <Button
                            className={classNames("btn__option", {
                                ["btn__option_active"]: showKanban,
                            })}
                            color="link"
                            onClick={() => changeDisplayingOrders()}
                        >
                            <Icon icon="kanban" />
                        </Button>
                        <Button
                            className={classNames("btn__option", {
                                ["btn__option_active"]: !showKanban,
                            })}
                            color="link"
                            onClick={() => changeDisplayingOrders()}
                        >
                            <Icon icon="table" />
                        </Button>
                    </div>
                </div>
                {/* <FiltersSection
                    filters={filters}
                    handleOptionSelect={handleOptionSelect}
                    selectedOptions={selectedOptions}
                /> */}
                <CustomSearchBox
                    setRestrictedParams={setRestrictedParams}
                    setSearchValue={setSearchValue}
                />
            </div>
            {showKanban ? <KanbanSection /> : <OrderTableList />}
        </div>
    );
};

export default Kanban;
