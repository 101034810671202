import gql from "graphql-tag";

export const QUERY_STORES_MENU_LIST = gql`
    query getStoreMenu {
        getStoreMenu {
            store_id
            account_id
            name
        }
    }
`;
