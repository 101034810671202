import React, { useEffect, useState } from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { Portlet, Section } from "../../base/components/portlet";
import Form from "../../base/components/form/Form";
import useMeta from "../../base/hooks/useMeta";
import useForm from "../../base/hooks/form/useForm";
import useFormActions from "../../base/hooks/form/useFormActions";
import {
    MUTATION_UPDATE_KLAVIYO,
    QUERY_LIST_STORE_APPS,
} from "../../apollo/module-operations/app";
import _ from "lodash";
import useValidator from "../../base/hooks/form/useValidator";
import { FormGroup, Col, Label, FormFeedback, Input } from "reactstrap";
import omit from "lodash/omit";

const KlaviyoForm = ({ app }) => {
    const { user } = useMeta();

    const { inputs, setInputs, inputChange } = useForm();

    useEffect(() => {
        const lists = app.lists
            ? app.lists.map(list => omit(list, "__typename"))
            : [];

        setInputs({
            private_key: app.private_key,
            lists: JSON.stringify(lists, null, 2),
            used_for_subscription: app.used_for_subscription,
        });
    }, [app]);

    const getLists = () => {
        try {
            return JSON.parse(inputs?.lists);
        } catch (error) {
            return [];
        }
    };

    let options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: {
                ...app,
                ...inputs,
                lists: getLists(),
            },
        },
    };

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "private_key",
                method: "isEmpty",
                validWhen: false,
                message: "Private is required.",
            },
            {
                field: "lists",
                method: "isJSON",
                validWhen: true,
                args: [],
                message: "Invalid Lists structure.",
            },
        ],
        {
            ...inputs,
        },
    );

    const { createOrUpdateButton } = useFormActions(
        "id",
        "Klavio",
        "",
        MUTATION_UPDATE_KLAVIYO,
        [
            {
                query: QUERY_LIST_STORE_APPS,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        "",
        isValid,
        isSubmited,
    );

    return (
        <Portlet
            centered
            header={{
                title: "Klavio",
                subtitle: "Klavio Information",
            }}
            sticky
            toolbar={<>{createOrUpdateButton}</>}
        >
            {!user.loading ? (
                <Form>
                    <Section>
                        <FormGroup row>
                            <Label className="row-label" lg={4} xl={3}>
                                Used for subscription
                            </Label>
                            <Col>
                                <span className="k-switch k-switch--md k-switch--icon">
                                    <label>
                                        <Input
                                            checked={
                                                inputs.used_for_subscription
                                                    ? "checked"
                                                    : ""
                                            }
                                            name="used_for_subscription"
                                            onChange={inputChange}
                                            type="checkbox"
                                        />
                                        <span />
                                    </label>
                                </span>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Private Key
                            </Label>
                            <Col>
                                <Input
                                    invalid={validator.private_key.isInvalid}
                                    name="private_key"
                                    onChange={inputChange}
                                    value={inputs.private_key}
                                />
                                <FormFeedback>
                                    {validator.private_key.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Lists
                            </Label>
                            <Col>
                                <Input
                                    invalid={validator.lists.isInvalid}
                                    name="lists"
                                    onChange={inputChange}
                                    rows={13}
                                    type="textarea"
                                    value={inputs?.lists || "[]"}
                                />
                                <FormFeedback>
                                    {validator.lists.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                    </Section>
                </Form>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
        </Portlet>
    );
};

export default KlaviyoForm;
