import { useState } from "react";

import { Tabs, Portlet } from "@/components";
import { useMeta, useConfirm, useNotification } from "@/hooks";
import { Order, Account, IdpAccount } from "@/types";
import { Content } from "@/layout";

import {
    Button,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
} from "reactstrap";

import { useQuery } from "@apollo/react-hooks";
import { SEARCH_PRODUCT_NAMES_QUERY } from "apollo/module-operations/product";
import { QUERY_GET_ORDER } from "../../apollo/module-operations/order";
import { QUERY_GET_STORE } from "../../apollo/module-operations/store";
import {
    QUERY_GET_CUSTOMER_BY_EMAIL,
    QUERY_GET_IDP_ACCOUNT,
} from "apollo/module-operations/customer";

import { printInvoice, printSlip } from "./print";

import OrderDataPane from "./kanban/panes/OrderDataPane";
import AdditionalDataPane from "./kanban/panes/AdditionalDataPane";
import ActivityLogPane from "./kanban/panes/ActivityLogPane";
import RawPane from "./kanban/panes/RawPane";

import _ from "lodash";
import { OrderActionButtons } from "./OrderActionButtons";

const OrderForm = ({ algoliaOrder, isModal = false }) => {
    const { user, resourceId, isCreate } = useMeta();
    const [storeInformation, setStoreInformation] = useState<object>();
    const [order, setOrder] = useState<Order>();
    const [namesProductsPOS, setNamesProductsPOS] = useState<[]>();
    const [query, setQuery] = useState<string>(null);
    const [account, setAccount] = useState<Account>();
    const [ipdAccount, setIDPAccount] = useState<IdpAccount>();

    const variables = {
        account_id: user.accountId,
        store_id: user.storeId,
    };

    const entityId = isModal ? algoliaOrder.objectID : resourceId;

    const { refetch, loading: getOrderLoading } = useQuery(QUERY_GET_ORDER, {
        skip: user.loading || !entityId,
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            entity_id: entityId,
        },
        onCompleted: resp => {
            const order = (resp && resp.getOrder) || {};

            const query = `${order?.line_items
                ?.map(item => {
                    return `id=='${item.pos_id}'`;
                })
                .join(" || ")}`;

            setQuery(query);
            setOrder(order);
        },
    });

    const { loading: searchProductNamesLoading } = useQuery(
        SEARCH_PRODUCT_NAMES_QUERY,
        {
            skip: user.loading || getOrderLoading || !order,
            fetchPolicy: "network-only",
            notifyOnNetworkStatusChange: true,
            variables: {
                account_id: user?.accountId,
                store_id: user?.storeId,
                query: query,
                first: order?.line_items?.length,
                provider_store_id: order?.jane_store_id,
            },
            onCompleted: resp => {
                setNamesProductsPOS(
                    (resp?.searchProduct?.edges ?? []).map(({ node }) => node),
                );
            },
        },
    );

    const { loading: getStoreLoading } = useQuery(QUERY_GET_STORE, {
        variables,
        skip: user.loading,
        onCompleted: resp => {
            setStoreInformation(resp.getStore);
        },
    });

    const { loading: getAccoutLoading } = useQuery(
        QUERY_GET_CUSTOMER_BY_EMAIL,
        {
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                email: order?.email,
            },
            skip: user.loading || getOrderLoading || !order,
            onCompleted: resp => {
                setAccount(resp.getAccountByEmail);
            },
        },
    );

    const { loading: getIDPAccoutLoading } = useQuery(QUERY_GET_IDP_ACCOUNT, {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            email: order?.email,
        },
        skip: user.loading || getOrderLoading || !order,
        onCompleted: resp => {
            setIDPAccount(resp.getIDPAccount);
        },
        onError: resp => {
            setIDPAccount(null);
        },
    });

    const [confirm] = useConfirm(async () => {
        await new Promise(r => setTimeout(r, 3000));

        notify("Resource deleted successfully", "success");
    });

    const notify = useNotification();

    const onAction = e => {
        onPrint(e);
    };

    const onPrint = option => {
        if (parseInt(option, 10) === 1) {
            const pdf = printInvoice(
                [order],
                storeInformation,
                "country",
                "state",
            );
            pdf.open();
        } else {
            if (parseInt(option, 10) === 2) {
                const _order = _.cloneDeep(order);

                _order.shippingPackages = _.map(order.line_items, p => ({
                    id: p.id,
                    title: p.name,
                    qtyInPackage: p.quantity,
                }));

                const pdf = printSlip(
                    [_order],
                    storeInformation,
                    "country",
                    "state",
                );
                pdf.open();
            }
        }
    };

    const isLoading =
        getOrderLoading ||
        searchProductNamesLoading ||
        getStoreLoading ||
        getAccoutLoading ||
        getIDPAccoutLoading ||
        !order;

    const renderTabs = () => {
        return (
            <Tabs
                navs={[
                    {
                        title: "Order data",
                        content: (
                            <OrderDataPane
                                account={account}
                                idpAccount={ipdAccount}
                                loading={isLoading}
                                namesProductsPOS={namesProductsPOS}
                                order={order}
                            />
                        ),
                    },
                    {
                        title: "Additional data",
                        content: (
                            <AdditionalDataPane
                                loading={isLoading}
                                order={order}
                            />
                        ),
                    },
                    {
                        title: "Activity logs",
                        content: (
                            <ActivityLogPane
                                loading={isLoading}
                                order={order}
                            />
                        ),
                    },
                    {
                        title: "Raw",
                        content: <RawPane loading={isLoading} order={order} />,
                    },
                ]}
            />
        );
    };

    return isModal ? (
        renderTabs()
    ) : (
        <>
            <Content>
                <Portlet
                    className="order__details"
                    header={{
                        title: "Order",
                        subtitle: "Order details",
                    }}
                    sticky
                    toolbar={
                        <>
                            <UncontrolledDropdown className="ml-3 text-dark">
                                <DropdownToggle caret color="metal">
                                    <i className="fas fa-print" /> Print
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => onAction(1)}>
                                        Invoice
                                    </DropdownItem>
                                    <DropdownItem onClick={() => onAction(2)}>
                                        Packaging slips
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            {!isCreate && (
                                <Button
                                    className="ml-3"
                                    color="metal"
                                    onClick={() => refetch()}
                                >
                                    <i className="fas fa-sync-alt" />
                                    Refresh
                                </Button>
                            )}
                            <OrderActionButtons
                                className="ml-3"
                                onActionCompleted={refetch}
                                order={order}
                            />
                        </>
                    }
                >
                    {renderTabs()}
                </Portlet>
            </Content>
        </>
    );
};

export default OrderForm;
