import React from "react";
import Spinner from "../../base/components/spinner/Spinner";
import {
    Portlet,
    Section,
    SectionTitle,
    Separator,
} from "../../base/components/portlet";
import { Button, FormGroup, Col } from "reactstrap";
import Form from "../../base/components/form/Form";
import useMeta from "../../base/hooks/useMeta";
import { categoryType } from "@/config";
import _ from "lodash";
import classNames from "classnames";
import { Icon, Paragraph, Select } from "@/components";
import { useForm } from "@/hooks";

const AppsConfigForm = ({
    apps,
    loading,
    installApp,
    unistallApp,
    loadingAction,
    setLoadingAction,
}) => {
    const { user } = useMeta();
    const { inputs, selectChange, selectValue } = useForm();

    const categoriesOptions = [{ label: "All", value: "ALL" }, ...categoryType];

    const filteredApps =
        inputs?.category && inputs?.category !== "ALL"
            ? apps.filter(app => app.app.category === inputs?.category)
            : apps;

    const handleAction = (option, isInstalled) => {
        setLoadingAction(option.variables.input.id);

        if (isInstalled) {
            unistallApp(option);
        } else {
            installApp(option);
        }
    };

    return (
        <Portlet
            className="business__settings apps__install"
            header={{
                title: "Apps",
                subtitle: "Supported third party integrations",
            }}
            sticky
            toolbar={[
                <Select
                    className="apps__select-category"
                    controlName="category"
                    key="select_store"
                    onChange={selectChange}
                    options={categoriesOptions}
                    placeholder="Filter by category"
                    value={selectValue(inputs.category, categoriesOptions)}
                />,
            ]}
        >
            {!loading ? (
                <Form>
                    <FormGroup row>
                        <Col>
                            <Section>
                                {/* TODO move the css to a class or use an existing alert component */}
                                <div className="app__info_message">
                                    <p>
                                        <span>
                                            <Icon icon="info" />
                                        </span>
                                        Be sure to reach out to your account
                                        representative before installing an app.
                                    </p>
                                </div>
                                <SectionTitle>All Apps</SectionTitle>
                                {filteredApps?.length > 0 && (
                                    <div className="app__container">
                                        {filteredApps?.map(item => {
                                            const {
                                                app,
                                                icon,
                                                description,
                                                isInstalled,
                                            } = item;

                                            const classCss = isInstalled
                                                ? "install"
                                                : "unistall";

                                            const option = {
                                                variables: {
                                                    account_id: user.accountId,
                                                    store_id: user.storeId,
                                                    input: {
                                                        id: app.id,
                                                        name: app.name,
                                                        handler: app.handler,
                                                        type: app.type,
                                                        category: app.category,
                                                        created_at:
                                                            app.created_at,
                                                        updated_at:
                                                            app.updated_at,
                                                        status: app.status,
                                                    },
                                                },
                                            };

                                            const showLoading =
                                                app?.id === loadingAction;

                                            return (
                                                <div
                                                    className={classNames(
                                                        "apps_card",
                                                        `apps_card_border-${classCss}`,
                                                    )}
                                                    key={app?.id}
                                                >
                                                    <div className="apps_card__header">
                                                        <figure>
                                                            <img
                                                                alt={app?.name}
                                                                height={32}
                                                                src={icon}
                                                                width={32}
                                                            />
                                                        </figure>
                                                        <h4>{app?.name}</h4>
                                                    </div>
                                                    <Paragraph
                                                        applyLimit={true}
                                                        className="apps_card__description"
                                                        lineLimit={3}
                                                        text={description}
                                                    />
                                                    <Separator />
                                                    <div className="apps_card__order_info">
                                                        <Button
                                                            className="ml-3 app__btn_action"
                                                            color={
                                                                isInstalled
                                                                    ? "secondary"
                                                                    : "primary"
                                                            }
                                                            disabled={loading}
                                                            onClick={() => {
                                                                handleAction(
                                                                    option,
                                                                    isInstalled,
                                                                );
                                                            }}
                                                            style={{
                                                                minWidth:
                                                                    "128px",
                                                            }}
                                                        >
                                                            <Spinner
                                                                color={
                                                                    isInstalled
                                                                        ? "default"
                                                                        : null
                                                                }
                                                                show={
                                                                    showLoading
                                                                }
                                                                sm
                                                            />
                                                            {isInstalled
                                                                ? "Uninstall"
                                                                : "Install"}
                                                        </Button>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                                {filteredApps?.length === 0 && (
                                    <div className="app__empty_results">
                                        <div className="empty_results_body">
                                            <Icon icon="search" />
                                            <h5>No search results found</h5>
                                            <p>
                                                {`No results match the criteria ${
                                                    inputs.category !== "" &&
                                                    `( ${inputs.category} )`
                                                }. Remove criteria to search again`}
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </Section>
                        </Col>
                    </FormGroup>
                </Form>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
        </Portlet>
    );
};

export default AppsConfigForm;
