import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useTableActions, useMeta, useRowActions } from "@/hooks";
import { Badge, Portlet, FiltersToggle, Table } from "@/components";
import Filters from "../../base/components/table/Filters";
import { FILTER_TYPES, Comparator } from "react-bootstrap-table2-filter";
import useFilter from "../../base/hooks/table/useFilter";
import {
    QUERY_PROMOTION_LIST,
    MUTATION_REMOVE_PROMOTION,
} from "../../apollo/module-operations/promotion";
import moment from "moment";
import { formatDiscountType } from "../../utils/utils";

import { Content } from "@/layout";
import { paginationOptions } from "../../config/components/table";
import routes from "../../config/routes/routes";
import {
    broadcasttype,
    discountType,
    discountRateType,
    discountApplyType,
} from "../../config/constant";
import { find } from "lodash";
import { QUERY_PRODUCT_LIST } from "../../apollo/module-operations/product";

const Promotions = () => {
    const { user } = useMeta();

    //---------------------------Defining filters-----------------------------
    const { TEXT, DATE, NUMBER } = FILTER_TYPES;

    const [showFilters, setShowFilters] = useState(false);

    const filterName = useFilter("name", TEXT, "Offer", "");

    const filterDiscountType = useFilter(
        "discountType",
        TEXT,
        "Discount Type",
        "",
    );

    const filterDiscount = useFilter("discount_quantity", NUMBER, "Discount", {
        comparator: Comparator.GE,
    });

    const filterExpiration = useFilter("expiration_date", DATE, "Expiration");

    const filterPriority = useFilter("priority", NUMBER, "Priority", {
        comparator: Comparator.GE,
    });

    const filters = [
        filterName,
        filterDiscountType,
        filterDiscount,
        filterExpiration,
        filterPriority,
    ];
    // -----------------------------------------------------------------------

    const { loading, data, refetch, networkStatus } = useQuery(
        QUERY_PROMOTION_LIST,
        {
            skip: user.loading,
            notifyOnNetworkStatusChange: true,
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
            },
        },
    );

    const { loading: loadingProductsList, data: productList } = useQuery(
        QUERY_PRODUCT_LIST,
        {
            skip: user.loading,
            notifyOnNetworkStatusChange: true,
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
            },
        },
    );

    const { refreshButton, addNewButton } = useTableActions(
        refetch,
        routes.PROMOTION_CREATE.route,
    );

    const { confirm, remove, update } = useRowActions(
        [
            {
                query: QUERY_PROMOTION_LIST,
                skip: user.loading,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        MUTATION_REMOVE_PROMOTION,
        routes.PROMOTION_DETAIL.route,
        "Promotion",
    );

    const columns = [
        {
            dataField: "id",
            text: "Id",
            hidden: true,
        },
        {
            dataField: "name",
            text: "Offer",
            sort: true,
        },
        {
            dataField: "discountType",
            text: "Discount Type",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "200px" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return cell ? <span>{formatDiscountType(cell)}</span> : "";
            },
            filterValue: (cell, row) => formatDiscountType(cell),
        },

        {
            dataField: "priority",
            text: "Priority",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "80px" };
            },
        },
        {
            dataField: "expiration_date",
            text: "Expiration",
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return cell ? (
                    <span>{moment(cell).format("MM/DD/YYYY HH:mm")}</span>
                ) : (
                    ""
                );
            },
            filterValue: (cell, row) => cell,
            headerStyle: (colum, colIndex) => {
                return { width: "150px" };
            },
        },

        {
            dataField: "status",
            text: "Status",
            headerStyle: (colum, colIndex) => {
                return { width: "100px" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Badge
                        className={
                            row.status ? "status__info" : "status__metalic"
                        }
                        label={row.status ? "Active" : "Inactive"}
                    />
                );
            },
            sort: true,
        },
    ];

    const expandRow = {
        renderer: row => (
            <div>
                <p style={{ marginTop: "10px" }}>
                    <strong>Label: </strong> {row.label}
                </p>
                <p style={{ marginTop: "10px" }}>
                    <strong>Blaze Id: </strong> {row.blaze_id}
                </p>
                {row.never_expire ? (
                    <p style={{ marginTop: "10px" }}>
                        <strong>Expire: </strong> Never
                    </p>
                ) : (
                    <>
                        <p style={{ marginTop: "10px" }}>
                            <strong>Start Date: </strong>
                            {moment(row.start_date).format("MM/DD/YYYY HH:mm")}
                        </p>
                        <p style={{ marginTop: "10px" }}>
                            <strong>End Date: </strong>
                            {moment(row.expiration_date).format(
                                "MM/DD/YYYY HH:mm",
                            )}
                        </p>
                    </>
                )}
                {!row.broadcast ? (
                    <p style={{ marginTop: "10px" }}>
                        <strong>Broadcast: </strong> Not Broadcast
                    </p>
                ) : (
                    <>
                        <p style={{ marginTop: "10px" }}>
                            <strong>Broadcast Type: </strong>{" "}
                            {
                                find(broadcasttype, {
                                    value: row.broadcast_type,
                                })?.label
                            }
                        </p>
                        <p style={{ margin: "0 auto", marginTop: "10px" }}>
                            <strong>Broadcast Color: </strong>{" "}
                            <div
                                style={{
                                    background: row.broadcast_color,
                                    width: "15px",
                                    height: "11px",
                                    display: "inline-flex",
                                    border: "1px solid",
                                    marginLeft: "5px",
                                }}
                            />
                        </p>
                    </>
                )}
                {row.discountType ? (
                    <p style={{ marginTop: "10px" }}>
                        <strong>Discount Type: </strong>{" "}
                        {
                            find(discountType, {
                                value: row.discountType,
                            }).label
                        }
                    </p>
                ) : (
                    <p style={{ marginTop: "10px" }}>
                        <strong>Discount Type: </strong> Not Discount{" "}
                    </p>
                )}

                {row.discount_rate_type ? (
                    <p style={{ marginTop: "10px" }}>
                        <strong>Discount Rate Type: </strong>{" "}
                        {
                            find(discountRateType, {
                                value: row.discount_rate_type,
                            }).label
                        }
                    </p>
                ) : null}

                {row.rate ? (
                    <p style={{ marginTop: "10px" }}>
                        <strong>Rate: </strong> {row.rate}
                    </p>
                ) : null}

                {row.discount_apply_type ? (
                    <p style={{ marginTop: "10px" }}>
                        <strong>Discount Apply Type: </strong>{" "}
                        {
                            find(discountApplyType, {
                                value: row.discount_apply_type,
                            }).label
                        }
                    </p>
                ) : null}

                {row.promo_code ? (
                    <p style={{ marginTop: "10px" }}>
                        <strong>Promo Code: </strong> {row.promo_code}
                    </p>
                ) : null}

                {row.minimum_order_required ? (
                    <p style={{ marginTop: "10px" }}>
                        <strong>Minimum Order Amount: </strong>{" "}
                        {row.minimum_order_amount}
                    </p>
                ) : null}

                {row.maximum_order_required ? (
                    <p style={{ marginTop: "10px" }}>
                        <strong>Maximum Order Amount: </strong>{" "}
                        {row.maximum_order_amount}
                    </p>
                ) : null}

                {row.first_time_patient ? (
                    <p style={{ marginTop: "10px" }}>
                        <strong>First Time Patient: </strong> Enable
                    </p>
                ) : null}

                {row.products_id ? (
                    <p style={{ marginTop: "10px" }}>
                        <strong>Apply to products: </strong>
                    </p>
                ) : (
                    <p style={{ marginTop: "10px" }}>
                        <strong>Apply to products: </strong> All Products
                    </p>
                )}
                {row.products_id &&
                    row.products_id.map((prod, index) => (
                        <p key={prod} style={{ margin: "5px 0px 0px 25px" }}>
                            {index + 1}.{"  "}
                            {find(_productsOptions, { id: prod }).name}
                        </p>
                    ))}
            </div>
        ),
        showExpandColumn: true,
        onlyOneExpanding: true,
        expandByColumnOnly: true,
        expandColumnPosition: "left",
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            if (isAnyExpands) {
                return <i className="fas fa-angle-double-down" />;
            }

            return <i className="fas fa-angle-double-right" />;
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return <i className="fas fa-angle-down" />;
            }

            return <i className="fas fa-angle-right" />;
        },
    };

    const actions = [
        {
            label: "Edit",
            action: (cell, row, rowIndex, formatExtraData) => {
                update(row.id);
            },
        },
        {
            label: "Delete",
            action: (cell, row, rowIndex, formatExtraData) => {
                remove({
                    variables: {
                        account_id: user.accountId,
                        store_id: user.storeId,
                        id: row.id,
                    },
                });
            },
        },
    ];

    const _loading = loading || networkStatus === 4;

    const _data = data && data.listPromotions;

    const _productsOptions = (productList && productList.listProducts) || [];

    return (
        <>
            <Content className="promotion__content">
                <Portlet
                    header={{
                        title: "Promotions",
                        subtitle: "All promotions availables in the system",
                    }}
                    sticky
                    toolbar={
                        <>
                            <FiltersToggle setShowFilters={setShowFilters} />
                            {refreshButton}
                            {addNewButton}
                        </>
                    }
                >
                    <Filters filters={filters} />
                    <Table
                        actions={actions}
                        columns={columns}
                        data={_data}
                        expandRow={expandRow}
                        filters={filters}
                        keyField="id"
                        loading={_loading && loadingProductsList}
                        pagination={paginationFactory(paginationOptions)}
                        showFilters={showFilters}
                    />
                </Portlet>
            </Content>
            {confirm}
        </>
    );
};

export default Promotions;
