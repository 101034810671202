import React, { useState } from "react";
import { FormGroup, Col, Label, FormFeedback, Row } from "reactstrap";
import Content from "../../base/layout/content/Content";
import { useQuery } from "@apollo/react-hooks";
import Input from "../../base/components/form/input/Input";
import useForm from "../../base/hooks/form/useForm";
import useValidator from "../../base/hooks/form/useValidator";
import useFormActions from "../../base/hooks/form/useFormActions";

import { Portlet, Section, SectionTitle } from "../../base/components/portlet";

import useMeta from "../../base/hooks/useMeta.js";
import Form from "../../base/components/form/Form";
import routes from "../../config/routes/routes";
import {
    MUTATION_CREATE_SYSTEM_APP,
    MUTATION_UPDATE_SYSTEM_APP,
    QUERY_SYSTEM_APP_LIST,
    QUERY_GET_SYSTEM_APP,
} from "../../apollo/module-operations/app";
import Select from "../../base/components/form/select/Select";
import { categoryType } from "../../config/constant";
import Spinner from "../../base/components/spinner/Spinner";
import moment from "moment";

const AppForm = () => {
    const { resourceId, isCreate, user } = useMeta();

    const [loading, setLoading] = useState(!isCreate);

    const { inputs, setInputs, inputChange, selectValue, selectChange } =
        useForm();

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "name",
                method: "isEmpty",
                validWhen: false,
                message: "This name is required.",
            },
            {
                field: "handler",
                method: "isEmpty",
                validWhen: false,
                message: "This handler is required.",
            },
            {
                field: "category",
                method: "isEmpty",
                validWhen: false,
                message: "This category is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    useQuery(QUERY_GET_SYSTEM_APP, {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            app_id: resourceId,
        },
        skip: isCreate || user.loading,
        onCompleted: resp => {
            setInputs(resp.getApp);

            setLoading(false);
        },
    });

    const options = {
        variables: {
            input: {
                id: inputs.id ?? "",
                status: inputs.status,
                name: inputs.name,
                handler: inputs.handler,
                category: inputs.category,
                created_at: inputs.created_at ?? moment(),
                updated_at: inputs.created_at ?? moment(),
                type: "System",
            },
        },
    };

    const { createOrUpdateButton } = useFormActions(
        "id",
        "App",
        MUTATION_CREATE_SYSTEM_APP,
        MUTATION_UPDATE_SYSTEM_APP,
        [
            {
                query: QUERY_SYSTEM_APP_LIST,
            },
        ],
        options,
        routes.APP_DETAIL.route,
        isValid,
        isSubmited,
    );

    return (
        <>
            <Content>
                <Portlet
                    header={{
                        title: "Form",
                        subtitle:
                            "Please enter all the require App information",
                    }}
                    sticky
                    toolbar={[createOrUpdateButton]}
                >
                    {!loading ? (
                        <Form>
                            <Section>
                                <Row>
                                    <Col>
                                        <SectionTitle md>
                                            Information
                                        </SectionTitle>
                                    </Col>
                                </Row>
                                <Col md={4}>
                                    <FormGroup
                                        className="align-items-center"
                                        row
                                    >
                                        <Label>Active</Label>
                                        <span className="k-switch k-switch--md k-switch--icon ml-5">
                                            <label>
                                                <Input
                                                    checked={
                                                        inputs.status
                                                            ? "checked"
                                                            : ""
                                                    }
                                                    name="status"
                                                    onChange={inputChange}
                                                    type="checkbox"
                                                />
                                                <span />
                                            </label>
                                        </span>
                                    </FormGroup>
                                </Col>
                                <Row>
                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>Name *</Label>

                                            <Input
                                                defaultValue={
                                                    inputs.name
                                                        ? inputs.name
                                                        : ""
                                                }
                                                invalid={
                                                    validator.name.isInvalid
                                                }
                                                name="name"
                                                onChange={inputChange}
                                                placeholder="Name"
                                            />
                                            <FormFeedback>
                                                {validator.name.message}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>Handler *</Label>
                                            <Input
                                                defaultValue={
                                                    inputs.handler
                                                        ? inputs.handler
                                                        : ""
                                                }
                                                invalid={
                                                    validator.handler.isInvalid
                                                }
                                                name="handler"
                                                onChange={inputChange}
                                                placeholder="Handler"
                                            />
                                            <FormFeedback>
                                                {validator.handler.message}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label>Category</Label>

                                            <Select
                                                controlName="category"
                                                defaultValue={selectValue(
                                                    inputs.category,
                                                    categoryType,
                                                )}
                                                invalid={
                                                    validator.category.isInvalid
                                                }
                                                onChange={selectChange}
                                                options={categoryType}
                                                placeholder="Category"
                                            />
                                            <FormFeedback>
                                                {validator.category.message}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Section>
                        </Form>
                    ) : (
                        <>
                            <Spinner color="metal" show sm />
                            &nbsp;&nbsp;Loading
                        </>
                    )}
                </Portlet>
            </Content>
        </>
    );
};

export default AppForm;
