import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Col, Row, FormFeedback, FormGroup, Label, Button } from "reactstrap";
import { useForm, useValidator } from "@/hooks";
import { Form, Input, Section, SectionTitle, Spinner } from "@/components";

const FavoriteStoreAlpineIOForm = ({
    app,
    handleCancel,
    handlerUpdatingOrCreate,
    isCreate,
    isUpdating,
    selectStore,
    setStore,
}) => {
    const [isFormReset, resetFormInputs] = useState(isCreate);
    const { inputs, inputChange, setInputs } = useForm();

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "id",
                method: "isEmpty",
                validWhen: false,
                message: "This Store id is required.",
            },
            {
                field: "name",
                method: "isEmpty",
                validWhen: false,
                message: "This Store name is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    useEffect(() => {
        if (!isCreate) {
            const objSelect = app?.favorites_stores[selectStore];
            setInputs({
                id: objSelect?.id,
                name: objSelect?.name,
            });
        }
    }, []);

    if (!isCreate && !isFormReset) {
        resetFormInputs(true);
    }

    const createOrUpdate = async () => {
        if (isSubmited) {
            isSubmited(true);
        }

        if (isValid()) {
            let favStores = _.cloneDeep(app.favorites_stores);

            if (isCreate) {
                favStores.push({
                    id: inputs.id,
                    name: inputs.name,
                });
            } else {
                favStores[selectStore] = inputs;
            }
            setStore(favStores);
            handlerUpdatingOrCreate(favStores);
        }
    };

    const handleClose = () => {
        handleCancel();
        resetFormInputs(false);
    };

    return (
        <Form>
            <Section className="mt-5">
                <Row>
                    <Col>
                        <SectionTitle md>
                            {isCreate
                                ? "Add Favorite Store"
                                : "Update Favorite Store"}
                        </SectionTitle>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Store id</Label>
                            <Input
                                invalid={validator.id.isInvalid}
                                name="id"
                                onChange={inputChange}
                                placeholder="Store id"
                                type="number"
                                value={inputs.id}
                            />
                            <FormFeedback>{validator.id.message}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <Label>Store name</Label>
                            <Input
                                invalid={validator.name.isInvalid}
                                name="name"
                                onChange={inputChange}
                                placeholder="Store name"
                                value={inputs.name}
                            />
                            <FormFeedback>
                                {validator.name.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="justify-content-end pr-3">
                    <Button
                        className="btn__options"
                        color="metal"
                        disabled={isUpdating}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="ml-3"
                        color="primary"
                        onClick={createOrUpdate}
                        style={{ with: "100px !important" }}
                    >
                        {isUpdating && <Spinner color="metal" show sm />}
                        <span>Save</span>
                    </Button>
                </Row>
            </Section>
        </Form>
    );
};

export default FavoriteStoreAlpineIOForm;
