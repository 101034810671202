import React from "react";
import { toast } from "react-toastify";
import { toastSettings } from "../../../config/components/toast";

const useNotification = () => {
    const notify = (message, color = null) => {
        let icon;

        switch (color) {
            case "info":
                icon = "fa-exclamation-circle";
                break;
            case "success":
                icon = "fa-check-circle";
                break;
            case "warning":
                icon = "fa-exclamation-triangle";
                break;
            case "error":
                icon = "fa-times-circle";
                break;
            default:
                icon = "fa-info-circle text-dark";
                break;
        }

        const body = (
            <div className="notification__body">
                <i className={icon} />
                <div className="notification__content">{message}</div>
            </div>
        );

        if (color) {
            toast[color](body, toastSettings);
        } else {
            toast(body, toastSettings);
        }
    };

    return notify;
};

export default useNotification;
