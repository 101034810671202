import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import AsideMenu from "./AsideMenu";
import { QUERY_UI } from "../../../../src/apollo/queries/local";
import { MUTATION_UI } from "../../../../src/apollo/mutations/local";

const Aside = () => {
    const { data } = useQuery(QUERY_UI);

    const { asideToggle } = data.ui;

    const [updateUi] = useMutation(MUTATION_UI, {
        variables: {
            ui: {
                asideToggle: false,
            },
        },
    });

    return (
        <>
            <button className="k-aside-close" onClick={updateUi}>
                <i className="fas fa-times" />
            </button>
            <AsideMenu />
            {asideToggle && (
                <div className="k-aside-overlay" onClick={updateUi} />
            )}
        </>
    );
};

export default Aside;
