import React from "react";
import { FormGroup, Col, Label, FormFeedback, Row, FormText } from "reactstrap";
import _ from "lodash";
import Spinner from "../../base/components/spinner/Spinner";
import Select from "../../base/components/form/select/Select";
import { Portlet, Section, SectionTitle } from "../../base/components/portlet";
import Input from "../../base/components/form/input/Input";
import InputGroupText from "../../base/components/form/input/InputGroupText";
import Form from "../../base/components/form/Form";
import MediaInput from "../../base/components/Medias";
import { currencyMask } from "../../utils/maskInput";

const GeneralsForm = ({
    toolbar,
    inputs,
    inputChange,
    selectChange,
    selectValue,
    validator,
    loading,
    timezonesOptions,
    currencyOptions,
    accountId,
    storeId,
}) => {
    const addProtocol = e => {
        const value = e.target.value;

        e.target.value = _.trimEnd(
            !_.startsWith(value, "http") ? `https://${value}` : value,
            "/",
        );

        if (value !== e.target.value) {
            inputChange(e);
        }
    };

    return (
        <Portlet
            className="business__settings"
            header={{
                title: "General information",
                subtitle: "Business General Information",
            }}
            sticky
            toolbar={toolbar}
        >
            {!loading ? (
                <Form>
                    <Section>
                        <Row>
                            <Col>
                                <SectionTitle md>
                                    Store information
                                </SectionTitle>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Label className="row-label">Status</Label>
                            <Col lg={4}>
                                <span className="k-switch k-switch--md k-switch--icon">
                                    <label>
                                        <Input
                                            checked={
                                                inputs.status ? "checked" : ""
                                            }
                                            name="status"
                                            onChange={inputChange}
                                            type="checkbox"
                                        />
                                        <span />
                                    </label>
                                </span>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col lg={4}>
                                <Label>Store Name *</Label>
                                <Input
                                    defaultValue={
                                        inputs.name ? inputs.name : ""
                                    }
                                    invalid={validator.name.isInvalid}
                                    name="name"
                                    onChange={inputChange}
                                />
                                <FormFeedback>
                                    {validator.name.message}
                                </FormFeedback>
                            </Col>
                            {/* <Row>
                            <Label lg={4} xl={3} />
                            <Col>
                                <SectionTitle md>Slogan</SectionTitle>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Slogan
                            </Label>
                            <Col>
                                <Input
                                    name="business_slogan"
                                    defaultValue={
                                        inputs.business_slogan
                                            ? inputs.business_slogan
                                            : ""
                                    }
                                    onChange={inputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Description
                            </Label>
                            <Col>
                                <Input
                                    type="textarea"
                                    rows="4"
                                    name="business_description"
                                    defaultValue={
                                        inputs.business_description
                                            ? inputs.business_description
                                            : ""
                                    }
                                    onChange={inputChange}
                                />
                            </Col>
                        </FormGroup> */}
                            <Col lg={4}>
                                <Label>Domain *</Label>
                                <Input
                                    invalid={validator.domain.isInvalid}
                                    name="domain"
                                    onBlur={addProtocol}
                                    onChange={inputChange}
                                    value={inputs.domain || ""}
                                />
                                <FormFeedback>
                                    {validator.domain.message}
                                </FormFeedback>
                                <FormText>
                                    Please make sure to include the https
                                    protocol, E.g. https://example.com
                                </FormText>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col lg={4}>
                                <Label>Large Logo:</Label>
                                <MediaInput
                                    name="logo"
                                    onChange={inputChange}
                                    typeInput="highlight"
                                    value={inputs.logo ?? ""}
                                />
                            </Col>
                            <Col lg={4}>
                                <Label>Small Logo:</Label>
                                <MediaInput
                                    name="logo_small"
                                    onChange={inputChange}
                                    typeInput="highlight"
                                    value={inputs.logo_small ?? ""}
                                />
                            </Col>
                        </FormGroup>
                    </Section>
                    <Section className="section-separator" />
                    <Section>
                        <Row>
                            <Col>
                                <SectionTitle md>
                                    Store representative
                                </SectionTitle>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Col lg={4}>
                                <Label>First Name *</Label>
                                <Input
                                    defaultValue={
                                        inputs.first_name
                                            ? inputs.first_name
                                            : ""
                                    }
                                    invalid={validator.first_name.isInvalid}
                                    name="first_name"
                                    onChange={inputChange}
                                />
                                <FormFeedback>
                                    {validator.first_name.message}
                                </FormFeedback>
                            </Col>
                            <Col lg={4}>
                                <Label>Last Name *</Label>
                                <Input
                                    defaultValue={
                                        inputs.last_name ? inputs.last_name : ""
                                    }
                                    invalid={validator.last_name.isInvalid}
                                    name="last_name"
                                    onChange={inputChange}
                                />
                                <FormFeedback>
                                    {validator.last_name.message}
                                </FormFeedback>
                            </Col>
                            <Col lg={4}>
                                <Label>Email *</Label>
                                <Input
                                    defaultValue={
                                        inputs.email ? inputs.email : ""
                                    }
                                    invalid={validator.email.isInvalid}
                                    name="email"
                                    onChange={inputChange}
                                />
                                <FormFeedback>
                                    {validator.email.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col lg={4}>
                                <Label>Phone *</Label>
                                <Input
                                    defaultValue={
                                        inputs.phone_number
                                            ? inputs.phone_number
                                            : ""
                                    }
                                    invalid={validator.phone_number.isInvalid}
                                    name="phone_number"
                                    onChange={inputChange}
                                />
                                <FormFeedback>
                                    {validator.phone_number.message}
                                </FormFeedback>
                            </Col>
                            <Col lg={4}>
                                <Label>License *</Label>
                                <Input
                                    defaultValue={
                                        inputs.license ? inputs.license : ""
                                    }
                                    invalid={validator.license.isInvalid}
                                    name="license"
                                    onChange={inputChange}
                                />
                                <FormFeedback>
                                    {validator.license.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                    </Section>
                    <Section className="section-separator" />
                    <Section>
                        <Row>
                            <Col>
                                <SectionTitle md>Location</SectionTitle>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Col lg={4}>
                                <Label>Address 1 *</Label>
                                <Input
                                    defaultValue={
                                        inputs.address1 ? inputs.address1 : ""
                                    }
                                    invalid={validator.address1.isInvalid}
                                    name="address1"
                                    onChange={inputChange}
                                />
                                <FormFeedback>
                                    {validator.address1.message}
                                </FormFeedback>
                            </Col>
                            <Col lg={4}>
                                <Label>Address 2</Label>
                                <Input
                                    defaultValue={
                                        inputs.address2 ? inputs.address2 : ""
                                    }
                                    name="address2"
                                    onChange={inputChange}
                                />
                            </Col>
                            <Col lg={4}>
                                <Label>City *</Label>
                                <Input
                                    defaultValue={
                                        inputs.city ? inputs.city : ""
                                    }
                                    invalid={validator.city.isInvalid}
                                    name="city"
                                    onChange={inputChange}
                                />
                                <FormFeedback>
                                    {validator.city.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col lg={4}>
                                <Label>State Code *</Label>
                                <Input
                                    defaultValue={
                                        inputs.province_code
                                            ? inputs.province_code
                                            : ""
                                    }
                                    invalid={validator.province_code.isInvalid}
                                    name="province_code"
                                    onChange={inputChange}
                                />
                                <FormFeedback>
                                    {validator.province_code.message}
                                </FormFeedback>
                            </Col>
                            <Col lg={4}>
                                <Label>Zip *</Label>
                                <Input
                                    defaultValue={inputs.zip ? inputs.zip : ""}
                                    invalid={validator.zip.isInvalid}
                                    name="zip"
                                    onChange={inputChange}
                                />
                                <FormFeedback>
                                    {validator.zip.message}
                                </FormFeedback>
                            </Col>
                            <Col lg={4}>
                                <Label>Country Code *</Label>
                                <Input
                                    defaultValue={
                                        inputs.country_code
                                            ? inputs.country_code
                                            : ""
                                    }
                                    invalid={validator.country_code.isInvalid}
                                    name="country_code"
                                    onChange={inputChange}
                                />
                                <FormFeedback>
                                    {validator.country_code.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col lg={4}>
                                <Label>Latitude</Label>
                                <Input
                                    defaultValue={
                                        inputs.latitude ? inputs.latitude : ""
                                    }
                                    name="latitude"
                                    onChange={inputChange}
                                />
                            </Col>
                            <Col lg={4}>
                                <Label>Longitude</Label>
                                <Input
                                    defaultValue={
                                        inputs.longitude ? inputs.longitude : ""
                                    }
                                    name="longitude"
                                    onChange={inputChange}
                                />
                            </Col>
                        </FormGroup>
                    </Section>
                    <Section className="section-separator" />
                    <Section>
                        <Row>
                            <Col>
                                <SectionTitle md>
                                    Timezone and currency
                                </SectionTitle>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Col lg={4}>
                                <Label>Timezone</Label>
                                <Select
                                    controlName="timezone"
                                    defaultValue={selectValue(
                                        inputs.timezone,
                                        timezonesOptions,
                                    )}
                                    invalid={validator.timezone.isInvalid}
                                    onChange={selectChange}
                                    options={timezonesOptions}
                                />

                                <FormFeedback>
                                    {validator.timezone.message}
                                </FormFeedback>
                            </Col>
                            <Col lg={4}>
                                <Label>Currency</Label>
                                <Select
                                    controlName="currency"
                                    defaultValue={selectValue(
                                        inputs.currency,
                                        currencyOptions,
                                    )}
                                    invalid={validator.currency.isInvalid}
                                    onChange={selectChange}
                                    options={currencyOptions}
                                />

                                <FormFeedback>
                                    {validator.timezone.message}
                                </FormFeedback>
                            </Col>
                            <Col lg={4}>
                                <Label>Money Format</Label>
                                <Input
                                    defaultValue={
                                        inputs.money_format
                                            ? inputs.money_format
                                            : ""
                                    }
                                    name="money_format"
                                    onChange={inputChange}
                                />
                            </Col>
                        </FormGroup>
                    </Section>
                    <Section className="section-separator" />
                    <Section>
                        <Row>
                            <Col>
                                <SectionTitle md>Minimum order</SectionTitle>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Col lg={4}>
                                <Label>Amount</Label>
                                <Input
                                    className="form-control"
                                    defaultValue={
                                        inputs.min_order_amount
                                            ? inputs.min_order_amount
                                            : ""
                                    }
                                    mask={currencyMask(inputs.min_order_amount)}
                                    name="min_order_amount"
                                    onChange={inputChange}
                                    prepend={<InputGroupText>$</InputGroupText>}
                                    typeInput={"masked"}
                                />
                            </Col>
                        </FormGroup>
                    </Section>
                    <Section className="section-separator" />
                    <Section>
                        <Row>
                            <Col>
                                <SectionTitle md>Account Info</SectionTitle>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <FormGroup>
                                    <Label>Account ID</Label>
                                    <Input
                                        disabled={true}
                                        name="account_id"
                                        value={accountId}
                                    />
                                </FormGroup>
                            </Col>

                            <Col lg={4}>
                                <FormGroup>
                                    <Label>Store ID</Label>
                                    <Input
                                        disabled={true}
                                        name="store_id"
                                        value={storeId}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Section>
                </Form>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
        </Portlet>
    );
};

export default GeneralsForm;
