import gql from "graphql-tag";

export const MUTATION_GET_IMAGEURL = gql`
    mutation uploadFile($input: FileUploadInput!) {
        uploadFile(input: $input) {
            headers {
                key
                value
            }
            url
        }
    }
`;

export const MUTATION_GET_MATERIAL_PRICE_URL = gql`
    mutation uploadMaterialPriceFile(
        $account_id: String!
        $store_id: String!
        $input: FileUploadInput!
    ) {
        uploadMaterialPriceFile(
            account_id: $account_id
            store_id: $store_id
            input: $input
        )
    }
`;

export const MUTATION_GET_MATERIAL_COLOR_URL = gql`
    mutation uploadMaterialColorFile(
        $account_id: String!
        $store_id: String!
        $input: FileUploadInput!
    ) {
        uploadMaterialColorFile(
            account_id: $account_id
            store_id: $store_id
            input: $input
        )
    }
`;
