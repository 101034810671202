import React from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { Portlet, Section, SectionTitle } from "../../base/components/portlet";
import { FormGroup, Col, Label, Row } from "reactstrap";
import Select from "../../base/components/form/select/Select";
import InputGroupText from "../../base/components/form/input/InputGroupText";
import { currencyMask } from "../../utils/maskInput";
import Input from "../../base/components/form/input/Input";
import Form from "../../base/components/form/Form";
import useMeta from "../../base/hooks/useMeta";
import useForm from "../../base/hooks/form/useForm";
import useFormActions from "../../base/hooks/form/useFormActions";
import { incrementRateType } from "../../config/constant";
import { toCurrency } from "../../utils/utils";
import {
    MUTATION_UPDATE_PRICE_TABLE_APP,
    QUERY_LIST_STORE_APPS,
} from "../../apollo/module-operations/app";
import _ from "lodash";
import useValidator from "../../base/hooks/form/useValidator";

const PriceTableForm = ({ app }) => {
    const { user } = useMeta();
    const { inputs, setInputs, inputChange, selectChange, selectValue } =
        useForm();

    if (!_.get(inputs, "id", null)) {
        setInputs(app);
    }
    const isAmount = input => {
        return input.increment_type === "AMOUNT" && input.rate;
    };

    let options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: {
                ...inputs,
                rate: isAmount(inputs)
                    ? toCurrency(inputs.rate)
                    : parseInt(inputs.rate, 10),
                type: "PriceTable",
            },
        },
    };

    const { isValid, isSubmited } = useValidator(
        [
            {
                field: "rate",
                method: "isEmpty",
                validWhen: false,
                message: "This rate is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    const { createOrUpdateButton } = useFormActions(
        "id",
        "Price Table",
        "",
        MUTATION_UPDATE_PRICE_TABLE_APP,
        [
            {
                query: QUERY_LIST_STORE_APPS,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        "",
        isValid,
        isSubmited,
        "primary",
    );

    return (
        <Portlet
            centered
            header={{
                title: "Price Table",
                subtitle: "Price Table Information",
            }}
            sticky
        >
            {!user.loading ? (
                <Form centered>
                    <Section>
                        <Row>
                            <Label lg={4} xl={3} />
                            <Col>
                                <SectionTitle md>Information</SectionTitle>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Increment Rate Type
                            </Label>
                            <Col lg={8} xl={9}>
                                <Select
                                    controlName="increment_type"
                                    defaultValue={selectValue(
                                        inputs.increment_type,
                                        incrementRateType,
                                    )}
                                    onChange={selectChange}
                                    options={incrementRateType}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Rate
                            </Label>
                            <Col lg={8} xl={9}>
                                {inputs.increment_type === "PERCENTAGE" && (
                                    <Input
                                        defaultValue={
                                            inputs.rate ? inputs.rate : ""
                                        }
                                        name="rate"
                                        onChange={inputChange}
                                        prepend={
                                            <InputGroupText>
                                                <span>%</span>
                                            </InputGroupText>
                                        }
                                        type="number"
                                    />
                                )}
                                {inputs.increment_type === "AMOUNT" && (
                                    <Input
                                        className="form-control"
                                        defaultValue={inputs.rate}
                                        mask={currencyMask(inputs.rate)}
                                        name="rate"
                                        onChange={inputChange}
                                        prepend={
                                            <InputGroupText>$</InputGroupText>
                                        }
                                        typeInput={"masked"}
                                    />
                                )}
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={4} xl={3} />
                            <Col>{createOrUpdateButton}</Col>
                        </FormGroup>
                    </Section>
                </Form>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
        </Portlet>
    );
};

export default PriceTableForm;
