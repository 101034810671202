import { FC, useState } from "react";
import { Button } from "reactstrap";
import { ApolloError } from "apollo-client";
import { useMutation } from "@apollo/react-hooks";

import { AlgoliaOrder, Order, OrderStatusType, Status } from "@/types";
import { useMeta, useNotification } from "@/hooks";
import { Spinner } from "@/components";
import { MUTATION_UPDATE_ORDER_STATUS } from "apollo/module-operations/order";
import classNames from "classnames";

interface OrderActionButtonsProps {
    order: Order | AlgoliaOrder;
    className?: string;
    onActionCompleted?: () => void;
    onActionFailed?: () => void;
}
const OrderActionButtons: FC<OrderActionButtonsProps> = ({
    order,
    className,
    onActionCompleted,
    onActionFailed,
}) => {
    const { user } = useMeta();
    const notify = useNotification();

    const [updatingStatus, setUpdatingStatus] = useState<Status | undefined>(
        undefined,
    );

    const [updateOrderStatus] = useMutation(MUTATION_UPDATE_ORDER_STATUS, {
        onCompleted: () => {
            setTimeout(() => {
                setUpdatingStatus(undefined);
                onActionCompleted?.();
                notify("Order status changed successfully.", "success");
            }, 5000);
        },

        onError: (error: ApolloError) => {
            setUpdatingStatus(undefined);
            onActionFailed?.();
            notify(error.message, "error");
        },
    });

    const handleUpdateOrder = async (status: Status) => {
        setUpdatingStatus(status);
        updateOrderStatus({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: {
                    id:
                        (order as Order).entity_id ||
                        (order as AlgoliaOrder).objectID,
                    status: status,
                },
            },
        });
    };

    const renderActionButton = () => {
        if (order?.status === "ABANDONED_CART") {
            return;
        }

        const actionButtons: JSX.Element[] = [];

        const statusCanBeDeclined: OrderStatusType[] = [
            "PENDING",
            "APPROVED",
            "READY",
            "ASSIGNED",
        ];

        if (
            order?.status === "APPROVED" ||
            order?.status === "READY" ||
            order?.status === "DECLINED" ||
            order?.status === "ASSIGNED"
        ) {
            actionButtons.push(
                <Button
                    color="warning"
                    disabled={!!updatingStatus}
                    onClick={() => handleUpdateOrder("PENDING")}
                >
                    {updatingStatus === "PENDING" && (
                        <Spinner color="metal" show sm />
                    )}
                    Back to processing
                </Button>,
            );
        }

        if (order?.status === "APPROVED" || order?.status === "READY") {
            actionButtons.push(
                <Button
                    color="primary"
                    disabled={!!updatingStatus}
                    onClick={() => handleUpdateOrder("DELIVERED")}
                >
                    {updatingStatus === "DELIVERED" && (
                        <Spinner color="metal" show sm />
                    )}
                    Completed
                </Button>,
            );
        }

        if (statusCanBeDeclined.includes(order?.status)) {
            actionButtons.push(
                <Button
                    color="danger"
                    disabled={!!updatingStatus}
                    onClick={() => handleUpdateOrder("DECLINED")}
                >
                    {updatingStatus === "DECLINED" && (
                        <Spinner color="metal" show sm />
                    )}
                    Reject order
                </Button>,
            );
        }

        if (order?.status === "PENDING") {
            actionButtons.push(
                <Button
                    color="info"
                    disabled={!!updatingStatus}
                    onClick={() => handleUpdateOrder("APPROVED")}
                >
                    {updatingStatus === "APPROVED" && (
                        <Spinner color="metal" show sm />
                    )}
                    Approve order
                </Button>,
            );
        }

        return (
            <div className={classNames("order_action__buttons", className)}>
                {actionButtons}
            </div>
        );
    };

    return renderActionButton();
};

export { OrderActionButtons };
