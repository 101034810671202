import React from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { Portlet, Section, SectionTitle } from "../../base/components/portlet";
import { FormGroup, Col, Label, Row, FormFeedback } from "reactstrap";
import Input from "../../base/components/form/input/Input";
import Form from "../../base/components/form/Form";
import useMeta from "../../base/hooks/useMeta";
import useForm from "../../base/hooks/form/useForm";
import useFormActions from "../../base/hooks/form/useFormActions";
import {
    MUTATION_UPDATE_BLINDDATA_APP,
    QUERY_LIST_STORE_APPS,
} from "../../apollo/module-operations/app";
import _ from "lodash";
import useValidator from "../../base/hooks/form/useValidator";

const WebPayForm = ({ app }) => {
    const { user } = useMeta();
    const { inputs, setInputs, inputChange } = useForm();

    if (!_.get(inputs, "id", null)) {
        setInputs(app);
    }

    let options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: {
                ...inputs,
                type: "BlindData",
            },
        },
    };

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "access_code",
                method: "isEmpty",
                validWhen: false,
                message: "This Access Code is required.",
            },

            {
                field: "company_name",
                method: "isEmpty",
                validWhen: false,
                message: "This Company Name is required.",
            },

            // {
            //     field: "sub_account_code",
            //     method: "isEmpty",
            //     validWhen: false,
            //     message: "This Sub Account Code is required.",
            // },
        ],
        {
            ...inputs,
        },
    );

    const { createOrUpdateButton } = useFormActions(
        "id",
        "Blinddata",
        "",
        MUTATION_UPDATE_BLINDDATA_APP,
        [
            {
                query: QUERY_LIST_STORE_APPS,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        "",
        isValid,
        isSubmited,
        "secondary",
    );

    return (
        <Portlet
            centered
            header={{
                title: "Blinddata",
                subtitle: "Blinddata Information",
            }}
            sticky
        >
            {!user.loading ? (
                <Form centered>
                    <Section>
                        <Row>
                            <Label lg={4} xl={3} />
                            <Col>
                                <SectionTitle md>Information</SectionTitle>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Company Name
                            </Label>
                            <Col>
                                <Input
                                    invalid={validator.company_name.isInvalid}
                                    name="company_name"
                                    onChange={inputChange}
                                    value={inputs.company_name}
                                />
                                <FormFeedback>
                                    {validator.company_name.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Access Code
                            </Label>
                            <Col>
                                <Input
                                    invalid={validator.access_code.isInvalid}
                                    name="access_code"
                                    onChange={inputChange}
                                    value={inputs.access_code}
                                />
                                <FormFeedback>
                                    {validator.access_code.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Sub Account Code
                            </Label>
                            <Col>
                                <Input
                                    name="sub_account_code"
                                    onChange={inputChange}
                                    value={inputs.sub_account_code}
                                    // invalid={
                                    //     validator.sub_account_code.isInvalid
                                    // }
                                />
                                {/*
                                <FormFeedback>
                                    {validator.sub_account_code.message}
                                </FormFeedback>
                                */}
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={4} xl={3} />
                            <Col>{createOrUpdateButton}</Col>
                        </FormGroup>
                    </Section>
                </Form>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
        </Portlet>
    );
};

export default WebPayForm;
