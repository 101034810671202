import gql from "graphql-tag";

let Page = {};
Page.fragments = {
    attributes: gql`
        fragment PageFragment on Page {
            id
            slug
            template
            created_at
            updated_at
            publish_at
            seo {
                url
                title
                meta_description
                meta_image
                include_local_schema
                meta_robots
            }
            html_body
            metadata
            image
            status
        }
    `,
};

export const QUERY_PAGE_LIST = gql`
    query listPages($account_id: String!, $store_id: String!) {
        listPages(account_id: $account_id, store_id: $store_id) {
            ...PageFragment
        }
    }
    ${Page.fragments.attributes}
`;

export const QUERY_GET_PAGE = gql`
    query getPage($account_id: String!, $store_id: String!, $page_id: String!) {
        getPage(
            account_id: $account_id
            store_id: $store_id
            page_id: $page_id
        ) {
            ...PageFragment
        }
    }
    ${Page.fragments.attributes}
`;

export const MUTATION_CREATE_PAGE = gql`
    mutation createPage(
        $account_id: String!
        $store_id: String!
        $input: PageInput!
    ) {
        createPage(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            ...PageFragment
        }
    }
    ${Page.fragments.attributes}
`;

export const MUTATION_UPDATE_PAGE = gql`
    mutation updatePage(
        $account_id: String!
        $store_id: String!
        $input: PageInput!
    ) {
        updatePage(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            ...PageFragment
        }
    }
    ${Page.fragments.attributes}
`;

export const MUTATION_REMOVE_PAGE = gql`
    mutation deletePage(
        $account_id: String!
        $store_id: String!
        $input: PageInput!
    ) {
        deletePage(account_id: $account_id, store_id: $store_id, input: $input)
    }
`;
