import React from "react";
import classNames from "classnames";

const SectionTitle = ({ children, sm, lg, className, ...props }) => {
    return (
        <div
            className={classNames(
                "k-section__title",
                { "k-section__title-sm": sm },
                { "k-section__title-lg": lg },
                className,
            )}
            {...props}
        >
            {children}
        </div>
    );
};

export default SectionTitle;
