import React, { useState } from "react";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Comparator } from "react-bootstrap-table2-filter";

import { Input, Select } from "base/components";

const options = [
    { value: Comparator.LE, label: "<=" },
    { value: Comparator.EQ, label: "=" },
    { value: Comparator.GE, label: ">=" },
    { value: "BETWEEN", label: "Between" },
];

const format = "MM-DD-YYYY";

const DateFilter = ({ onFilter, column, setValue }) => {
    const [comparator, setComparator] = useState(options[0]);

    const [single, setSingle] = useState(true);

    const now = moment().toDate();

    const [startDate, setStartDate] = useState(now);

    const [endDate, setEndDate] = useState(now);

    const updateComparator = cmp => {
        // const end =
        //     cmp.value === "BETWEEN" ? endDate.add(1, "month") : startDate;
        setSingle(cmp.value !== "BETWEEN");

        setComparator(cmp);

        // setEndDate(end);

        onFilter({
            startDate: startDate,
            endDate: endDate,
            comparator: cmp.value,
        });
    };

    const apply = (e, picker) => {
        const _startDate = picker.startDate.toDate();

        const _endDate = picker.endDate.toDate();

        setStartDate(_startDate);

        setEndDate(_endDate);

        onFilter({
            startDate: _startDate,
            endDate: _endDate,
            comparator: comparator.value,
        });
    };

    const value = `${moment(startDate).format(format)}${
        single ? "" : ` & ${moment(endDate).format(format)}`
    }`;

    return (
        <>
            <Select
                className="mb-2"
                onChange={updateComparator}
                options={options}
                placeholder="Comparator"
                value={comparator}
            />
            <DateRangePicker
                autoApply
                autoUpdateInput
                containerClass="d-block react-bootstrap-daterangepicker-container"
                endDate={endDate}
                key={single}
                onApply={apply}
                singleDatePicker={single}
                startDate={startDate}
            >
                <div className="input-group date" title={value}>
                    <Input
                        placeholder={`Select ${column.text}...`}
                        readOnly={true}
                        value={value}
                    />
                    <div className="input-group-append">
                        <span className="input-group-text">
                            <i className="fas fa-calendar-alt" />
                        </span>
                    </div>
                </div>
            </DateRangePicker>
        </>
    );
};

export default DateFilter;
