import { FC, useEffect, useRef, useState } from "react";
import { Button } from "reactstrap";
import classNames from "classnames";

interface IParagraphProps {
    lineLimit?: number;
    applyLimit: boolean;
    text?: string;
    className?: string;
}

const Paragraph: FC<IParagraphProps> = ({
    lineLimit,
    applyLimit = false,
    text,
    className,
}) => {
    const linesNumber = lineLimit ?? 3;
    const [isTextExpanded, setIsTextExpanded] = useState(false);
    const [shouldShowButton, setShouldShowButton] = useState(false);
    const paragraphRef = useRef<HTMLParagraphElement | null>(null);

    const checkOverflow = () => {
        if (paragraphRef.current && applyLimit) {
            const lineHeight = parseInt(
                window.getComputedStyle(paragraphRef.current).lineHeight,
                10,
            );
            const maxHeight = lineHeight * linesNumber;
            const actualHeight = paragraphRef.current.scrollHeight;

            setShouldShowButton(actualHeight > maxHeight);
        }
    };

    useEffect(() => {
        const observer = new ResizeObserver(() => {
            // Use requestAnimationFrame to ensure DOM is updated
            requestAnimationFrame(checkOverflow);
        });

        if (paragraphRef.current) {
            observer.observe(paragraphRef.current);
        }

        // Initial check and add resize listener
        checkOverflow();
        const handleResize = () => {
            requestAnimationFrame(checkOverflow);
        };
        window.addEventListener("resize", handleResize);

        return () => {
            observer.disconnect();
            window.removeEventListener("resize", handleResize);
        };
    }, [linesNumber, applyLimit, text]);

    if (!text) {
        return null;
    }

    return (
        <div className="paragraph__description_container">
            <p
                className={classNames("paragraph__description", className, {
                    ["show_more"]: isTextExpanded,
                })}
                ref={paragraphRef}
                style={{ WebkitLineClamp: linesNumber, lineClamp: linesNumber }}
            >
                {text}
            </p>
            {shouldShowButton && applyLimit && (
                <Button
                    className="show_more_button"
                    color="link"
                    onClick={() => setIsTextExpanded(!isTextExpanded)}
                >
                    {isTextExpanded ? "less..." : "...more"}
                </Button>
            )}
        </div>
    );
};

export default Paragraph;
