import React from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { Portlet, Section, SectionTitle } from "../../base/components/portlet";
import { FormGroup, Col, Label, FormFeedback, Row } from "reactstrap";
import Input from "../../base/components/form/input/Input";
import Form from "../../base/components/form/Form";
import useMeta from "../../base/hooks/useMeta";
import useForm from "../../base/hooks/form/useForm";
import useValidator from "../../base/hooks/form/useValidator";
import useFormActions from "../../base/hooks/form/useFormActions";
import {
    MUTATION_UPADTE_AUTHORIZE_NET_APP,
    QUERY_LIST_STORE_APPS,
} from "../../apollo/module-operations/app";
import _ from "lodash";

const AppAuthorizeNetForm = ({ toolbar, app }) => {
    const { user } = useMeta();

    const { inputs, setInputs, inputChange } = useForm();

    if (!_.get(inputs, "id", null)) {
        setInputs(app);
    }

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "api_login_id",
                method: "isEmpty",
                validWhen: false,
                message: "This API Login Id is required.",
            },
            {
                field: "transaction_key",
                method: "isEmpty",
                validWhen: false,
                message: "This Transaction Key is required.",
            },
            {
                field: "test_api_login_id",
                method: "isEmpty",
                validWhen: false,
                message: "This API Login Id is required.",
            },
            {
                field: "test_transaction_key",
                method: "isEmpty",
                validWhen: false,
                message: "This Transaction Key is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    let options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: {
                ...inputs,
                type: "AuthorizeNet",
                sandbox: inputs.sandbox === "true" || inputs.sandbox === true,
            },
        },
    };

    const { createOrUpdateButton } = useFormActions(
        "id",
        "Authorize Net",
        "",
        MUTATION_UPADTE_AUTHORIZE_NET_APP,
        [
            {
                query: QUERY_LIST_STORE_APPS,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        "",
        isValid,
        isSubmited,
        "secondary",
    );

    return (
        <Portlet
            centered
            header={{
                title: "Authorize Net",
                subtitle: "Authorize Net Information",
            }}
            sticky
            toolbar={toolbar}
        >
            {!false ? (
                <Form centered>
                    <Section>
                        <Row>
                            <Label lg={4} xl={3} />
                            <Col>
                                <SectionTitle md>Information</SectionTitle>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Sandbox
                            </Label>
                            <Col>
                                <span className="k-switch k-switch--md k-switch--icon">
                                    <label>
                                        <Input
                                            checked={
                                                inputs.sandbox ? "checked" : ""
                                            }
                                            name="sandbox"
                                            onChange={inputChange}
                                            type="checkbox"
                                        />
                                        <span />
                                    </label>
                                </span>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Api login id
                            </Label>
                            <Col>
                                <Input
                                    invalid={
                                        inputs.sandbox
                                            ? validator.test_api_login_id
                                                  .isInvalid
                                            : validator.api_login_id.isInvalid
                                    }
                                    name={
                                        inputs.sandbox
                                            ? "test_api_login_id"
                                            : "api_login_id"
                                    }
                                    onChange={inputChange}
                                    value={
                                        inputs.sandbox
                                            ? inputs.test_api_login_id
                                            : inputs.api_login_id
                                    }
                                />
                                <FormFeedback>
                                    {inputs.sandbox
                                        ? validator.test_api_login_id.message
                                        : validator.api_login_id.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Transaction key
                            </Label>
                            <Col>
                                <Input
                                    invalid={
                                        inputs.sandbox
                                            ? validator.test_transaction_key
                                                  .isInvalid
                                            : validator.transaction_key
                                                  .isInvalid
                                    }
                                    name={
                                        inputs.sandbox
                                            ? "test_transaction_key"
                                            : "transaction_key"
                                    }
                                    onChange={inputChange}
                                    value={
                                        inputs.sandbox
                                            ? inputs.test_transaction_key
                                            : inputs.transaction_key
                                    }
                                />
                                <FormFeedback>
                                    {inputs.sandbox
                                        ? validator.test_transaction_key.message
                                        : validator.transaction_key.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label lg={4} xl={3} />
                            <Col>{createOrUpdateButton}</Col>
                        </FormGroup>
                    </Section>
                </Form>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
        </Portlet>
    );
};

export default AppAuthorizeNetForm;
