import React from "react";
import classNames from "classnames";

const PortletBody = ({ children, fit }) => {
    return (
        <div
            className={classNames("k-portlet__body", {
                "k-portlet__body--fit": fit,
            })}
        >
            <div className="k-portlet__content">{children}</div>
        </div>
    );
};

export default PortletBody;
