import React from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { Portlet, Section, SectionTitle } from "../../base/components/portlet";
import { FormGroup, Col, Label, Row } from "reactstrap";
import Input from "../../base/components/form/input/Input";
import Form from "../../base/components/form/Form";

const SocialForm = ({ toolbar, inputs, inputChange, loading }) => {
    return (
        <Portlet
            className="business__settings"
            header={{
                title: "Social",
                subtitle: "Social Media Information",
            }}
            sticky
            toolbar={toolbar}
        >
            {!loading ? (
                <Form>
                    <Section>
                        <Row>
                            <Col>
                                <SectionTitle md>Information</SectionTitle>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Col lg={4}>
                                <Label>Instagram</Label>
                                <Input
                                    defaultValue={
                                        inputs.instagram ? inputs.instagram : ""
                                    }
                                    name="instagram"
                                    onChange={inputChange}
                                />
                            </Col>
                            <Col lg={4}>
                                <Label>Facebook</Label>
                                <Input
                                    defaultValue={
                                        inputs.facebook ? inputs.facebook : ""
                                    }
                                    name="facebook"
                                    onChange={inputChange}
                                />
                            </Col>
                            <Col lg={4}>
                                <Label>Twitter</Label>
                                <Input
                                    defaultValue={
                                        inputs.twitter ? inputs.twitter : ""
                                    }
                                    name="twitter"
                                    onChange={inputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col lg={4}>
                                <Label>Yelp</Label>
                                <Input
                                    defaultValue={
                                        inputs.yelp ? inputs.yelp : ""
                                    }
                                    name="yelp"
                                    onChange={inputChange}
                                />
                            </Col>
                            <Col lg={4}>
                                <Label>Youtube</Label>
                                <Input
                                    defaultValue={
                                        inputs.youtube ? inputs.youtube : ""
                                    }
                                    name="youtube"
                                    onChange={inputChange}
                                />
                            </Col>
                        </FormGroup>
                    </Section>
                </Form>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
        </Portlet>
    );
};

export default SocialForm;
