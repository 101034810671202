import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import {
    InMemoryCache,
    IntrospectionFragmentMatcher,
    defaultDataIdFromObject,
} from "apollo-cache-inmemory";
import { initialLocalState } from "../config/apollo/data";
import { Auth } from "aws-amplify";

// Use this Query to Update the fragmentTypes
// query {
//   __schema {
//     types {
//       kind
//       name
//       possibleTypes {
//         name
//       }
//     }
//   }
// }

import introspectionQueryResultData from "./fragmentTypes.json";

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
});

const cache = new InMemoryCache({
    fragmentMatcher: fragmentMatcher,
    dataIdFromObject: object => {
        switch (object.__typename) {
            case "LineItem":
                return `${Math.random().toString(36).slice(-5)}-${object.id}`;
            case "Variant":
                return `${Math.random().toString(36).slice(-5)}-${object.id}`;
            case "OrderDiscountItem":
                return `${Math.random().toString(36).slice(-5)}-${object.id}`;
            default:
                return defaultDataIdFromObject(object);
        }
    },
});

cache.writeData(initialLocalState);

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT_URL,
});

const authLink = setContext(async (_, { headers }) => {
    let congnitoUser = await Auth.currentAuthenticatedUser();
    return {
        headers: {
            ...headers,
            Authorization: congnitoUser.signInUserSession.accessToken.jwtToken,
        },
    };
});

const data = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
    resolvers: {
        Mutation: {
            updateUi: (_, args, { cache }) => {
                cache.writeData({
                    data: {
                        ui: {
                            ...args.ui,
                            __typename: "Ui",
                        },
                    },
                });
            },
        },
    },
});

export default data;
