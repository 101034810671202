import { FC } from "react";
import { Tabs } from "@/components";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Raw } from "./tabs";

const TreezObjectModal: FC<ITreezObjectModal> = ({
    handleModalToggle,
    isModalOpen,
    object,
    title = "Product Details",
}) => {
    return (
        <Modal
            centered
            className={"treez_modal_product"}
            fade={false}
            isOpen={isModalOpen}
            toggle={handleModalToggle}
        >
            <ModalHeader toggle={handleModalToggle}>{title}</ModalHeader>
            <ModalBody>
                <Tabs
                    navs={[
                        {
                            title: "Raw",
                            content: <Raw object={object} />,
                        },
                    ]}
                />
            </ModalBody>
        </Modal>
    );
};

export default TreezObjectModal;

interface ITreezObjectModal {
    handleModalToggle: () => void;
    isModalOpen: boolean;
    object: any;
    title?: string;
}
