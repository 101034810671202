import { Section } from "@/components";
import { FC } from "react";
import { Row } from "reactstrap";

const Raw: FC<IRaw> = ({ object }) => {
    return (
        <Section>
            <Row>
                <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                    {JSON.stringify(object, null, 2)}
                </pre>
            </Row>
        </Section>
    );
};

export default Raw;

interface IRaw {
    object: any;
}
