import React from "react";
import classNames from "classnames";

const Form = ({ children, labelsLeft, centered, className }) => {
    return (
        <div
            className={classNames(
                className,
                { "k-form k-form--label-right": !labelsLeft },
                { "k-form--centered": centered },
            )}
        >
            {children}
        </div>
    );
};

export default Form;
