import { useState, useMemo } from "react";
import { Button } from "reactstrap";
import { v4 } from "uuid";
import { startCase } from "lodash";

import { currencyFormat } from "@/utils";
import { Badge, Icon, Input, Table } from "@/components";
import { NOT_AVAILABLE } from "@/config";
import useNotification from "../../../../base/hooks/components/useNotification";

import placeholder from "../../../../assets/images/placeholder.png";

const OrdersItemTable = ({
    getOrderLoading,
    lineItems,
    loading,
    showEditForm,
    updating,
    handleAddItemQuantity,
    handleSubItemQuantity,
    selection,
    setSelection,
    widthColumnPrice,
    namesProductsPOS,
}) => {
    const notify = useNotification();
    const [key, setKey] = useState(true);
    const lineItemsWithGuid = useMemo(
        () =>
            lineItems?.map(item => ({
                ...item,
                guid: v4(),
                pos_name: namesProductsPOS?.find(p => p.id === item.pos_id)
                    ?.name,
            })) ?? [],
        [lineItems, namesProductsPOS],
    );

    const getColumnPrice = () => {
        switch (widthColumnPrice) {
            case "52%":
                return "13%";
            default:
                return "7%";
        }
    };

    const columns = [
        {
            dataField: "guid",
            text: "guid",
            hidden: true,
        },
        {
            dataField: "item_id",
            text: "Key",
            hidden: true,
        },
        {
            dataField: "item_id",
            text: "Id",
            hidden: true,
        },
        {
            dataField: "thumbnail",
            text: "Image",
            headerStyle: (colum, colIndex) => {
                return { width: "6%" };
            },
            sort: false,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <img
                        alt="Item"
                        height="51"
                        src={row?.thumbnail || placeholder}
                        width="51"
                    />
                );
            },
        },
        {
            dataField: "name",
            text: "Name",
            headerStyle: (colum, colIndex) => {
                return { width: "30%" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <div className="order__table_row_secondary_with_badge">
                        {!row?.name ? (
                            <span>{NOT_AVAILABLE}</span>
                        ) : (
                            <span>{`${row?.name}`}</span>
                        )}
                        <Button
                            className="order__table_copy_action"
                            onClick={() => clipboardCopy(row)}
                            title="Copy POS product name"
                        >
                            <Icon color="#727a94" icon="copy" />
                        </Button>
                        {!row?.pos_id ? (
                            <Badge
                                className="status__error"
                                label="Empty pos id"
                            />
                        ) : null}
                    </div>
                );
            },
        },
        {
            dataField: "brand",
            text: "Brand",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "10%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <div className="order__table_row_secondary">
                        {cell ? cell : NOT_AVAILABLE}
                    </div>
                );
            },
        },
        {
            dataField: "category",
            text: "Category",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "8%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <div className="order__table_row_secondary">
                        {cell ? cell : NOT_AVAILABLE}
                    </div>
                );
            },
        },
        {
            dataField: "weight",
            text: "Weight",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "10%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <div className="order__table_row_secondary">
                        {startCase(row.variants[0]?.type)}
                    </div>
                );
            },
        },
        {
            dataField: "quantity",
            text: "Quantity",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "8%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <>
                        {!showEditForm ? (
                            <div className="order__table_row_secondary">
                                {row?.quantity || NOT_AVAILABLE}
                            </div>
                        ) : (
                            <div className="quantity_content">
                                <Button
                                    aria-label="Decrement quantity"
                                    className="btn_small_action"
                                    color="metal"
                                    icon
                                    onClick={() =>
                                        handleSubItemQuantity(rowIndex)
                                    }
                                >
                                    <i
                                        className={
                                            row?.quantity === 1
                                                ? "far fa-trash-alt"
                                                : "fas fa-minus"
                                        }
                                    />
                                </Button>
                                {row?.quantity}
                                <Button
                                    aria-label="Increment quantity"
                                    className="btn_small_action"
                                    color="metal"
                                    icon
                                    onClick={() =>
                                        handleAddItemQuantity(rowIndex)
                                    }
                                >
                                    <i className="fas fa-plus" />
                                </Button>
                            </div>
                        )}
                    </>
                );
            },
        },
        {
            dataField: "barcodes",
            text: "Barcodes",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "12%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <>
                        <div className="order__table_row_secondary">
                            {row.barcodes?.length ? (
                                <ul className="barcodes_list">
                                    {row.barcodes?.map((code, index) => (
                                        <li key={index}>{code}</li>
                                    ))}
                                </ul>
                            ) : (
                                <span>{NOT_AVAILABLE}</span>
                            )}
                        </div>
                    </>
                );
            },
        },
        {
            dataField: "price",
            text: "Price",
            id: "col-fixed",
            headerStyle: (colum, colIndex) => {
                return { width: getColumnPrice() };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <div className="order__table_row_secondary">
                        {row && row.price
                            ? `${currencyFormat(row.price)}`
                            : "$0"}
                    </div>
                );
            },
            sort: true,
        },
    ];

    const clipboardCopy = item => {
        const nameItem = item.name || NOT_AVAILABLE;
        const text = item?.pos_name || nameItem;
        navigator.clipboard.writeText(text);
        notify(`${text} is copied`, "success");
    };

    const selectRow = {
        mode: "checkbox",
        classes: "table-row--selected",
        clickToSelect: false,
        onSelect: (row, isSelect, rowIndex, e) => {
            let selected_item = [];
            setKey(!key);

            if (isSelect) {
                selected_item = [...selection, rowIndex];
            } else {
                selected_item = selection.filter(s => s !== rowIndex);
            }
            setSelection(selected_item);
        },
        onSelectAll: (isSelect, rows, e) => {
            const selected_item = [];
            setKey(!key);
            if (isSelect) {
                rows.forEach((r, index) => {
                    selected_item.push(index);
                });
            }
            setSelection(selected_item);
        },
        selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
            <label className="k-checkbox k-checkbox--brand">
                <Input type="checkbox" {...rest} readOnly />
                <span />
            </label>
        ),
        selectionRenderer: ({ mode, rowIndex, ...rest }) => (
            <label className="k-checkbox k-checkbox--brand">
                <Input type={mode} {...rest} readOnly />
                <span />
            </label>
        ),
    };

    const isLoading = loading || updating || getOrderLoading;

    return (
        <>
            {!showEditForm && (
                <Table
                    columns={columns}
                    data={lineItemsWithGuid}
                    keyField="guid"
                    loading={isLoading}
                />
            )}
            {showEditForm && (
                <Table
                    columns={columns}
                    data={lineItemsWithGuid}
                    keyField="guid"
                    loading={isLoading}
                    selectRow={selectRow}
                />
            )}
        </>
    );
};

export default OrdersItemTable;
