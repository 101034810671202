import { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import useConfirm from "../components/useConfirm";
import useNotification from "../components/useNotification";
import _ from "lodash";
import gql from "graphql-tag";

const useRowActions = (refetchQueries, removeGql, route, resource) => {
    const history = useHistory();

    const notify = useNotification();

    const [removeOptions, setRemoveOptions] = useState(null);

    const PLACEHOLDER_MUTATION = gql`
        mutation {
            null
        }
    `;

    const [removeMutation] = useMutation(
        removeGql ? removeGql : PLACEHOLDER_MUTATION,
        {
            refetchQueries,
            onCompleted: () => {
                notify(`${resource} Was Removed Successfully."`, "success");
            },
            onError: () => {
                notify(
                    `Error Removing The ${resource}, If The Error Persist Please Contact Support.`,
                    "error",
                );
            },
        },
    );

    const [confirm, launch] = useConfirm(() => {
        removeMutation(removeOptions);
    }, `Are You Sure You Want To Remove The ${resource} ?`);

    const remove = options => {
        setRemoveOptions(options);
        launch();
    };

    const update = id => {
        let url = _.replace(route, ":id", id);

        history.push(url);
    };

    return { confirm, remove, update };
};

export default useRowActions;
