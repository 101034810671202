export default {
    position: "center",
    options: {
        customConfig: "",
        language: "en",
        startupFocus: true,
        extraAllowedContent: "*(*);*{*}", // Allows any class and any inline style
        allowedContent: true, // Disable auto-formatting, class removing, etc.
        extraPlugins: "colorbutton,font",
        toolbarGroups: [
            {
                name: "clipboard",
                groups: ["undo", "clipboard"],
            },
            {
                name: "editing",
                groups: ["find", "selection", "spellchecker", "editing"],
            },
            { name: "links", groups: ["links"] },
            { name: "insert", groups: ["insert"] },
            { name: "forms", groups: ["forms"] },
            { name: "tools", groups: ["tools"] },
            {
                name: "document",
                groups: ["mode", "document", "doctools"],
            },
            { name: "others", groups: ["others"] },
            "/",
            {
                name: "basicstyles",
                groups: ["basicstyles", "cleanup"],
            },
            {
                name: "colors",
                groups: ["TextColor", "BGColor"],
            },
            {
                name: "paragraph",
                groups: [
                    "list",
                    "indent",
                    "blocks",
                    "align",
                    "bidi",
                    "paragraph",
                ],
            },
            "/",
            { name: "styles", groups: ["Font", "FontSize"] },
            { name: "about", groups: ["about"] },
        ],
        removeButtons:
            "Underline,Subscript,Superscript,PasteText,PasteFromWord,Scayt,Anchor,Image,Maximize,Blockquote,About",
    },
};
