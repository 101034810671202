import React from "react";
import { Button } from "reactstrap";
import Icon from "base/components/Icon";

const FiltersToggle = ({ setShowFilters }) => {
    return (
        <Button
            color="metal"
            onClick={() => {
                setShowFilters(s => !s);
            }}
        >
            <span className="mr-2">
                <Icon icon="filters" />
            </span>
            Filters
        </Button>
    );
};

export default FiltersToggle;
