import React, { useState } from "react";
import { Row, Col, FormGroup, Label, FormFeedback } from "reactstrap";
import { useQuery } from "@apollo/react-hooks";
import Content from "../../base/layout/content/Content";
import { Portlet, Section, SectionTitle } from "../../base/components/portlet";
import Form from "../../base/components/form/Form";
import Input from "../../base/components/form/input/Input";
import MediaInput from "../../base/components/Medias";
import Spinner from "../../base/components/spinner/Spinner";
import useMeta from "../../base/hooks/useMeta";
import useForm from "../../base/hooks/form/useForm";
import useValidator from "../../base/hooks/form/useValidator";
import useFormActions from "../../base/hooks/form/useFormActions";
import {
    MUTATION_CREATE_PAGE,
    MUTATION_UPDATE_PAGE,
    QUERY_GET_PAGE,
    QUERY_PAGE_LIST,
} from "../../apollo/module-operations/page";
import routes from "../../config/routes/routes";
import DateRangePicker from "react-bootstrap-daterangepicker";
import InputGroupText from "../../base/components/form/input/InputGroupText";
import RichEditor from "../../base/components/form/rich-editor/RichEditor";
import {
    PAGE_VARIABLE_START,
    PAGE_VARIABLE_END,
    PAGE_VARIABLES_REGEXP,
    PageVariables,
    templateOptions,
} from "../../config/constant";
import Select from "../../base/components/form/select/Select";
import moment from "moment";
import { isEmpty } from "lodash";
import isJSON from "validator/lib/isJSON";
import Switch from "../../base/components/form/input/Switch";
// import PageVariablesModal from "./PageVariables";

const PageForm = () => {
    const { resourceId, isCreate, user } = useMeta();
    const [loading, setLoading] = useState(!isCreate);
    const [isVariablesOpen, setIsVariablesOpen] = useState(false);

    const {
        inputs,
        setInputs,
        inputChange,
        datepickerChange,
        genericChange,
        selectValue,
        selectChange,
    } = useForm({
        template: "TEXT",
        publish_at: new Date().toISOString(),
    });

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "slug",
                method: "isEmpty",
                validWhen: false,
                message: "slug is required.",
            },
            {
                field: "template",
                method: "isEmpty",
                validWhen: false,
                message: "Template is required.",
            },
            {
                field: "publish_at",
                method: "isEmpty",
                validWhen: false,
                message: "This date is required.",
            },
            {
                field: "url",
                method: "isEmpty",
                validWhen: false,
                message: "This url is required.",
            },
            {
                field: "title",
                method: "isEmpty",
                validWhen: false,
                message: "This title is required.",
            },
            {
                field: "meta_description",
                method: "isEmpty",
                validWhen: false,
                message: "This description is required.",
            },
            {
                field: "metadata",
                method: state => (isEmpty(state) ? true : isJSON(state)),
                validWhen: true,
                message: "Incorrect JSON format.",
            },
        ],
        {
            ...inputs,
            ...inputs.seo,
        },
    );

    const { data } = useQuery(QUERY_GET_PAGE, {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            page_id: resourceId,
        },
        skip: isCreate || user.loading,
        onCompleted: () => {
            setInputs(data.getPage);

            setLoading(false);
        },
    });

    const options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: inputs,
        },
    };

    const { createOrUpdateButton } = useFormActions(
        "id",
        "Page",
        MUTATION_CREATE_PAGE,
        MUTATION_UPDATE_PAGE,
        [
            {
                query: QUERY_PAGE_LIST,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        routes.PAGE_DETAIL.route,
        isValid,
        isSubmited,
    );

    return (
        <>
            <Content>
                <Portlet
                    header={{
                        title: "Form",
                        subtitle: "Provide all required information.",
                    }}
                    sticky
                    toolbar={
                        <>
                            {/* <Button
                                color="metal"
                                onClick={() => setIsVariablesOpen(true)}
                                className="ml-3"
                            >
                                <i className="fas fa-code" />
                                Variables
                            </Button> */}
                            {createOrUpdateButton}
                        </>
                    }
                >
                    {!loading ? (
                        <Form centered>
                            <Section>
                                <Row>
                                    <Label lg={4} xl={3} />
                                    <Col>
                                        <SectionTitle md>
                                            Information
                                        </SectionTitle>
                                    </Col>
                                </Row>
                                <FormGroup row>
                                    <Label lg={4} xl={3}>
                                        Active
                                    </Label>
                                    <Col>
                                        <span className="k-switch k-switch--md k-switch--icon">
                                            <label>
                                                <Input
                                                    checked={
                                                        inputs.status
                                                            ? "checked"
                                                            : ""
                                                    }
                                                    name="status"
                                                    onChange={inputChange}
                                                    type="checkbox"
                                                />
                                                <span />
                                            </label>
                                        </span>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label lg={4} xl={3}>
                                        Slug
                                    </Label>
                                    <Col>
                                        <Input
                                            invalid={validator.slug.isInvalid}
                                            name="slug"
                                            onChange={inputChange}
                                            value={inputs.slug ?? ""}
                                        />
                                        <FormFeedback>
                                            {validator.slug.message}
                                        </FormFeedback>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label lg={4} xl={3}>
                                        Template
                                    </Label>
                                    <Col style={{ zIndex: 4 }}>
                                        <Select
                                            controlName="template"
                                            invalid={
                                                validator.template.isInvalid
                                            }
                                            onChange={selectChange}
                                            options={templateOptions}
                                            value={selectValue(
                                                inputs.template,
                                                templateOptions,
                                            )}
                                        />
                                        <FormFeedback>
                                            {validator.template.message}
                                        </FormFeedback>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label lg={4} xl={3}>
                                        Variables
                                    </Label>
                                    <p className="col mb-0">
                                        {!PageVariables[inputs.template] && (
                                            <span className="d-block font-italic col-form-label">
                                                No variables are available for
                                                this template.
                                            </span>
                                        )}
                                        {Object.keys(
                                            PageVariables[inputs.template] ??
                                                {},
                                        ).map((key, i) => (
                                            <>
                                                <code
                                                    className="mb-1 mr-2 d-inline-block"
                                                    onClick={e =>
                                                        navigator.clipboard.writeText(
                                                            `${PAGE_VARIABLE_START}${key}${PAGE_VARIABLE_END}`,
                                                        )
                                                    }
                                                    role="button"
                                                    style={{
                                                        cursor: "pointer",
                                                        lineHeight: 1.2,
                                                    }}
                                                >
                                                    {key}
                                                </code>
                                            </>
                                        ))}
                                    </p>
                                </FormGroup>
                                <FormGroup
                                    className={`${
                                        validator.publish_at.isInvalid
                                            ? "validate"
                                            : ""
                                    }`}
                                    row
                                >
                                    <Label lg={4} xl={3}>
                                        Publish At
                                    </Label>
                                    <Col>
                                        <DateRangePicker
                                            autoApply
                                            onApply={(e, picker) => {
                                                datepickerChange(
                                                    picker,
                                                    "publish_at",
                                                );
                                            }}
                                            singleDatePicker
                                        >
                                            <Input
                                                append={
                                                    <InputGroupText>
                                                        <i className="fas fa-calendar-alt" />
                                                    </InputGroupText>
                                                }
                                                invalid={
                                                    validator.publish_at
                                                        .isInvalid
                                                }
                                                readOnly
                                                value={
                                                    inputs.publish_at
                                                        ? moment(
                                                              inputs.publish_at,
                                                          ).format("MM/DD/YYYY")
                                                        : moment().format(
                                                              "MM/DD/YYYY",
                                                          )
                                                }
                                            />
                                        </DateRangePicker>
                                        <FormFeedback>
                                            {validator.publish_at.message}
                                        </FormFeedback>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label lg={4} xl={3}>
                                        Image
                                    </Label>
                                    <Col>
                                        <MediaInput
                                            highlightWords={
                                                PAGE_VARIABLES_REGEXP
                                            }
                                            name="image"
                                            onChange={inputChange}
                                            typeInput="highlight"
                                            value={inputs.image ?? ""}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label lg={4} xl={3}>
                                        Content
                                    </Label>
                                    <Col>
                                        <RichEditor
                                            name="html_body"
                                            onChange={genericChange}
                                            placeholder="Write the page content here..."
                                            value={inputs.html_body}
                                        />
                                    </Col>
                                </FormGroup>
                                <Row>
                                    <Label lg={4} xl={3} />
                                    <Col>
                                        <SectionTitle md>SEO</SectionTitle>
                                    </Col>
                                </Row>
                                <FormGroup row>
                                    <Label lg={4} xl={3}>
                                        Index
                                    </Label>
                                    <Switch
                                        checked={!!inputs.seo?.meta_robots}
                                        className="col-6"
                                        name="seo.meta_robots"
                                        onChange={inputChange}
                                    />
                                </FormGroup>
                                <FormGroup row>
                                    <Label lg={4} xl={3}>
                                        Business schema
                                    </Label>
                                    <Switch
                                        checked={
                                            !!inputs.seo?.include_local_schema
                                        }
                                        className="col-6"
                                        name="seo.include_local_schema"
                                        onChange={inputChange}
                                    />
                                </FormGroup>
                                <FormGroup row>
                                    <Label lg={4} xl={3}>
                                        Url
                                    </Label>
                                    <Col>
                                        <Input
                                            highlightWords={
                                                PAGE_VARIABLES_REGEXP
                                            }
                                            invalid={validator.url.isInvalid}
                                            name="seo.url"
                                            onChange={inputChange}
                                            value={inputs.seo?.url ?? ""}
                                        />
                                        <FormFeedback>
                                            {validator.url.message}
                                        </FormFeedback>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label lg={4} xl={3}>
                                        Title
                                    </Label>
                                    <Col>
                                        <Input
                                            highlightWords={
                                                PAGE_VARIABLES_REGEXP
                                            }
                                            invalid={validator.title.isInvalid}
                                            name="seo.title"
                                            onChange={inputChange}
                                            value={inputs.seo?.title ?? ""}
                                        />
                                        <FormFeedback>
                                            {validator.title.message}
                                        </FormFeedback>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label lg={4} xl={3}>
                                        Description
                                    </Label>
                                    <Col>
                                        <Input
                                            highlightWords={
                                                PAGE_VARIABLES_REGEXP
                                            }
                                            invalid={
                                                validator.meta_description
                                                    .isInvalid
                                            }
                                            name="seo.meta_description"
                                            onChange={inputChange}
                                            value={
                                                inputs.seo?.meta_description ??
                                                ""
                                            }
                                        />
                                        <FormFeedback>
                                            {validator.meta_description.message}
                                        </FormFeedback>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label lg={4} xl={3}>
                                        Social image
                                    </Label>
                                    <Col>
                                        <MediaInput
                                            highlightWords={
                                                PAGE_VARIABLES_REGEXP
                                            }
                                            name="seo.meta_image"
                                            onChange={inputChange}
                                            value={inputs.seo?.meta_image ?? ""}
                                        />
                                    </Col>
                                </FormGroup>
                                <Row>
                                    <Label lg={4} xl={3} />
                                    <Col>
                                        <SectionTitle md>
                                            Meta information
                                        </SectionTitle>
                                    </Col>
                                </Row>
                                <FormGroup row>
                                    <Label lg={4} xl={3}>
                                        Metadata
                                    </Label>
                                    <Col>
                                        <Input
                                            invalid={
                                                validator.metadata.isInvalid
                                            }
                                            name="metadata"
                                            onChange={inputChange}
                                            rows={10}
                                            type="textarea"
                                            value={inputs.metadata ?? ""}
                                        />
                                        <FormFeedback>
                                            {validator.metadata.message}
                                        </FormFeedback>
                                    </Col>
                                </FormGroup>
                            </Section>
                        </Form>
                    ) : (
                        <>
                            <Spinner color="metal" show sm />
                            &nbsp;&nbsp;Loading
                        </>
                    )}
                </Portlet>
            </Content>
            {/* <PageVariablesModal
                isOpen={isVariablesOpen}
                toggle={() => setIsVariablesOpen(false)}
                variables={PageVariables[inputs.template]}
            /> */}
        </>
    );
};

export default PageForm;
