import React, { useState } from "react";
import { Row, Col, FormGroup, Label, FormFeedback, FormText } from "reactstrap";
import { useQuery } from "@apollo/react-hooks";
import Content from "../../base/layout/content/Content";
import { Portlet, Section, SectionTitle } from "../../base/components/portlet";
import Form from "../../base/components/form/Form";
import Input from "../../base/components/form/input/Input";
import Spinner from "../../base/components/spinner/Spinner";
import useMeta from "../../base/hooks/useMeta";
import useForm from "../../base/hooks/form/useForm";
import useValidator from "../../base/hooks/form/useValidator";
import useFormActions from "../../base/hooks/form/useFormActions";
import {
    MUTATION_CREATE_PROMOTION,
    MUTATION_UPDATE_PROMOTION,
    QUERY_PROMOTION_LIST,
    QUERY_GET_PROMOTION,
} from "../../apollo/module-operations/promotion";
import { QUERY_LIST_STORE_APPS } from "@/data";
import { QUERY_PRODUCT_LIST } from "../../apollo/module-operations/product";
import {
    broadcasttype,
    discountType,
    discountRateType,
    discountApplyType,
} from "../../config/constant";
import routes from "../../config/routes/routes";
import Select from "../../base/components/form/select/Select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import InputGroupText from "../../base/components/form/input/InputGroupText";
import { toCurrency } from "../../utils/utils";
import moment from "moment";
import { currencyMask } from "../../utils/maskInput";
import _ from "lodash";
import { SketchPicker } from "react-color";
import classNames from "classnames";

const PromotionForm = () => {
    const { resourceId, isCreate, user } = useMeta();
    const [sketchPickerColor, setSketchPickerColor] = useState("#37d67a");
    const [showPicker, setShowPicker] = useState(false);
    const [blazeAppActive, setBlazeAppActive] = useState(false);

    const [loading, setLoading] = useState(!isCreate);

    const {
        inputs,
        setInputs,
        inputChange,
        datepickerChange,
        selectValue,
        selectChange,
        genericChange,
    } = useForm();

    const [productsOptions, setAllProducts] = useState([]);
    const [productsSelect, setProductsSelect] = useState([]);

    const { loading: loadingProductsList } = useQuery(QUERY_PRODUCT_LIST, {
        skip: user.loading,
        notifyOnNetworkStatusChange: true,
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
        },
        onCompleted: resp => {
            let prodlist = [];
            let list_arr = [...(inputs.products_id || [])];
            let selected = [];

            _.forEach(resp.listProducts, prod => {
                if (list_arr.includes(prod.id)) {
                    selected.push({
                        value: prod.id,
                        label: prod.name,
                    });
                }
                prodlist.push({
                    value: prod.id,
                    label: prod.name,
                });
            });

            setProductsSelect(selected);
            setAllProducts(prodlist);
        },
    });

    const { loading: loadingApps } = useQuery(QUERY_LIST_STORE_APPS, {
        skip: user.loading,
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            category: "ERP",
        },
        onCompleted: res => {
            const blazeApp = res?.listStoreApps?.find(
                app => app.handler === "blaze",
            );

            setBlazeAppActive(blazeApp?.id ? true : false);
        },
    });

    const checkNeverExpire = () => {
        return !inputs.never_expire
            ? _.isEmpty(inputs.expiration_date) || _.isEmpty(inputs.start_date)
            : false;
    };

    const discountTypes = inputs.discountType || "";
    const discountRateTypes = inputs.discount_rate_type || "";

    const isAmount = input => {
        return (
            input.discount_rate_type &&
            discountRateTypes === "AMOUNT" &&
            input.rate
        );
    };

    const isPromoCode = () => {
        let discountApplyTypes = inputs.discount_apply_type || "";
        // Check if the promotion is promo code
        if (
            discountTypes === "AMOUNT_OFF_ORDER" &&
            discountApplyTypes === "PROMOTION_CODE"
        ) {
            return true;
        }
    };

    const checkPromoCode = () => {
        let promoCode = _.isEmpty(inputs.promo_code);
        if (isPromoCode()) {
            return promoCode;
        }
        return false;
    };

    const checkMinimumOrderAmount = () => {
        return inputs.minimum_order_required
            ? _.isEmpty(_.toString(inputs.minimum_order_amount))
            : false;
    };

    const checkMaximumOrderAmount = () => {
        return inputs.maximum_order_required
            ? _.isEmpty(_.toString(inputs.maximum_order_amount))
            : false;
    };

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "name",
                method: "isEmpty",
                validWhen: false,
                message: "This name is required.",
            },
            {
                field: "label",
                method: "isEmpty",
                validWhen: false,
                message: "This label is required.",
            },
            {
                field: "blaze_id",
                method: "isEmpty",
                validWhen: false,
                message: "This blaze id is required.",
            },
            {
                field: "priority",
                method: "isEmpty",
                validWhen: false,
                message: "This priority is required.",
            },
            {
                field: "expiration_date",
                method: checkNeverExpire,
                validWhen: false,
                message: "This expiration date is required.",
            },
            {
                field: "start_date",
                method: checkNeverExpire,
                validWhen: false,
                message: "This start expiration date is required.",
            },
            {
                field: "promo_code",
                method: checkPromoCode,
                validWhen: false,
                message: "This promo code is required.",
            },
            {
                field: "minimum_order_amount",
                method: checkMinimumOrderAmount,
                validWhen: false,
                message: "This minimum order amount quantity is required.",
            },
            {
                field: "maximum_order_amount",
                method: checkMaximumOrderAmount,
                validWhen: false,
                message: "This maximun order amount quantity is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    useQuery(QUERY_GET_PROMOTION, {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            promotion_id: resourceId,
        },
        skip: user.loading || !loading,
        onCompleted: resp => {
            let input = _.cloneDeep(resp.getPromotion);
            let selected = [];

            _.forEach(resp.getPromotion.products_id, prod => {
                if (productsOptions.includes(prod.id)) {
                    selected.push({
                        value: prod.id,
                        label: prod.name,
                    });
                }
            });

            setProductsSelect(selected);
            setInputs(input);
            setLoading(false);
        },
    });

    const options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: {
                ...inputs,
                priority: parseInt(inputs.priority, 10),
                rate: isAmount(inputs)
                    ? toCurrency(inputs.rate)
                    : parseInt(inputs.rate, 10),
                /*  limit_quantity: inputs.limit_quantity
                    ? parseInt(inputs.limit_quantity, 10)
                    : 0,
 */ minimum_order_amount: inputs.minimum_order_required
                    ? toCurrency(inputs.minimum_order_amount)
                    : 0,
                maximum_order_amount: inputs.maximum_order_required
                    ? toCurrency(inputs.maximum_order_amount)
                    : 0,
            },
        },
    };

    const { createOrUpdateButton } = useFormActions(
        "id",
        "Promotion",
        MUTATION_CREATE_PROMOTION,
        MUTATION_UPDATE_PROMOTION,
        [
            {
                query: QUERY_PROMOTION_LIST,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        routes.PROMOTION_DETAIL.route,
        isValid,
        isSubmited,
    );

    const onChangeProducts = options => {
        let all = [];
        setProductsSelect(options);

        _.forEach(options, v => {
            all.push(v.value);
        });

        genericChange(all, "products_id");
    };

    const onColorChange = updatedColor => {
        setSketchPickerColor(updatedColor.hex);
        genericChange(updatedColor.hex, "broadcast_color");
    };

    return (
        <>
            <Content className="promotion__form">
                <Portlet
                    header={{
                        title: "Form",
                        subtitle: "Provide all required information.",
                    }}
                    sticky
                    toolbar={[createOrUpdateButton]}
                >
                    {!loading && !loadingProductsList && !loadingApps ? (
                        <Form>
                            <Section>
                                <Row>
                                    <SectionTitle md>Information</SectionTitle>
                                </Row>
                                <FormGroup row>
                                    <Label
                                        className="row-label text-left"
                                        lg={1}
                                    >
                                        Active
                                    </Label>
                                    <Col lg={4}>
                                        <span className="k-switch k-switch--md k-switch--icon">
                                            <label>
                                                <Input
                                                    checked={
                                                        inputs.status
                                                            ? "checked"
                                                            : ""
                                                    }
                                                    name="status"
                                                    onChange={inputChange}
                                                    type="checkbox"
                                                />
                                                <span />
                                            </label>
                                        </span>
                                    </Col>
                                </FormGroup>
                                <Row>
                                    <Col lg={4}>
                                        <FormGroup>
                                            <Label className="row-label">
                                                Name *
                                            </Label>

                                            <Input
                                                defaultValue={
                                                    inputs.name
                                                        ? inputs.name
                                                        : ""
                                                }
                                                invalid={
                                                    validator.name.isInvalid
                                                }
                                                name="name"
                                                onChange={inputChange}
                                                placeholder="Name"
                                            />
                                            <FormFeedback>
                                                {validator.name.message}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    {blazeAppActive && (
                                        <Col lg={4}>
                                            <FormGroup>
                                                <Label>Blaze Id *</Label>

                                                <Input
                                                    defaultValue={
                                                        inputs.blaze_id
                                                            ? inputs.blaze_id
                                                            : ""
                                                    }
                                                    invalid={
                                                        validator.blaze_id
                                                            .isInvalid
                                                    }
                                                    name="blaze_id"
                                                    onChange={inputChange}
                                                    placeholder="Blaze id"
                                                />
                                                <FormFeedback>
                                                    {validator.blaze_id.message}
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    )}
                                </Row>
                            </Section>
                            <Section>
                                <Row>
                                    <SectionTitle md>Date</SectionTitle>
                                </Row>
                                <FormGroup row>
                                    <Label
                                        className="row-label text-left"
                                        lg={1}
                                    >
                                        Never Expire
                                    </Label>
                                    <Col lg={4}>
                                        <span className="k-switch k-switch--md k-switch--icon">
                                            <label>
                                                <Input
                                                    checked={
                                                        inputs.never_expire
                                                            ? "checked"
                                                            : ""
                                                    }
                                                    name="never_expire"
                                                    onChange={inputChange}
                                                    placeholder="Never expire"
                                                    type="checkbox"
                                                />
                                                <span />
                                            </label>
                                        </span>
                                    </Col>
                                </FormGroup>
                                <Row>
                                    {!inputs.never_expire && (
                                        <Col lg={4}>
                                            <FormGroup
                                                className={`${
                                                    validator.start_date
                                                        .isInvalid
                                                        ? "validate"
                                                        : ""
                                                }`}
                                            >
                                                <Label>Start Date *</Label>
                                                <DateRangePicker
                                                    autoApply
                                                    onApply={(e, picker) => {
                                                        datepickerChange(
                                                            picker,
                                                            "start_date",
                                                        );
                                                    }}
                                                    singleDatePicker
                                                >
                                                    <Input
                                                        append={
                                                            <InputGroupText>
                                                                <i className="fas fa-calendar-alt" />
                                                            </InputGroupText>
                                                        }
                                                        defaultValue={
                                                            inputs.start_date
                                                                ? moment(
                                                                      inputs.start_date,
                                                                  ).format(
                                                                      "MM/DD/YYYY",
                                                                  )
                                                                : ""
                                                        }
                                                        invalid={
                                                            validator.start_date
                                                                .isInvalid
                                                        }
                                                        placeholder="Start date"
                                                        readOnly
                                                    />
                                                </DateRangePicker>
                                                <FormFeedback>
                                                    {
                                                        validator.start_date
                                                            .message
                                                    }
                                                </FormFeedback>
                                                <FormText>
                                                    You must select a date with
                                                    this control.
                                                </FormText>
                                            </FormGroup>
                                        </Col>
                                    )}
                                    {!inputs.never_expire && (
                                        <Col lg={4}>
                                            <FormGroup
                                                className={`${
                                                    validator.expiration_date
                                                        .isInvalid
                                                        ? "validate"
                                                        : ""
                                                }`}
                                            >
                                                <Label>Expiration Date *</Label>

                                                <DateRangePicker
                                                    autoApply
                                                    onApply={(e, picker) => {
                                                        datepickerChange(
                                                            picker,
                                                            "expiration_date",
                                                        );
                                                    }}
                                                    singleDatePicker
                                                >
                                                    <Input
                                                        append={
                                                            <InputGroupText>
                                                                <i className="fas fa-calendar-alt" />
                                                            </InputGroupText>
                                                        }
                                                        defaultValue={
                                                            inputs.expiration_date
                                                                ? moment(
                                                                      inputs.expiration_date,
                                                                  ).format(
                                                                      "MM/DD/YYYY",
                                                                  )
                                                                : ""
                                                        }
                                                        invalid={
                                                            validator
                                                                .expiration_date
                                                                .isInvalid
                                                        }
                                                        placeholder="Expiration date"
                                                        readOnly
                                                    />
                                                </DateRangePicker>
                                                <FormFeedback>
                                                    {
                                                        validator
                                                            .expiration_date
                                                            .message
                                                    }
                                                </FormFeedback>
                                                <FormText>
                                                    You must select a date with
                                                    this control.
                                                </FormText>
                                            </FormGroup>
                                        </Col>
                                    )}
                                </Row>
                            </Section>
                            <Section>
                                <Row>
                                    <SectionTitle md>Condition</SectionTitle>
                                </Row>
                                <Row>
                                    <Col lg={4}>
                                        <FormGroup>
                                            <Label className="row-label">
                                                Priority
                                            </Label>

                                            <Input
                                                defaultValue={
                                                    inputs.priority
                                                        ? inputs.priority
                                                        : ""
                                                }
                                                invalid={
                                                    validator.priority.isInvalid
                                                }
                                                name="priority"
                                                onChange={inputChange}
                                                placeholder="Priority"
                                                type="number"
                                            />
                                            <FormFeedback>
                                                {validator.priority.message}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4}>
                                        <FormGroup>
                                            <Label>Apply to products</Label>
                                            <Select
                                                closeMenuOnSelect={false}
                                                controlName="products_id"
                                                isClearable={false}
                                                isMulti
                                                isSearchable={true}
                                                onChange={onChangeProducts}
                                                options={productsOptions}
                                                value={productsSelect}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col
                                        lg={4}
                                        style={{ justifyContent: "center" }}
                                    >
                                        <FormGroup className={"d-flex"}>
                                            <Label
                                                className="row-label text-left"
                                                lg={5}
                                                xl={3}
                                            >
                                                Minimum Order Required
                                            </Label>
                                            <span className="k-switch k-switch--md k-switch--icon">
                                                <label>
                                                    <Input
                                                        checked={
                                                            inputs.minimum_order_required
                                                                ? "checked"
                                                                : ""
                                                        }
                                                        name="minimum_order_required"
                                                        onChange={inputChange}
                                                        type="checkbox"
                                                    />
                                                    <span />
                                                </label>
                                            </span>
                                        </FormGroup>
                                        {inputs.minimum_order_required && (
                                            <Col lg={12}>
                                                <FormGroup
                                                    className={`${
                                                        validator
                                                            .minimum_order_amount
                                                            .isInvalid
                                                            ? "validate"
                                                            : ""
                                                    }`}
                                                >
                                                    <Label>
                                                        Minimum Order Amount
                                                    </Label>

                                                    <Input
                                                        className="form-control"
                                                        defaultValue={
                                                            inputs.minimum_order_amount
                                                        }
                                                        invalid={
                                                            validator
                                                                .minimum_order_amount
                                                                .isInvalid
                                                        }
                                                        mask={currencyMask(
                                                            inputs.minimum_order_amount,
                                                        )}
                                                        name="minimum_order_amount"
                                                        onChange={inputChange}
                                                        placeholder="Minimum Order Amount"
                                                        prepend={
                                                            <InputGroupText>
                                                                $
                                                            </InputGroupText>
                                                        }
                                                        typeInput={"masked"}
                                                    />
                                                    <FormFeedback>
                                                        {
                                                            validator
                                                                .minimum_order_amount
                                                                .message
                                                        }
                                                    </FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        )}
                                    </Col>
                                    <Col
                                        lg={4}
                                        style={{ justifyContent: "center" }}
                                    >
                                        <FormGroup className={"d-flex"}>
                                            <Label
                                                className="row-label text-left"
                                                lg={5}
                                                xl={3}
                                            >
                                                Maximum Order Required
                                            </Label>

                                            <span className="k-switch k-switch--md k-switch--icon">
                                                <label>
                                                    <Input
                                                        checked={
                                                            inputs.maximum_order_required
                                                                ? "checked"
                                                                : ""
                                                        }
                                                        name="maximum_order_required"
                                                        onChange={inputChange}
                                                        type="checkbox"
                                                    />
                                                    <span />
                                                </label>
                                            </span>
                                        </FormGroup>
                                        {inputs.maximum_order_required && (
                                            <Col lg={12}>
                                                <FormGroup
                                                    className={`${
                                                        validator
                                                            .maximum_order_amount
                                                            .isInvalid
                                                            ? "validate"
                                                            : ""
                                                    }`}
                                                >
                                                    <Label>
                                                        Maximum Order Required
                                                    </Label>

                                                    <Input
                                                        className="form-control"
                                                        defaultValue={
                                                            inputs.maximum_order_amount
                                                        }
                                                        invalid={
                                                            validator
                                                                .maximum_order_amount
                                                                .isInvalid
                                                        }
                                                        mask={currencyMask(
                                                            inputs.maximum_order_amount,
                                                        )}
                                                        name="maximum_order_amount"
                                                        onChange={inputChange}
                                                        placeholder="Maximum Order Required"
                                                        prepend={
                                                            <InputGroupText>
                                                                $
                                                            </InputGroupText>
                                                        }
                                                        typeInput={"masked"}
                                                    />
                                                    <FormFeedback>
                                                        {
                                                            validator
                                                                .maximum_order_amount
                                                                .message
                                                        }
                                                    </FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        )}
                                    </Col>
                                    <Col lg={4}>
                                        <FormGroup className={"d-flex"}>
                                            <Label
                                                className="row-label text-left"
                                                lg={4}
                                                xl={3}
                                            >
                                                First Time Patient
                                            </Label>
                                            <span className="k-switch k-switch--md k-switch--icon">
                                                <label>
                                                    <Input
                                                        checked={
                                                            inputs.first_time_patient
                                                                ? "checked"
                                                                : ""
                                                        }
                                                        name="first_time_patient"
                                                        onChange={inputChange}
                                                        type="checkbox"
                                                    />
                                                    <span />
                                                </label>
                                            </span>{" "}
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Section>
                            <Section>
                                <Row>
                                    <SectionTitle md>
                                        Discount Type
                                    </SectionTitle>
                                </Row>
                                <Row>
                                    <Col lg={4}>
                                        <FormGroup>
                                            <Label>Discount Type</Label>
                                            <Select
                                                controlName="discountType"
                                                defaultValue={selectValue(
                                                    inputs.discountType,
                                                    discountType,
                                                )}
                                                onChange={selectChange}
                                                options={discountType}
                                            />
                                        </FormGroup>
                                    </Col>

                                    {discountTypes === "AMOUNT_OFF_ORDER" && (
                                        <Col lg={4}>
                                            <FormGroup>
                                                <Label>
                                                    Discount Rate Type
                                                </Label>

                                                <Select
                                                    controlName="discount_rate_type"
                                                    defaultValue={selectValue(
                                                        inputs.discount_rate_type,
                                                        discountRateType,
                                                    )}
                                                    onChange={selectChange}
                                                    options={discountRateType}
                                                />
                                            </FormGroup>
                                        </Col>
                                    )}

                                    {discountTypes === "AMOUNT_OFF_ORDER" &&
                                        discountRateTypes && (
                                            <Col lg={4}>
                                                <FormGroup>
                                                    <Label>Rate</Label>

                                                    {discountRateTypes ===
                                                        "PERCENTAGE" && (
                                                        <Input
                                                            defaultValue={
                                                                inputs.rate
                                                                    ? inputs.rate
                                                                    : ""
                                                            }
                                                            name="rate"
                                                            onChange={
                                                                inputChange
                                                            }
                                                            placeholder="Rate"
                                                            prepend={
                                                                <InputGroupText>
                                                                    <span>
                                                                        %
                                                                    </span>
                                                                </InputGroupText>
                                                            }
                                                            type="number"
                                                        />
                                                    )}
                                                    {discountRateTypes ===
                                                        "AMOUNT" && (
                                                        <Input
                                                            className="form-control"
                                                            defaultValue={
                                                                inputs.rate
                                                            }
                                                            mask={currencyMask(
                                                                inputs.rate,
                                                            )}
                                                            name="rate"
                                                            onChange={
                                                                inputChange
                                                            }
                                                            placeholder="Rate"
                                                            prepend={
                                                                <InputGroupText>
                                                                    $
                                                                </InputGroupText>
                                                            }
                                                            typeInput={"masked"}
                                                        />
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        )}

                                    {discountTypes === "AMOUNT_OFF_ORDER" && (
                                        <Col lg={4}>
                                            <FormGroup>
                                                <Label>Discount Types</Label>

                                                <Select
                                                    controlName="discount_apply_type"
                                                    defaultValue={selectValue(
                                                        inputs.discount_apply_type,
                                                        discountApplyType,
                                                    )}
                                                    onChange={selectChange}
                                                    options={discountApplyType}
                                                />
                                            </FormGroup>
                                        </Col>
                                    )}

                                    {isPromoCode() && (
                                        <Col lg={4}>
                                            <FormGroup row>
                                                <Label>Promo Code</Label>

                                                <Input
                                                    defaultValue={
                                                        inputs.promo_code
                                                            ? inputs.promo_code
                                                            : ""
                                                    }
                                                    invalid={
                                                        validator.promo_code
                                                            .isInvalid
                                                    }
                                                    name="promo_code"
                                                    onChange={inputChange}
                                                    placeholder="Promo Code"
                                                />
                                                <FormFeedback>
                                                    {
                                                        validator.promo_code
                                                            .message
                                                    }
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    )}
                                </Row>
                            </Section>
                            <Section>
                                <Row>
                                    <SectionTitle md>Broadcast</SectionTitle>
                                </Row>
                                <FormGroup row>
                                    <Label
                                        className="row-label text-left"
                                        lg={1}
                                    >
                                        Broadcast
                                    </Label>
                                    <Col lg={4}>
                                        <span className="k-switch k-switch--md k-switch--icon">
                                            <label>
                                                <Input
                                                    checked={
                                                        inputs.broadcast
                                                            ? "checked"
                                                            : ""
                                                    }
                                                    name="broadcast"
                                                    onChange={inputChange}
                                                    type="checkbox"
                                                />
                                                <span />
                                            </label>
                                        </span>
                                    </Col>
                                </FormGroup>

                                {inputs.broadcast && (
                                    <Row>
                                        <Col lg={4}>
                                            <FormGroup>
                                                <Label className="row-label">
                                                    Label *
                                                </Label>

                                                <Input
                                                    defaultValue={
                                                        inputs.label
                                                            ? inputs.label
                                                            : ""
                                                    }
                                                    invalid={
                                                        validator.label
                                                            .isInvalid
                                                    }
                                                    name="label"
                                                    onChange={inputChange}
                                                    placeholder="Label"
                                                />
                                                <FormFeedback>
                                                    {validator.label.message}
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col lg={4}>
                                            <FormGroup>
                                                <Label>Broadcast Type</Label>

                                                <Select
                                                    controlName="broadcast_type"
                                                    defaultValue={selectValue(
                                                        inputs.broadcast_type,
                                                        broadcasttype,
                                                    )}
                                                    onChange={selectChange}
                                                    options={broadcasttype}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={4}>
                                            <FormGroup>
                                                <Label>Broadcast Color</Label>
                                                <div className="sketchpicker">
                                                    {/* Div to display the color  */}
                                                    <div
                                                        className="promotion__broadcast_color"
                                                        onClick={() => {
                                                            setShowPicker(
                                                                !showPicker,
                                                            );
                                                        }}
                                                        role="button"
                                                        style={{
                                                            // border: "2px solid white",
                                                            display: "block",
                                                            backgroundColor: `${sketchPickerColor}`,
                                                        }}
                                                    ></div>
                                                    {/* Sketch Picker from react-color and handling color on onChange event */}
                                                    <SketchPicker
                                                        className={classNames(
                                                            "promotion__picker_color",
                                                            {
                                                                ["promotion__picker_color_hidden"]:
                                                                    !showPicker,
                                                            },
                                                        )}
                                                        color={
                                                            sketchPickerColor
                                                        }
                                                        onChange={color => {
                                                            onColorChange(
                                                                color,
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}
                            </Section>
                        </Form>
                    ) : (
                        <>
                            <Spinner color="metal" show sm />
                            &nbsp;&nbsp;Loading
                        </>
                    )}
                </Portlet>
            </Content>
        </>
    );
};

export default PromotionForm;
