import gql from "graphql-tag";

export const QUERY_UI = gql`
    query {
        ui @client {
            asideHover
            asideMinimize
            asideToggle
            headerToggle
        }
    }
`;
