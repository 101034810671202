import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import Content from "../../base/layout/content/Content";
import { Portlet } from "../../base/components/portlet";
import classNames from "classnames";
import useMeta from "../../base/hooks/useMeta.js";
import useTableActions from "../../base/hooks/table/useTableActions";
import paginationFactory from "react-bootstrap-table2-paginator";
import { paginationOptions } from "../../config/components/table";
import { QUERY_PRODUCT_LIST } from "../../apollo/module-operations/product";

import _ from "lodash";

import FiltersToggle from "../../base/components/table/FiltersToggle";
import Filters from "../../base/components/table/Filters";
import Table from "../../base/components/table/Table";
import { FILTER_TYPES } from "react-bootstrap-table2-filter";
import useFilter from "../../base/hooks/table/useFilter";

const Products = () => {
    const { user } = useMeta();

    //---------------------------Defining filters-----------------------------
    const { TEXT } = FILTER_TYPES;

    const [showFilters, setShowFilters] = useState(false);

    const filterSku = useFilter("sku", TEXT, "Sku", "");

    const filterName = useFilter("name", TEXT, "Name", "");

    const filterCategory = useFilter("category", TEXT, "Name", "");

    const filters = [filterSku, filterName, filterCategory];
    // -----------------------------------------------------------------------

    const { loading, data, refetch, networkStatus } = useQuery(
        QUERY_PRODUCT_LIST,
        {
            skip: user.loading,
            notifyOnNetworkStatusChange: true,
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
            },
        },
    );

    const { refreshButton } = useTableActions(refetch);

    const columns = [
        {
            dataField: "id",
            text: "Id",
            hidden: true,
        },
        {
            dataField: "name",
            text: "Name",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "300px" };
            },
        },
        {
            dataField: "sku",
            text: "SKU",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "100px" };
            },
        },
        {
            dataField: "category",
            text: "Category",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "150px" };
            },
        },
        {
            dataField: "brand_name",
            text: "Brand",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "150px" };
            },
        },

        {
            dataField: "unit_price",
            text: "Unit Price",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "80px" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <span>${(cell / 100).toFixed(2)}</span>;
            },
        },

        {
            dataField: "in_stock",
            text: "In Stock",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "80px" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <span
                        className={classNames(
                            "badge badge-pill",
                            `badge-${cell ? "success" : "secondary"}`,
                        )}
                    >
                        {cell ? "Yes" : "No"}
                    </span>
                );
            },
        },
    ];

    const _loading = loading || networkStatus === 4;

    const _data = data && data.listProducts;

    return (
        <>
            <Content>
                <Portlet
                    header={{
                        title: "Products",
                        subtitle: "All products availables in the system",
                    }}
                    sticky
                    toolbar={
                        <>
                            <FiltersToggle setShowFilters={setShowFilters} />
                            {refreshButton}
                        </>
                    }
                >
                    <Filters filters={filters} />
                    <Table
                        columns={columns}
                        data={_data}
                        filters={filters}
                        keyField="id"
                        loading={_loading}
                        pagination={paginationFactory(paginationOptions)}
                        showFilters={showFilters}
                    />
                </Portlet>
            </Content>
        </>
    );
};

export default Products;
