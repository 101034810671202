import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    FormFeedback,
    FormGroup,
    Button,
} from "reactstrap";
import Input from "../../base/components/form/input/Input";
import Spinner from "../../base/components/spinner/Spinner";
import { omit, updateAccountAttribute } from "../../utils/utils";
import { useMutation } from "@apollo/react-hooks";
import useMeta from "../../base/hooks/useMeta";
import useForm from "../../base/hooks/form/useForm";
import useValidator from "../../base/hooks/form/useValidator";
import { useHistory } from "react-router-dom";
import useNotification from "../../base/hooks/components/useNotification";
import {
    MUTATION_CREATE_STORE_ASYNC,
    QUERY_STORES_FROM_ACCOUNT_LIST,
} from "../../apollo/module-operations/store";

const StoreFormModal = ({ isOpen, toggle }) => {
    const { resourceId, user } = useMeta();

    const { inputs, inputChange } = useForm();

    const history = useHistory();

    const notify = useNotification();

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "name",
                method: "isEmpty",
                validWhen: false,
                message: "This name is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    let options = {
        variables: {
            account_id: resourceId,
            input: inputs,
        },
    };

    const [mutateCreateStore, { loading: createStoreLoading }] = useMutation(
        MUTATION_CREATE_STORE_ASYNC,
        {
            refetchQueries: [
                {
                    query: QUERY_STORES_FROM_ACCOUNT_LIST,
                    variables: {
                        account_id: user.accountId,
                    },
                },
            ],
            onCompleted: resp => {
                toggle(false);

                /*  updateAccountAttribute(
                      resourceId,
                      resp.createStore.id,
                      history,
                   );
                */
                notify("Store Created Successfully", "success");
            },
            onError: () => {
                notify("Error Creating The Store", "error");
            },
        },
    );

    const createStore = async () => {
        if (isSubmited) {
            isSubmited(true);
        }

        if (isValid()) {
            options = omit(options);
            mutateCreateStore(options);
        }
    };

    const cancel = () => {
        toggle(false);
    };

    return (
        <Modal isOpen={isOpen} onClosed={() => {}} size="sm">
            <ModalHeader>Create Store</ModalHeader>
            <ModalBody>
                <FormGroup className="mb-0">
                    <Input
                        defaultValue={inputs.name}
                        invalid={validator.name.isInvalid}
                        name="name"
                        onChange={inputChange}
                        placeholder="Store Name"
                    />
                    <FormFeedback>{validator.name.message}</FormFeedback>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" disabled={false} onClick={createStore}>
                    <Spinner show={createStoreLoading} sm />
                    Create
                </Button>
                <Button
                    className="ml-3"
                    color="secondary"
                    disabled={createStoreLoading}
                    onClick={cancel}
                    outline
                >
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default StoreFormModal;
