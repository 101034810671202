import { FC } from "react";
import Select from "react-select";
import Creatable from "react-select/creatable";
import classNames from "classnames";

interface ISelectProps {
    invalid?: any;
    controlName?: any;
    onChange?: any;
    options?: any;
    value?: any;
    className?: any;
    creatable?: any;
    defaultValue?: any;
    placeholder?: string;
}

const _Select: FC<ISelectProps> = ({
    invalid,
    className,
    controlName,
    onChange,
    creatable,
    defaultValue,
    ...props
}) => {
    const element = creatable ? (
        <Creatable
            className={classNames(className, {
                "form-control is-invalid": invalid,
            })}
            onChange={o => {
                onChange(o, controlName);
            }}
            {...props}
        />
    ) : (
        <Select
            className={classNames(className, {
                "form-control is-invalid": invalid,
            })}
            classNamePrefix="react-select"
            defaultValue={defaultValue}
            onChange={o => {
                onChange(o, controlName);
            }}
            {...props}
        />
    );
    return element;
};

export default _Select;
