import routes from "../routes/routes";

const { SETTINGS, BUSINESS_ACCOUNTS, APP_LIST, NOTIFICATIONS } = routes;

export const navigation = [
    /*  {
        label: "Medias",
        slug: "medias",
        route: null,
        icon: "fas fa-image",
    }, */
    {
        label: NOTIFICATIONS.label,
        route: NOTIFICATIONS.route,
        icon: "notifications",
        color: "#2D3345",
    },
    {
        label: SETTINGS.label,
        route: SETTINGS.route,
        icon: "setting",
        color: "#2D3345",
    },
];

export const userMenu = [
    {
        label: BUSINESS_ACCOUNTS.label,
        route: BUSINESS_ACCOUNTS.route,
        icon: "fas fa-user-edit",
        permissions: BUSINESS_ACCOUNTS.permissions,
    },
    {
        label: APP_LIST.label,
        route: APP_LIST.route,
        icon: "fas fa-puzzle-piece",
        permissions: APP_LIST.permissions,
    },
];
