import React, { useRef, useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
    GoogleMap,
    Marker,
    DrawingManager,
    LoadScriptNext,
} from "@react-google-maps/api";

const mapContainerStyle = {
    width: "100%",
    height: "400px",
    position: "relative",
};

const GoogleMapComponent = ({
    onSelect,
    isOpen,
    toggleModal,
    key,
    coordinates,
}) => {
    const mapRef = useRef();
    const [selectedShape, setSelectedShape] = useState(null);
    const [drawingMode, setDrawingMode] = useState("polygon");
    const [currentShape, setCurrentShape] = useState(null);

    const center = {
        lat: coordinates.lat ?? 36.7783,
        lng: coordinates.lng ?? -119.4179,
    };

    const onLoad = map => {
        mapRef.current = map;
    };

    const handleOverlayComplete = e => {
        const overlay = e.overlay;

        // Clear the previous shape if it exists
        if (currentShape) {
            currentShape.setMap(null);
        }

        // Set the new shape
        setCurrentShape(overlay);
        setSelectedShape(overlay);
        setDrawingMode(null); // Exit drawing mode
    };

    const handleSelectClick = () => {
        if (selectedShape) {
            let geoJson;

            if (selectedShape instanceof window.google.maps.Polygon) {
                geoJson = selectedShape
                    .getPath()
                    .getArray()
                    .map(latLng => ({
                        lat: latLng.lat(),
                        lng: latLng.lng(),
                    }));
            } else if (selectedShape instanceof window.google.maps.Polyline) {
                geoJson = selectedShape
                    .getPath()
                    .getArray()
                    .map(latLng => ({
                        lat: latLng.lat(),
                        lng: latLng.lng(),
                    }));
            } else if (selectedShape instanceof window.google.maps.Rectangle) {
                const bounds = selectedShape.getBounds();
                geoJson = {
                    type: "rectangle",
                    north: bounds.getNorthEast().lat(),
                    east: bounds.getNorthEast().lng(),
                    south: bounds.getSouthWest().lat(),
                    west: bounds.getSouthWest().lng(),
                };
            } else if (selectedShape instanceof window.google.maps.Circle) {
                const center = selectedShape.getCenter();
                const radius = selectedShape.getRadius();
                geoJson = {
                    type: "circle",
                    center: {
                        lat: center.lat(),
                        lng: center.lng(),
                    },
                    radius: radius,
                };
            } else {
                console.error("Unsupported shape type");
                return; // No valid shape type
            }

            onSelect(geoJson);
            toggleModal(); // Close the modal after selecting the area
        }
    };

    useEffect(() => {
        if (selectedShape) {
            selectedShape.setMap(mapRef.current);
        }
    }, [selectedShape]);

    useEffect(() => {}, [key]);

    return (
        <Modal
            isOpen={isOpen}
            size="lg"
            style={{ marginTop: "120px" }}
            toggle={toggleModal}
        >
            <ModalHeader toggle={toggleModal}>Geofence</ModalHeader>
            <ModalBody>
                <div style={mapContainerStyle}>
                    <GoogleMap
                        center={center}
                        id="drawing-map"
                        mapContainerStyle={{
                            width: "100%",
                            height: "100%",
                        }}
                        onLoad={onLoad}
                        zoom={10}
                    >
                        <Marker position={center} />
                        <DrawingManager
                            drawingMode={drawingMode}
                            onOverlayComplete={handleOverlayComplete}
                        />
                    </GoogleMap>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="secondary"
                    onClick={toggleModal}
                    style={{ width: "140px", marginRight: "8px" }}
                >
                    Close
                </Button>
                <Button
                    color="primary"
                    disabled={!selectedShape}
                    onClick={handleSelectClick}
                    style={{ width: "140px" }}
                >
                    Add
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default GoogleMapComponent;
