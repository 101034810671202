import { useState } from "react";
import { Badge, Input, Table } from "@/components";
import moment from "moment";
import { KANBAN_LANES } from "config/constant";
import { useStoreContext } from "context";
import { convertTZ } from "utils/timezone";

const OrderTable = ({ orders, handleModalToggle, handleDetailOpen }) => {
    const [key, setKey] = useState(true);
    const [selection, setSelection] = useState([]);
    const { store } = useStoreContext();

    const getColorStatus = status => {
        const statusOrder = Object.entries(KANBAN_LANES).find(
            ([laneName, laneProps]) => {
                return laneProps.status.includes(status);
            },
        );
        return statusOrder?.[1]?.color;
    };

    const columns = [
        {
            dataField: "key",
            text: "Key",
            hidden: true,
        },
        {
            dataField: "objectID",
            text: "objectID",
            hidden: true,
        },
        {
            dataField: "order_number",
            text: "Order Number",
            headerStyle: (colum, colIndex) => {
                return { width: "6%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return `#${row?.order_number}`;
            },
        },
        {
            dataField: "created_at",
            text: "Created At",
            headerStyle: (colum, colIndex) => {
                return { width: "15%" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                const localUTCDate = cell
                    ? convertTZ(cell, store?.timezone)
                    : "";

                const date = localUTCDate
                    ? moment(localUTCDate).format("MMMM Do YYYY")
                    : "";
                const time = localUTCDate
                    ? moment(localUTCDate).format("HH:mm:ss")
                    : "";
                return (
                    <>
                        <div className="costumers__table_row_primary">
                            {date}
                        </div>
                        <div className="costumers__table_row_secondary">
                            {time}
                        </div>
                    </>
                );
            },
        },
        {
            dataField: "type",
            text: "Type",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "12%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Badge
                        className={`status__${
                            getColorStatus(row.status) || "info"
                        }`}
                        label={row.type}
                    />
                );
            },
        },
        {
            dataField: "status",
            text: "Status",
            headerStyle: (colum, colIndex) => {
                return { width: "12%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Badge
                        className={`status__${
                            getColorStatus(row.status) || "info"
                        }`}
                        label={row.status.replaceAll("_", " ")}
                    />
                );
            },
            sort: true,
        },
        {
            dataField: "total_price",
            text: "Price",
            headerStyle: (colum, colIndex) => {
                return { width: "12%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <div className="costumers__table_row_primary">
                        {row?.total_price ? `$${row.total_price}` : "$0"}
                    </div>
                );
            },
            sort: true,
        },
        {
            dataField: "customer",
            text: "Customer",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "30%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <>
                        <div className="costumers__table_row_primary">
                            {row?.customer}
                        </div>
                        <div className="costumers__table_row_secondary">
                            {row.address}
                        </div>
                    </>
                );
            },
        },
    ];

    const selectRow = {
        mode: "checkbox",
        classes: "table-row--selected",
        clickToSelect: false,
        onSelect: (row, isSelect, rowIndex, e) => {
            let selected_customers = [];
            setKey(!key);

            if (isSelect) {
                selected_customers = [...selection, row.entity_id];
            } else {
                selected_customers = selection.filter(s => s !== row.entity_id);
            }
            setSelection(selected_customers);
        },
        onSelectAll: (isSelect, rows, e) => {
            const selected_customers = [];
            setKey(!key);
            if (isSelect) {
                rows.forEach((r, index) => {
                    selected_customers.push(r.entity_id);
                });
            }
            setSelection(selected_customers);
        },
        selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
            <label className="k-checkbox k-checkbox--brand">
                <Input type="checkbox" {...rest} readOnly />
                <span />
            </label>
        ),
        selectionRenderer: ({ mode, rowIndex, ...rest }) => (
            <label className="k-checkbox k-checkbox--brand">
                <Input type={mode} {...rest} readOnly />
                <span />
            </label>
        ),
    };

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            handleModalToggle(row);
        },
    };

    const actions = [
        {
            label: "Edit",
            action: (cell, row, rowIndex, formatExtraData) => {
                handleDetailOpen(row.objectID);
            },
        },
    ];

    return (
        <>
            <Table
                actions={actions}
                className="table table-hover"
                columns={columns}
                data={orders || []}
                keyField="objectID"
                remote={{ filter: true }}
                rowEvents={rowEvents}
                selectRow={selectRow}
            />
        </>
    );
};

export default OrderTable;
