import _ from "lodash";
import useMeta from "../useMeta";

const usePermissions = () => {
    const { user } = useMeta();

    const isAllow = permissions => {
        return (
            _.intersection(permissions, user.roles).length > 0 ||
            _.isNil(permissions)
        );
    };

    return isAllow;
};

export default usePermissions;
