import React, { useState } from "react";
import {
    ButtonDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
} from "reactstrap";
import classNames from "classnames";

const Actions = ({ actions, cell, row, rowIndex, formatExtraData }) => {
    const [isOptionsPopupOpen, setIsOptionsPopupOpen] = useState(false);

    const handleOptionsPopupToggle = () => {
        let collections = [
            ...document.getElementsByClassName("table-responsive"),
        ];
        collections &&
            collections.map(elem => {
                elem.style.overflow = isOptionsPopupOpen ? "auto" : "inherit";
            });

        setIsOptionsPopupOpen(isOptionsPopupOpen => !isOptionsPopupOpen);
    };

    return (
        <ButtonDropdown
            className={classNames("more-actions", {
                ["more-actions__active"]: isOptionsPopupOpen,
            })}
            direction={"down"}
            id={row.id}
            isOpen={isOptionsPopupOpen}
            toggle={handleOptionsPopupToggle}
        >
            <DropdownToggle
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                {" "}
                <i className="fas fa-ellipsis-h" />
            </DropdownToggle>
            <DropdownMenu
                style={{
                    left: "initial",
                    top: "30px",
                }}
            >
                <DropdownItem header key={`header-action-${row.id}`}>
                    Actions
                </DropdownItem>
                {actions.map((a, i) => {
                    const id = `action-${row.keyField}-${i}`;

                    return (
                        <DropdownItem
                            key={id}
                            onClick={e => {
                                a.action(cell, row, rowIndex, formatExtraData);
                                e.stopPropagation();
                            }}
                        >
                            {a.label}
                        </DropdownItem>
                    );
                })}
            </DropdownMenu>
        </ButtonDropdown>
    );
};

export default Actions;
