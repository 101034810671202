import { FC } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import Spinner from "../spinner/Spinner";
import _ from "lodash";
import classNames from "classnames";
import moment from "moment";
import filterFactory, {
    numberFilter,
    customFilter,
    Comparator,
    FILTER_TYPES,
} from "react-bootstrap-table2-filter";
import DateFilter from "./DateFilter";
import TextFilter from "./TextFilter";
import Actions from "./Actions";

const Table = ({
    keyField,
    loading,
    className,
    columns,
    data,
    actions,
    actionFooter,
    defaultSorted,
    pagination,
    selectRow,
    cellEdit,
    expandRow,
    borderless,
    filters,
    showFilters,
    rowEvents,
    footerClasses,
    validate,
    remote,
    onTableChange,
}) => {
    const _columns = columns.filter(column => column.dataField !== "actions");

    const headerFormatter = (
        column,
        colIndex,
        { sortElement, filterElement },
    ) => {
        return (
            <div className="d-flex justify-content-end flex-column">
                <div className="d-flex justify-content-between">
                    {column.text}
                    {sortElement}
                </div>
                {showFilters && (
                    <div
                        onClick={e => {
                            e.stopPropagation();
                        }}
                        style={{
                            margin: "0 -.75rem",
                            marginTop: ".75rem",
                            padding: "1rem .75rem .25rem",
                            borderTop: "2px solid #f7f8fa",
                        }}
                    >
                        {filterElement}
                    </div>
                )}
            </div>
        );
    };

    _.forEach(_columns, c => {
        c.headerFormatter = headerFormatter;

        if (filters) {
            const match = _.find(filters, f => f.dataField === c.dataField);

            if (match) {
                const config = {
                    delay: 0,
                    // defaultValue: match.defaultValue,
                    getFilter: filter => {
                        match.filter.current = filter;
                    },
                };

                switch (match.type) {
                    case FILTER_TYPES.TEXT:
                        config.onFilter = value => {
                            match.setValue(value);
                        };

                        c.filterRenderer = (onFilter, column) => {
                            match.filter.current = onFilter;

                            return (
                                <TextFilter
                                    column={column}
                                    onFilter={onFilter}
                                    setValue={match.setValue}
                                />
                            );
                        };

                        c.filter = customFilter(config);

                        break;
                    case FILTER_TYPES.NUMBER:
                        config.defaultValue = match.defaultValue;

                        config.onFilter = value => {
                            match.setValue(
                                value.number
                                    ? `${value.comparator} ${value.number}`
                                    : "",
                            );
                        };

                        c.filter = numberFilter(config);

                        break;
                    case FILTER_TYPES.DATE:
                        config.type = match.type;

                        config.onFilter = (value, data) => {
                            const _value = value || {};

                            const isBetween = _value.comparator === "BETWEEN";

                            match.setValue(
                                _value.startDate
                                    ? `${
                                          isBetween
                                              ? "Between"
                                              : _value.comparator
                                      } ${moment(_value.startDate).format(
                                          "MM-DD-YYYY",
                                      )}${
                                          isBetween
                                              ? ` & ${moment(
                                                    _value.endDate,
                                                ).format("MM-DD-YYYY")}`
                                              : ""
                                      }`
                                    : "",
                            );

                            return data.filter(row => {
                                const ref = moment(row[c.dataField]);

                                switch (_value.comparator) {
                                    case Comparator.LE:
                                        return ref.isSameOrBefore(
                                            _value.startDate,
                                        );

                                    case Comparator.EQ:
                                        return ref.isSame(_value.startDate);

                                    case Comparator.GE:
                                        return ref.isSameOrAfter(
                                            _value.startDate,
                                        );

                                    case "BETWEEN":
                                        return ref.isBetween(
                                            _value.startDate,
                                            _value.endDate,
                                        );

                                    default:
                                        return data;
                                }
                            });
                        };

                        c.filterRenderer = (onFilter, column) => {
                            match.filter.current = onFilter;

                            return (
                                <DateFilter
                                    column={column}
                                    onFilter={onFilter}
                                    setValue={match.setValue}
                                />
                            );
                        };

                        c.filter = customFilter(config);

                        break;
                    default:
                        break;
                }
            }
        }

        if (c.sort) {
            c.sortCaret = (order, column) => {
                return (
                    <>
                        <span
                            className="ml-auto"
                            style={{
                                opacity: order === "asc" || !order ? 0.5 : 1,
                            }}
                        >
                            ↓
                        </span>
                        <span
                            style={{
                                opacity: order === "desc" || !order ? 0.5 : 1,
                            }}
                        >
                            ↑
                        </span>
                    </>
                );
            };
        }

        c.headerClasses = "align-top";
    });

    if (actions) {
        _columns.push({
            dataField: "actions",
            text: "Actions",
            headerAlign: "center",
            headerStyle: (colum, colIndex) => {
                return { width: "10%" };
            },
            style: { whiteSpace: "nowrap", width: 1 },
            headerClasses: "align-center w-1",
            ...(filters ? { headerFormatter } : {}),

            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Actions
                        actions={actions}
                        cell={cell}
                        formatExtraData={formatExtraData}
                        row={row}
                        rowIndex={rowIndex}
                    />
                );
            },
        });

        if (actionFooter) {
            _columns[_columns.length - 1].footer = actionFooter;
        }
    }

    const _data = loading || !data ? [] : data;

    return (
        <div className={classNames(validate, "table__wrapper")}>
            <BootstrapTable
                bootstrap4
                bordered={!borderless ? true : false}
                cellEdit={cellEdit}
                columns={_columns}
                data={_data}
                defaultSorted={defaultSorted}
                expandRow={expandRow}
                filter={filterFactory()}
                footerClasses={footerClasses}
                headerClasses={!borderless ? "" : "d-none"}
                hover
                keyField={keyField}
                noDataIndication={() =>
                    loading ? (
                        <>
                            <Spinner color="metal" show sm />
                            &nbsp;&nbsp;Loading
                        </>
                    ) : (
                        "No Data"
                    )
                }
                onTableChange={onTableChange}
                pagination={pagination}
                remote={remote}
                rowEvents={rowEvents}
                selectRow={selectRow}
                wrapperClasses="table-responsive"
            />
        </div>
    );
};

export default Table;
