import { useMutation } from "@apollo/react-hooks";

import {
    MUTATION_UPDATE_ORDER_DETAIL,
    QUERY_GET_ORDER,
} from "apollo/module-operations/order";
import { useMeta } from "@/hooks";
import { UpdateOrderDetailsInput } from "@/types";
import { ApolloError } from "apollo-client";

const useUpdateOrderDetails = (
    entityId: string,
    onCompleted?: () => void,
    onError?: (error: ApolloError) => void,
) => {
    const { user } = useMeta();

    const [updateOrder, { loading }] = useMutation(
        MUTATION_UPDATE_ORDER_DETAIL,
        {
            onCompleted: () => {
                onCompleted?.();
            },
            onError: (error: ApolloError) => {
                onError?.(error);
            },
            refetchQueries: [
                {
                    query: QUERY_GET_ORDER,
                    variables: {
                        account_id: user.accountId,
                        store_id: user.storeId,
                        entity_id: entityId,
                    },
                },
                "getOrder", // Query name
            ],
        },
    );

    const updateOrderDetails = (input: UpdateOrderDetailsInput) => {
        updateOrder({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input,
            },
        });
    };

    return { updateOrderDetails, isUpdating: loading };
};

export { useUpdateOrderDetails };
