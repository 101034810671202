export default {
    blocksBasicOpts: {
        flexGrid: 1,
    },
    customStyleManager: [
        {
            name: "General",
            open: false,
            buildProps: [
                "float",
                "display",
                "position",
                "top",
                "right",
                "left",
                "bottom",
            ],
            properties: [
                {
                    name: "Float",
                    property: "float",
                    type: "radio",
                    defaults: "none",
                    list: [
                        {
                            value: "none",
                            className: "fa fa-close w-100",
                        },
                        {
                            value: "left",
                            className: "fa fa-align-left w-100",
                        },
                        {
                            value: "right",
                            className: "fa fa-align-right w-100",
                        },
                    ],
                },
                { property: "position", type: "select" },
            ],
        },
        {
            name: "Flex",
            open: false,
            properties: [
                {
                    name: "Flex Container",
                    property: "display",
                    type: "select",
                    defaults: "block",
                    list: [
                        { value: "block", name: "Disable" },
                        { value: "flex", name: "Enable" },
                    ],
                },

                {
                    name: "Direction",
                    property: "flex-direction",
                    type: "select",
                    defaults: "row",
                    list: [
                        {
                            value: "row",
                            name: "Row",
                            title: "Row",
                        },
                        {
                            value: "row-reverse",
                            name: "Row reverse",
                            title: "Row reverse",
                        },
                        {
                            value: "column",
                            name: "Column",
                            title: "Column",
                        },
                        {
                            value: "column-reverse",
                            name: "Column reverse",
                            title: "Column reverse",
                        },
                    ],
                },

                {
                    name: "Order",
                    property: "order",
                    type: "integer",
                    defaults: 0,
                    min: 0,
                },
                {
                    name: "Justify",
                    property: "justify-content",
                    type: "select",
                    defaults: "flex-start",
                    list: [
                        {
                            value: "flex-start",
                            name: "Start",
                        },
                        {
                            value: "flex-end",
                            name: "End",
                        },
                        {
                            value: "space-between",
                            name: "Space between",
                        },
                        {
                            value: "space-around",
                            name: "Space around",
                        },
                        {
                            value: "center",
                            name: "Center",
                        },
                    ],
                },
                {
                    name: "Align Items",
                    property: "align-items",
                    type: "select",
                    defaults: "center",
                    list: [
                        {
                            value: "flex-start",
                            name: "Start",
                        },
                        {
                            value: "flex-end",
                            name: "End",
                        },
                        {
                            value: "stretch",
                            name: "Stretch",
                        },
                        {
                            value: "center",
                            name: "Center",
                        },
                    ],
                },
                {
                    name: "Align Self",
                    property: "align-self",
                    type: "select",
                    defaults: "auto",
                    list: [
                        {
                            value: "auto",
                            name: "Auto",
                        },
                        {
                            value: "flex-start",
                            name: "Start",
                        },
                        {
                            value: "flex-end",
                            name: "End",
                        },
                        {
                            value: "stretch",
                            name: "Stretch",
                        },
                        {
                            value: "center",
                            name: "Center",
                        },
                    ],
                },

                {
                    name: "Flex Parent",
                    property: "label-parent-flex",
                    type: "integer",
                },
                {
                    name: "Flex Children",
                    property: "label-parent-flex",
                    type: "integer",
                },
                {
                    name: "Flex",
                    property: "flex",
                    type: "composite",
                    properties: [
                        {
                            name: "Grow",
                            property: "flex-grow",
                            type: "integer",
                            defaults: 0,
                            min: 0,
                        },
                        {
                            name: "Shrink",
                            property: "flex-shrink",
                            type: "integer",
                            defaults: 0,
                            min: 0,
                        },
                        {
                            name: "Basis",
                            property: "flex-basis",
                            type: "integer",
                            units: ["px", "%", ""],
                            unit: "",
                            defaults: "auto",
                        },
                    ],
                },
            ],
        },

        {
            name: "Dimensions",
            open: false,
            buildProps: [
                "width",
                "max-width",
                "height",
                "min-height",
                "flex-width",
                "padding",
                "margin",
            ],
            properties: [
                {
                    id: "flex-width",
                    type: "integer",
                    name: "Width",
                    units: ["px", "%"],
                    property: "flex-basis",
                    toRequire: 1,
                },
                {
                    property: "margin",
                    properties: [
                        { name: "Top", property: "margin-top" },
                        {
                            name: "Right",
                            property: "margin-right",
                        },
                        {
                            name: "Bottom",
                            property: "margin-bottom",
                        },
                        {
                            name: "Left",
                            property: "margin-left",
                        },
                    ],
                },
                {
                    property: "padding",
                    properties: [
                        {
                            name: "Top",
                            property: "padding-top",
                        },
                        {
                            name: "Right",
                            property: "padding-right",
                        },
                        {
                            name: "Bottom",
                            property: "padding-bottom",
                        },
                        {
                            name: "Left",
                            property: "padding-left",
                        },
                    ],
                },
            ],
        },
        {
            name: "Typography",
            open: false,
            buildProps: [
                "font-family",
                "text-align",
                "text-decoration",
                "font-size",
                "font-weight",
                "line-height",
                "letter-spacing",
                "color",
                "text-shadow",
            ],
            properties: [
                {
                    name: "Family",
                    property: "font-family",
                    full: 1,
                },
                {
                    property: "text-align",
                    name: "Alignment",
                    type: "radio",
                    defaults: "left",
                    list: [
                        {
                            value: "left",
                            name: "Left",
                            className: "fa fa-align-left w-100",
                        },
                        {
                            value: "center",
                            name: "Center",
                            className: "fa fa-align-center w-100",
                        },
                        {
                            value: "right",
                            name: "Right",
                            className: "fa fa-align-right w-100",
                        },
                        {
                            value: "justify",
                            name: "Justify",
                            className: "fa fa-align-justify w-100",
                        },
                    ],
                },
                {
                    property: "text-decoration",
                    name: "Decoration",
                    type: "radio",
                    defaults: "none",
                    list: [
                        {
                            value: "none",
                            name: "None",
                            className: "fa fa-times w-100",
                        },
                        {
                            value: "underline",
                            name: "underline",
                            className: "fa fa-underline w-100",
                        },
                        {
                            value: "line-through",
                            name: "Line-through",
                            className: "fa fa-strikethrough w-100",
                        },
                    ],
                },
                { name: "Weight", property: "font-weight" },
                { name: "Color", property: "color" },
                {
                    property: "text-shadow",
                    properties: [
                        {
                            name: "X position",
                            property: "text-shadow-h",
                        },
                        {
                            name: "Y position",
                            property: "text-shadow-v",
                        },
                        {
                            name: "Blur",
                            property: "text-shadow-blur",
                        },
                        {
                            name: "Color",
                            property: "text-shadow-color",
                        },
                    ],
                },
            ],
        },
        {
            name: "Decorations",
            open: false,
            buildProps: [
                "opacity",
                "border-radius",
                "border",
                "box-shadow",
                "background-bg",
            ],
            properties: [
                {
                    type: "slider",
                    property: "opacity",
                    defaults: 1,
                    step: 0.01,
                    max: 1,
                    min: 0,
                },
                {
                    property: "border-radius",
                    properties: [
                        {
                            name: "Top",
                            property: "border-top-left-radius",
                        },
                        {
                            name: "Right",
                            property: "border-top-right-radius",
                        },
                        {
                            name: "Bottom",
                            property: "border-bottom-left-radius",
                        },
                        {
                            name: "Left",
                            property: "border-bottom-right-radius",
                        },
                    ],
                },
                {
                    property: "box-shadow",
                    properties: [
                        {
                            name: "X position",
                            property: "box-shadow-h",
                        },
                        {
                            name: "Y position",
                            property: "box-shadow-v",
                        },
                        {
                            name: "Blur",
                            property: "box-shadow-blur",
                        },
                        {
                            name: "Spread",
                            property: "box-shadow-spread",
                        },
                        {
                            name: "Color",
                            property: "box-shadow-color",
                        },
                        {
                            name: "Shadow type",
                            property: "box-shadow-type",
                        },
                    ],
                },
                {
                    id: "background-bg",
                    property: "background",
                    type: "bg",
                },
            ],
        },
        {
            name: "Extra",
            open: false,
            buildProps: ["perspective", "transform", "transition", "filter"],
            properties: [
                {
                    property: "transition",
                    properties: [
                        {
                            name: "Property",
                            property: "transition-property",
                        },
                        {
                            name: "Duration",
                            property: "transition-duration",
                        },
                        {
                            name: "Easing",
                            property: "transition-timing-function",
                        },
                    ],
                },
                {
                    property: "transform",
                    properties: [
                        {
                            name: "Rotate X",
                            property: "transform-rotate-x",
                        },
                        {
                            name: "Rotate Y",
                            property: "transform-rotate-y",
                        },
                        {
                            name: "Rotate Z",
                            property: "transform-rotate-z",
                        },
                        {
                            name: "Scale X",
                            property: "transform-scale-x",
                        },
                        {
                            name: "Scale Y",
                            property: "transform-scale-y",
                        },
                        {
                            name: "Scale Z",
                            property: "transform-scale-z",
                        },
                    ],
                },
                {
                    property: "filter",
                    name: "Filter",
                    type: "filter",
                    full: 1,
                },
            ],
        },
    ],
};
