import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import classNames from "classnames";
import Brand from "../brand/Brand";
import AsideNavigation from "./AsideNavigation";
import ToolBar from "./ToolBar";
import { QUERY_UI } from "../../../../src/apollo/queries/local";
import { MUTATION_UI } from "../../../../src/apollo/mutations/local";

const AsideMenu = () => {
    const { data } = useQuery(QUERY_UI);

    const { asideHover, asideMinimize, asideToggle } = data.ui;

    const [updateUi] = useMutation(MUTATION_UI);

    const options = (enter = false) => ({
        variables: {
            ui: {
                asideHover: enter,
                asideMinimize: !enter,
            },
        },
    });

    const mouseEnter = () => {
        if (asideMinimize) {
            updateUi(options(true));
        }
    };

    const mouseLeave = () => {
        if (asideHover) {
            updateUi(options());
        }
    };

    return (
        <aside
            className={classNames(
                "k-aside",
                "k-aside--fixed",
                "k-grid__item",
                "k-grid",
                "k-grid--desktop",
                "k-grid--hor-desktop",
                { "k-aside--on": asideToggle },
            )}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
        >
            <Brand />
            <div className="k-aside-menu-wrapper k-grid__item k-grid__item--fluid">
                <AsideNavigation />
            </div>
            <ToolBar />
        </aside>
    );
};

export default AsideMenu;
