import React from "react";
import HeaderOptions from "./HeaderOptions";
import LanguageSelector from "./language/LanguageSelector";
import DeploymentStatus from "./deployment-status/DeploymentStatus";
import Stores from "./stores/Stores";
import UserMenu from "./user-menu/UserMenu";

const Header = () => {
    return (
        <div className="k-header k-grid__item  k-header--fixed ">
            <span />
            <div className="k-header__topbar">
                <Stores />
                <div className="k-header__topbar-item-separator" />
                <DeploymentStatus />
                <HeaderOptions />
                {/*  <LanguageSelector /> */}
                <UserMenu />
            </div>
        </div>
    );
};

export default Header;
