import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Badge, Portlet, Table } from "@/components";
import useMeta from "../../base/hooks/useMeta.js";
import { updateAccountAttribute } from "../../utils/utils";
import { useHistory } from "react-router-dom";
import { QUERY_STORES_FROM_ACCOUNT_LIST } from "../../apollo/module-operations/store";
import { formatAddress } from "../../utils/utils";
import paginationFactory from "react-bootstrap-table2-paginator";
import { paginationOptions } from "../../config/components/table";

const AccountStores = ({ toolbar }) => {
    const { resourceId, isCreate } = useMeta();

    const history = useHistory();

    const {
        data: stores,
        loading: storeLoading,
        networkStatus,
    } = useQuery(QUERY_STORES_FROM_ACCOUNT_LIST, {
        variables: {
            account_id: resourceId,
        },
        skip: isCreate,
    });
    const columns = [
        {
            dataField: "id",
            text: "Id",
            hidden: true,
        },
        {
            dataField: "name",
            text: "Name",
            sort: true,
        },
        {
            dataField: "email",
            text: "Emial",
            sort: true,
        },
        {
            dataField: "phone_number",
            text: "Phone",
            sort: true,
        },
        {
            dataField: "address1",
            text: "Address",
            headerStyle: (colum, colIndex) => {
                return { width: "30%" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                let address = formatAddress({
                    address1: row.address1,
                    address2: row.address2,
                    city: row.city,
                    country: row.country,
                    country_code: row.country_code,
                    country_name: row.country_name,
                    state: row.state,
                    state_code: row.state_code,
                });

                return <div>{address}</div>;
            },
        },
        {
            dataField: "status",
            text: "Status",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "15%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Badge
                        className={
                            row.status ? "status__success" : "status__error"
                        }
                        label={row.status ? "Active" : "No Active"}
                    />
                );
            },
        },
    ];

    const actions = [
        {
            label: "Update",
            icon: "fas fa-pencil-alt",
            action: async (cell, row, rowIndex, formatExtraData) => {
                updateAccountAttribute(resourceId, row.id, history);
            },
        },
    ];

    const _storeLoading = storeLoading || networkStatus === 4;

    const _stores = stores && stores.listStoresFromAccount;

    return (
        <>
            <Portlet
                header={{
                    title: "Stores",
                    subtitle: "All Business Stores",
                }}
                sticky
                toolbar={toolbar}
            >
                <Table
                    actions={actions}
                    columns={columns}
                    data={_stores}
                    keyField="id"
                    loading={_storeLoading}
                    pagination={paginationFactory(paginationOptions)}
                />
            </Portlet>
        </>
    );
};

export default AccountStores;
