import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";

const useUser = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Attributes
    const [user, setUser] = useState(null);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(cognitoUser => {
                let payload = cognitoUser.signInUserSession.idToken.payload;

                let user = {
                    accountId: payload["custom:ACCOUNT_ID"],
                    storeId: payload["custom:STORE_ID"],
                    roles: payload["cognito:groups"],
                    email: cognitoUser.attributes.email,
                    id: cognitoUser.attributes.sub,
                };

                setUser(user);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                setError(err);
            });
    }, []);

    return { loading, error, ...user };
};

export default useUser;
