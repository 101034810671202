import React from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { Portlet, Section, SectionTitle } from "../../base/components/portlet";
import { FormGroup, Col, Label, Row } from "reactstrap";
import Select from "../../base/components/form/select/Select";
import Input from "../../base/components/form/input/Input";
import Form from "../../base/components/form/Form";
import InputGroupText from "../../base/components/form/input/InputGroupText";
import { currencyMask } from "../../utils/maskInput";

const TaxForm = ({
    toolbar,
    inputs,
    inputChange,
    selectChange,
    selectValue,
    taxTypeOptions,
    loading,
}) => {
    const flatRate = inputs.tax_type === "FLAT_RATE";

    return (
        <Portlet
            className="business__settings"
            header={{
                title: "Tax",
                subtitle: "Business Tax Information",
            }}
            sticky
            toolbar={toolbar}
        >
            {!loading ? (
                <Form>
                    <Section>
                        <Row>
                            <Col>
                                <SectionTitle md>Calculate</SectionTitle>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ maxWidth: "550px" }} xl={6}>
                                <FormGroup className="d-flex align-items-center justify-content-left">
                                    <Label className="text-left mr-3">
                                        Calculate and charge tax at checkout (If
                                        this is not checked, then taxes are
                                        included on the product price)
                                    </Label>
                                    <span className="k-switch k-switch--md k-switch--icon">
                                        <label>
                                            <Input
                                                checked={
                                                    inputs.tax_active
                                                        ? "checked"
                                                        : ""
                                                }
                                                name="tax_active"
                                                onChange={inputChange}
                                                type="checkbox"
                                            />
                                            <span />
                                        </label>
                                    </span>
                                </FormGroup>
                            </Col>
                        </Row>

                        {inputs.tax_active && (
                            <>
                                <FormGroup row>
                                    <Col lg={4}>
                                        <Label>Calculate Type</Label>
                                        <Select
                                            controlName="tax_type"
                                            defaultValue={selectValue(
                                                inputs.tax_type,
                                                taxTypeOptions,
                                            )}
                                            onChange={selectChange}
                                            options={taxTypeOptions}
                                        />
                                    </Col>
                                    <Col lg={4}>
                                        <Label>Amount</Label>
                                        <Input
                                            defaultValue={
                                                inputs.tax_ammont
                                                    ? inputs.tax_ammont
                                                    : ""
                                            }
                                            mask={currencyMask(
                                                inputs.tax_ammont || "",
                                            )}
                                            name="tax_ammont"
                                            onChange={inputChange}
                                            prepend={
                                                <InputGroupText>
                                                    {flatRate ? "$" : "%"}
                                                </InputGroupText>
                                            }
                                            typeInput="masked"
                                        />
                                    </Col>
                                </FormGroup>
                            </>
                        )}
                    </Section>
                </Form>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
        </Portlet>
    );
};

export default TaxForm;
