import React from "react";
// import { useMutation } from "@apollo/react-hooks";
import useNotification from "../../../hooks/components/useNotification";
// import PerfectScrollbar from "react-perfect-scrollbar";
import VerticalNav from "../../../components/navs/vertical/VerticalNav";
import VerticalNavItem from "../../../components/navs/vertical/VerticalNavItem";
import { Auth } from "aws-amplify";

const StoresItems = ({ toggle, stores, activeStore, setLoadingStore }) => {
    const notify = useNotification();

    const changeStore = (accountId, storeId) => {
        setLoadingStore(true);

        // Get the cognito user
        Auth.currentAuthenticatedUser({ bypassCache: true })
            .then(user => {
                // Update the user attributes
                Auth.updateUserAttributes(user, {
                    "custom:ACCOUNT_ID": accountId,
                    "custom:STORE_ID": storeId,
                })
                    .then(result => {
                        // Calling this again causes refreshed user cache from localStarge.
                        // This is the solution proposed here:
                        // https://github.com/aws-amplify/amplify-js/issues/2534
                        Auth.currentAuthenticatedUser({
                            bypassCache: true,
                        }).then(user => {
                            // Reload the application
                            window.location.reload();
                        });
                    })
                    .catch(e => {
                        handleError(e);
                    });
            })
            .catch(e => {
                handleError(e);
            });
    };

    const handleError = e => {
        notify("Error switching store. Please try again.", "error");

        setLoadingStore(false);
    };

    return (
        // Note: If you will use vertical scroll, you must
        // set max-height style property to <VerticalNav>
        // with class .k-nav inside the header.scss file.
        // Also you must to remove the parent <div> with class
        // .scrollbar-container.
        //
        // <PerfectScrollbar
        //     options={{
        //         suppressScrollX: true
        //     }}
        // >
        <div className="scrollbar-container">
            <VerticalNav onClick={toggle} sm>
                {stores.map(value => (
                    <VerticalNavItem
                        action={() => {
                            changeStore(value.account_id, value.store_id);
                        }}
                        active={value.store_id === activeStore}
                        key={value.store_id}
                    >
                        {value.name}
                    </VerticalNavItem>
                ))}
            </VerticalNav>
        </div>
        // </PerfectScrollbar>
    );
};

export default StoresItems;
