import gql from "graphql-tag";

let StatDaily = {};

StatDaily.fragments = {
    attributes: gql`
        fragment StatDailyFragment on StatDaily {
            month
            subtotal
            total
        }
    `,
};

export const QUERY_GET_STAT = gql`
    query getStats($account_id: String!, $store_id: String!) {
        getStats(account_id: $account_id, store_id: $store_id) {
            anual {
                ...StatDailyFragment
            }
            snapshot {
                labels
                subtotal
                total
            }
            top_products {
                id
                title
                qty
                price
                weight
            }
            growth {
                type
                total
                percentage
                revenue
            }
            total_sales
            total_sales_amount
        }
    }
    ${StatDaily.fragments.attributes}
`;

export const QUERY_GET_SNAPSHOT = gql`
    query getSnapshot($account_id: String!, $store_id: String!, $option: Int!) {
        getSnapshot(
            account_id: $account_id
            store_id: $store_id
            option: $option
        ) {
            labels
            subtotal
            total
            total_sales
            total_sales_amount
        }
    }
`;
