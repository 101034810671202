import React from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { Portlet, Section, SectionTitle } from "../../base/components/portlet";
import { FormGroup, Col, Label, Row, FormFeedback } from "reactstrap";
import Input from "../../base/components/form/input/Input";
import Form from "../../base/components/form/Form";
import useMeta from "../../base/hooks/useMeta";
import useForm from "../../base/hooks/form/useForm";
import useFormActions from "../../base/hooks/form/useFormActions";
import {
    MUTATION_UPDATE_COVA_APP,
    QUERY_LIST_STORE_APPS,
} from "../../apollo/module-operations/app";
import _ from "lodash";
import useValidator from "../../base/hooks/form/useValidator";

const CovaForm = ({ app }) => {
    const { user } = useMeta();
    const { inputs, setInputs, inputChange } = useForm();

    if (!_.get(inputs, "id", null)) {
        setInputs(app);
    }

    let options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: {
                ...inputs,
                id: inputs.id,
                client_id: inputs.client_id,
                client_secret: inputs.client_secret,
                username: inputs.username,
                password: inputs.password,
                company_id: inputs.company_id,
                type: "Cova",
            },
        },
    };

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "id",
                method: "isEmpty",
                validWhen: false,
                message: "This app id is required.",
            },

            {
                field: "client_id",
                method: "isEmpty",
                validWhen: false,
                message: "This client id is required.",
            },

            {
                field: "client_secret",
                method: "isEmpty",
                validWhen: false,
                message: "This client secret is required.",
            },

            {
                field: "username",
                method: "isEmpty",
                validWhen: false,
                message: "This username is required.",
            },
            {
                field: "password",
                method: "isEmpty",
                validWhen: false,
                message: "This password is required.",
            },

            {
                field: "company_id",
                method: "isEmpty",
                validWhen: false,
                message: "This company id is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    const { createOrUpdateButton } = useFormActions(
        "id",
        "Cova",
        "",
        MUTATION_UPDATE_COVA_APP,
        [
            {
                query: QUERY_LIST_STORE_APPS,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        "",
        isValid,
        isSubmited,
        "primary",
    );

    return (
        <Portlet
            centered
            header={{
                title: "Cova",
                subtitle: "Cova Information",
            }}
            sticky
        >
            {!user.loading ? (
                <Form centered>
                    <Section>
                        <Row>
                            <Label lg={4} xl={3} />
                            <Col>
                                <SectionTitle md>Information</SectionTitle>
                            </Col>
                        </Row>

                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Client Id
                            </Label>
                            <Col>
                                <Input
                                    invalid={validator.client_id.isInvalid}
                                    name="client_id"
                                    onChange={inputChange}
                                    value={inputs.client_id}
                                />
                                <FormFeedback>
                                    {validator.client_id.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Client Secret
                            </Label>
                            <Col>
                                <Input
                                    invalid={validator.client_secret.isInvalid}
                                    name="client_secret"
                                    onChange={inputChange}
                                    value={inputs.client_secret}
                                />
                                <FormFeedback>
                                    {validator.client_secret.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Username
                            </Label>
                            <Col>
                                <Input
                                    invalid={validator.username.isInvalid}
                                    name="username"
                                    onChange={inputChange}
                                    value={inputs.username}
                                />
                                <FormFeedback>
                                    {validator.username.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Password
                            </Label>
                            <Col>
                                <Input
                                    invalid={validator.password.isInvalid}
                                    name="password"
                                    onChange={inputChange}
                                    type="password"
                                    value={inputs.password}
                                />
                                <FormFeedback>
                                    {validator.password.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Company Id
                            </Label>
                            <Col>
                                <Input
                                    invalid={validator.company_id.isInvalid}
                                    name="company_id"
                                    onChange={inputChange}
                                    value={inputs.company_id}
                                />
                                <FormFeedback>
                                    {validator.company_id.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label lg={4} xl={3} />
                            <Col>{createOrUpdateButton}</Col>
                        </FormGroup>
                    </Section>
                </Form>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
        </Portlet>
    );
};

export default CovaForm;
