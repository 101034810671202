import React, { useState, useEffect } from "react";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import Content from "../../base/layout/content/Content";
import { Badge, Portlet } from "@/components";
import classNames from "classnames";
import useMeta from "../../base/hooks/useMeta.js";
import useTableActions from "../../base/hooks/table/useTableActions";
import useRowActions from "../../base/hooks/table/useRowActions";
import {
    QUERY_ACCOUNT_LIST,
    MUTATION_REMOVE_ACCOUNT,
} from "../../apollo/module-operations/business_account";
import routes from "../../config/routes/routes";
import moment from "moment";
import { Button } from "reactstrap";
import { formatAddress } from "../../utils/utils";
import paginationFactory from "react-bootstrap-table2-paginator";
import { paginationOptions } from "../../config/components/table";

import FiltersToggle from "../../base/components/table/FiltersToggle";
import Filters from "../../base/components/table/Filters";
import Table from "../../base/components/table/Table";
import { FILTER_TYPES } from "react-bootstrap-table2-filter";
import useFilter from "../../base/hooks/table/useFilter";
import useNotification from "../../base/hooks/components/useNotification";

const Accounts = () => {
    const { user } = useMeta();

    //---------------------------Defining filters-----------------------------
    const { TEXT, DATE } = FILTER_TYPES;

    const [showFilters, setShowFilters] = useState(false);

    const filterCompany = useFilter("company", TEXT, "Company", "");

    const filterCreated = useFilter("created_at", DATE, "Created At");

    const filterName = useFilter("first_name", TEXT, "First Name", "");

    const filterLast = useFilter("last_name", TEXT, "Last Name", "");

    const filterPhone = useFilter("phone", TEXT, "Phone", "");

    const filterAddress = useFilter("address", TEXT, "Address", "");

    const filters = [
        filterCompany,
        filterCreated,
        filterName,
        filterLast,
        filterPhone,
        filterAddress,
    ];
    // -----------------------------------------------------------------------

    // Call to a query for getting the information but only
    // when user is not loading.
    /* const { loading, data, refetch, networkStatus } = useQuery(
        QUERY_ACCOUNT_LIST,
        {
            skip: user.loading,
            notifyOnNetworkStatusChange: true,
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                cursor: null,
            },
        },
    );
 */
    const [
        listAccounts,
        { loading: loading, data: data, networkStatus, refetch, fetchMore },
    ] = useLazyQuery(QUERY_ACCOUNT_LIST, {
        skip: user.loading,
        notifyOnNetworkStatusChange: true,
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
        },
    });

    // This hooks the refresh button and the add new button.
    const { refreshButton, addNewButton } = useTableActions(
        refetch,
        routes.ACCOUNT_CREATE.route,
    );

    // This hooks returns (for now) two functions:
    // "remove a specific row" with the confirm modal,
    // and "got to update form". See below how to use it.
    // The first parameter is a array of queries which
    // will be called after mutation is completed.
    // This is usefun when remove a row and you want to
    // "refresh" your list.
    const { confirm, remove, update } = useRowActions(
        [
            {
                query: QUERY_ACCOUNT_LIST,
            },
        ],
        MUTATION_REMOVE_ACCOUNT,
        routes.ACCOUNT_DETAIL.route,
        "Account",
    );

    const defaultSorted = [
        {
            dataField: "created_at",
            order: "desc",
        },
    ];

    useEffect(() => {
        !user.loading && listAccounts();

        // eslint-disable-next-line
    }, [user.loading]);

    const next = cursor => {
        fetchMore({
            query: QUERY_ACCOUNT_LIST,
            variables: {
                cursor: cursor,
                account_id: user.accountId,
                store_id: user.storeId,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                const previousEntry = previousResult.listAccounts;
                const newAccounts = fetchMoreResult.listAccounts.accounts;
                const newCursor = fetchMoreResult.listAccounts.cursor;

                return {
                    listAccounts: {
                        cursor: newCursor,
                        accounts: [...previousEntry.accounts, ...newAccounts],
                        __typename: previousEntry.__typename,
                    },
                };
            },
        });
    };

    const notify = useNotification();

    // You should define your columns as usual but not
    // the "actions" column.
    const columns = [
        {
            dataField: "entity_id",
            text: "Id",
            hidden: true,
        },
        {
            dataField: "key",
            hidden: true,
        },
        {
            dataField: "created_at",
            text: "Created At",
            headerStyle: (colum, colIndex) => {
                return { width: "10%" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <span>{moment(cell).format("MM/DD/YYYY HH:mm")}</span>;
            },
        },
        {
            dataField: "first_name",
            text: "First Name",
            sort: true,
        },
        {
            dataField: "last_name",
            text: "Last Name",
            sort: true,
        },
        {
            dataField: "email",
            text: "Email",
            sort: true,
        },
        {
            dataField: "phone",
            text: "Phone",
            sort: true,
        },
        {
            dataField: "address",
            text: "Address",
            headerStyle: (colum, colIndex) => {
                return { width: "30%" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                let address = "";
                if (cell) {
                    address = formatAddress(cell);
                }

                return <div>{address}</div>;
            },
            filterValue: (cell, row) => formatAddress(cell),
        },
        {
            dataField: "has_account",
            text: "Account",
            headerStyle: (colum, colIndex) => {
                return { width: "15%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Badge
                        className={
                            row.has_account ? "status__info" : "status__metalic"
                        }
                        label={row.has_account ? "Active" : "No Active"}
                    />
                );
            },
            sort: true,
        },
        {
            dataField: "active",
            text: "Status",
            headerStyle: (colum, colIndex) => {
                return { width: "10%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Badge
                        className={
                            row.active ? "status__success" : "status__error"
                        }
                        label={row.active ? "Active" : "No Active"}
                    />
                );
            },
            sort: true,
        },
    ];

    // The actions of each row are defined here
    // with only three attrs: the label of its tooltip,
    // the icon of the button, and a action which will
    // be called on the onClick even of the button.
    // As you can see, this function recieve exactly
    // the same parameters as the "formatter" attr
    // on a column.
    const actions = [
        {
            label: "Update",
            icon: "fas fa-pencil-alt",
            action: (cell, row, rowIndex, formatExtraData) => {
                update(row.entity_id);
            },
        },
        {
            label: "Remove",
            icon: "fas fa-trash",
            action: (cell, row, rowIndex, formatExtraData) => {
                remove({
                    variables: {
                        id: row.id,
                    },
                });
            },
        },
    ];

    // This variable defines when the data is loading or not.
    const _loading = loading || networkStatus === 4;

    // If data is ready then data will be "data.whatever"
    // else will be undefined. Unefined values will be parsed to
    // a empty array behind the fold. Don't worrie about it.
    const _data = (data && data.listAccounts.accounts) || [];
    const _cursor = data && data.listAccounts.cursor;

    return (
        <>
            <Content>
                <Portlet
                    header={{
                        title: "Accounts",
                        subtitle: "All accounts availables in the system.",
                    }}
                    sticky
                    toolbar={
                        <>
                            <FiltersToggle setShowFilters={setShowFilters} />
                            {refreshButton}
                            {addNewButton}
                        </>
                    }
                >
                    <Filters filters={filters} />
                    <Table
                        actions={actions}
                        columns={columns}
                        data={_data}
                        defaultSorted={defaultSorted}
                        filters={filters}
                        keyField="entity_id"
                        loading={_loading}
                        showFilters={showFilters}
                        // pagination={paginationFactory(paginationOptions)}
                    />
                    {!loading && _cursor && (
                        <Button
                            className="float-right"
                            color="primary"
                            onClick={() => next(_cursor)}
                        >
                            <i className="fas fa-arrow-right" /> Show more
                        </Button>
                    )}
                </Portlet>
            </Content>
            {confirm}
        </>
    );
};

export default Accounts;
