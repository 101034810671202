import React from "react";
import { JsonTree } from "react-editable-json-tree";

const JsonEditor = ({ value, onChange, ...props }) => {
    const getButton = icon => {
        return (
            <button className={`btn btn-sm btn-icon btn-hover-metal ml-1`}>
                <i className={`fas fa-${icon}`} />
            </button>
        );
    };

    return (
        <div
            className="border rounded"
            style={{
                padding: "0.65rem 1rem",
                backgroundColor: "#fefefe",
            }}
        >
            <JsonTree
                addButtonElement={getButton("check")}
                cancelButtonElement={getButton("times")}
                data={value}
                editButtonElement={getButton("check")}
                minusMenuElement={getButton("trash-alt")}
                onFullyUpdate={onChange}
                plusMenuElement={getButton("plus")}
                {...props}
            />
        </div>
    );
};

export default JsonEditor;
