import { Button } from "reactstrap";
import { useRefinementList } from "react-instantsearch-hooks-web";
import { orderBy, sortBy } from "lodash";
import { FC, Fragment } from "react";
import { timeWindowFormat } from "utils/utils";
import { Badge } from "../badge";
import CheckBox from "../CheckBox";

interface IRefinementListProps {
    attribute: string;
    type?: "button" | "checkbox";
}

export const RefinementList: FC<IRefinementListProps> = ({
    attribute,
    type = "button",
}) => {
    const { items, refine } = useRefinementList({
        attribute,
        limit: 1000,
    });

    const sortedItems = orderBy(items, ["label"]);

    let storesName = {};
    if (process.env.REACT_APP_ALGOLIA_STORE_ID_NAME_MAPPING) {
        storesName = JSON.parse(
            process.env.REACT_APP_ALGOLIA_STORE_ID_NAME_MAPPING,
        );
    }

    return (
        <div className="filters__filter">
            {sortedItems.map(({ label, isRefined, value, count }) => {
                let itemLabel = label;

                if (attribute === "jane_store.id") {
                    itemLabel = storesName[label] || label;
                }

                if (attribute === "delivery_time_window") {
                    itemLabel = timeWindowFormat(value);
                }

                return (
                    <Fragment key={value}>
                        {type === "button" && (
                            <Button
                                active={isRefined}
                                className="filters_options-filter_button"
                                id={value}
                                onClick={() => refine(value)}
                                title={value}
                            >
                                <div className="button_label">
                                    {itemLabel}
                                    <Badge
                                        className="status__success"
                                        label={count}
                                    />
                                </div>
                            </Button>
                        )}
                        {type === "checkbox" && (
                            <div
                                className="refinement_list_checkbox"
                                onClick={() => refine(value)}
                            >
                                <CheckBox
                                    checked={isRefined}
                                    label={itemLabel}
                                />
                                <Badge
                                    className="status__success"
                                    label={count}
                                />
                            </div>
                        )}
                    </Fragment>
                );
            })}
        </div>
    );
};
