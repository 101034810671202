import React from "react";
import Balloon from "./Balloon";
import { Section, SectionTitle } from "../portlet";
import _ from "lodash";

const Filters = ({ filters }) => {
    const _filters = _.filter(filters, f => !_.isEmpty(f.value));

    if (!_filters.length) {
        return null;
    }

    return (
        <div className="mx-n3 pb-1">
            <Section>
                <SectionTitle className="mb-3">
                    <div className="mb-n3 filter__header">
                        <span> Applied filters </span>
                        <span className="ml-3 text-secondary font-weight-normal">
                            |
                        </span>
                        <button
                            className="btn btn-link filters__btn_link_clear"
                            onClick={() => {
                                _.forEach(_filters, f => {
                                    if (f.filter.current) {
                                        f.filter.current(f.defaultValue);
                                    }
                                    f.setValue(null);
                                });
                            }}
                        >
                            Clear filters
                        </button>
                    </div>
                </SectionTitle>
                <div className="mb-3 d-flex justify-content-between align-items-center flex-wrap">
                    <div className="d-flex flex-wrap">
                        {_filters.map(f => (
                            <Balloon filter={f} key={f.dataField} />
                        ))}
                    </div>
                </div>
            </Section>
        </div>
    );
};

export default Filters;
