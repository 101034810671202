export default {
    blocks: [
        "grid-items",
        "list-items",
        "header",
        "section",
        "footer",
        "iframe",
        // "button"
    ],
};
