import React from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { Portlet, Section, SectionTitle } from "../../base/components/portlet";
import { FormGroup, Col, Label, Button, Row, FormFeedback } from "reactstrap";
import Input from "../../base/components/form/input/Input";
import Form from "../../base/components/form/Form";
import useMeta from "../../base/hooks/useMeta";
import useForm from "../../base/hooks/form/useForm";
import useFormActions from "../../base/hooks/form/useFormActions";
import {
    MUTATION_UPDATE_JANE_APP,
    QUERY_LIST_STORE_APPS,
} from "../../apollo/module-operations/app";

import { MUTATION_SYNC_FROM_JANE } from "../../apollo/module-operations/jane";

import _ from "lodash";
import useValidator from "../../base/hooks/form/useValidator";
import { useMutation } from "@apollo/react-hooks";

import useNotification from "../../base/hooks/components/useNotification";

const JaneForm = ({ app }) => {
    const notify = useNotification();

    const { user } = useMeta();
    const { inputs, setInputs, inputChange } = useForm();

    if (!_.get(inputs, "id", null)) {
        setInputs(app);
    }

    let options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: {
                ...inputs,
                id: inputs.id,
                app_id: inputs.app_id,
                api_key: inputs.api_key,
                store_id: inputs.store_id,
                product_index: inputs.product_index,
                stores_index: inputs.stores_index,

                reviews_index: inputs.reviews_index,
                client_id: inputs.client_id,
                client_secret: inputs.client_secret,
                host: inputs.host,

                type: "Jane",
            },
        },
    };

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "id",
                method: "isEmpty",
                validWhen: false,
                message: "This app id is required.",
            },

            {
                field: "app_id",
                method: "isEmpty",
                validWhen: false,
                message: "This app id is required.",
            },

            {
                field: "api_key",
                method: "isEmpty",
                validWhen: false,
                message: "This api key is required.",
            },

            {
                field: "store_id",
                method: "isEmpty",
                validWhen: false,
                message: "This store id is required.",
            },

            {
                field: "product_index",
                method: "isEmpty",
                validWhen: false,
                message: "This product index is required.",
            },

            {
                field: "reviews_index",
                method: "isEmpty",
                validWhen: false,
                message: "This review index is required.",
            },

            {
                field: "stores_index",
                method: "isEmpty",
                validWhen: false,
                message: "This store index is required.",
            },

            {
                field: "client_id",
                method: "isEmpty",
                validWhen: false,
                message: "This client id is required.",
            },

            {
                field: "client_secret",
                method: "isEmpty",
                validWhen: false,
                message: "This client secret is required.",
            },

            {
                field: "operation_client_id",
                method: "isEmpty",
                validWhen: false,
                message: "This client id is required.",
            },

            {
                field: "operation_client_secret",
                method: "isEmpty",
                validWhen: false,
                message: "This client secret is required.",
            },

            {
                field: "host",
                method: "isEmpty",
                validWhen: false,
                message: "This host is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    const { createOrUpdateButton } = useFormActions(
        "id",
        "Jane",
        "",
        MUTATION_UPDATE_JANE_APP,
        [
            {
                query: QUERY_LIST_STORE_APPS,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        "",
        isValid,
        isSubmited,
        "primary",
    );

    const [mutateSyncFromJane, { loading: syncLoading }] = useMutation(
        MUTATION_SYNC_FROM_JANE,
        {
            onCompleted: resp => {
                //toggle(false);

                notify("Sync Product From Jane Successfully", "success");
            },
            onError: () => {
                notify("Error Trying to Sync Product", "error");
            },
        },
    );

    const syncProducstFromJane = async () => {
        mutateSyncFromJane({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
            },
        });
    };

    return (
        <Portlet
            className="business__settings"
            header={{
                title: "Jane",
                subtitle: "Jane Information",
            }}
            sticky
            toolbar={
                <>
                    <Button
                        className="ml-3"
                        color="metal"
                        onClick={syncProducstFromJane}
                    >
                        {syncLoading ? (
                            <Spinner color="#0e1017" show={true} sm />
                        ) : (
                            <i className={`fas fa-sync-alt`} />
                        )}
                        Sync Products
                    </Button>
                    {createOrUpdateButton}
                </>
            }
        >
            {!user.loading ? (
                <Form>
                    <Section>
                        <Row>
                            <Col>
                                <SectionTitle md>Algolia</SectionTitle>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Col lg={4}>
                                <Label>App Id</Label>
                                <Input
                                    invalid={validator.app_id.isInvalid}
                                    name="app_id"
                                    onChange={inputChange}
                                    value={inputs.app_id}
                                />
                                <FormFeedback>
                                    {validator.app_id.message}
                                </FormFeedback>
                            </Col>
                            <Col lg={4}>
                                <Label>Api Key</Label>
                                <Input
                                    invalid={validator.api_key.isInvalid}
                                    name="api_key"
                                    onChange={inputChange}
                                    value={inputs.api_key}
                                />
                                <FormFeedback>
                                    {validator.api_key.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col lg={4}>
                                <Label>Product Index</Label>
                                <Input
                                    invalid={validator.product_index.isInvalid}
                                    name="product_index"
                                    onChange={inputChange}
                                    value={inputs.product_index}
                                />
                                <FormFeedback>
                                    {validator.product_index.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col lg={4}>
                                <Label>Review Index</Label>
                                <Input
                                    invalid={validator.reviews_index.isInvalid}
                                    name="reviews_index"
                                    onChange={inputChange}
                                    value={inputs.reviews_index}
                                />
                                <FormFeedback>
                                    {validator.reviews_index.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col lg={4}>
                                <Label>Stores Index</Label>
                                <Input
                                    invalid={validator.stores_index.isInvalid}
                                    name="stores_index"
                                    onChange={inputChange}
                                    value={inputs.stores_index}
                                />
                                <FormFeedback>
                                    {validator.stores_index.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                    </Section>
                    <Section className="section-separator" />
                    <Section>
                        <Row>
                            <Col>
                                <SectionTitle md>API Key</SectionTitle>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Col lg={4}>
                                <Label>Host</Label>
                                <Input
                                    invalid={validator.host.isInvalid}
                                    name="host"
                                    onChange={inputChange}
                                    value={inputs.host}
                                />
                                <FormFeedback>
                                    {validator.host.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col lg={4}>
                                <Label>Store Id</Label>
                                <Input
                                    invalid={validator.store_id.isInvalid}
                                    name="store_id"
                                    onChange={inputChange}
                                    value={inputs.store_id}
                                />
                                <FormFeedback>
                                    {validator.store_id.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col>
                                <Label>Client Id</Label>
                                <Input
                                    invalid={validator.client_id.isInvalid}
                                    name="client_id"
                                    onChange={inputChange}
                                    value={inputs.client_id}
                                />
                                <FormFeedback>
                                    {validator.client_id.message}
                                </FormFeedback>
                            </Col>
                            <Col>
                                <Label>Client Secret</Label>
                                <Input
                                    invalid={validator.client_secret.isInvalid}
                                    name="client_secret"
                                    onChange={inputChange}
                                    value={inputs.client_secret}
                                />
                                <FormFeedback>
                                    {validator.client_secret.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col lg={6}>
                                <Label>Operation Client Id</Label>
                                <Input
                                    invalid={
                                        validator.operation_client_id.isInvalid
                                    }
                                    name="operation_client_id"
                                    onChange={inputChange}
                                    value={inputs.operation_client_id}
                                />
                                <FormFeedback>
                                    {validator.operation_client_id.message}
                                </FormFeedback>
                            </Col>
                            <Col lg={6}>
                                <Label>Operation Client Secret</Label>
                                <Input
                                    invalid={
                                        validator.operation_client_secret
                                            .isInvalid
                                    }
                                    name="operation_client_secret"
                                    onChange={inputChange}
                                    value={inputs.operation_client_secret}
                                />
                                <FormFeedback>
                                    {validator.operation_client_secret.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                    </Section>
                    <Section className="section-separator" />
                    <Section>
                        <Row>
                            <Col>
                                <SectionTitle md>User Auth</SectionTitle>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Label className="row-label">
                                Auto Migrate User
                            </Label>
                            <Col>
                                <span className="k-switch k-switch--md k-switch--icon">
                                    <label>
                                        <Input
                                            checked={
                                                inputs.auto_migrate_user
                                                    ? "checked"
                                                    : ""
                                            }
                                            name="auto_migrate_user"
                                            onChange={inputChange}
                                            type="checkbox"
                                        />
                                        <span />
                                    </label>
                                </span>
                            </Col>
                        </FormGroup>
                    </Section>
                </Form>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
        </Portlet>
    );
};

export default JaneForm;
