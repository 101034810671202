import React, { useState } from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import { navigation } from "../../../config/layout/header";
import { Medias } from "../../components/Medias";
import { Icon } from "@/components";

const HeaderOptions = () => {
    // Medias list
    const [isMediasOpen, setIsMediasOpen] = useState(false);

    const Element = ({ children, i, link }) => {
        const isRoute = typeof link.route === "string";

        const handleClick = e => {
            e.preventDefault();

            if (link.slug === "medias") {
                setIsMediasOpen(true);
            }
        };

        return (
            <Link
                className="k-header__topbar-wrapper"
                id={`header-option-${i}`}
                onClick={isRoute ? undefined : handleClick}
                to={isRoute ? link.route : "#"}
            >
                {children}
                {link.slug === "medias" && (
                    <Medias
                        isOpen={isMediasOpen}
                        toggle={() => setIsMediasOpen(false)}
                    />
                )}
            </Link>
        );
    };

    return navigation.map((l, i) => (
        <div className="k-header__topbar-item" key={`header-option-${i}`}>
            <Element i={i} link={l}>
                <span className="k-header__topbar-icon">
                    {/* <i className={l.icon} /> */}
                    <Icon color={l.color} icon={l.icon} />
                </span>
                <UncontrolledTooltip target={`header-option-${i}`}>
                    {l.label}
                </UncontrolledTooltip>
            </Element>
        </div>
    ));
};

export default HeaderOptions;
