import React, { useEffect } from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    FormFeedback,
    FormGroup,
    Label,
    Button,
} from "reactstrap";
import Input from "../../base/components/form/input/Input";
import useForm from "../../base/hooks/form/useForm";
import useValidator from "../../base/hooks/form/useValidator";
import { targetType } from "../../config/constant";
import Select from "../../base/components/form/select/Select";
import nanoid from "nanoid";
import _ from "lodash";

const NavigationItemForm = ({
    isOpen,
    toggle,
    item,
    items,
    isCreate,
    genericChange,
}) => {
    const { inputs, setInputs, inputChange, selectValue, selectChange } =
        useForm();

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "name",
                method: "isEmpty",
                validWhen: false,
                message: "Item name is required.",
            },
            {
                field: "url",
                method: "isEmpty",
                validWhen: false,
                message: "Url is required.",
            },
        ],
        { ...inputs },
    );

    useEffect(
        () => {
            if (isCreate) {
                clear();
            } else {
                setInputs({
                    name: item.name,
                    url: item.url,
                    target: item.target,
                });
            }
        },

        // eslint-disable-next-line
        [],
    );

    const clear = () =>
        setInputs({ name: "", url: "", target: targetType[0].value });

    const cancel = () => toggle(false);

    const createOrUpdate = () => {
        isSubmited(true);

        if (!isValid()) return;

        const { name, target, url } = inputs;

        let newMenuItems = _.cloneDeep(items || []);

        if (isCreate) {
            newMenuItems.push({
                id: nanoid(10),
                name,
                url,
                target,
                parent_menu_item_id: null,
                position: _.filter(items, i => i.parent_menu_item_id === null)
                    .length,
                __typename: "MenuItem",
            });
        } else {
            const realItem = _.find(newMenuItems, i => i.id === item.id);

            realItem.name = name;
            realItem.url = url;
            realItem.target = target;
        }

        genericChange(newMenuItems, "menu_items");

        toggle(false);

        isSubmited(false);

        isCreate && clear();
    };

    return (
        <Modal isOpen={isOpen} size="sm">
            <ModalHeader>{isCreate ? "Create" : "Update"} item</ModalHeader>
            <ModalBody>
                <FormGroup className="mb-2">
                    <Label>Name</Label>
                    <Input
                        invalid={validator.name.isInvalid}
                        name="name"
                        onChange={inputChange}
                        value={inputs.name || ""}
                    />
                    <FormFeedback>{validator.name.message}</FormFeedback>
                </FormGroup>
                <FormGroup className="mb-2">
                    <Label>Url</Label>
                    <Input
                        invalid={validator.url.isInvalid}
                        name="url"
                        onChange={inputChange}
                        value={inputs.url || ""}
                    />
                    <FormFeedback>{validator.url.message}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label>Target</Label>
                    <Select
                        controlName="target"
                        onChange={selectChange}
                        options={targetType}
                        value={selectValue(inputs.target, targetType)}
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={createOrUpdate}>
                    {isCreate ? "Create" : "Update"}
                </Button>
                <Button className="ml-3" color="metal" onClick={cancel}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default NavigationItemForm;
