import React, { useState } from "react";
import { Button } from "reactstrap";
import _ from "lodash";
import NavigationItemForm from "./NavigationItemForm";
import useNotification from "../../base/hooks/components/useNotification";
import useConfirm from "../../base/hooks/components/useConfirm";

const NavigationItem = ({
    item,
    items,
    handler,
    collapseIcon,
    genericChange,
}) => {
    const [isOpen, toggle] = useState(false);

    const notify = useNotification();

    const [confirm, launch] = useConfirm(() => {
        genericChange(
            _.filter(items, i => i.id !== item.id),
            "menu_items",
        );

        notify("Item removed successfully", "success");
    }, "Are you sure you want remove this item?");

    return (
        <>
            {handler}
            {collapseIcon}
            &nbsp;
            {item.name}
            <small className="mx-3 text-muted text-truncate">
                {item.url || "/"}
            </small>
            <div className="nestable-tools">
                <Button
                    className="btn-icon"
                    onClick={() => toggle(true)}
                    outline
                    size="sm"
                    title="Update"
                >
                    <i className="fas fa-pencil-alt" />
                </Button>
                <Button
                    className="btn-icon ml-3"
                    onClick={launch}
                    outline
                    size="sm"
                    title="Remove"
                >
                    <i className="fas fa-trash" />
                </Button>
            </div>
            <NavigationItemForm
                genericChange={genericChange}
                isOpen={isOpen}
                item={item}
                items={items}
                toggle={toggle}
            />
            {confirm}
        </>
    );
};

export default NavigationItem;
