import { useMutation } from "@apollo/react-hooks";

import {
    MUTATION_UPDATE_ORDER_LINE_ITEMS_BARCODES,
    QUERY_GET_ORDER,
} from "apollo/module-operations/order";
import { useMeta } from "@/hooks";
import { LineItem } from "@/types";

const useUpdateBarcodes = (entityId: string) => {
    const { user } = useMeta();

    const [updateOrderLineItemsBarCodes, { loading }] = useMutation(
        MUTATION_UPDATE_ORDER_LINE_ITEMS_BARCODES,
        {
            refetchQueries: [
                {
                    query: QUERY_GET_ORDER,
                    variables: {
                        account_id: user.accountId,
                        store_id: user.storeId,
                        entity_id: entityId,
                    },
                },
                "getOrder", // Query name
            ],
        },
    );

    const updateLineItemBarcodes = (orderId: string, lineItems: LineItem[]) => {
        updateOrderLineItemsBarCodes({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
                input: {
                    id: orderId,
                    line_items: lineItems.map(item => ({
                        id: item.id,
                        bar_codes: item.barcodes,
                        weight: item.variants[0]?.type,
                    })),
                },
            },
        });
    };

    return { updateLineItemBarcodes, isUpdating: loading };
};

export { useUpdateBarcodes };
