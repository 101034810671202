import React from "react";
import { Input } from "reactstrap";

const Switch = ({ className, style, inline, label, ...props }) => {
    const _label = (
        <div
            className={inline ? "d-inline-block align-top mr-2" : ""}
            style={{
                marginTop: inline ? 7 : 0,
            }}
        >
            {label}
        </div>
    );

    return (
        <div
            className={`k-switch k-switch--md k-switch--icon ${
                className || ""
            }`}
            style={style}
        >
            {inline && _label}
            <label>
                <Input {...props} type="checkbox" />
                <span />
            </label>
            {!inline && (
                <>
                    <br />
                    {_label}
                </>
            )}
        </div>
    );
};

export default Switch;
