import {
    Section,
    SectionTitle,
    Spinner,
    Table,
    Form,
    Select,
    Badge,
} from "@/components";
import { Row, Col, FormGroup, Label, Button, TabPane, Input } from "reactstrap";
import { useValidator, useForm } from "@/hooks";
import { VERIFICATION_TYPES } from "config/constant";
import { NOT_AVAILABLE } from "config/constant";
import { useEffect, useState } from "react";

const VerificationPane = ({
    tabId,
    inputs,
    inputChange,
    loading,
    loadingReset,
    statusBerbix,
    selectValue,
    selectChange,
    changeBerbixVerificationStatus,
}) => {
    const [status, setStatus] = useState("NOT AVAILABLE");
    const urlBerbixVerification = `https://mydive.idscan.net/Requests/Details/${inputs?.berbix_id}`;
    useEffect(() => {
        setStatus(
            VERIFICATION_TYPES.find(e => e.value === statusBerbix)?.label ||
                "NOT AVAILABLE",
        );
    }, [statusBerbix]);

    return (
        <TabPane className="tap__panel_verification" tabId={tabId}>
            <Section>
                <Row>
                    <Col>
                        <SectionTitle md>User Verifications</SectionTitle>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} style={{ maxWidth: "420px" }}>
                        <FormGroup className="mb-3">
                            <Label>Status</Label>
                            <Select
                                className="referral_selector"
                                controlName="berbix_verified"
                                onChange={selectChange}
                                options={VERIFICATION_TYPES}
                                value={selectValue(
                                    inputs.berbix_verified,
                                    VERIFICATION_TYPES,
                                )}
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Label>Notes</Label>
                            <Input
                                name="note"
                                onChange={inputChange}
                                placeholder="Write the note..."
                                rows={4}
                                type="textarea"
                                value={inputs?.note || ""}
                            />
                        </FormGroup>
                        <Row className="actions">
                            <Button
                                color="primary"
                                disabled={loadingReset}
                                onClick={changeBerbixVerificationStatus}
                            >
                                <Spinner show={loadingReset} sm />
                                Change verification status
                            </Button>
                            <a
                                className="btn btn-secondary"
                                href={urlBerbixVerification}
                                //href="https://dashboard.berbix.com/login"
                                rel="noreferrer"
                                role="button"
                                target="_blank"
                            >
                                Go to Id Scan System
                            </a>
                        </Row>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>ID Scan</Label>
                            <Row className="subrow__info">
                                <Label>Status</Label>{" "}
                                <Badge
                                    className={
                                        !statusBerbix
                                            ? "status__error badge_info"
                                            : "status__success badge_info"
                                    }
                                    label={status}
                                    md
                                />
                            </Row>
                            <Row className="subrow__info">
                                {" "}
                                <Label>Transaction ID</Label>{" "}
                                <Badge
                                    className={
                                        inputs.berbix_id
                                            ? "status__success badge_info"
                                            : "status__error badge_info"
                                    }
                                    label={inputs.berbix_id || NOT_AVAILABLE}
                                    md
                                />
                            </Row>
                            <Row className="subrow__info">
                                {" "}
                                <Label>Validation Error </Label>{" "}
                                <Badge
                                    className={
                                        inputs.berbix_error
                                            ? "status__error badge_info"
                                            : "status__metalic badge_info"
                                    }
                                    label={inputs.berbix_error || NOT_AVAILABLE}
                                    md
                                />
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>
            </Section>
        </TabPane>
    );
};

export default VerificationPane;
