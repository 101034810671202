import React from "react";
import classNames from "classnames";

const VerticalNav = ({ children, sm, ...props }) => {
    return (
        <ul
            {...props}
            className={classNames("k-nav", "k-nav--v3", {
                "k-nav--md-space": sm,
            })}
        >
            {children}
        </ul>
    );
};

export default VerticalNav;
