import { CreateAtDateFilter } from "./CreateAtDateFilter";
import { TotalPriceFilter } from "./TotalPriceFilter";
import { RefinementList } from "./RefinementList";
import { startCase } from "lodash";
import { DeliveryTimeFilter } from "./DeliveryTimeFilter";

const filterComponents = {
    date: () => <CreateAtDateFilter />,
    total_price: () => <TotalPriceFilter />,
    type: () => <RefinementList attribute="type" />,
    status: () => <RefinementList attribute="status" />,
    regions: () => <RefinementList attribute="city" type="checkbox" />,
    store: () => <RefinementList attribute="jane_store.id" type="checkbox" />,
    delivery_time: () => <DeliveryTimeFilter />,
    delivery_time_window: () => (
        <RefinementList attribute="delivery_time_window" type="checkbox" />
    ),
};

let defaultFilterOrder = [
    "date",
    "type",
    "delivery_time",
    "delivery_time_window",
    "status",
    "regions",
    "store",
    "total_price",
];

const Filters = () => {
    if (process.env.REACT_APP_ALGOLIA_ORDERS_FILTER_SORT) {
        defaultFilterOrder =
            process.env.REACT_APP_ALGOLIA_ORDERS_FILTER_SORT.split(",");
    }

    return (
        <div className="filters__options">
            <h2 className="filters__subtitle">Filtering options</h2>
            <div className="filters__container">
                {defaultFilterOrder.map((filter, key) => {
                    const Filter = filterComponents[filter];

                    return (
                        <div className="filters__body" key={`filter-${key}`}>
                            <h3>{startCase(filter)}</h3>
                            <Filter />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Filters;
