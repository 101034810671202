import gql from "graphql-tag";

let DeploymentStatus = {};
DeploymentStatus.fragments = {
    attributes: gql`
        fragment DeploymentStatusFragment on DeploymentStatus {
            status
            last_updated_since
        }
    `,
};

export const QUERY_CHECK_DEPLOYMENT_STATUS = gql`
    query checkDeploymentStatus($account_id: String!, $store_id: String!) {
        checkDeploymentStatus(account_id: $account_id, store_id: $store_id) {
            ...DeploymentStatusFragment
        }
    }
    ${DeploymentStatus.fragments.attributes}
`;
