export const containerSettings = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnVisibilityChange: true,
    draggable: true,
    pauseOnHover: true,
    style: {
        zIndex: 1051,
    },
};

export const toastSettings = {
    className: "rounded",
};
