import { useEffect } from "react";

const useFixedBodyOnPopup = (isPopupOpen: boolean) => {
    useEffect(() => {
        // When the popup is shown, we want a fixed body
        const root = document.getElementById("root");
        if (isPopupOpen) {
            root.style.height = "100vh";
            root.style.overflow = "hidden";
            return;
        }

        root.style.height = "auto";
        root.style.overflow = "unset";
    }, [isPopupOpen]);

    useEffect(() => {
        const root = document.getElementById("root");

        return () => {
            root.style.height = "auto";
            root.style.overflow = "unset";
        };
    });
};

export { useFixedBodyOnPopup };
