import React from "react";
import classNames from "classnames";

const Separator = ({ dashed, fit }) => {
    return (
        <div
            className={classNames(
                "k-separator",
                "k-separator--space-lg",
                `k-separator--border-${dashed ? "dashed" : "solid"}`,
                { "k-separator--portlet-fit": fit },
            )}
        />
    );
};

export default Separator;
