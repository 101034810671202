import { permission } from "../auth/permissions";

const routes = {
    DASHBOARD: {
        label: "General overview",
        route: "/",
        root: true,
    },

    // Business Accounts
    BUSINESS_ACCOUNTS: {
        label: "Accounts",
        route: "/business_accounts",
        permissions: permission.ACCOUNTS.read,
    },
    BUSINESS_ACCOUNT_CREATE: {
        label: "Create business account",
        route: "/business_account/create",
        breadcrumb: [
            {
                label: "Business Account list",
                route: "/business_accounts",
            },
        ],
    },
    BUSINESS_ACCOUNT_DETAIL: {
        label: "Update business account",
        route: "/business_account/detail/:id",
        breadcrumb: [
            {
                label: "Business account list",
                route: "/business_accounts",
            },
        ],
    },

    // Accounts
    ACCOUNTS: {
        label: "Accounts",
        route: "/accounts",
        permissions: permission.ACCOUNTS.read,
    },
    ACCOUNT_CREATE: {
        label: "Create account",
        route: "/account/create",
        breadcrumb: [
            {
                label: "Account list",
                route: "/accounts",
            },
        ],
    },
    ACCOUNT_DETAIL: {
        label: "Update account",
        route: "/account/detail/:id",
        breadcrumb: [
            {
                label: "Account list",
                route: "/accounts",
            },
        ],
    },

    // Apps
    APP_LIST: {
        label: "Apps",
        route: "/apps",
        permissions: permission.APPS.read,
    },
    APP_CREATE: {
        label: "Create app",
        route: "/app/create",
        breadcrumb: [
            {
                label: "App list",
                route: "/apps",
            },
        ],
    },
    APP_DETAIL: {
        label: "Update app",
        route: "/app/detail/:id",
        breadcrumb: [
            {
                label: "App list",
                route: "/apps",
            },
        ],
    },

    // Brand
    BRAND_LIST: {
        label: "Brands",
        route: "/brands",
    },
    BRAND_CREATE: {
        label: "Create brand",
        route: "/brand/create",
        breadcrumb: [
            {
                label: "Brands list",
                route: "/brands",
            },
        ],
    },
    BRAND_DETAIL: {
        label: "Update brand",
        route: "/brand/detail/:id",
        breadcrumb: [
            {
                label: "Brands list",
                route: "/brands",
            },
        ],
    },

    // Vendor
    VENDOR_LIST: {
        label: "Vendors",
        route: "/vendors",
    },

    // Order
    ORDER_LIST: {
        label: "Orders",
        route: "/orders",
    },
    ORDER_ABANDONED: {
        label: "Abandoned orders",
        route: "/orders_abandoned",
    },
    ORDER_CREATE: {
        label: "Create order",
        route: "/order/create",
        breadcrumb: [
            {
                label: "Orders list",
                route: "/orders",
            },
        ],
    },
    ORDER_DETAIL: {
        label: "Order details",
        route: "/order/detail/:id",
        breadcrumb: [
            {
                label: "Orders list",
                route: "/orders",
            },
        ],
    },
    ORDER_FULFILMENT: {
        label: "Fulfillment",
        route: "/order/fulfillment/:id",
        breadcrumb: [
            {
                label: "Orders list",
                route: "/orders",
            },
        ],
    },

    // Product
    PRODUCT_LIST: {
        label: "Products",
        route: "/products",
    },
    PRODUCT_BLAZE_INVENTORY: {
        label: "Blaze Inventory",
        route: "/blaze-inventory",
    },
    PRODUCT_CREATE: {
        label: "Create product",
        route: "/product/create",
        breadcrumb: [
            {
                label: "Product list",
                route: "/products",
            },
        ],
    },
    PRODUCT_DETAIL: {
        label: "Update product",
        route: "/product/detail/:id",
        breadcrumb: [
            {
                label: "Product list",
                route: "/products",
            },
        ],
    },

    // Category
    CATEGORY_LIST: {
        label: "Categories",
        route: "/categories",
    },
    CATEGORY_CREATE: {
        label: "Create category",
        route: "/category/create",
        breadcrumb: [
            {
                label: "Category list",
                route: "/categories",
            },
        ],
    },
    CATEGORY_DETAIL: {
        label: "Update category",
        route: "/category/detail/:id",
        breadcrumb: [
            {
                label: "Category list",
                route: "/categories",
            },
        ],
    },

    // Option
    OPTION_LIST: {
        label: "Options",
        route: "/options",
    },
    OPTION_CREATE: {
        label: "Create option",
        route: "/option/create",
        breadcrumb: [
            {
                label: "Options list",
                route: "/options",
            },
        ],
    },
    OPTION_DETAIL: {
        label: "Update variant",
        route: "/option/detail/:id",
        breadcrumb: [
            {
                label: "Option list",
                route: "/options",
            },
        ],
    },

    // Page
    PAGE_LIST: {
        label: "Pages",
        route: "/pages",
    },
    PAGE_CREATE: {
        label: "Create page",
        route: "/page/create",
        breadcrumb: [
            {
                label: "Page list",
                route: "/pages",
            },
        ],
    },
    PAGE_DETAIL: {
        label: "Update page",
        route: "/page/detail/:id",
        breadcrumb: [
            {
                label: "Page list",
                route: "/pages",
            },
        ],
    },

    // Landing pages
    LANDING_LIST: {
        label: "Landings",
        route: "/landings",
    },
    LANDING_CREATE: {
        label: "Create landing",
        route: "/landing/create",
        breadcrumb: [
            {
                label: "Landing list",
                route: "/landings",
            },
        ],
    },
    LANDING_DETAIL: {
        label: "Update landing",
        route: "/landing/detail/:id",
        breadcrumb: [
            {
                label: "Landing list",
                route: "/landings",
            },
        ],
    },

    // Navigation
    NAVIGATION_LIST: {
        label: "Navigations",
        route: "/navigations",
    },
    NAVIGATION_CREATE: {
        label: "Create navigation",
        route: "/navigation/create",
        breadcrumb: [
            {
                label: "Navigation list",
                route: "/navigations",
            },
        ],
    },
    NAVIGATION_DETAIL: {
        label: "Update navigation",
        route: "/navigation/detail/:id",
        breadcrumb: [
            {
                label: "Navigation list",
                route: "/navigations",
            },
        ],
    },

    // Promotion
    PROMOTION_LIST: {
        label: "Promotions",
        route: "/promotions",
    },
    PROMOTION_CREATE: {
        label: "Create promotion",
        route: "/promotion/create",
        breadcrumb: [
            {
                label: "Promotion list",
                route: "/promotions",
            },
        ],
    },
    PROMOTION_DETAIL: {
        label: "Update promotion",
        route: "/promotion/detail/:id",
        breadcrumb: [
            {
                label: "Promotion list",
                route: "/promotions",
            },
        ],
    },

    // Customer
    CUSTOMER_LIST: {
        label: "Customers",
        route: "/customers",
    },
    CUSTOMER_CREATE: {
        label: "Create customer",
        route: "/customer/create",
        breadcrumb: [
            {
                label: "Customer list",
                route: "/customers",
            },
        ],
    },
    CUSTOMER_DETAIL: {
        label: "Update customer",
        route: "/customer/detail/:id",
        breadcrumb: [
            {
                label: "Customer list",
                route: "/customers",
            },
        ],
    },

    //Logistics

    ONFLEET_LIST: {
        label: "OnFleet drivers",
        route: "/app/logistics/onfleet-drivers",
        handler: "onFleet",
    },

    SING_IN: {
        label: "Sign in",
        route: "/sign-in",
    },
    SING_OUT: {
        label: "Sign out",
        route: "/sign-out",
    },

    COMPANY_INFO: {
        label: "GapCommerce",
        route: "https://www.gapcommerce.com",
    },

    // APP_TERMS: {
    //     label: "Terms",
    //     route: "/terms",
    // },
    // APP_ABOUT_US: {
    //     label: "About us",
    //     route: "/about-us",
    // },
    // APP_CONTACT: {
    //     label: "Contact",
    //     route: "/contact",
    // },

    SETTINGS: {
        label: "Settings",
        route: "/settings",
    },

    NOTIFICATIONS: {
        label: "Notifications",
        route: "/notifications",
    },
    //TreezProducts
    TREEZ_PRODUCTS: {
        label: "Treez Products",
        route: "/treez-products",
        handler: "treez",
    },

    TREEZ_LIST_GROUPS: {
        label: "Treez Groups",
        route: "/treez-groups",
        handler: "treezGroups",
    },
    TREEZ_LIST_DISCOUTS: {
        label: "Treez Discounts",
        route: "/treez-discounts",
        handler: "treezDiscounts",
    },
};

export default routes;
