import gql from "graphql-tag";

let BusinessAccount = {};
BusinessAccount.fragments = {
    attributes: gql`
        fragment AttributesFragment on BusinessAccount {
            id
            company
            first_name
            last_name
            phone
            email
            signed
            status
            created_at
            updated_at
            address {
                address1
                address2
                city
                zip
                province
                province_code
                country
                country_code
            }
        }
    `,
};

export const QUERY_ACCOUNT_LIST = gql`
    query allBusinessAccounts {
        allBusinessAccounts {
            ...AttributesFragment
        }
    }
    ${BusinessAccount.fragments.attributes}
`;

export const QUERY_GET_ACCOUNT = gql`
    query getBusinessAccount($id: String!) {
        getBusinessAccount(id: $id) {
            ...AttributesFragment
        }
    }
    ${BusinessAccount.fragments.attributes}
`;

export const MUTATION_CREATE_ACCOUNT = gql`
    mutation createBusinessAccount($input: BusinessAccountInput!) {
        createBusinessAccount(input: $input) {
            ...AttributesFragment
        }
    }
    ${BusinessAccount.fragments.attributes}
`;

export const MUTATION_UPDATE_ACCOUNT = gql`
    mutation updateBusinessAccount($input: BusinessAccountInput!) {
        updateBusinessAccount(input: $input) {
            ...AttributesFragment
        }
    }
    ${BusinessAccount.fragments.attributes}
`;

export const MUTATION_REMOVE_ACCOUNT = gql`
    mutation removeBusinessAccount($id: String!) {
        removeBusinessAccount(id: $id)
    }
`;
