import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import Content from "../../base/layout/content/Content";
import { Portlet } from "../../base/components/portlet";
import useMeta from "../../base/hooks/useMeta.js";
import useTableActions from "../../base/hooks/table/useTableActions";
import useRowActions from "../../base/hooks/table/useRowActions";
import { QUERY_BRAND_LIST } from "../../apollo/module-operations/brand";
import paginationFactory from "react-bootstrap-table2-paginator";
import { paginationOptions } from "../../config/components/table";
import classNames from "classnames";
import FiltersToggle from "../../base/components/table/FiltersToggle";
import Filters from "../../base/components/table/Filters";
import Table from "../../base/components/table/Table";
import routes from "../../config/routes/routes";
import { FILTER_TYPES, Comparator } from "react-bootstrap-table2-filter";
import useFilter from "../../base/hooks/table/useFilter";
import _ from "lodash";

const Brands = () => {
    const { user } = useMeta();

    //---------------------------Defining filters-----------------------------
    const { TEXT, NUMBER } = FILTER_TYPES;

    const [showFilters, setShowFilters] = useState(false);

    const filterName = useFilter("name", TEXT, "Name", "");

    const filterSort = useFilter("sort", NUMBER, "Sort", {
        comparator: Comparator.GE,
    });

    const filters = [filterName, filterSort];
    // -----------------------------------------------------------------------

    const { loading, data, refetch, networkStatus } = useQuery(
        QUERY_BRAND_LIST,
        {
            skip: user.loading,
            notifyOnNetworkStatusChange: true,
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
            },
        },
    );

    const { refreshButton, addButton } = useTableActions(
        refetch,
        routes.BRAND_CREATE.route,
    );

    const { update } = useRowActions(
        [
            {
                query: QUERY_BRAND_LIST,
                skip: user.loading,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        "",
        routes.BRAND_DETAIL.route,
        "Brand",
    );

    const columns = [
        {
            dataField: "id",
            text: "Id",
            hidden: true,
        },
        {
            dataField: "name",
            text: "Name",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "120px" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                let brandImage = null;
                _.map(row.asset, function (value, key) {
                    if (key === "image") {
                        brandImage = value;
                    }
                });
                return (
                    <div className="k-user-card-v2">
                        <div className="k-user-card-v2__pic">
                            {brandImage && (
                                <img
                                    alt="Brand Image"
                                    className="k-marginless"
                                    src={brandImage}
                                    style={{ borderRadius: 5 }}
                                />
                            )}
                        </div>
                        <div className="k-user-card-v2__details">
                            <span className="k-user-card-v2__name">
                                {_.capitalize(row.name)}
                            </span>
                        </div>
                    </div>
                );
            },
        },
        {
            dataField: "phone_number",
            text: "Phone",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "100px" };
            },
        },
        {
            dataField: "website",
            text: "Website",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "100px" };
            },
        },
        {
            dataField: "enabled",
            text: "Is Active",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "80px" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <span
                        className={classNames(
                            "badge badge-pill",
                            `badge-${cell ? "success" : "secondary"}`,
                        )}
                    >
                        {cell ? "Yes" : "No"}
                    </span>
                );
            },
        },
    ];

    const actions = [
        {
            label: "Update",
            icon: "fas fa-pencil-alt",
            action: (cell, row, rowIndex, formatExtraData) => {
                update(row.id);
            },
        },
    ];

    const _loading = loading || networkStatus === 4;

    const _data = data && data.listBrands;

    return (
        <>
            <Content>
                <Portlet
                    header={{
                        title: "Brands",
                        subtitle: "All brands availables in the system",
                    }}
                    sticky
                    toolbar={
                        <>
                            <FiltersToggle setShowFilters={setShowFilters} />
                            {refreshButton}
                        </>
                    }
                >
                    <Filters filters={filters} />
                    <Table
                        actions={actions}
                        columns={columns}
                        data={_data}
                        filters={filters}
                        keyField="id"
                        loading={_loading}
                        pagination={paginationFactory(paginationOptions)}
                        showFilters={showFilters}
                    />
                </Portlet>
            </Content>
        </>
    );
};

export default Brands;
