import React, { useState } from "react";
import { Row, Col, FormGroup, Label, FormFeedback, Button } from "reactstrap";
import { useQuery } from "@apollo/react-hooks";
import Content from "../../base/layout/content/Content";
import { Portlet, Section, SectionTitle } from "../../base/components/portlet";
import Form from "../../base/components/form/Form";
import Input from "../../base/components/form/input/Input";
import Spinner from "../../base/components/spinner/Spinner";
import useMeta from "../../base/hooks/useMeta";
import useForm from "../../base/hooks/form/useForm";
import useValidator from "../../base/hooks/form/useValidator";
import useFormActions from "../../base/hooks/form/useFormActions";
import {
    MUTATION_UPDATE_CATEGORY,
    QUERY_CATEGORY_LIST,
    QUERY_GET_CATEGORY,
} from "../../apollo/module-operations/category";
import { useHistory } from "react-router-dom";

import { QUERY_PRODUCT_LIST } from "../../apollo/module-operations/product";

import useRowActions from "../../base/hooks/table/useRowActions";
import routes from "../../config/routes/routes";
import MediaInput from "../../base/components/Medias";
import _ from "lodash";
//import CategoryDropzone from "./CategoryDropzone";

const CategoryForm = () => {
    const { resourceId, isCreate, user } = useMeta();
    const [loading, setLoading] = useState(!isCreate);

    const history = useHistory();

    const { inputs, setInputs, inputChange } = useForm();

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "name",
                method: "isEmpty",
                validWhen: false,
                message: "This name is required.",
            },
            {
                field: "description",
                method: "isEmpty",
                validWhen: false,
                message: "This description is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    useQuery(QUERY_GET_CATEGORY, {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            category_id: resourceId,
        },
        skip: user.loading || !loading,
        onCompleted: resp => {
            let input = _.cloneDeep(resp.getCategory);

            setInputs(input);
            setLoading(false);
        },
    });

    let options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: {
                ...inputs,
            },
        },
    };

    const { createOrUpdateButton } = useFormActions(
        "id",
        "Category",
        "",
        MUTATION_UPDATE_CATEGORY,
        [
            {
                query: QUERY_CATEGORY_LIST,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        routes.CATEGORY_DETAIL.route,
        isValid,
        isSubmited,
    );

    return (
        <>
            <Content>
                <Portlet
                    header={{
                        title: "Form",
                        subtitle: "Provide all required information.",
                    }}
                    sticky
                    toolbar={[createOrUpdateButton]}
                >
                    {!loading ? (
                        <Form centered>
                            <Section>
                                <Row>
                                    <Label lg={4} xl={3} />
                                    <Col>
                                        <SectionTitle md>
                                            Information
                                        </SectionTitle>
                                    </Col>
                                </Row>
                                <FormGroup row>
                                    <Label lg={4} xl={3}>
                                        Enable
                                    </Label>
                                    <Col>
                                        <span className="k-switch k-switch--md k-switch--icon">
                                            <label>
                                                <Input
                                                    checked={
                                                        inputs.enabled
                                                            ? "checked"
                                                            : ""
                                                    }
                                                    disabled
                                                    name="enabled"
                                                    onChange={inputChange}
                                                    type="checkbox"
                                                />
                                                <span />
                                            </label>
                                        </span>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label lg={4} xl={3}>
                                        Is Cannabis
                                    </Label>
                                    <Col>
                                        <span className="k-switch k-switch--md k-switch--icon">
                                            <label>
                                                <Input
                                                    checked={
                                                        inputs.is_cannabis
                                                            ? "checked"
                                                            : ""
                                                    }
                                                    disabled
                                                    name="enabled"
                                                    onChange={inputChange}
                                                    type="checkbox"
                                                />
                                                <span />
                                            </label>
                                        </span>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label lg={4} xl={3}>
                                        Name *
                                    </Label>
                                    <Col>
                                        <Input
                                            defaultValue={
                                                inputs.name ? inputs.name : ""
                                            }
                                            invalid={validator.name.isInvalid}
                                            name="name"
                                            onChange={inputChange}
                                        />
                                        <FormFeedback>
                                            {validator.name.message}
                                        </FormFeedback>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label lg={4} xl={3}>
                                        Image
                                    </Label>
                                    <Col>
                                        <MediaInput
                                            name="asset.image"
                                            onChange={inputChange}
                                            typeInput="highlight"
                                            value={inputs.asset.image ?? ""}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label lg={4} xl={3}>
                                        Description *
                                    </Label>
                                    <Col>
                                        <Input
                                            defaultValue={
                                                inputs.description
                                                    ? inputs.description
                                                    : ""
                                            }
                                            invalid={
                                                validator.description.isInvalid
                                            }
                                            name="description"
                                            onChange={inputChange}
                                            rows="4"
                                            type="textarea"
                                        />
                                        <FormFeedback>
                                            {validator.description.message}
                                        </FormFeedback>
                                    </Col>
                                </FormGroup>
                            </Section>
                        </Form>
                    ) : (
                        <>
                            <Spinner color="metal" show sm />
                            &nbsp;&nbsp;Loading
                        </>
                    )}
                </Portlet>
            </Content>
        </>
    );
};

export default CategoryForm;
