import React from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { Portlet, Section, SectionTitle } from "../../base/components/portlet";
import { FormGroup, Col, Label, Row, Button, FormFeedback } from "reactstrap";
import Input from "../../base/components/form/input/Input";
import Form from "../../base/components/form/Form";
import useMeta from "../../base/hooks/useMeta";
import useForm from "../../base/hooks/form/useForm";
import useFormActions from "../../base/hooks/form/useFormActions";
import {
    MUTATION_UPDATE_BLAZE_APP,
    QUERY_LIST_STORE_APPS,
} from "../../apollo/module-operations/app";
import { MUTATION_SYNC_FROM_BLAZE } from "../../apollo/module-operations/blaze";
import { useMutation } from "@apollo/react-hooks";
import useValidator from "../../base/hooks/form/useValidator";
import useNotification from "../../base/hooks/components/useNotification";
import _ from "lodash";

const BlazeForm = ({ app }) => {
    const notify = useNotification();

    const { user } = useMeta();
    const { inputs, setInputs, inputChange } = useForm();

    if (!_.get(inputs, "id", null)) {
        setInputs(app);
    }

    let options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: {
                ...inputs,
                id: inputs.id,
                sandbox: inputs.sandbox,
                auth_key: inputs.auth_key,
                dev_auth_key: inputs.dev_auth_key,
                partner_key: inputs.partner_key,
                dev_partner_key: inputs.dev_partner_key,
                type: "Blaze",
            },
        },
    };

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "id",
                method: "isEmpty",
                validWhen: false,
                message: "This app id is required.",
            },

            {
                field: "auth_key",
                method: "isEmpty",
                validWhen: false,
                message: "This auth key is required.",
            },

            {
                field: "dev_auth_key",
                method: "isEmpty",
                validWhen: false,
                message: "This dev auth key id is required.",
            },
            {
                field: "partner_key",
                method: "isEmpty",
                validWhen: false,
                message: "This partner key id is required.",
            },
            {
                field: "dev_partner_key",
                method: "isEmpty",
                validWhen: false,
                message: "This dev partner key id is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    const { createOrUpdateButton } = useFormActions(
        "id",
        "Blaze",
        "",
        MUTATION_UPDATE_BLAZE_APP,
        [
            {
                query: QUERY_LIST_STORE_APPS,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        "",
        isValid,
        isSubmited,
        "primary",
    );

    const [mutateSyncFromBlaze, { loading: syncLoading }] = useMutation(
        MUTATION_SYNC_FROM_BLAZE,
        {
            onCompleted: resp => {
                //toggle(false);

                notify("Sync Product From Blaze Successfully", "success");
            },
            onError: () => {
                notify("Error Trying to Sync Product", "error");
            },
        },
    );

    const syncProducstFromBlaze = async () => {
        mutateSyncFromBlaze({
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
            },
        });
    };

    return (
        <Portlet
            centered
            header={{
                title: "Blaze",
                subtitle: "Blaze Information",
            }}
            sticky
            toolbar={
                <>
                    <Button
                        className="ml-3"
                        color="metal"
                        onClick={syncProducstFromBlaze}
                    >
                        {syncLoading ? (
                            <Spinner show={true} sm />
                        ) : (
                            <i className={`fas fa-sync-alt`} />
                        )}
                        Sync Products
                    </Button>
                    {createOrUpdateButton}
                </>
            }
        >
            {!user.loading ? (
                <Form centered>
                    <Section>
                        <Row>
                            <Label lg={4} xl={3} />
                            <Col>
                                <SectionTitle md>Information</SectionTitle>
                            </Col>
                        </Row>

                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Sandbox
                            </Label>
                            <Col>
                                <span className="k-switch k-switch--md k-switch--icon">
                                    <label>
                                        <Input
                                            checked={
                                                inputs.sandbox ? "checked" : ""
                                            }
                                            name="sandbox"
                                            onChange={inputChange}
                                            type="checkbox"
                                        />
                                        <span />
                                    </label>
                                </span>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Auth Key
                            </Label>
                            <Col>
                                <Input
                                    invalid={validator.auth_key.isInvalid}
                                    name="auth_key"
                                    onChange={inputChange}
                                    value={inputs.auth_key}
                                />
                                <FormFeedback>
                                    {validator.auth_key.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Dev auth key
                            </Label>
                            <Col>
                                <Input
                                    invalid={validator.dev_auth_key.isInvalid}
                                    name="dev_auth_key"
                                    onChange={inputChange}
                                    value={inputs.dev_auth_key}
                                />
                                <FormFeedback>
                                    {validator.dev_auth_key.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Parther key
                            </Label>
                            <Col>
                                <Input
                                    invalid={validator.partner_key.isInvalid}
                                    name="partner_key"
                                    onChange={inputChange}
                                    value={inputs.partner_key}
                                />
                                <FormFeedback>
                                    {validator.partner_key.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Dev Parther key
                            </Label>
                            <Col>
                                <Input
                                    invalid={
                                        validator.dev_partner_key.isInvalid
                                    }
                                    name="dev_partner_key"
                                    onChange={inputChange}
                                    value={inputs.dev_partner_key}
                                />
                                <FormFeedback>
                                    {validator.dev_partner_key.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                    </Section>
                </Form>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
        </Portlet>
    );
};

export default BlazeForm;
