import { useQuery } from "@apollo/react-hooks";

import { Store } from "@/types";
import { useMeta } from "@/hooks";
import { QUERY_GET_STORE } from "apollo/module-operations/store";
import { useState } from "react";

const useStore = (): { store: Store; loading: boolean } => {
    const { user } = useMeta();
    const [store, setStore] = useState<Store>();

    const { loading } = useQuery(QUERY_GET_STORE, {
        skip: user.loading,
        fetchPolicy: "network-only",
        variables: {
            account_id: user?.accountId,
            store_id: user?.storeId,
        },
        onCompleted: data => {
            setStore(data?.getStore);
        },
    });

    return { store, loading };
};

export { useStore };
