import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
    Col,
    Row,
    FormFeedback,
    FormGroup,
    Label,
    Button,
    Tooltip,
    InputGroupText,
} from "reactstrap";
import {
    GoogleMap,
    Polygon,
    Circle,
    LoadScriptNext,
    Rectangle,
    Marker,
} from "@react-google-maps/api";
import { useQuery } from "@apollo/react-hooks";
import { QUERY_TREEZ_HEADLESS_CONFIG } from "../../apollo/module-operations/treez";
import { GEO_TYPE } from "../../config/constant";
import { useForm, useValidator } from "@/hooks";

import {
    Form,
    Icon,
    Input,
    Section,
    SectionTitle,
    Select,
    Spinner,
    Tabs,
} from "@/components";
import GoogleMapComponent from "./GoogleMapComponent";
import { useMeta } from "@/hooks";
import { currencyMask } from "utils/maskInput";
import WorkingHour from "base/components/workingHour/WorkingHour";
import { DAYSOFWEEK } from "config/constant";
import { toCurrency, currencyToStg } from "../../utils/utils";

const ScheduleForm = ({
    isCreate,
    handleCancel,
    schedules,
    selectSchedule,
    isUpdating,
    handlerUpdatingOrCreate,
    context,
    stores,
    defaultCoordinates,
    googleMapKey,
}) => {
    const { user } = useMeta();
    const [modal, setModal] = useState(false);
    const [isFormReset, resetFormInputs] = useState(isCreate);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [inventories, setInventories] = useState([]);
    const [storeCoordinates, setStoreCoordinates] = useState(null);
    const { inputs, inputChange, selectChange, selectValue, setInputs } =
        useForm();

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "name",
                method: "isEmpty",
                validWhen: false,
                message: "The Name is required.",
            },
            {
                field: "stores_provider_id",
                method: "isEmpty",
                validWhen: false,
                message: "The Store provider id is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    const {
        validator: pickupValidator,
        isValid: isValidPickup,
        isSubmited: isSubmitPickup,
    } = useValidator(
        [
            {
                field: "name",
                method: "isEmpty",
                validWhen: false,
                message: "The Name is required.",
            },
            {
                field: "stores_provider_id",
                method: "isEmpty",
                validWhen: false,
                message: "The Store provider id is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    const toggle = () => {
        setTooltipOpen(!tooltipOpen);
    };

    useEffect(() => {
        if (!isCreate) {
            const objSelect = schedules[selectSchedule];
            const expressObject =
                context === "Delivery" ? objSelect.express_menu_config : [];
            const inventoriesIds =
                expressObject?.inventory_provider_ids?.split(",") ?? [];
            setInputs(
                context === "Delivery"
                    ? {
                          active: expressObject?.active ?? false,
                          active_by_default:
                              expressObject?.active_by_default ?? false,
                          start: expressObject?.start ?? "",
                          end: expressObject?.end ?? "",
                          expressName: expressObject?.name ?? "",
                          expressNote: expressObject?.name ?? "",
                          inventory_provider_ids: inventoriesIds,

                          delivery_fee: currencyToStg(objSelect.delivery_fee),
                          free_after: currencyToStg(objSelect.free_after),
                          min: currencyToStg(objSelect.min),

                          name: objSelect.name ?? "",
                          note: objSelect.note ?? "",
                          stores_provider_id: objSelect.stores_provider_id,
                          time_slots: objSelect.time_slots,
                          working_hours: objSelect.working_hours,
                          geo_type: objSelect.geo_type ?? "ZIP",
                          zips: objSelect.zips ?? "",
                          radius: objSelect.radius ?? 1,
                          polygon_geo_zone: objSelect.polygon_geo_zone ?? "",
                      }
                    : {
                          name: objSelect.name ?? "",
                          note: objSelect.note ?? "",
                          stores_provider_id: objSelect.stores_provider_id,
                          time_slots: objSelect.time_slots,
                          working_hours: objSelect.working_hours,
                      },
            );
        }

        if (isCreate && context === "Delivery") {
            setInputs({
                ...inputs,
                geo_type: "ZIP",
                zips: "",
                radius: 1,
                polygon_geo_zone: "",
            });
        }

        setStoreCoordinates(defaultCoordinates);
    }, [schedules]);

    let options = {
        account_id: user.accountId,
        store_id: user.storeId,
        provider_id: inputs.stores_provider_id ?? stores?.[0]?.value,
        customerType: "ALL",
        strictMedicalCustomerType: false,
    };

    const { loading: loadingInventories, refetch } = useQuery(
        QUERY_TREEZ_HEADLESS_CONFIG,
        {
            variables: options,
            skip:
                user.loading ||
                (!inputs && !inputs.stores_provider_id) ||
                stores.length === 0,
            onCompleted: resp => {
                const dataStore = resp?.getTreezHeadlessConfig;
                setInventories(
                    dataStore?.locations?.map(loc => ({
                        label: loc.name,
                        value: loc.id,
                        type: loc.inventory_type,
                    })),
                );
                setStoreCoordinates({
                    lat: Number(dataStore?.address?.latitude),
                    lng: Number(dataStore?.address?.longitude),
                });
            },
            onError: () => {
                setInputs({ ...inputs, inventories: [] });
            },
        },
    );

    if (!isCreate && !isFormReset) {
        resetFormInputs(true);
    }

    const removeAllSpaces = str => {
        if (!str) {
            return "";
        }

        //return str.replace(/\s+/g, "") // Remove all spaces from the string.;
        return str
            .split(",")
            .map(item => item.trim()) // Remove spaces at the beginning and end of each element.
            .join(",");
    };

    const handleSelectChange = option => {
        if (inputs?.stores_provider_id !== option.value) {
            if (context === "Delivery") {
                setInputs({
                    ...inputs,
                    stores_provider_id: option.value,
                    geo_type: "ZIP",
                    zips: "",
                    radius: 1,
                    polygon_geo_zone: "",
                    inventory_provider_ids: [],
                });

                refetch();
            }

            if (context === "Pickup") {
                setInputs({
                    ...inputs,
                    stores_provider_id: option.value,
                });
            }
        }
    };

    const sortTimeSlots = timeSlots => {
        const dayOfWeekMap = {
            MONDAY: 0,
            TUESDAY: 1,
            WEDNESDAY: 2,
            THURSDAY: 3,
            FRIDAY: 4,
            SATURDAY: 5,
            SUNDAY: 6,
        };

        return timeSlots?.sort((a, b) => {
            const dayA = dayOfWeekMap[a.day_of_the_week];
            const dayB = dayOfWeekMap[b.day_of_the_week];

            if (dayA === dayB) {
                return a.start.localeCompare(b.start);
            }

            return dayA - dayB;
        });
    };

    const createOrUpdate = async () => {
        if (isSubmited) {
            isSubmited(true);
        }

        if (isSubmitPickup) {
            isSubmitPickup(true);
        }

        const isValidDeliveryFrom = isValid() && context === "Delivery";
        const isValidPickupForm = isValidPickup() && context !== "Delivery";

        if (isValidDeliveryFrom || isValidPickupForm) {
            let newSchedule = {
                name: inputs.name,
                note: inputs.note,
                stores_provider_id: inputs.stores_provider_id,
                time_slots: sortTimeSlots(inputs.time_slots),
                working_hours: inputs.working_hours,
            };

            if (context === "Delivery") {
                let inventoriesIds = inputs.inventory_provider_ids;
                if (
                    inputs.inventory_provider_ids &&
                    typeof inputs.inventory_provider_ids !== "string"
                ) {
                    inventoriesIds = inputs.inventory_provider_ids.join(", ");
                }

                inventoriesIds = removeAllSpaces(inventoriesIds);

                const zips = removeAllSpaces(inputs.zips);

                newSchedule = {
                    ...newSchedule,
                    free_after: toCurrency(inputs.free_after),
                    delivery_fee: toCurrency(inputs.delivery_fee),
                    min: toCurrency(inputs.min),
                    express_menu_config: {
                        inventory_provider_ids: inventoriesIds,
                        name: inputs.expressName,
                        note: inputs.expressNote,
                        active: inputs.active,
                        active_by_default: inputs.active_by_default,
                        start: inputs.start,
                        end: inputs.end,
                    },
                    geo_type: inputs.geo_type,
                    radius: inputs.radius,
                    polygon_geo_zone: inputs.polygon_geo_zone,
                    zips: zips,
                };
            }

            let updateSchedules = _.cloneDeep(schedules ?? []);

            if (isCreate) {
                updateSchedules.push(newSchedule);
            } else {
                updateSchedules[selectSchedule] = newSchedule;
            }

            handlerUpdatingOrCreate(updateSchedules);
        }
    };

    const getDayAndMonth = dayOfWeek => {
        const dayIndex = DAYSOFWEEK.indexOf(dayOfWeek.toUpperCase());

        if (dayIndex === -1) {
            throw new Error("Invalid day of the week");
        }

        const today = new Date();

        const currentDayIndex = today.getDay();
        const daysSinceMonday = (currentDayIndex + 6) % 7;

        const mondayOfThisWeek = new Date(today);
        mondayOfThisWeek.setDate(today.getDate() - daysSinceMonday);

        const targetDate = new Date(mondayOfThisWeek);
        targetDate.setDate(mondayOfThisWeek.getDate() + dayIndex);

        const day = targetDate.getDate();
        const month = targetDate.getMonth() + 1;

        return { day, month };
    };

    const handleClose = () => {
        handleCancel();
        resetFormInputs(false);
    };

    const getTitle = () => {
        const titleContext =
            context === "Delivery" ? "Delivery Zone" : "Pickup Schedule";

        if (isCreate) {
            return `Add ${titleContext}`;
        }

        return `Update ${titleContext}`;
    };

    const renderDaysOfWeekTabs = () => {
        const tabs = DAYSOFWEEK.map(day => {
            const date = getDayAndMonth(day);
            return {
                title: day,
                date: `${date.month}/${date.day}`,
                content: (
                    <WorkingHour
                        day={day}
                        inputs={inputs}
                        key={day}
                        setInputs={setInputs}
                    />
                ),
            };
        });

        return tabs;
    };

    const handleInputChange = (e, newValue) => {
        if (!newValue) {
            const updatedTimeSlots = [
                ...(inputs.time_slots || []).map(slot => ({
                    ...slot,
                    is_express: false,
                })),
            ];

            setInputs({
                ...inputs,
                active: false,
                time_slots: updatedTimeSlots,
            });
            return;
        }

        setInputs({ ...inputs, active: newValue });
        return;
    };

    const getValidator = context => {
        if (context === "Delivery") {
            return validator;
        }

        return pickupValidator;
    };

    const isShowElemnt = context === "Delivery";

    const toggleModal = () => {
        setModal(!modal);
    };

    const handleSelectGeofence = geoJsonData => {
        setInputs({ ...inputs, polygon_geo_zone: JSON.stringify(geoJsonData) });
        toggleModal();
    };

    // On change store logic
    const onChangeStore = options => {
        const optionsSelect = options?.map(op => op.value);
        setInputs({ ...inputs, inventory_provider_ids: optionsSelect });
    };

    const getRenderValues = idsList => {
        if (!inventories) {
            return idsList;
        }

        if (!idsList) {
            return [];
        }

        return inventories?.filter(op =>
            idsList?.some(id => op.value?.includes(id)),
        );
    };

    const getAreaSelected = () => {
        let distance = 0;
        if (inputs?.radius) {
            distance = inputs?.radius * 1609.34;
        }

        return {
            center: { lat: storeCoordinates?.lat, lng: storeCoordinates?.lng },
            radius: distance,
            type: "circle",
        };
    };

    const GoogleMapPreview = ({ selectedArea }) => {
        const containerStyle = {
            width: "100%",
            height: "168px",
        };

        const optionStyle = {
            fillColor: "lightblack",
            fillOpacity: 0.4,
            strokeColor: "black",
            strokeOpacity: 1.0,
            strokeWeight: 2,
        };

        const defaultUbication = {
            lat: storeCoordinates?.lat ?? 36.7783,
            lng: storeCoordinates?.lng ?? -119.4179,
        };

        const parseArea =
            typeof selectedArea === "string" && selectedArea !== ""
                ? JSON.parse(selectedArea)
                : selectedArea;
        const type = parseArea?.type ?? "";
        let shape = null;
        let centerPoint = null;
        let radius = 1000;

        if (type === "circle") {
            centerPoint = {
                lat: parseArea?.center?.lat,
                lng: parseArea?.center?.lng,
            };
            radius = parseArea?.radius;
        } else if (type === "rectangle") {
            const bounds = {
                north: parseArea?.north,
                south: parseArea?.south,
                east: parseArea?.east,
                west: parseArea?.west,
            };
            shape = bounds;
        } else {
            shape = parseArea;
        }

        const centerMap =
            type === "circle"
                ? centerPoint
                : type === "rectangle"
                ? {
                      lat: (parseArea?.south + parseArea?.north) / 2,
                      lng: (parseArea?.west + parseArea?.east) / 2,
                  }
                : shape?.[0];

        const center = {
            lat: centerMap?.lat ?? defaultUbication?.lat,
            lng: centerMap?.lng ?? defaultUbication?.lng,
        };

        const markerOptions = {
            position: {
                lat: defaultUbication?.lat,
                lng: defaultUbication?.lng,
            },
        };

        const renderShape = () => {
            if (type === "rectangle" && shape) {
                return <Rectangle bounds={shape} options={optionStyle} />;
            } else if (type === "circle" && centerPoint) {
                return (
                    <Circle
                        center={centerPoint}
                        options={optionStyle}
                        radius={radius}
                    />
                );
            } else if (shape) {
                return <Polygon options={optionStyle} paths={shape} />;
            }
            return null;
        };

        return (
            <GoogleMap
                center={center}
                mapContainerStyle={containerStyle}
                zoom={9}
            >
                {renderShape()}
                <Marker {...markerOptions} />
            </GoogleMap>
        );
    };

    return (
        <Form className="mb-4">
            <LoadScriptNext
                googleMapsApiKey={googleMapKey}
                libraries={["drawing"]}
            >
                <Section className="p-4 mb-2">
                    <Row>
                        <Col>
                            <SectionTitle md>{getTitle()}</SectionTitle>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <SectionTitle md>Informations</SectionTitle>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4} md={6}>
                            <FormGroup>
                                <Label>Name</Label>
                                <Input
                                    invalid={
                                        getValidator(context).name.isInvalid
                                    }
                                    name="name"
                                    onChange={inputChange}
                                    placeholder="Name"
                                    value={inputs.name}
                                />
                                <FormFeedback>
                                    {getValidator(context).name.message}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col lg={4} md={6}>
                            <FormGroup>
                                <Label>Store Provider Id</Label>
                                <Select
                                    controlName="stores_provider_id"
                                    invalid={
                                        getValidator(context).stores_provider_id
                                            .isInvalid
                                    }
                                    onChange={handleSelectChange}
                                    options={stores}
                                    value={selectValue(
                                        inputs.stores_provider_id,
                                        stores,
                                    )}
                                />
                                <FormFeedback>
                                    {
                                        getValidator(context).stores_provider_id
                                            .message
                                    }
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        {isShowElemnt && (
                            <>
                                <Col lg={4} md={6}>
                                    <FormGroup>
                                        <Label>Geo Type</Label>
                                        <div
                                            style={{
                                                marginBottom: "12px",
                                            }}
                                        >
                                            <Select
                                                controlName="geo_type"
                                                onChange={selectChange}
                                                options={GEO_TYPE}
                                                value={selectValue(
                                                    inputs.geo_type,
                                                    GEO_TYPE,
                                                )}
                                            />
                                        </div>
                                        {inputs?.geo_type === "RADIUS" && (
                                            <>
                                                <Label>Distance</Label>
                                                <Input
                                                    min={1}
                                                    name="radius"
                                                    onChange={inputChange}
                                                    placeholder="Maximum distance for delivery..."
                                                    prepend={
                                                        <InputGroupText>
                                                            Mi
                                                        </InputGroupText>
                                                    }
                                                    type="number"
                                                    value={inputs.radius ?? 1}
                                                />
                                            </>
                                        )}
                                        {inputs?.geo_type === "GEOFENCE" && (
                                            <>
                                                <Label>Selected area</Label>
                                                <Input
                                                    className={
                                                        "input__geo_selected_area"
                                                    }
                                                    name="polygon_geo_zone"
                                                    onChange={inputChange}
                                                    placeholder="Write the poligon geo zone, separated by commas..."
                                                    rows={6}
                                                    style={{
                                                        height: "158px",
                                                    }}
                                                    type="textarea"
                                                    value={
                                                        inputs.polygon_geo_zone
                                                    }
                                                />
                                                <GoogleMapComponent
                                                    coordinates={
                                                        storeCoordinates
                                                    }
                                                    isOpen={modal}
                                                    key={googleMapKey}
                                                    onSelect={
                                                        handleSelectGeofence
                                                    }
                                                    selectedShapeGeoJson={
                                                        inputs.polygon_geo_zone ??
                                                        null
                                                    }
                                                    toggleModal={toggleModal}
                                                />
                                            </>
                                        )}
                                    </FormGroup>
                                </Col>
                                {inputs?.geo_type !== "ZIP" && (
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="geoReference">
                                                GEO REFERENCE
                                            </Label>
                                            <span className="message__geo_referent">
                                                *The store address is being
                                                taken as center of the area
                                            </span>
                                            <div
                                                style={{
                                                    position: "relative",
                                                    width: "100%",
                                                    height: "168px",
                                                }}
                                            >
                                                <GoogleMapPreview
                                                    coordinate={
                                                        storeCoordinates
                                                    }
                                                    key={googleMapKey}
                                                    selectedArea={
                                                        inputs?.geo_type ===
                                                        "RADIUS"
                                                            ? getAreaSelected()
                                                            : inputs?.polygon_geo_zone
                                                    }
                                                />
                                                <FormFeedback
                                                    style={{
                                                        display: !googleMapKey
                                                            ? "block"
                                                            : "none",
                                                    }}
                                                >
                                                    {
                                                        "Google Map URL not configured"
                                                    }
                                                </FormFeedback>
                                            </div>
                                            {inputs?.geo_type ===
                                                "GEOFENCE" && (
                                                <Button
                                                    className="btn__geo_select mt-2"
                                                    color="primary"
                                                    disabled={!googleMapKey}
                                                    onClick={toggleModal}
                                                >
                                                    <i className="fas fa-pencil-alt" />
                                                    Update
                                                </Button>
                                            )}
                                        </FormGroup>
                                    </Col>
                                )}
                                {inputs?.geo_type === "ZIP" && (
                                    <Col lg={4} md={6}>
                                        <FormGroup>
                                            <Label>Zips</Label>
                                            <Input
                                                name="zips"
                                                onChange={inputChange}
                                                placeholder="Write the zips, separated by commas..."
                                                rows={2}
                                                style={{
                                                    height: "74px",
                                                }}
                                                type="textarea"
                                                value={inputs.zips}
                                            />
                                        </FormGroup>
                                    </Col>
                                )}
                            </>
                        )}
                    </Row>
                    <Row>
                        <Col lg={4} md={6}>
                            <FormGroup>
                                <Label>Note</Label>
                                <Input
                                    name="note"
                                    onChange={inputChange}
                                    placeholder="Write the notes..."
                                    rows={5}
                                    type="textarea"
                                    value={inputs.note}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Section>
                <Section className="schedule_form__separator" />
                {isShowElemnt && (
                    <>
                        <Section className="p-4 mb-2">
                            <Row>
                                <Col>
                                    <SectionTitle md>Fees</SectionTitle>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3} md={4}>
                                    <FormGroup>
                                        <Label>Minimum On Cart *</Label>
                                        <Input
                                            className="form-control"
                                            defaultValue={inputs.min ?? ""}
                                            mask={currencyMask(inputs.min)}
                                            name="min"
                                            onChange={inputChange}
                                            prepend={
                                                <InputGroupText>
                                                    $
                                                </InputGroupText>
                                            }
                                            typeInput={"masked"}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={3} md={4}>
                                    <FormGroup>
                                        <Label>Free After</Label>
                                        <Input
                                            className="form-control"
                                            defaultValue={
                                                inputs.free_after ?? ""
                                            }
                                            mask={currencyMask(
                                                inputs.free_after,
                                            )}
                                            name="free_after"
                                            onChange={inputChange}
                                            prepend={
                                                <InputGroupText>
                                                    $
                                                </InputGroupText>
                                            }
                                            typeInput={"masked"}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={3} md={4}>
                                    <FormGroup>
                                        <Label>Delivery Fee</Label>
                                        <Input
                                            className="form-control"
                                            defaultValue={
                                                inputs.delivery_fee ?? ""
                                            }
                                            mask={currencyMask(
                                                inputs.delivery_fee,
                                            )}
                                            name="delivery_fee"
                                            onChange={inputChange}
                                            prepend={
                                                <InputGroupText>
                                                    $
                                                </InputGroupText>
                                            }
                                            typeInput={"masked"}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Section>
                        <Section className="schedule_form__separator" />
                        <Section className="p-4 mb-2">
                            <Row>
                                <Col>
                                    <SectionTitle md>Express Menu</SectionTitle>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3}>
                                    <FormGroup className="mb-0" row>
                                        <Label
                                            className="row-label pl-0 mr-5"
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "3px",
                                            }}
                                        >
                                            Active{" "}
                                            <span
                                                id="TooltipExample"
                                                style={{
                                                    width: "18px",
                                                    marginBottom: "10px",
                                                }}
                                            >
                                                <Icon icon="info" />
                                            </span>
                                        </Label>

                                        <span className="k-switch k-switch--md k-switch--icon">
                                            <label>
                                                <Input
                                                    checked={
                                                        inputs.active
                                                            ? "checked"
                                                            : ""
                                                    }
                                                    name="active"
                                                    onChange={e => {
                                                        handleInputChange(
                                                            e,
                                                            !inputs.active,
                                                        );
                                                    }}
                                                    type="checkbox"
                                                />
                                                <span />
                                            </label>
                                        </span>
                                    </FormGroup>
                                    <Tooltip
                                        isOpen={tooltipOpen}
                                        placement="top"
                                        style={{ textAlign: "left" }}
                                        target="TooltipExample"
                                        toggle={toggle}
                                    >
                                        If enabled, it will allow your customers
                                        to place orders from your express menu
                                        on an express delivery schedule
                                    </Tooltip>
                                </Col>
                                {inputs.active && (
                                    <Col lg={4}>
                                        <FormGroup row>
                                            <Label className="row-label pl-0 mr-5">
                                                Active By Default
                                            </Label>

                                            <span className="k-switch k-switch--md k-switch--icon">
                                                <label>
                                                    <Input
                                                        checked={
                                                            inputs.active_by_default
                                                                ? "checked"
                                                                : ""
                                                        }
                                                        name="active_by_default"
                                                        onChange={inputChange}
                                                        type="checkbox"
                                                    />
                                                    <span />
                                                </label>
                                            </span>
                                        </FormGroup>
                                    </Col>
                                )}
                            </Row>
                            {inputs.active && (
                                <>
                                    <Row style={{ with: "100%" }}>
                                        <Col lg={3}>
                                            <FormGroup>
                                                <Label>Express Name</Label>
                                                <Input
                                                    name="expressName"
                                                    onChange={inputChange}
                                                    placeholder="Name"
                                                    value={inputs.expressName}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="pr-0" lg={2} md={5}>
                                            <FormGroup>
                                                <Label>Time Slot</Label>
                                                <Input
                                                    className="no-border-right"
                                                    max="24:00"
                                                    min="00:00"
                                                    name="start"
                                                    onChange={inputChange}
                                                    placeholder="Start time"
                                                    type="time"
                                                    value={inputs.start}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <div className="working__hours-decorator menu__express-hours">
                                            <Icon icon={"arrow_next"} />
                                        </div>
                                        <Col
                                            className="ml-0 pl-0 pr-0"
                                            lg={2}
                                            md={5}
                                        >
                                            <FormGroup>
                                                <Label className="menu__express-label-fill">
                                                    {""}
                                                </Label>
                                                <Input
                                                    className="no-border-left"
                                                    max="24:00"
                                                    min={inputs.start}
                                                    name="end"
                                                    onChange={inputChange}
                                                    placeholder="End time"
                                                    type="time"
                                                    value={inputs.end}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={4} md={6}>
                                            <FormGroup>
                                                <Label>
                                                    Inventory Locations
                                                </Label>
                                                {!loadingInventories ? (
                                                    inventories.length > 0 ? (
                                                        <Select
                                                            closeMenuOnSelect={
                                                                false
                                                            }
                                                            controlName="inventory_provider_ids"
                                                            isClearable={false}
                                                            isMulti
                                                            isSearchable={true}
                                                            onChange={
                                                                onChangeStore
                                                            }
                                                            options={
                                                                inventories
                                                            }
                                                            value={getRenderValues(
                                                                inputs.inventory_provider_ids,
                                                            )}
                                                        />
                                                    ) : (
                                                        <Input
                                                            name="inventory_provider_ids"
                                                            onChange={
                                                                inputChange
                                                            }
                                                            placeholder="Write the inventories ids, separated by commas..."
                                                            rows={2}
                                                            type="textarea"
                                                            value={
                                                                inputs.inventory_provider_ids
                                                            }
                                                        />
                                                    )
                                                ) : (
                                                    <div>
                                                        cargando inventories
                                                    </div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col lg={4} md={6}>
                                            <FormGroup>
                                                <Label>Note</Label>
                                                <Input
                                                    name="expressNote"
                                                    onChange={inputChange}
                                                    placeholder="Write the notes..."
                                                    rows={2}
                                                    type="textarea"
                                                    value={inputs.expressNote}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Section>
                    </>
                )}
                <Section className="schedule_form__separator" />
                <Section className="p-4 mb-2">
                    <Row>
                        <Col>
                            <SectionTitle md>Working Hours</SectionTitle>
                        </Col>
                    </Row>
                    <Row>
                        <Tabs
                            className="schedule_form__tabs"
                            navs={renderDaysOfWeekTabs()}
                        />
                    </Row>
                    <Row className="justify-content-end pr-3">
                        <Button
                            className="btn__options"
                            color="metal"
                            disabled={isUpdating}
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="ml-3"
                            color="primary"
                            onClick={createOrUpdate}
                            style={{ with: "100px !important" }}
                        >
                            {isUpdating && <Spinner color="metal" show sm />}
                            <span>Save</span>
                        </Button>
                    </Row>
                </Section>
                <Section className="section-separator" />
            </LoadScriptNext>
        </Form>
    );
};

export default ScheduleForm;
