export const initialLocalState = {
    data: {
        ui: {
            asideHover: false,
            asideMinimize: false,
            asideToggle: false,
            headerToggle: false,
            __typename: "Ui",
        },
    },
};
