import createNumberMask from "text-mask-addons/dist/createNumberMask";

export const currencyMask = rawValue => {
    let numberMask = () => {
        return createNumberMask({
            prefix: "",
            allowDecimal: true,
            includeThousandsSeparator: false,
            requireDecimal: true,
        });
    };

    let mask = numberMask(rawValue);
    return mask;
};
