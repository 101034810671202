import { useState } from "react";
import { QUERY_GET_STORE_DOMAIN } from "../../../apollo/module-operations/store";
import { useQuery } from "@apollo/react-hooks";

const useStoreDomain = ({ loading, account_id, store_id }) => {
    const [domain, setDomain] = useState("");

    useQuery(QUERY_GET_STORE_DOMAIN, {
        skip: loading,
        variables: {
            account_id: account_id,
            store_id: store_id,
        },
        onCompleted: resp => {
            setDomain(resp ? resp.getStore.domain : "https://");
        },
    });

    return domain;
};

export default useStoreDomain;
