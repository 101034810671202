import React from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { Portlet, Section, SectionTitle } from "@/components";
import { FormGroup, Col, Label, Row, FormFeedback } from "reactstrap";
import Input from "../../base/components/form/input/Input";
import Form from "../../base/components/form/Form";
import { useFormActions, useForm, useMeta } from "@/hooks";
import {
    MUTATION_UPDATE_SURFSIDE,
    QUERY_LIST_STORE_APPS,
} from "../../apollo/module-operations/app";
import _ from "lodash";
import useValidator from "../../base/hooks/form/useValidator";

const SurfsideForm = ({ app }) => {
    const { user } = useMeta();
    const { inputs, setInputs, inputChange, selectChange, selectValue } =
        useForm();

    if (!_.get(inputs, "id", null)) {
        setInputs(app);
    }

    let options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: {
                ...inputs,
                id: inputs.id,
                active: inputs.active,
                account_id: inputs.account_id,
                source_id: inputs.source_id,
                channel_id: inputs.channel_id,
                type: "Surfside",
            },
        },
    };

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "id",
                method: "isEmpty",
                validWhen: false,
                message: "This app id is required.",
            },
            {
                field: "account_id",
                method: "isEmpty",
                validWhen: false,
                message: "This Account id is required.",
            },
            {
                field: "source_id",
                method: "isEmpty",
                validWhen: false,
                message: "This Source id is required.",
            },
            {
                field: "channel_id",
                method: "isEmpty",
                validWhen: false,
                message: "This Merchant id is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    const { createOrUpdateButton } = useFormActions(
        "id",
        "Surfside",
        "",
        MUTATION_UPDATE_SURFSIDE,
        [
            {
                query: QUERY_LIST_STORE_APPS,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        "",
        isValid,
        isSubmited,
        "primary",
    );

    return (
        <Portlet
            centered
            className="business__settings"
            header={{
                title: "Surfside",
                subtitle: "Surfside Information",
            }}
            sticky
            toolbar={createOrUpdateButton}
        >
            {!user.loading ? (
                <Form>
                    <Section>
                        <Row>
                            <Col>
                                <SectionTitle md>Information</SectionTitle>
                            </Col>
                        </Row>

                        <FormGroup row>
                            <Label className="row-label">Active</Label>
                            <Col lg={4}>
                                <span className="k-switch k-switch--md k-switch--icon">
                                    <label>
                                        <Input
                                            checked={
                                                inputs.active ? "checked" : ""
                                            }
                                            name="active"
                                            onChange={inputChange}
                                            type="checkbox"
                                        />
                                        <span />
                                    </label>
                                </span>
                            </Col>
                        </FormGroup>

                        <Row>
                            <Col lg={5}>
                                <FormGroup row>
                                    <Label>Account Id</Label>
                                    <Input
                                        invalid={validator.account_id.isInvalid}
                                        name="account_id"
                                        onChange={inputChange}
                                        value={inputs.account_id}
                                    />
                                    <FormFeedback>
                                        {validator.account_id.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>

                            <Col lg={1}></Col>
                        </Row>

                        <Row>
                            <Col lg={5}>
                                <FormGroup row>
                                    <Label>Source Id</Label>
                                    <Input
                                        invalid={validator.source_id.isInvalid}
                                        name="source_id"
                                        onChange={inputChange}
                                        value={inputs.source_id}
                                    />
                                    <FormFeedback>
                                        {validator.source_id.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>

                            <Col lg={6}>
                                <FormGroup row>
                                    <Label>Channel Id</Label>

                                    <Input
                                        invalid={validator.channel_id.isInvalid}
                                        name="channel_id"
                                        onChange={inputChange}
                                        value={inputs.channel_id}
                                    />
                                    <FormFeedback>
                                        {validator.channel_id.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Section>
                </Form>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
        </Portlet>
    );
};

export default SurfsideForm;
