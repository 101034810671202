import React from "react";
import FooterMenu from "./FooterMenu";
import routes from "../../../config/routes/routes.js";

const Footer = () => {
    const { COMPANY_INFO } = routes;

    return (
        <footer className="k-footer k-grid__item k-grid k-grid--desktop k-grid--ver-desktop">
            <div className="k-footer__copyright">
                <a
                    className="k-link"
                    href={COMPANY_INFO.route}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {COMPANY_INFO.label}
                </a>
                &nbsp; All rights reserved {new Date().getFullYear()}{" "}
                &nbsp;&copy;
            </div>
            <FooterMenu />
        </footer>
    );
};

export default Footer;
