import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import Content from "../../base/layout/content/Content";
import { Portlet } from "../../base/components/portlet";
import useMeta from "../../base/hooks/useMeta.js";
import useTableActions from "../../base/hooks/table/useTableActions";
import { QUERY_VENDOR_LIST } from "../../apollo/module-operations/vendor";
import paginationFactory from "react-bootstrap-table2-paginator";
import { paginationOptions } from "../../config/components/table";
import classNames from "classnames";
import FiltersToggle from "../../base/components/table/FiltersToggle";
import Filters from "../../base/components/table/Filters";
import Table from "../../base/components/table/Table";
import { FILTER_TYPES, Comparator } from "react-bootstrap-table2-filter";
import useFilter from "../../base/hooks/table/useFilter";

const Vendors = () => {
    const { user } = useMeta();

    //---------------------------Defining filters-----------------------------
    const { TEXT, NUMBER } = FILTER_TYPES;

    const [showFilters, setShowFilters] = useState(false);

    const filterName = useFilter("name", TEXT, "Name", "");

    const filterSort = useFilter("sort", NUMBER, "Sort", {
        comparator: Comparator.GE,
    });

    const filters = [filterName, filterSort];
    // -----------------------------------------------------------------------

    const { loading, data, refetch, networkStatus } = useQuery(
        QUERY_VENDOR_LIST,
        {
            skip: user.loading,
            notifyOnNetworkStatusChange: true,
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
            },
        },
    );

    const { refreshButton } = useTableActions(refetch);

    const columns = [
        {
            dataField: "id",
            text: "Id",
            hidden: true,
        },
        {
            dataField: "name",
            text: "Name",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "250px" };
            },
        },
        {
            dataField: "phone_number",
            text: "Phone",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "100px" };
            },
        },
        {
            dataField: "email",
            text: "Email",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "80px" };
            },
        },
        {
            dataField: "website",
            text: "Website",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "80px" };
            },
        },
        {
            dataField: "company_type",
            text: "Company Type",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "100px" };
            },
        },
        {
            dataField: "enabled",
            text: "Is Active",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "80px" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <span
                        className={classNames(
                            "badge badge-pill",
                            `badge-${cell ? "success" : "secondary"}`,
                        )}
                    >
                        {cell ? "Yes" : "No"}
                    </span>
                );
            },
        },
    ];

    const _loading = loading || networkStatus === 4;

    const _data = data && data.listVendors;

    return (
        <>
            <Content>
                <Portlet
                    header={{
                        title: "Vendors",
                        subtitle: "All vendors availables in the system",
                    }}
                    sticky
                    toolbar={
                        <>
                            <FiltersToggle setShowFilters={setShowFilters} />
                            {refreshButton}
                        </>
                    }
                >
                    <Filters filters={filters} />
                    <Table
                        columns={columns}
                        data={_data}
                        filters={filters}
                        keyField="id"
                        loading={_loading}
                        pagination={paginationFactory(paginationOptions)}
                        showFilters={showFilters}
                    />
                </Portlet>
            </Content>
        </>
    );
};

export default Vendors;
