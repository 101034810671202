import { useQuery } from "@apollo/react-hooks";
import { Content } from "@/layout";
import { Portlet, Table, Badge } from "@/components";
import { useMeta, useTableActions } from "@/hooks";
import { QUERY_LIST_DELIVERY_WORKERS_IN_LOCATION } from "apollo/module-operations/delivery";

const OnfleetDrivers = () => {
    const { user } = useMeta();

    const {
        data: drivers_data,
        loading,
        refetch,
        networkStatus,
    } = useQuery(QUERY_LIST_DELIVERY_WORKERS_IN_LOCATION, {
        notifyOnNetworkStatusChange: true,
        skip: user.loading,
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
        },
    });

    const { refreshButton } = useTableActions(refetch, "");

    const columns = [
        {
            dataField: "id",
            text: "Key",
            hidden: true,
        },
        {
            dataField: "name",
            text: "Name",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "25%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{row.name}</div>;
            },
        },
        {
            dataField: "id",
            text: "Id",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "25%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <div className="onfleet_driver__table_row_primary">
                        {row.id}
                    </div>
                );
            },
        },
        {
            dataField: "phone",
            text: "Phone",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "25%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return <div>{row.phone}</div>;
            },
        },
        {
            dataField: "on_duty",
            text: "On duty",
            headerStyle: (colum, colIndex) => {
                return { width: "25%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Badge
                        className={
                            row.on_duty ? "status__info" : "status__metalic"
                        }
                        label={row.on_duty ? "True" : "False"}
                    />
                );
            },
            sort: true,
        },
    ];

    const _loading = loading || networkStatus === 4;

    const _drivers = (drivers_data && drivers_data.listWorkersInLocation) || [];

    return (
        <>
            <Content>
                <Portlet
                    className="onfleet_driver__content"
                    header={{
                        title: "Drivers",
                        subtitle: "All our drivers in the system",
                    }}
                    sticky
                    toolbar={refreshButton}
                >
                    <Table
                        className="table table-hover"
                        columns={columns}
                        data={_drivers}
                        keyField="id"
                        loading={_loading}
                        remote={{ filter: true }}
                    />
                </Portlet>
            </Content>
        </>
    );
};

export default OnfleetDrivers;
