import classNames from "classnames";
import {
    ButtonGroup,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { Badge } from "@/components";
import { FC, useState } from "react";
import { AlgoliaOrder } from "types/algolia";
import moment from "moment";
import { useHistory } from "react-router-dom";
import routes from "config/routes/routes";
import { replace } from "lodash";
import { convertTZ, timeWindowFormat } from "@/utils";
import { useStoreContext } from "context";

interface CardProps {
    order: AlgoliaOrder;
    accentClass: string;
    handleModalToggle: (order: AlgoliaOrder) => void;
}

const KanbanCard: FC<CardProps> = ({
    order,
    handleModalToggle,
    accentClass,
}) => {
    const [isOptionsPopupOpen, setIsOptionsPopupOpen] = useState(false);
    const history = useHistory();
    const { store } = useStoreContext();

    const utcReservationWindow = convertTZ(
        order?.reservation_start_window,
        store?.timezone,
    );

    const datetime = `${moment(utcReservationWindow).format("MMMM Do")}`;

    const handleOptionsPopupToggle = () => {
        setIsOptionsPopupOpen(isOptionsPopupOpen => !isOptionsPopupOpen);
    };

    const handleDetailOpen = id => {
        const url = replace(routes.ORDER_DETAIL.route, ":id", id);
        handleOptionsPopupToggle();
        history.push(url);
    };

    return (
        <ButtonGroup className="kanban_card__bt_group">
            <button
                className={classNames(
                    "kanban_card",
                    `kanban_card_border-${accentClass}`,
                    { card__active: isOptionsPopupOpen },
                )}
                onClick={() => handleModalToggle(order)}
            >
                <div className="kanban_card__header">
                    <h4>{order.customer}</h4>
                </div>
                <p className="kanban_card__city">{order.city}</p>
                <p className="kanban_card__datetime">{`${datetime},  ${timeWindowFormat(
                    order.delivery_time_window,
                )}`}</p>
                <div className="kanban_card__order_info">
                    <Badge
                        className={`status__${accentClass}`}
                        label={order.type}
                    />
                    <p className="kanban_card__order_price">
                        ${order.total_price.toFixed(2)}
                    </p>
                </div>
            </button>
            <ButtonDropdown
                className="btn-card-dropdown"
                isOpen={isOptionsPopupOpen}
                toggle={handleOptionsPopupToggle}
            >
                <DropdownToggle
                    className={classNames("moreButton", {
                        ["moreButton__active"]: isOptionsPopupOpen,
                    })}
                >
                    <i className="fa fa-ellipsis-h" />
                </DropdownToggle>
                <DropdownMenu className="more-options">
                    <DropdownItem header>Actions</DropdownItem>
                    <DropdownItem
                        onClick={() => {
                            handleDetailOpen(order.objectID);
                        }}
                    >
                        Details
                    </DropdownItem>
                </DropdownMenu>
            </ButtonDropdown>
        </ButtonGroup>
    );
};

export default KanbanCard;
