import gql from "graphql-tag";

let NotificationSetting = {};
NotificationSetting.fragments = {
    attributes: gql`
        fragment NotificationSettingFragment on NotificationSetting {
            type
            handler
            content
            email_subject
            notification_active
        }
    `,
};

export const QUERY_GET_NOTIFICATION_SETTING = gql`
    query getStoreNotificationSetting(
        $account_id: String!
        $store_id: String!
        $handler: String!
    ) {
        getStoreNotificationSetting(
            account_id: $account_id
            store_id: $store_id
            handler: $handler
        ) {
            ...NotificationSettingFragment
        }
    }
    ${NotificationSetting.fragments.attributes}
`;

export const MUTATION_SET_DEFAULT_NOTIFICATION = gql`
    mutation setDefaultNotificationSettings(
        $account_id: String!
        $store_id: String!
    ) {
        setDefaultNotificationSettings(
            account_id: $account_id
            store_id: $store_id
        ) {
            ...NotificationSettingFragment
        }
    }
    ${NotificationSetting.fragments.attributes}
`;

export const MUTATION_UPDATE_NOTIFICATION_SETTING = gql`
    mutation updateStoreNotificationSetting(
        $account_id: String!
        $store_id: String!
        $input: NotificationSettingInput!
    ) {
        updateStoreNotificationSetting(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            ...NotificationSettingFragment
        }
    }
    ${NotificationSetting.fragments.attributes}
`;
