import React from "react";
import { useQuery } from "@apollo/react-hooks";
import classNames from "classnames";

import { Routes } from "@/config";

import HeaderMobile from "./header/HeaderMobile";
import Aside from "./aside/Aside";
import Header from "./header/Header";
import SubHeader from "./sub-header/SubHeader";
import Footer from "./footer/Footer";
import { QUERY_UI } from "../../apollo/queries/local";

const Layout = () => {
    const { data } = useQuery(QUERY_UI);

    const { asideHover, asideMinimize, asideToggle, headerToggle } = data.ui;

    return (
        <main
            className={classNames(
                "k-header--fixed",
                "k-header-mobile--fixed",
                "k-aside--enabled",
                "k-aside--fixed",
                { "k-aside--minimize-hover": asideHover },
                { "k-aside--minimize": asideMinimize },
                { "k-aside--on": asideToggle },
                { "k-header__topbar--mobile-on": headerToggle },
            )}
        >
            <HeaderMobile />
            <div className="k-grid k-grid--hor k-grid--root">
                <div className="k-grid__item k-grid__item--fluid k-grid k-grid--ver k-page">
                    <Aside />
                    <div
                        className="k-grid__item k-grid__item--fluid k-grid k-grid--hor k-wrapper"
                        style={{
                            minHeight: "100vh",
                        }}
                    >
                        <Header />
                        <div className="k-content k-grid__item k-grid__item--fluid k-grid k-grid--hor">
                            <SubHeader />
                            <Routes />
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Layout;
