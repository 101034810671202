import React from "react";
import classNames from "classnames";

const PortletHeader = ({ lg, noBorder, icon, title, subtitle, toolbar }) => {
    return (
        <div
            className={classNames(
                "k-portlet__head",
                { "k-portlet__head--lg": lg },
                { "k-portlet__head--noborder": noBorder },
            )}
        >
            <div className="k-portlet__head-label">
                {icon && (
                    <span className="k-portlet__head-icon">
                        <i className={icon} />
                    </span>
                )}
                {title && (
                    <h3 className="k-portlet__head-title">
                        {title}
                        {subtitle && (
                            <small className="d-none d-md-inline">
                                {subtitle}
                            </small>
                        )}
                    </h3>
                )}
            </div>
            {toolbar && (
                <div className="k-portlet__head-toolbar">{toolbar}</div>
            )}
        </div>
    );
};

export default PortletHeader;
