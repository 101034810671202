import { useParams, useLocation } from "react-router-dom";
import _ from "lodash";
import useUser from "./auth/useUser.js";

const useMeta = () => {
    const location = useLocation();
    const params = useParams();
    const user = useUser();

    const resourceId = params.id;
    const isCreate = _.includes(location.pathname, "/create");

    return { resourceId, isCreate, user };
};

export default useMeta;
