import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import Content from "../../base/layout/content/Content";
import { Portlet } from "../../base/components/portlet";

import FiltersToggle from "../../base/components/table/FiltersToggle";
import Filters from "../../base/components/table/Filters";
import Table from "../../base/components/table/Table";
import { FILTER_TYPES } from "react-bootstrap-table2-filter";
import useFilter from "../../base/hooks/table/useFilter";

import classNames from "classnames";
import useMeta from "../../base/hooks/useMeta.js";
import useTableActions from "../../base/hooks/table/useTableActions";
import useRowActions from "../../base/hooks/table/useRowActions";
import { QUERY_PAGE_LIST } from "../../apollo/module-operations/page";
import { MUTATION_REMOVE_PAGE } from "../../apollo/module-operations/page";
import routes from "../../config/routes/routes";
import moment from "moment";
import paginationFactory from "react-bootstrap-table2-paginator";
import { paginationOptions } from "../../config/components/table";

const Pages = () => {
    const { user } = useMeta();

    //---------------------------Defining filters-----------------------------
    const { TEXT, DATE } = FILTER_TYPES;

    const [showFilters, setShowFilters] = useState(false);

    const filterCreated = useFilter("created_at", DATE, "Created At");

    const filterSlug = useFilter("slug", TEXT, "Slug", "");

    const filterTemplate = useFilter("template", TEXT, "Template", "");

    const filters = [filterCreated, filterSlug, filterTemplate];
    // -----------------------------------------------------------------------

    const { loading, data, refetch, networkStatus } = useQuery(
        QUERY_PAGE_LIST,
        {
            skip: user.loading,
            notifyOnNetworkStatusChange: true,
            variables: {
                account_id: user.accountId,
                store_id: user.storeId,
            },
        },
    );

    const { refreshButton, addNewButton } = useTableActions(
        refetch,
        routes.PAGE_CREATE.route,
    );

    const { confirm, remove, update } = useRowActions(
        [
            {
                query: QUERY_PAGE_LIST,
                skip: user.loading,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        MUTATION_REMOVE_PAGE,
        routes.PAGE_DETAIL.route,
        "Page",
    );

    const columns = [
        {
            dataField: "id",
            text: "Id",
            hidden: true,
        },
        {
            dataField: "created_at",
            text: "Created At",
            align: "center",
            headerStyle: (colum, colIndex) => {
                return { width: "180px" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <span>
                        {cell ? moment(cell).format("MM/DD/YYYY HH:mm") : " - "}
                    </span>
                );
            },
            filterValue: (cell, row) => cell,
        },
        {
            dataField: "slug",
            text: "Slug",
            sort: true,
        },
        {
            dataField: "template",
            text: "Template",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "180px" };
            },
        },
        {
            dataField: "status",
            text: "Status",
            headerStyle: (colum, colIndex) => {
                return { width: "100px" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <span
                        className={classNames(
                            "badge badge-pill",
                            `badge-${cell ? "success" : "secondary"}`,
                        )}
                    >
                        {cell ? "Active" : "No Active"}
                    </span>
                );
            },
            sort: true,
        },
    ];

    const actions = [
        {
            label: "Update",
            icon: "fas fa-pencil-alt",
            action: (cell, row, rowIndex, formatExtraData) => {
                update(row.id);
            },
        },
        {
            label: "Remove",
            icon: "fas fa-trash",
            action: (cell, row, rowIndex, formatExtraData) => {
                remove({
                    variables: {
                        account_id: user.accountId,
                        store_id: user.storeId,
                        input: {
                            id: row.id,
                        },
                    },
                });
            },
        },
    ];

    const _loading = loading || networkStatus === 4;

    const _data = data && data.listPages;

    return (
        <>
            <Content>
                <Portlet
                    header={{
                        title: "Pages",
                        subtitle: "All pages availables in the system",
                    }}
                    sticky
                    toolbar={
                        <>
                            <FiltersToggle setShowFilters={setShowFilters} />
                            {refreshButton}
                            {addNewButton}
                        </>
                    }
                >
                    <Filters filters={filters} />
                    <Table
                        actions={actions}
                        columns={columns}
                        data={_data}
                        filters={filters}
                        keyField="id"
                        loading={_loading}
                        pagination={paginationFactory(paginationOptions)}
                        showFilters={showFilters}
                    />
                </Portlet>
            </Content>
            {confirm}
        </>
    );
};

export default Pages;
