import React from "react";

const AsideSection = ({ label }) => {
    return (
        <li className="k-menu__section ">
            <h4 className="k-menu__section-text">{label}</h4>
            <i className="k-menu__section-icon h4" style={{ opacity: 0.5 }}>
                ···
            </i>
        </li>
    );
};

export default AsideSection;
