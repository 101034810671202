import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Col, Row, FormFeedback, FormGroup, Label, Button } from "reactstrap";
import { useForm, useValidator } from "@/hooks";
import { Form, Input, Section, SectionTitle, Spinner } from "@/components";

const MerchantForm = ({
    app,
    handleCancel,
    handlerUpdatingOrCreate,
    isCreate,
    isUpdating,
    selectMerchant,
    setMerchants,
}) => {
    const [isFormReset, resetFormInputs] = useState(isCreate);
    const { inputs, inputChange, setInputs } = useForm();

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "store_id",
                method: "isEmpty",
                validWhen: false,
                message: "This Store Id is required.",
            },
            {
                field: "merchant",
                method: "isEmpty",
                validWhen: false,
                message: "This Merchant is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    useEffect(() => {
        if (!isCreate) {
            const objSelect = app?.merchants[selectMerchant];
            setInputs({
                store_id: objSelect?.store_id,
                merchant: objSelect?.merchant,
            });
        }
    }, []);

    if (!isCreate && !isFormReset) {
        resetFormInputs(true);
    }

    const createOrUpdate = async () => {
        if (isSubmited) {
            isSubmited(true);
        }

        if (isValid()) {
            let newMerchants = _.cloneDeep(app.merchants || []);

            if (isCreate) {
                newMerchants.push({
                    store_id: inputs.store_id,
                    merchant: inputs.merchant,
                });
            } else {
                newMerchants[selectMerchant] = {
                    ...inputs,
                    store_id: inputs.store_id,
                    merchant: inputs.merchant,
                };
            }
            setMerchants(newMerchants);
            handlerUpdatingOrCreate(newMerchants);
        }
    };

    const handleClose = () => {
        handleCancel();
        resetFormInputs(false);
    };

    return (
        <Form>
            <Section className="mt-5">
                <Row>
                    <Col>
                        <SectionTitle md>
                            {isCreate ? "Add Merchant" : "Update Merchant"}
                        </SectionTitle>
                    </Col>
                </Row>
                <Row>
                    <Col lg={3} md={6}>
                        <FormGroup>
                            <Label>Store Id</Label>
                            <Input
                                invalid={validator.store_id.isInvalid}
                                name="store_id"
                                onChange={inputChange}
                                placeholder="Store Id"
                                // type="number"
                                value={inputs.store_id}
                            />
                            <FormFeedback>
                                {validator.store_id.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg={3} md={6}>
                        <FormGroup invalid={validator.merchant.isInvalid}>
                            <Label>Merchant</Label>
                            <Input
                                invalid={validator.merchant.isInvalid}
                                name="merchant"
                                onChange={inputChange}
                                placeholder="Merchant"
                                // type="number"
                                value={inputs.merchant}
                            />
                            <FormFeedback>
                                {validator.merchant.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="justify-content-end pr-3">
                    <Button
                        className="btn__options"
                        color="metal"
                        disabled={isUpdating}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="ml-3"
                        color="primary"
                        onClick={createOrUpdate}
                        style={{ minWidth: "80px" }}
                    >
                        {isUpdating && <Spinner color="metal" show sm />}
                        <span>Save</span>
                    </Button>
                </Row>
            </Section>
        </Form>
    );
};

export default MerchantForm;
