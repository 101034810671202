import React, { FC, useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import classNames from "classnames";

interface INavProps {
    content: JSX.Element;
    date?: string;
    title: string;
}
interface ITabProps {
    navs: INavProps[];
    className?: string;
}

const Tabs: FC<ITabProps> = ({ navs, className }) => {
    const [activeTab, setActiveTab] = useState("0");

    const toggle = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    return (
        <div className={classNames("customer__details_tabs", className)}>
            <Nav tabs>
                {navs.map((nav, index) => {
                    return (
                        <NavItem key={`${index}`}>
                            <NavLink
                                className={classnames({
                                    active: activeTab === `${index}`,
                                })}
                                onClick={() => {
                                    toggle(`${index}`);
                                }}
                            >
                                <div className="customer__details_tabs_title">
                                    <span>{nav.title}</span>
                                    {nav.date && (
                                        <span className="tabs_title">
                                            {nav.date}
                                        </span>
                                    )}
                                </div>
                            </NavLink>
                        </NavItem>
                    );
                })}
            </Nav>
            <TabContent activeTab={activeTab}>
                {navs.map((nav, index) => {
                    return (
                        <TabPane key={`${index}`} tabId={`${index}`}>
                            {nav.content}
                        </TabPane>
                    );
                })}
            </TabContent>
        </div>
    );
};

export default Tabs;
