const getProduct = (options, productId) => {
    const productMarkup = _product => [
        {
            type: "image",
            attributes: {
                src: _product.thumbnail,
                alt: _product.name || "",
            },
        },
        {
            tagName: "p",
            type: "text",
            components: [
                {
                    tagName: "strong",
                    type: "text",
                    components: {
                        type: "textnode",
                        content: _product.name || "Product title",
                    },
                },
                {
                    tagName: "span",
                    type: "text",
                    components: {
                        type: "textnode",
                        content: `$${_product.price || "0.00"}`,
                    },
                },
                {
                    tagName: "small",
                    type: "text",
                    components: {
                        type: "textnode",
                        content: "Plus applicable taxes",
                    },
                },
            ],
        },
    ];

    const products = productId.map(p =>
        options.products.find(_p => _p.id === p),
    );

    const params = productId.map(p => `i=${p}`).join("&");

    const components =
        products.length === 1
            ? productMarkup(products[0])
            : [
                  {
                      tagName: "div",
                      components: products.map(p => ({
                          tagName: "div",
                          components: productMarkup(p),
                      })),
                  },
              ];

    components.push(
        `<a href="${options.domain}/checkout?${params}"
            target="_blank"
            rel="noopener noreferrer"
        >
            Buy now
        </a>
        <style>
            .products > div {
                display: flex;
                width: 100%;
            }

            .products > div div {
                padding: 0 15px;
                display: flex;
                flex-direction: column;
                align-items: center;
                flex-grow: 1;
            }

            .products img {
                margin-bottom: auto;
                width: 100%;
            }

            .products p {
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 20px;
                margin-bottom: 0;
            }

            .products strong {
                font-size: 25px;
                margin-bottom: 8px;
            }

            .products span {
                font-size: 19px;
                margin-bottom: 5px;
            }

            .products small {
                color: gray;
            }

            .products a {
                margin-top: 35px;
                font-size: 19px;
                text-decoration: none;
                padding: 10px 20px;
                border-radius: 2px;
                background-color: #5d78ff;
                color: white;
            }
        </style>`,
    );

    return components;
};

const loadTraits = (editor, options) => {
    const tm = editor.TraitManager;

    tm.addType("multi-select", {
        createInput({ trait }) {
            const traitOpts = trait.get("options") || [];

            const el = document.createElement("select");

            el.setAttribute("multiple", true);
            el.innerHTML = traitOpts
                .map(o => `<option value="${o.id}">${o.name}</option>`)
                .join("");

            return el;
        },
        onEvent({ elInput, component, event }) {
            const dataPid = [...elInput.selectedOptions].map(o => o.value);

            component.set("data-pid", dataPid);
        },
        // onUpdate({ elInput, component }) {
        //     options.productId.forEach(p => {
        //         const i = [...elInput.options].findIndex(o => o.value === p);

        //         if (i !== -1) {
        //             elInput.options[i].selected = true;
        //         }
        //     });

        //     component.set("data-pid", options.productId);

        //     elInput.dispatchEvent(new CustomEvent("change"));
        // }
    });
};

const loadComponents = (editor, options) => {
    const domc = editor.DomComponents;

    domc.addType("ecommerce-product", {
        model: {
            defaults: {
                name: "Products",
                "data-pid": options.productId,
                traits: [
                    {
                        type: "multi-select",
                        name: "data-pid",
                        label: "Products",
                        options: options.products,
                    },
                ],
                components: getProduct(options, options.productId),
                style: {
                    display: "flex",
                    "flex-direction": "column",
                    "align-items": "center",
                    border: "1px solid #e3e3e3",
                    padding: "30px 20px",
                },
            },
            init() {
                this.on("change:data-pid", this.handleProductIdChange);

                this.addClass("products");
            },
            handleProductIdChange(model, value) {
                this.components(getProduct(options, value));
            },
        },
    });
};

const loadBlocks = (editor, options) => {
    const bm = editor.BlockManager;

    bm.add("ecommerce-product", {
        label: "Products",
        attributes: { class: "fa fa-shopping-cart" },
        category: "Ecommerce",
        content: { type: "ecommerce-product" },
    });
};

export default (editor, opts = {}) => {
    const options = {
        ...opts,
        productId: [opts.products[0].id],
    };

    loadTraits(editor, options);

    loadComponents(editor, options);

    loadBlocks(editor, options);
};
