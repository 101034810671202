import { useNumericMenu } from "react-instantsearch-hooks-web";
import { FC, useEffect, useState } from "react";
import { NumericMenu } from "./NumericMenu";

interface IDateRange {
    start?: number;
    end?: number;
    label: string;
}

export const DeliveryTimeFilter: FC = () => {
    const [rangeDate, setRangeDate] = useState<IDateRange>({
        start: 0,
        end: 0,
        label: "Range",
    });

    const now = new Date();

    const { items, refine } = useNumericMenu({
        attribute: "reservation_start_window_timestamp",
        items: [
            {
                label: "All",
            },
            {
                start: Math.floor(now.setHours(0, 0, 0, 0) / 1000),
                label: "Today",
            },
            {
                start: Math.floor(now.setDate(now.getDate() - 1) / 1000),
                label: "24 Hours",
            },
            {
                start: rangeDate.start,
                end: rangeDate.end,
                label: rangeDate.label,
            },
        ],
    });

    useEffect(() => {
        if (rangeDate.start === 0) {
            return;
        }

        refine(
            encodeURI(
                JSON.stringify({
                    start: rangeDate.start,
                    end: rangeDate.end,
                }),
            ),
        );
    }, [rangeDate]);

    const dateRange = value => {
        setRangeDate({
            start: value.startDate.unix(),
            end: value.endDate.unix(),
            label: `${value.startDate.format(
                "MMM Do YY",
            )} - ${value.endDate.format("MMM Do YY")}`,
        });
    };

    return (
        <NumericMenu
            dateRange={dateRange}
            element="date"
            items={items}
            refine={refine}
        />
    );
};
