import React from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { Portlet, Section, SectionTitle } from "../../base/components/portlet";
import { FormGroup, Col, Label, Row, FormFeedback } from "reactstrap";
import Input from "../../base/components/form/input/Input";
import Form from "../../base/components/form/Form";
import useMeta from "../../base/hooks/useMeta";
import useForm from "../../base/hooks/form/useForm";
import useFormActions from "../../base/hooks/form/useFormActions";
import {
    MUTATION_UPDATE_CHILE_EXPRESS_APP,
    QUERY_LIST_STORE_APPS,
} from "../../apollo/module-operations/app";
import _ from "lodash";
import useValidator from "../../base/hooks/form/useValidator";

const MeasureRequestForm = ({ app }) => {
    const { user } = useMeta();
    const { inputs, setInputs, inputChange } = useForm();

    if (!_.get(inputs, "id", null)) {
        setInputs(app);
    }

    let options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: {
                ...inputs,
                tcc: parseInt(inputs.tcc),
                test_tcc: parseInt(inputs.test_tcc),
                rut: parseInt(inputs.rut),
                test_rut: parseInt(inputs.test_rut),

                type: "ChileExpress",
            },
        },
    };

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "tcc",
                method: "isEmpty",
                validWhen: false,
                message: "This tcc is required.",
            },

            {
                field: "test_tcc",
                method: "isEmpty",
                validWhen: false,
                message: "This tcc is required.",
            },

            {
                field: "rut",
                method: "isEmpty",
                validWhen: false,
                message: "This rut is required.",
            },

            {
                field: "test_rut",
                method: "isEmpty",
                validWhen: false,
                message: "This rut is required.",
            },

            {
                field: "send_key",
                method: "isEmpty",
                validWhen: false,
                message: "This send key is required.",
            },

            {
                field: "test_send_key",
                method: "isEmpty",
                validWhen: false,
                message: "This send key is required.",
            },

            {
                field: "coverage_key",
                method: "isEmpty",
                validWhen: false,
                message: "This coverage key is required.",
            },

            {
                field: "test_coverage_key",
                method: "isEmpty",
                validWhen: false,
                message: "This coverage key is required.",
            },

            {
                field: "quote_key",
                method: "isEmpty",
                validWhen: false,
                message: "This quote key is required.",
            },

            {
                field: "test_quote_key",
                method: "isEmpty",
                validWhen: false,
                message: "This quote key is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    const { createOrUpdateButton } = useFormActions(
        "id",
        "ChileExpress",
        "",
        MUTATION_UPDATE_CHILE_EXPRESS_APP,
        [
            {
                query: QUERY_LIST_STORE_APPS,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        "",
        isValid,
        isSubmited,
        "primary",
    );

    return (
        <Portlet
            centered
            header={{
                title: "Chile Express",
                subtitle: "Chile Express Information",
            }}
            sticky
        >
            {!user.loading ? (
                <Form centered>
                    <Section>
                        <Row>
                            <Label lg={4} xl={3} />
                            <Col>
                                <SectionTitle md>Information</SectionTitle>
                            </Col>
                        </Row>

                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Sandbox
                            </Label>
                            <Col>
                                <span className="k-switch k-switch--md k-switch--icon">
                                    <label>
                                        <Input
                                            checked={
                                                inputs.sandbox ? "checked" : ""
                                            }
                                            name="sandbox"
                                            onChange={inputChange}
                                            type="checkbox"
                                        />
                                        <span />
                                    </label>
                                </span>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                TCC
                            </Label>
                            <Col>
                                <Input
                                    invalid={
                                        inputs.sandbox
                                            ? validator.test_tcc.isInvalid
                                            : validator.tcc.isInvalid
                                    }
                                    name={inputs.sandbox ? "test_tcc" : "tcc"}
                                    onChange={inputChange}
                                    type="number"
                                    value={
                                        inputs.sandbox
                                            ? inputs.test_tcc
                                            : inputs.tcc
                                    }
                                />
                                <FormFeedback>
                                    {inputs.sandbox
                                        ? validator.test_tcc.message
                                        : validator.tcc.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                RUT
                            </Label>
                            <Col>
                                <Input
                                    invalid={
                                        inputs.sandbox
                                            ? validator.test_rut.isInvalid
                                            : validator.rut.isInvalid
                                    }
                                    name={inputs.sandbox ? "test_rut" : "rut"}
                                    onChange={inputChange}
                                    type="number"
                                    value={
                                        inputs.sandbox
                                            ? inputs.test_rut
                                            : inputs.rut
                                    }
                                />
                                <FormFeedback>
                                    {inputs.sandbox
                                        ? validator.test_rut.message
                                        : validator.rut.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Send key
                            </Label>
                            <Col>
                                <Input
                                    invalid={
                                        inputs.sandbox
                                            ? validator.test_send_key.isInvalid
                                            : validator.send_key.isInvalid
                                    }
                                    name={
                                        inputs.sandbox
                                            ? "test_send_key"
                                            : "send_key"
                                    }
                                    onChange={inputChange}
                                    value={
                                        inputs.sandbox
                                            ? inputs.test_send_key
                                            : inputs.send_key
                                    }
                                />
                                <FormFeedback>
                                    {inputs.sandbox
                                        ? validator.test_send_key.message
                                        : validator.send_key.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Coverage key
                            </Label>
                            <Col>
                                <Input
                                    invalid={
                                        inputs.sandbox
                                            ? validator.test_coverage_key
                                                  .isInvalid
                                            : validator.coverage_key.isInvalid
                                    }
                                    name={
                                        inputs.sandbox
                                            ? "test_coverage_key"
                                            : "coverage_key"
                                    }
                                    onChange={inputChange}
                                    value={
                                        inputs.sandbox
                                            ? inputs.test_coverage_key
                                            : inputs.coverage_key
                                    }
                                />
                                <FormFeedback>
                                    {inputs.sandbox
                                        ? validator.test_coverage_key.message
                                        : validator.coverage_key.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Quote key
                            </Label>
                            <Col>
                                <Input
                                    invalid={
                                        inputs.sandbox
                                            ? validator.test_quote_key.isInvalid
                                            : validator.quote_key.isInvalid
                                    }
                                    name={
                                        inputs.sandbox
                                            ? "test_quote_key"
                                            : "quote_key"
                                    }
                                    onChange={inputChange}
                                    value={
                                        inputs.sandbox
                                            ? inputs.test_quote_key
                                            : inputs.quote_key
                                    }
                                />
                                <FormFeedback>
                                    {inputs.sandbox
                                        ? validator.test_quote_key.message
                                        : validator.quote_key.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label lg={4} xl={3} />
                            <Col>{createOrUpdateButton}</Col>
                        </FormGroup>
                    </Section>
                </Form>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
        </Portlet>
    );
};

export default MeasureRequestForm;
