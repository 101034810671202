import { FC, useEffect, useRef, useState } from "react";
import { Col, Button, FormGroup, FormFeedback, Row } from "reactstrap";
import _ from "lodash";
import moment from "moment";
import classNames from "classnames";
import DateRangePicker from "react-bootstrap-daterangepicker";

import {
    Badge,
    Icon,
    Input,
    Message,
    Section,
    Select,
    Spinner,
} from "@/components";
import { useValidator, useForm } from "@/hooks";
import { currencyFormat, timeWindowFormat } from "@/utils";
import { IDataPaneProps, UpdateOrderDetailsInput } from "@/types";
import OrdersItemTable from "./OrdersItemTable";
import { PAYMENT_METHOD, DELIVERY_WINDOW_HOURS, NOT_AVAILABLE } from "@/config";
import { ProductBarcodes } from "./ProductBarcodes";
import { useLineItems } from "./hooks/useLineItems";
import { useUpdateOrderDetails } from "./hooks";
import { useHistory } from "react-router-dom";

import { AddLineItemForm } from "./AddLineItemForm";
import routes from "config/routes/routes";
import { ApolloError } from "apollo-client";

const OrderDataPane: FC<IDataPaneProps> = ({
    loading,
    order,
    account,
    idpAccount,
    namesProductsPOS,
}) => {
    const history = useHistory();

    const [showEditForm, setShowEditForm] = useState(false);
    const [selection, setSelection] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const topPaneRef = useRef<HTMLDivElement>();
    const showButtons = order?.status === "PENDING";
    const isEditable = order?.status === "PENDING" && showEditForm;

    const scrollToTop = () => {
        topPaneRef.current?.scrollIntoView({
            block: "nearest",
            behavior: "smooth",
        });
    };

    const {
        lineItems,
        setLineItems,
        addLineItemQuantity,
        subLineItemQuantity,
        removeLineItems,
        addLineItem,
    } = useLineItems(order?.line_items);

    const { updateOrderDetails, isUpdating } = useUpdateOrderDetails(
        order?.entity_id,
        () => {
            setSuccess("Order updated successfully");
            scrollToTop();
            setError(null);
            setShowEditForm(false);
            setInputs({ ...inputs, promo_code: "" });

            new Promise(r => setTimeout(r, 3000)).then(() => {
                setSuccess(null);
            });
        },
        (error: ApolloError) => {
            setSuccess(null);
            setError(error?.message);
            scrollToTop();
        },
    );

    const {
        inputs,
        setInputs,
        inputChange,
        datepickerChange,
        selectChange,
        genericChange,
        selectValue,
    } = useForm();

    useEffect(() => {
        setInputs({
            id: order?.entity_id,
            address: order?.delivery_address,
            line_items: lineItems,
            payment_method: order?.payment_details?.type,
            time_window: {
                deliver_after: order?.delivery_details?.deliver_after,
                time_window: order?.delivery_details?.time_window,
            },
            notes: order?.notes,
        } as UpdateOrderDetailsInput);
    }, [order, lineItems]);

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "payment_method",
                method: "isEmpty",
                validWhen: false,
                message: "This payment method is required.",
            },
            {
                field: "address.address1",
                method: "isEmpty",
                validWhen: false,
                message: "This address is required.",
            },
            {
                field: "address.city",
                method: "isEmpty",
                validWhen: false,
                message: "This city is required.",
            },
            {
                field: "address.zip",
                method: "isEmpty",
                validWhen: false,
                message: "This zip is required.",
            },
            {
                field: "address.phone",
                method: "isEmpty",
                validWhen: false,
                message: "This phone is required.",
            },
        ],
        {
            ...inputs,
            ...inputs.delivery_address,
            ...inputs.payment_details,
        },
    );

    const handleSaveOrder = () => {
        if (isSubmited) {
            isSubmited(true);
        }

        if (!isValid()) {
            return;
        }

        updateOrderDetails(inputs);
    };

    const handleCancel = () => {
        setError(null);
        setSuccess(null);

        setShowAddForm(false);
        setShowEditForm(false);
        setLineItems(order?.line_items);
    };

    const orderType = order?.delivery_details?.pickup ? "Pickup" : "Delivery";

    const changeItems = (result, item) => {
        const line_items = _.cloneDeep(inputs.line_items);

        genericChange(line_items, "line_items");
    };

    const handleRemoveLineItems = () => {
        removeLineItems(selection);
        setSelection([]);
    };

    const [showAddForm, setShowAddForm] = useState(false);

    const handleAddItem = () => {
        setShowAddForm(true);
    };

    const handleAccountDetian = e => {
        const url = _.replace(
            routes.CUSTOMER_DETAIL.route,
            ":id",
            account?.entity_id,
        );

        history.push(url);
    };

    const renderVerifyStatus = () => {
        if (!idpAccount || !idpAccount?.berbix_verified) {
            return (
                <Badge
                    className={"status__error"}
                    label={"DRIVER´S LICENSE NOT VERIFIED"}
                    md={true}
                />
            );
        }

        return (
            <Badge
                className={"status__success"}
                label={"DRIVER´S LICENSE VERIFIED"}
                md={true}
            />
        );
    };

    const isLoading = loading;

    if (isLoading) {
        return SkeletonScreen();
    }

    const handleRemoveDiscountItem = promoCode => {
        const _discountItems = _.cloneDeep(order?.discount_items);

        const newDiscountItems = _discountItems.filter(
            discount => discount.promo_code != promoCode,
        );

        setInputs({ ...inputs, discount_items: newDiscountItems });
    };

    const getColumWidth = () => {
        if (isEditable || order?.discount_items?.length) {
            return "52%";
        }

        return "37%";
    };

    return (
        <Section>
            <div ref={topPaneRef} />
            <Row>
                <Col className="order__status">
                    <Badge
                        className={"status__metalic"}
                        label={_.startCase(order?.status)}
                        md={true}
                    />
                    {renderVerifyStatus()}
                </Col>
            </Row>
            <Row className="order_sticky_header">
                <Col>
                    <div className="order__details_header">
                        <span className="order__type">
                            {`#${order.order_number} - ${orderType} (${moment(
                                order?.delivery_details.deliver_after,
                            ).format("ddd, MMM Do")}, ${timeWindowFormat(
                                order?.delivery_details.time_window,
                            )})`}
                        </span>
                        {showButtons && (
                            <div className="order__details_header">
                                {!isEditable && (
                                    <Button
                                        className="btn__options btn__edit"
                                        color="metal"
                                        onClick={() => {
                                            setShowEditForm(true);
                                        }}
                                    >
                                        <Icon icon="edit" />
                                        Edit order
                                    </Button>
                                )}
                                {isEditable && (
                                    <div className="order__details_actions">
                                        <Button
                                            className="btn__options"
                                            color="metal"
                                            disabled={isUpdating}
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="btn__options"
                                            color="primary"
                                            onClick={handleSaveOrder}
                                        >
                                            {isUpdating && (
                                                <Spinner
                                                    color="metal"
                                                    show
                                                    sm
                                                />
                                            )}
                                            <span>Save</span>
                                        </Button>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
            <div className="shadow" />
            <div className="shadow_cover" />
            <div className="feedback_message">
                <Message
                    closable
                    onClose={() => {
                        setError("");
                    }}
                    text={error}
                    type="error"
                />
                <Message
                    closable
                    onClose={() => {
                        setSuccess("");
                    }}
                    text={success}
                    type="success"
                />
            </div>
            <Row className="mt-4 max-lg">
                <Col
                    className={classNames("my-4 my-md-0 pr-0", {
                        ["pr-5"]: order?.delivery_details?.pickup,
                    })}
                    lg={6}
                >
                    <div
                        className={classNames(
                            "order__detail_info_content",
                            "separator",
                        )}
                    >
                        {order?.delivery_details?.pickup ? (
                            <h5> Pickup for </h5>
                        ) : (
                            <h5> Delivery for </h5>
                        )}
                        <div className="order__detail_info">
                            <div className="order__detail_info_item">
                                <strong>Name:</strong>
                                <span className="d-block">
                                    <a onClick={handleAccountDetian}>
                                        {`${
                                            order?.delivery_address
                                                ?.first_name || ""
                                        } ${
                                            order?.delivery_address
                                                ?.last_name || ""
                                        }`}
                                    </a>
                                </span>
                            </div>
                            <div className="order__detail_info_item">
                                <strong>Phone:</strong>
                                <span className="d-block">
                                    {isEditable ? (
                                        <FormGroup>
                                            <Input
                                                defaultValue={
                                                    order?.delivery_address
                                                        ?.phone ?? ""
                                                }
                                                invalid={
                                                    validator["address.phone"]
                                                        .isInvalid
                                                }
                                                name="address.phone"
                                                onChange={inputChange}
                                                placeholder="Phone"
                                                value={inputs.address.phone}
                                            />
                                            <FormFeedback>
                                                {
                                                    validator["address.phone"]
                                                        .message
                                                }
                                            </FormFeedback>
                                        </FormGroup>
                                    ) : (
                                        <span className="d-block">
                                            {order?.delivery_address?.phone ||
                                                NOT_AVAILABLE}
                                        </span>
                                    )}
                                </span>
                            </div>
                            <div className="order__detail_info_item">
                                <strong>Email:</strong>
                                <span className="d-block">
                                    {order?.email || NOT_AVAILABLE}
                                </span>
                            </div>
                            <div className="order__detail_info_item">
                                <strong>Payment method:</strong>
                                {isEditable ? (
                                    <FormGroup>
                                        <Select
                                            controlName="payment_method"
                                            defaultValue={selectValue(
                                                order?.payment_details?.type,
                                                PAYMENT_METHOD,
                                            )}
                                            invalid={
                                                validator["payment_method"]
                                                    .isInvalid
                                            }
                                            onChange={selectChange}
                                            options={PAYMENT_METHOD}
                                        />
                                        <FormFeedback>
                                            {
                                                (validator as any)
                                                    .payment_method.message
                                            }
                                        </FormFeedback>
                                    </FormGroup>
                                ) : (
                                    <span className="d-block">
                                        {_.startCase(
                                            order?.payment_details?.type,
                                        ) || NOT_AVAILABLE}
                                    </span>
                                )}
                            </div>
                            <div className="order__detail_info_item">
                                <strong>Verified status:</strong>
                                <span className="d-block">
                                    {renderVerifyStatus()}
                                </span>
                            </div>
                            <div className="order__detail_info_item">
                                <strong>Device:</strong>
                                <span className="d-block">
                                    {order?.client_detail?.device ||
                                        NOT_AVAILABLE}
                                </span>
                            </div>
                            <div className="order__detail_info_item">
                                <strong>Delivery window:</strong>
                                {isEditable ? (
                                    <div className="order__detail_info_item__window">
                                        <FormGroup>
                                            <DateRangePicker
                                                autoApply
                                                onApply={(e, picker) => {
                                                    datepickerChange(
                                                        picker,
                                                        "time_window.deliver_after",
                                                    );
                                                }}
                                                singleDatePicker
                                                startDate={
                                                    order?.delivery_details
                                                        ?.deliver_after
                                                        ? moment(
                                                              order
                                                                  ?.delivery_details
                                                                  ?.deliver_after,
                                                          ).format("MM/DD/YYYY")
                                                        : ""
                                                }
                                            >
                                                <Input
                                                    onChange={inputChange}
                                                    placeholder="Select a date"
                                                    value={
                                                        inputs?.time_window
                                                            ?.deliver_after
                                                            ? moment(
                                                                  inputs
                                                                      ?.time_window
                                                                      ?.deliver_after,
                                                              ).format(
                                                                  "MM/DD/YYYY",
                                                              )
                                                            : ""
                                                    }
                                                />
                                            </DateRangePicker>
                                        </FormGroup>
                                        <FormGroup>
                                            <Select
                                                controlName="time_window.time_window"
                                                onChange={selectChange}
                                                options={[
                                                    ...DELIVERY_WINDOW_HOURS,
                                                    {
                                                        value: order
                                                            ?.delivery_details
                                                            ?.time_window,
                                                        label: timeWindowFormat(
                                                            order
                                                                ?.delivery_details
                                                                ?.time_window,
                                                        ),
                                                    },
                                                ]}
                                                placeholder="Select time slot"
                                                value={selectValue(
                                                    inputs.time_window
                                                        .time_window,
                                                    [
                                                        ...DELIVERY_WINDOW_HOURS,
                                                        {
                                                            value: order
                                                                ?.delivery_details
                                                                ?.time_window,
                                                            label: timeWindowFormat(
                                                                order
                                                                    ?.delivery_details
                                                                    ?.time_window,
                                                            ),
                                                        },
                                                    ],
                                                )}
                                            />
                                        </FormGroup>
                                    </div>
                                ) : (
                                    <span className="d-block">
                                        {`${moment(
                                            order.delivery_details
                                                .deliver_after,
                                        ).format("MMMM Do")}${
                                            order.delivery_details.time_window
                                                ? `, ${timeWindowFormat(
                                                      order.delivery_details
                                                          .time_window,
                                                  )}`
                                                : ""
                                        }`}
                                    </span>
                                )}
                            </div>
                            <div className="order__detail_info_item">
                                <strong>Customer Notes:</strong>
                                {isEditable && (
                                    <FormGroup>
                                        <Input
                                            name="notes"
                                            onChange={inputChange}
                                            placeholder="Write the note..."
                                            rows={5}
                                            type="textarea"
                                            value={inputs?.notes || ""}
                                        />
                                    </FormGroup>
                                )}
                                {!isEditable && (
                                    <span className="d-block">
                                        {order?.notes || ""}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className="my-4 my-md-0 p-0" lg={6}>
                    <div className="order__detail_info_content content_delivery">
                        <h5>
                            {`${
                                order?.delivery_details?.pickup
                                    ? "Pickup"
                                    : "Delivery"
                            } Location`}
                        </h5>
                        <div className="order__detail_info">
                            <div className="order__detail_info_item">
                                <strong>Address line 1:</strong>
                                {isEditable ? (
                                    <FormGroup>
                                        <Input
                                            defaultValue={
                                                order?.delivery_address
                                                    ?.address1 ?? ""
                                            }
                                            invalid={
                                                validator["address.address1"]
                                                    .isInvalid
                                            }
                                            name="address.address1"
                                            onChange={inputChange}
                                            placeholder="Address line"
                                            value={inputs.address.address1}
                                        />
                                        <FormFeedback>
                                            {
                                                validator["address.address1"]
                                                    .message
                                            }
                                        </FormFeedback>
                                    </FormGroup>
                                ) : (
                                    <span className="d-block">
                                        {order?.delivery_address?.address1 ||
                                            NOT_AVAILABLE}
                                    </span>
                                )}
                            </div>

                            <div className="order__detail_info_item">
                                <strong>Address line 2:</strong>
                                {isEditable ? (
                                    <FormGroup>
                                        <Input
                                            defaultValue={
                                                order?.delivery_address
                                                    ?.address2 ?? ""
                                            }
                                            name="address.address2"
                                            onChange={inputChange}
                                            placeholder="Address line"
                                            value={inputs.address.address2}
                                        />
                                    </FormGroup>
                                ) : (
                                    <span className="d-block">
                                        {order?.delivery_address?.address2 ||
                                            NOT_AVAILABLE}
                                    </span>
                                )}
                            </div>

                            <div className="order__detail_info_item">
                                <strong>City:</strong>
                                {isEditable ? (
                                    <FormGroup>
                                        <Input
                                            defaultValue={
                                                order?.delivery_address?.city ??
                                                ""
                                            }
                                            invalid={
                                                validator["address.city"]
                                                    .isInvalid
                                            }
                                            name="address.city"
                                            onChange={inputChange}
                                            placeholder="City"
                                            value={inputs.address.city}
                                        />
                                        <FormFeedback>
                                            {validator["address.city"].message}
                                        </FormFeedback>
                                    </FormGroup>
                                ) : (
                                    <span className="d-block">
                                        {order?.delivery_address?.city ||
                                            NOT_AVAILABLE}
                                    </span>
                                )}
                            </div>

                            <div className="order__detail_info_item">
                                <strong>Zip code:</strong>
                                {isEditable ? (
                                    <FormGroup>
                                        <Input
                                            defaultValue={
                                                order?.delivery_address?.zip ??
                                                ""
                                            }
                                            invalid={
                                                validator["address.zip"]
                                                    .isInvalid
                                            }
                                            name="address.zip"
                                            onChange={inputChange}
                                            placeholder="Zip"
                                            value={inputs.address.zip}
                                        />
                                        <FormFeedback>
                                            {validator["address.zip"].message}
                                        </FormFeedback>
                                    </FormGroup>
                                ) : (
                                    <span className="d-block">
                                        {order?.delivery_address?.zip ||
                                            NOT_AVAILABLE}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            {order?.status === "PENDING" && (
                <ProductBarcodes
                    isLoading={isLoading}
                    lineItems={order?.line_items}
                    orderId={order?.entity_id}
                />
            )}
            <Row className="mt-5 mb-4">
                <Col>
                    <h5>Items Ordered</h5>
                    <OrdersItemTable
                        getOrderLoading={isLoading}
                        handleAddItemQuantity={addLineItemQuantity}
                        handleSubItemQuantity={subLineItemQuantity}
                        lineItems={lineItems}
                        loading={loading}
                        namesProductsPOS={namesProductsPOS}
                        selection={selection}
                        setSelection={setSelection}
                        showEditForm={isEditable}
                        updating={false}
                        widthColumnPrice={getColumWidth()}
                    />
                </Col>
            </Row>
            {isEditable && showAddForm && (
                <Row className="mb-4">
                    <Col>
                        <AddLineItemForm
                            addLineItem={addLineItem}
                            hideForm={() => {
                                setShowAddForm(false);
                            }}
                            lineItems={order.line_items}
                            providerStoreID={
                                order.jane_store_id || order.provider_store_id
                            }
                        />
                    </Col>
                </Row>
            )}
            <Row className="table__content_wrapper">
                <div className="table_actions">
                    {isEditable && (
                        <div className="order__details_toolbar">
                            <Button
                                className="btn__options btn__toolbar"
                                color="metal"
                                disabled={showAddForm}
                                onChange={changeItems}
                                onClick={handleAddItem}
                            >
                                <Icon icon="add" />
                                Add item
                            </Button>
                            {selection.length ? (
                                <Button
                                    className="btn__options btn__toolbar"
                                    color="metal"
                                    onClick={handleRemoveLineItems}
                                >
                                    <Icon icon="remove" />
                                    {`Delete item${
                                        selection.length > 1 ? "s" : ""
                                    }`}{" "}
                                    {selection.length
                                        ? `(${selection.length})`
                                        : null}
                                </Button>
                            ) : null}
                        </div>
                    )}
                </div>
                <table
                    className={classNames("table__summary", {
                        ["table__summary_edit"]:
                            isEditable && !order?.discount_items,
                        ["table__summary_promo_code"]:
                            order?.discount_items?.length > 0,
                        ["table__summary_promo_code_edit"]: isEditable,
                    })}
                >
                    <tbody>
                        {order?.discount_items?.length || isEditable ? (
                            <tr className="sumary__less_border">
                                <td className="td__promo_code">Promo code:</td>
                                <td
                                    className="text-left"
                                    width={getColumWidth()}
                                >
                                    {isEditable ? (
                                        <FormGroup>
                                            <Input
                                                name="promo_code"
                                                onChange={inputChange}
                                                placeholder="Promo code..."
                                                type="text"
                                                value={inputs.promo_code || ""}
                                            />
                                        </FormGroup>
                                    ) : null}

                                    {order?.discount_items?.length ? (
                                        <ul>
                                            {order?.discount_items.map(
                                                (discount, index) => {
                                                    return (
                                                        <li key={index}>
                                                            {
                                                                discount.promo_code
                                                            }

                                                            {isEditable ? (
                                                                <Button
                                                                    color="link"
                                                                    onClick={() =>
                                                                        handleRemoveDiscountItem(
                                                                            discount?.promo_code,
                                                                        )
                                                                    }
                                                                >
                                                                    <Icon icon="delete" />
                                                                </Button>
                                                            ) : null}
                                                        </li>
                                                    );
                                                },
                                            )}
                                        </ul>
                                    ) : null}
                                </td>
                            </tr>
                        ) : null}
                        <tr className="sumary__less_border">
                            <td className="h5 pr-5 py-1 text-left">
                                Subtotal:
                            </td>
                            <td className="text-left" width={getColumWidth()}>
                                {" "}
                                <span className="text-left">
                                    {`${currencyFormat(order.subtotal_price)}`}
                                </span>
                            </td>
                        </tr>
                        <tr className="sumary__less_border">
                            <td className="h5 pr-5 py-1 text-left">
                                Delivery fee:
                            </td>
                            <td className="text-left" width={getColumWidth()}>
                                <span className="text-left">
                                    {`${currencyFormat(order.total_delivery)}`}
                                </span>
                            </td>
                        </tr>
                        <tr className="sumary__less_border">
                            <td className="h5 pr-5 py-1 text-left">
                                Service fee:
                            </td>
                            <td className="text-left" width={getColumWidth()}>
                                <span className="text-left">
                                    {`${currencyFormat(
                                        order.total_service_fee,
                                    )}`}
                                </span>
                            </td>
                        </tr>
                        <tr className="sumary__less_border">
                            <td className="h5 pr-5 py-1 text-left">Tax:</td>
                            <td className="text-left" width={getColumWidth()}>
                                <span className="text-left">
                                    {_.get(order, "total_tax", 0) > 0
                                        ? `${currencyFormat(order.total_tax)}`
                                        : "Included"}
                                </span>
                            </td>
                        </tr>
                        {_.get(order, "total_discounts", 0) > 0 && (
                            <tr className="text-brand sumary__less_border">
                                <td className="text-danger h5 pr-5 py-1 text-left">
                                    Discounts:
                                </td>
                                <td
                                    className="text-left"
                                    width={getColumWidth()}
                                >
                                    <span className="text-left">
                                        {`-${currencyFormat(
                                            order.total_discounts,
                                        )}`}
                                    </span>
                                </td>
                            </tr>
                        )}
                        <tr className="sumary__less_border">
                            <td className="h5 text-brand pr-5 py-1 text-left">
                                Total:
                            </td>
                            <td
                                className="h5 text-brand text-left"
                                width={getColumWidth()}
                            >
                                <span className="text-left">
                                    {`${currencyFormat(order.total_price)}`}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Row>
        </Section>
    );

    function SkeletonScreen() {
        return (
            <>
                <Row>
                    <Col>
                        <div className="content-placeholder">
                            <div className="cp-w-lg cp-h-lg" />
                        </div>
                    </Col>
                </Row>
                <Row className={"mt-5"}>
                    <Col>
                        <div className="content-placeholder">
                            <div className="cp-w-lg cp-h-md" />
                        </div>
                        <div className="content-placeholder mt-4">
                            <div className="cp-w-lg cp-h-md" />
                        </div>
                        <div className="content-placeholder mt-4">
                            <div className="cp-w-lg cp-h-md" />
                        </div>
                    </Col>
                    <Col>
                        <div className="content-placeholder">
                            <div className="cp-w-lg cp-h-md" />
                        </div>
                    </Col>
                </Row>
                <Row className={"mt-5"}>
                    <Col>
                        <div className="content-placeholder">
                            <div className="cp-w-lg cp-h-md" />
                        </div>
                        <div className="content-placeholder mt-4">
                            <div className="cp-w-lg cp-h-md" />
                        </div>
                        <div className="content-placeholder mt-4">
                            <div className="cp-w-lg cp-h-md" />
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
};

export default OrderDataPane;
