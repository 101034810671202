import React from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { Portlet, Section, SectionTitle } from "../../base/components/portlet";
import { FormGroup, Col, Label, Row, FormFeedback } from "reactstrap";
import Input from "../../base/components/form/input/Input";
import Form from "../../base/components/form/Form";
import useMeta from "../../base/hooks/useMeta";
import useForm from "../../base/hooks/form/useForm";
import useFormActions from "../../base/hooks/form/useFormActions";
import {
    MUTATION_UPDATE_STRIPE_APP,
    QUERY_LIST_STORE_APPS,
} from "../../apollo/module-operations/app";
import _ from "lodash";
import useValidator from "../../base/hooks/form/useValidator";

const StripeForm = ({ app }) => {
    const { user } = useMeta();
    const { inputs, setInputs, inputChange } = useForm();

    if (!_.get(inputs, "id", null)) {
        setInputs(app);
    }

    let options = {
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
            input: {
                ...inputs,
                live_mode: inputs.live_mode ? true : false,
                stripe_payment_category: "PAYMENT_TOKEN_FRONTEND",
                type: "Stripe",
            },
        },
    };

    const { validator, isValid, isSubmited } = useValidator(
        [
            {
                field: "public_test_key",
                method: "isEmpty",
                validWhen: false,
                message: "This Public Key is required.",
            },
            {
                field: "public_key",
                method: "isEmpty",
                validWhen: false,
                message: "This Public Key is required.",
            },
            {
                field: "secret_test_key",
                method: "isEmpty",
                validWhen: false,
                message: "This Secret Key is required.",
            },
            {
                field: "secret_key",
                method: "isEmpty",
                validWhen: false,
                message: "This Secret Key is required.",
            },
        ],
        {
            ...inputs,
        },
    );

    const { createOrUpdateButton } = useFormActions(
        "id",
        "Stripe",
        "",
        MUTATION_UPDATE_STRIPE_APP,
        [
            {
                query: QUERY_LIST_STORE_APPS,
                variables: {
                    account_id: user.accountId,
                    store_id: user.storeId,
                },
            },
        ],
        options,
        "",
        isValid,
        isSubmited,
        "secondary",
    );

    return (
        <Portlet
            centered
            header={{
                title: "Stripe",
                subtitle: "Stripe Information",
            }}
            sticky
        >
            {!user.loading ? (
                <Form centered>
                    <Section>
                        <Row>
                            <Label lg={4} xl={3} />
                            <Col>
                                <SectionTitle md>Information</SectionTitle>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Test Mode
                            </Label>
                            <Col>
                                <span className="k-switch k-switch--md k-switch--icon">
                                    <label>
                                        <Input
                                            checked={
                                                inputs.live_mode
                                                    ? "checked"
                                                    : ""
                                            }
                                            name="live_mode"
                                            onChange={inputChange}
                                            type="checkbox"
                                        />
                                        <span />
                                    </label>
                                </span>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Public Key
                            </Label>
                            <Col>
                                <Input
                                    invalid={
                                        inputs.live_mode
                                            ? validator.public_test_key
                                                  .isInvalid
                                            : validator.public_key.isInvalid
                                    }
                                    name={
                                        inputs.live_mode
                                            ? "public_test_key"
                                            : "public_key"
                                    }
                                    onChange={inputChange}
                                    value={
                                        inputs.live_mode
                                            ? inputs.public_test_key
                                            : inputs.public_key
                                    }
                                />
                                <FormFeedback>
                                    {inputs.live_mode
                                        ? validator.public_test_key.message
                                        : validator.public_key.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Secret Key
                            </Label>
                            <Col>
                                <Input
                                    invalid={
                                        inputs.live_mode
                                            ? validator.secret_test_key
                                                  .isInvalid
                                            : validator.secret_key.isInvalid
                                    }
                                    name={
                                        inputs.live_mode
                                            ? "secret_test_key"
                                            : "secret_key"
                                    }
                                    onChange={inputChange}
                                    value={
                                        inputs.live_mode
                                            ? inputs.secret_test_key
                                            : inputs.secret_key
                                    }
                                />
                                <FormFeedback>
                                    {inputs.live_mode
                                        ? validator.secret_test_key.message
                                        : validator.secret_key.message}
                                </FormFeedback>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label lg={4} xl={3} />
                            <Col>{createOrUpdateButton}</Col>
                        </FormGroup>
                    </Section>
                </Form>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
        </Portlet>
    );
};

export default StripeForm;
