import gql from "graphql-tag";

export const QUERY_PRODUCTS_TREEZ = gql`
    query listTreezProducts($account_id: String!, $store_id: String!) {
        listTreezProducts(account_id: $account_id, store_id: $store_id) {
            products {
                id
                name
                barcodes
            }
        }
    }
`;

//Discounts of TREEZ

export const QUERY_TREEZ_HEADLESS_DISCOUNT = gql`
    query getTreezHeadlessDiscounts(
        $account_id: String!
        $store_id: String!
        $provider_id: String!
    ) {
        getTreezHeadlessDiscounts(
            account_id: $account_id
            store_id: $store_id
            provider_id: $provider_id
        ) {
            id
            title
            method
            stackable
            currentFlag
            amount
            groups
            groups_required
            cart
            discount_numeric_type
            require_coupon
            active
            adjustment
            rewardable
        }
    }
`;

//Groups of TREEZ

export const QUERY_TREEZ_HEADLESS_GROUPS = gql`
    query getTreezHeadlessGroups(
        $account_id: String!
        $store_id: String!
        $provider_id: String!
    ) {
        getTreezHeadlessGroups(
            account_id: $account_id
            store_id: $store_id
            provider_id: $provider_id
        ) {
            id
            name
            type
            rules
            in_list
            out_list
            current_flag
            restaurant_id
            syncing
        }
    }
`;

//CONFIG of TREEZ

export const QUERY_TREEZ_HEADLESS_CONFIG = gql`
    query getTreezHeadlessConfig(
        $account_id: String!
        $store_id: String!
        $provider_id: String!
        $customerType: CustomerType!
        $strictMedicalCustomerType: Boolean
    ) {
        getTreezHeadlessConfig(
            account_id: $account_id
            store_id: $store_id
            provider_id: $provider_id
            customerType: $customerType
            strictMedicalCustomerType: $strictMedicalCustomerType
        ) {
            short_name
            locations {
                id
                inventory_type
                name
            }
            address {
                street
                city
                state
                country
                zip
                longitude
                latitude
            }
        }
    }
`;
