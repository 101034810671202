import gql from "graphql-tag";

let Landing = {};
Landing.fragments = {
    attributes: gql`
        fragment LandingFragment on Landing {
            id
            url
            title
            project
            published
        }
    `,
    list: gql`
        fragment LandingListFragment on Landing {
            id
            url
            title
            published
        }
    `,
};

export const QUERY_LANDING_LIST = gql`
    query listLandings($account_id: String!, $store_id: String!) {
        listLandings(account_id: $account_id, store_id: $store_id) {
            ...LandingListFragment
        }
    }
    ${Landing.fragments.list}
`;

export const QUERY_GET_LANDING = gql`
    query getLanding(
        $account_id: String!
        $landing_id: String!
        $store_id: String!
    ) {
        getLanding(
            account_id: $account_id
            store_id: $store_id
            landing_id: $landing_id
        ) {
            ...LandingFragment
        }
    }
    ${Landing.fragments.attributes}
`;

export const MUTATION_CREATE_LANDING = gql`
    mutation createLanding(
        $account_id: String!
        $store_id: String!
        $input: LandingInput!
    ) {
        createLanding(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            ...LandingFragment
        }
    }
    ${Landing.fragments.attributes}
`;

export const MUTATION_UPDATE_LANDING = gql`
    mutation updateLanding(
        $account_id: String!
        $store_id: String!
        $input: LandingInput!
    ) {
        updateLanding(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            ...LandingFragment
        }
    }
    ${Landing.fragments.attributes}
`;

export const MUTATION_PUBLISH_LANDING = gql`
    mutation publishLanding(
        $account_id: String!
        $store_id: String!
        $input: LandingInput!
    ) {
        publishLanding(
            account_id: $account_id
            store_id: $store_id
            input: $input
        )
    }
`;

export const MUTATION_REMOVE_LANDING = gql`
    mutation deleteLanding(
        $account_id: String!
        $store_id: String!
        $input: LandingInput!
    ) {
        deleteLanding(
            account_id: $account_id
            store_id: $store_id
            input: $input
        )
    }
`;
