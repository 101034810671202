import React from "react";
import Spinner from "../../base/components/spinner/Spinner";
import { Portlet, Section, SectionTitle } from "../../base/components/portlet";
import { FormGroup, Col, Label, Row, FormFeedback } from "reactstrap";
import Input from "../../base/components/form/input/Input";
import Form from "../../base/components/form/Form";
import { TYPEOFSERVICE } from "../../config/constant";
import { Select } from "@/components";

const AdminSettingForm = ({
    toolbar,
    inputs,
    inputChange,
    selectChange,
    selectValue,
    validator,
    loading,
    accountId,
    storeId,
}) => {
    return (
        <Portlet
            className="business__settings"
            header={{
                title: "Admin Setting",
                subtitle: "Admin Setting Information",
            }}
            sticky
            toolbar={toolbar}
        >
            {!loading ? (
                <Form>
                    <Section>
                        <Row>
                            <Col>
                                <SectionTitle md>Plan</SectionTitle>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Col lg={4}>
                                <Label>Tier Plan</Label>
                                <Select
                                    controlName="tier_plan"
                                    onChange={selectChange}
                                    options={TYPEOFSERVICE}
                                    value={selectValue(
                                        inputs.tier_plan || "SCALE",
                                        TYPEOFSERVICE,
                                    )}
                                />
                            </Col>
                        </FormGroup>
                    </Section>
                    <Section className="section-separator" />
                    <Section>
                        <Row>
                            <Col>
                                <SectionTitle md>Account Info</SectionTitle>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <FormGroup>
                                    <Label>Account ID</Label>
                                    <Input
                                        disabled={true}
                                        name="account_id"
                                        value={accountId}
                                    />
                                </FormGroup>
                            </Col>

                            <Col lg={4}>
                                <FormGroup>
                                    <Label>Store ID</Label>
                                    <Input
                                        disabled={true}
                                        name="store_id"
                                        value={storeId}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Section>
                    <Section className="section-separator" />
                    <Section>
                        <Row>
                            <Col>
                                <SectionTitle md>
                                    Frontend Deployment
                                </SectionTitle>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <FormGroup row>
                                    <Label>Provider URL</Label>
                                    <Input
                                        defaultValue={
                                            inputs.provider_deployment_url ?? ""
                                        }
                                        name="provider_deployment_url"
                                        onChange={inputChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={4}>
                                <FormGroup row>
                                    <Label>Vercel Project Id</Label>
                                    <Input
                                        defaultValue={
                                            inputs.vercel_project_id ?? ""
                                        }
                                        name="vercel_project_id"
                                        onChange={inputChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Section>
                    <Section>
                        <Row>
                            <Col lg={4}>
                                <FormGroup row>
                                    <Label>Vercel Team Id</Label>
                                    <Input
                                        defaultValue={
                                            inputs.vercel_team_id ?? ""
                                        }
                                        name="vercel_team_id"
                                        onChange={inputChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Section>
                    <Section className="section-separator" />
                    <Section>
                        <Row>
                            <Col>
                                <SectionTitle md>CMS</SectionTitle>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <FormGroup>
                                    <Label>Prismic Api Endpoint</Label>
                                    <Input
                                        defaultValue={
                                            inputs.prismic_api_endpoint ?? ""
                                        }
                                        name="prismic_api_endpoint"
                                        onChange={inputChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Section>
                    <Section className="section-separator" />
                    <Section>
                        <Row>
                            <Col>
                                <SectionTitle md>Database Setting</SectionTitle>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <Label>Dynamo Order Table Name</Label>
                                <Input
                                    defaultValue={
                                        inputs.dynamo_order_table_name ?? ""
                                    }
                                    name="dynamo_order_table_name"
                                    onChange={inputChange}
                                />
                            </Col>
                        </Row>
                    </Section>
                    <Section className="section-separator" />
                    <Section>
                        <Row>
                            <Col>
                                <SectionTitle md>
                                    Account Activation
                                </SectionTitle>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Label className="row-label">Active</Label>
                            <Col>
                                <span className="k-switch k-switch--md k-switch--icon">
                                    <label>
                                        <Input
                                            checked={
                                                inputs.account_setting &&
                                                inputs.account_setting.active
                                                    ? "checked"
                                                    : ""
                                            }
                                            name="account_setting.active"
                                            onChange={inputChange}
                                            type="checkbox"
                                        />
                                        <span />
                                    </label>
                                </span>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label className="row-label">
                                Auto Verify Email
                            </Label>
                            <Col>
                                <span className="k-switch k-switch--md k-switch--icon">
                                    <label>
                                        <Input
                                            checked={
                                                inputs.account_setting &&
                                                inputs.account_setting
                                                    .auto_verify_email
                                                    ? "checked"
                                                    : ""
                                            }
                                            name="account_setting.auto_verify_email"
                                            onChange={inputChange}
                                            type="checkbox"
                                        />
                                        <span />
                                    </label>
                                </span>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col>
                                <Label>Cognito Identity Pool Id</Label>
                                <Input
                                    defaultValue={
                                        inputs.account_setting
                                            ? inputs.account_setting
                                                  .cognito_identity_pool_id
                                            : ""
                                    }
                                    name="account_setting.cognito_identity_pool_id"
                                    onChange={inputChange}
                                />
                            </Col>
                            <Col>
                                <Label>Cognito Region</Label>
                                <Input
                                    defaultValue={
                                        inputs.account_setting
                                            ? inputs.account_setting
                                                  .cognito_region
                                            : ""
                                    }
                                    name="account_setting.cognito_region"
                                    onChange={inputChange}
                                />
                            </Col>
                            <Col>
                                <Label>User Pools Id</Label>
                                <Input
                                    defaultValue={
                                        inputs.account_setting
                                            ? inputs.account_setting
                                                  .user_pools_id
                                            : ""
                                    }
                                    name="account_setting.user_pools_id"
                                    onChange={inputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col lg={4}>
                                <Label>User Pools Web Client Id</Label>
                                <Input
                                    defaultValue={
                                        inputs.account_setting
                                            ? inputs.account_setting
                                                  .user_pools_web_client_id
                                            : ""
                                    }
                                    name="account_setting.user_pools_web_client_id"
                                    onChange={inputChange}
                                />
                            </Col>
                        </FormGroup>
                    </Section>
                    <Section className="section-separator" />
                    <Section>
                        <Row>
                            <Col>
                                <SectionTitle md>Analytics</SectionTitle>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Label className="row-label">Active</Label>
                            <Col>
                                <span className="k-switch k-switch--md k-switch--icon">
                                    <label>
                                        <Input
                                            checked={
                                                inputs.aws_pinpoint_analytics &&
                                                inputs.aws_pinpoint_analytics
                                                    .active
                                                    ? "checked"
                                                    : ""
                                            }
                                            name="aws_pinpoint_analytics.active"
                                            onChange={inputChange}
                                            type="checkbox"
                                        />
                                        <span />
                                    </label>
                                </span>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col>
                                <Label>Region</Label>
                                <Input
                                    defaultValue={
                                        inputs.aws_pinpoint_analytics
                                            ? inputs.aws_pinpoint_analytics
                                                  .region
                                            : ""
                                    }
                                    name="aws_pinpoint_analytics.region"
                                    onChange={inputChange}
                                />
                            </Col>
                            <Col>
                                <Label>App Id</Label>
                                <Input
                                    defaultValue={
                                        inputs.aws_pinpoint_analytics
                                            ? inputs.aws_pinpoint_analytics
                                                  .app_id
                                            : ""
                                    }
                                    name="aws_pinpoint_analytics.app_id"
                                    onChange={inputChange}
                                />
                            </Col>
                            <Col>
                                <Label>Identity Pool Id</Label>
                                <Input
                                    defaultValue={
                                        inputs.aws_pinpoint_analytics
                                            ? inputs.aws_pinpoint_analytics
                                                  .identity_pool_id
                                            : ""
                                    }
                                    name="aws_pinpoint_analytics.identity_pool_id"
                                    onChange={inputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col lg={4}>
                                <Label>Auth Region</Label>
                                <Input
                                    defaultValue={
                                        inputs.aws_pinpoint_analytics
                                            ? inputs.aws_pinpoint_analytics
                                                  .auth_region
                                            : ""
                                    }
                                    name="aws_pinpoint_analytics.auth_region"
                                    onChange={inputChange}
                                />
                            </Col>
                        </FormGroup>
                    </Section>
                    <Section className="section-separator" />
                    <Section>
                        <Row>
                            <Col>
                                <SectionTitle md>Algolia</SectionTitle>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Col lg={4}>
                                <Label>Order Index</Label>
                                <Input
                                    defaultValue={
                                        inputs.algolia_order_index || ""
                                    }
                                    /*   invalid={
                                        validator.algolia_order_index.isInvalid
                                    } */
                                    name="algolia_order_index"
                                    onChange={inputChange}
                                />
                                {/*  <FormFeedback>
                                    {validator.algolia_order_index.message}
                                </FormFeedback> */}
                            </Col>
                        </FormGroup>
                    </Section>
                    <Section className="section-separator" />
                    <Section>
                        <Row>
                            <Col>
                                <SectionTitle md>Basic Settings</SectionTitle>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Col lg={4}>
                                <Label>S3 Bucket</Label>
                                <Input
                                    defaultValue={inputs.s3_bucket ?? ""}
                                    name="s3_bucket"
                                    onChange={inputChange}
                                />
                            </Col>
                        </FormGroup>
                        <Row>
                            <Col lg={4}>
                                <FormGroup>
                                    <Label>Store Cache Version</Label>
                                    <Input
                                        defaultValue={
                                            inputs.store_cache_version ?? ""
                                        }
                                        name="store_cache_version"
                                        onChange={inputChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={4}>
                                <FormGroup>
                                    <Label>Google Map Api URL</Label>
                                    <Input
                                        defaultValue={
                                            inputs.google_map_api_url ?? ""
                                        }
                                        name="google_map_api_url"
                                        onChange={inputChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <FormGroup>
                                    <Label>AWS Profile</Label>
                                    <Input
                                        defaultValue={inputs.aws_profile ?? ""}
                                        invalid={
                                            validator.aws_profile.isInvalid
                                        }
                                        name="aws_profile"
                                        onChange={inputChange}
                                    />
                                    <FormFeedback>
                                        {validator.aws_profile.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col lg={4}>
                                <FormGroup>
                                    <Label>AWS Region</Label>
                                    <Input
                                        defaultValue={inputs.aws_region ?? ""}
                                        invalid={validator.aws_region.isInvalid}
                                        name="aws_region"
                                        onChange={inputChange}
                                    />
                                    <FormFeedback>
                                        {validator.aws_region.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Section>

                    {/*   
                    <Section>
                        <Row>
                            <Label lg={4} xl={3} />
                            <Col>
                                <SectionTitle md>
                                    Account Activation
                                </SectionTitle>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Active
                            </Label>
                            <Col>
                                <span className="k-switch k-switch--md k-switch--icon">
                                    <label>
                                        <Input
                                            checked={
                                                inputs.account_setting &&
                                                inputs.account_setting.active
                                                    ? "checked"
                                                    : ""
                                            }
                                            onChange={inputChange}
                                            name="account_setting.active"
                                            type="checkbox"
                                        />
                                        <span />
                                    </label>
                                </span>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Auto Verify Email
                            </Label>
                            <Col>
                                <span className="k-switch k-switch--md k-switch--icon">
                                    <label>
                                        <Input
                                            checked={
                                                inputs.account_setting &&
                                                inputs.account_setting
                                                    .auto_verify_email
                                                    ? "checked"
                                                    : ""
                                            }
                                            onChange={inputChange}
                                            name="account_setting.auto_verify_email"
                                            type="checkbox"
                                        />
                                        <span />
                                    </label>
                                </span>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Cognito Identity Pool Id
                            </Label>
                            <Col>
                                <Input
                                    name="account_setting.cognito_identity_pool_id"
                                    defaultValue={
                                        inputs.account_setting
                                            ? inputs.account_setting
                                                  .cognito_identity_pool_id
                                            : ""
                                    }
                                    onChange={inputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Cognito Region
                            </Label>
                            <Col>
                                <Input
                                    name="account_setting.cognito_region"
                                    defaultValue={
                                        inputs.account_setting
                                            ? inputs.account_setting
                                                  .cognito_region
                                            : ""
                                    }
                                    onChange={inputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                User Pools Id
                            </Label>
                            <Col>
                                <Input
                                    name="account_setting.user_pools_id"
                                    defaultValue={
                                        inputs.account_setting
                                            ? inputs.account_setting
                                                  .user_pools_id
                                            : ""
                                    }
                                    onChange={inputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                User Pools Web Client Id
                            </Label>
                            <Col>
                                <Input
                                    name="account_setting.user_pools_web_client_id"
                                    defaultValue={
                                        inputs.account_setting
                                            ? inputs.account_setting
                                                  .user_pools_web_client_id
                                            : ""
                                    }
                                    onChange={inputChange}
                                />
                            </Col>
                        </FormGroup>
                    </Section>

                    Shipping integration 
                    <Section>
                        <Row>
                            <Label lg={4} xl={3} />
                            <Col>
                                <SectionTitle md>Shipping</SectionTitle>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Client id
                            </Label>
                            <Col>
                                <Input
                                    name="client_id"
                                    value={_.get(inputs, "client_id") || ""}
                                    onChange={inputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label lg={4} xl={3}>
                                Client secret
                            </Label>
                            <Col>
                                <Input
                                    name="client_secret"
                                    value={_.get(inputs, "client_secret") || ""}
                                    onChange={inputChange}
                                />
                            </Col>
                        </FormGroup>
                    </Section>
                    */}
                </Form>
            ) : (
                <>
                    <Spinner color="metal" show sm />
                    &nbsp;&nbsp;Loading
                </>
            )}
        </Portlet>
    );
};

export default AdminSettingForm;
