import React, { useState, useEffect, useRef } from "react";
import Content from "../../base/layout/content/Content";
import useMeta from "../../base/hooks/useMeta";
import useStoreDomain from "../../base/hooks/store/useStoreDomain";
import { Portlet } from "../../base/components/portlet";
import Spinner from "../../base/components/spinner/Spinner";
import useNotification from "../../base/hooks/components/useNotification";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import {
    QUERY_GET_LANDING,
    MUTATION_CREATE_LANDING,
    MUTATION_UPDATE_LANDING,
    MUTATION_PUBLISH_LANDING,
    QUERY_LANDING_LIST,
} from "../../apollo/module-operations/landing";
import routes from "../../config/routes/routes";
import _ from "lodash";
import LandingSelector from "./LandingSelector";
import grapesjs from "grapesjs";
import baseCss from "./config/css";
import storageManager from "./config/storage";
import plugins from "./config/plugins";
import options from "./config/options";
import init from "./config/init";
import "./assets/main.scss";
import { QUERY_PRODUCT_LIST } from "../../apollo/module-operations/product";

const SAVED_SUCCESSFULLY_MESSAGE = "Landing page :operation successfully.";
const SAVED_ERROR_MESSAGE = "Error :operation the landing page.";
const PUBLISHED_SUCCESSFULLY_MESSAGE = "Landing page published successfully.";
const PUBLISHED_ERROR_MESSAGE = "Error publishing the landing page.";

const LandingForm = () => {
    const { resourceId, isCreate, user } = useMeta();

    const [loading, setLoading] = useState(true);

    const [initialized, setInitialized] = useState(false);

    const [selectorOpen, setSelectorOpen] = useState({
        open: isCreate,
        empty: true,
    });

    const [productList, setProductList] = useState(null);

    const domain = useStoreDomain({
        loading: user.loading,
        account_id: user.accountId,
        store_id: user.storeId,
    });

    const history = useHistory();

    const notify = useNotification();

    const editor = useRef(null);

    const variables = { account_id: user.accountId, store_id: user.storeId };

    const refetchQueries = [{ query: QUERY_LANDING_LIST, variables }];

    const toggleSelector = () =>
        setSelectorOpen(old => ({ open: !old.open, empty: old.empty }));

    const selectTemplate = template =>
        editor.current.runCommand("load", template);

    const handleTemplate = () => setSelectorOpen({ open: true, empty: false });

    useEffect(() => {
        if (user.loading || !domain || !productList) return;

        editor.current = grapesjs.init({
            container: "#gjs",
            allowScripts: true,
            noticeOnUnload: false,
            showOffsets: true,
            showOffsetsSelected: true,
            baseCss,
            storageManager,
            selectorManager: { componentFirst: true },
            styleManager: { clearProperties: 1 },
            plugins,
            pluginsOpts: options({
                accountId: user.accountId,
                storeId: user.storeId,
                notify,
                handleSave,
                handlePublish,
                handleTemplate,
                productList,
                domain,
            }),
        });

        init(editor.current);

        setInitialized(true);

        setLoading(false);

        // eslint-disable-next-line
    }, [user.loading, domain, productList, isCreate, resourceId]);

    useEffect(() => {
        if (!initialized) return;

        editor.current.Panels.getButton("options", "publish").set(
            "attributes",
            {
                style: isCreate ? "display:none!important" : "",
            },
        );

        // eslint-disable-next-line
    }, [isCreate, initialized]);

    useQuery(QUERY_GET_LANDING, {
        variables: { ...variables, landing_id: resourceId },
        skip: isCreate || user.loading || !domain || !productList,
        fetchPolicy: "network-only",
        onCompleted: resp => {
            editor.current.runCommand("load", resp.getLanding.project);
            setLoading(false);
        },
    });

    useQuery(QUERY_PRODUCT_LIST, {
        skip: user.loading,
        notifyOnNetworkStatusChange: true,
        variables: {
            account_id: user.accountId,
            store_id: user.storeId,
        },
        onCompleted: resp => {
            let products = _.map(resp.listProducts, p => ({
                id: p.id,
                name: p.title,
                price: p.price,
                thumbnail: p.thumbnails[0],
            }));

            setProductList(products);
        },
    });

    const [createOrUpdate] = useMutation(
        isCreate ? MUTATION_CREATE_LANDING : MUTATION_UPDATE_LANDING,
        {
            refetchQueries,
            onCompleted: resp => {
                notify(
                    _.replace(
                        SAVED_SUCCESSFULLY_MESSAGE,
                        ":operation",
                        isCreate ? "created" : "updated",
                    ),
                    "success",
                );

                if (!isCreate) return;

                const keys = _.keys(resp);

                const entity = resp[keys[0]];

                history.replace(
                    _.replace(routes.LANDING_DETAIL.route, ":id", entity.id),
                );
            },
            onError: () => {
                notify(
                    _.replace(
                        SAVED_ERROR_MESSAGE,
                        ":operation",
                        isCreate ? "creating" : "updating",
                    ),
                    "error",
                );
            },
        },
    );

    const handleSave = payload => {
        createOrUpdate({
            variables: {
                ...variables,
                input: {
                    ...payload,
                    ...(isCreate ? {} : { id: resourceId }),
                },
            },
        });
    };

    const [publish] = useMutation(MUTATION_PUBLISH_LANDING, {
        refetchQueries,
        onCompleted: resp => {
            notify(PUBLISHED_SUCCESSFULLY_MESSAGE, "success");
            window.open(`${resp.publishLanding}`);
        },
        onError: () => notify(PUBLISHED_ERROR_MESSAGE, "error"),
    });

    const handlePublish = payload => {
        publish({
            variables: {
                ...variables,
                input: {
                    ...payload,
                    id: resourceId,
                },
            },
        });
    };

    return (
        <>
            <Content>
                <Portlet fit={!loading}>
                    <div id="gjs" />
                    {loading && (
                        <>
                            <Spinner color="metal" show sm />
                            &nbsp;&nbsp;Loading
                        </>
                    )}
                </Portlet>
                <LandingSelector
                    empty={selectorOpen.empty}
                    isOpen={selectorOpen.open && initialized}
                    onSelect={selectTemplate}
                    toggle={toggleSelector}
                />
            </Content>
        </>
    );
};

export default LandingForm;
