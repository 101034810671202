import React, { useState, useEffect } from "react";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import { useNotification } from "@/hooks";
import { Icon, Input, Section } from "@/components";
import { DAYSOFWEEK } from "config/constant";

const WorkingHour = ({ day, inputs, setInputs }) => {
    const notify = useNotification();

    const [applyChangesAllDays, setApplyChangesAllDays] = useState(null);

    const [dayInputs, setDayInputs] = useState({
        working_hours: { start: "", end: "" },
        time_slots: [],
    });

    useEffect(() => {
        const currentDayData = inputs.working_hours?.find(
            wh => wh.day_of_the_week === day,
        ) || { start: "", end: "", day_of_the_week: day };
        const currentTimeSlots =
            inputs.time_slots?.filter(ts => ts.day_of_the_week === day) || [];

        setDayInputs({
            working_hours: currentDayData,
            time_slots: currentTimeSlots,
        });
    }, [day, inputs]);

    const handleWorkingHourChange = (field, value) => {
        setDayInputs(prev => {
            const updatedInputs = {
                ...prev,
                working_hours: {
                    ...prev.working_hours,
                    [field]: value,
                },
            };

            handleSave(updatedInputs, false);

            return updatedInputs;
        });
    };

    const handleTimeSlotChange = (index, field, value) => {
        setDayInputs(prev => {
            const updatedSlots = [...prev.time_slots];
            updatedSlots[index] = { ...updatedSlots[index], [field]: value };

            const updatedInputs = {
                ...prev,
                time_slots: updatedSlots,
            };

            handleSave(updatedInputs, true);

            return updatedInputs;
        });
    };

    const handleAddTimeSlot = () => {
        setDayInputs(prev => ({
            ...prev,
            time_slots: [
                ...(prev.time_slots || []),
                {
                    start: "",
                    end: "",
                    cutoff_time: "",
                    disable_for_today: false,
                    disable: false,
                    day_of_the_week: day,
                    is_express: false,
                },
            ],
        }));
    };

    const handleRemoveTimeSlot = index => {
        setDayInputs(prev => {
            const updatedSlots = prev.time_slots.filter((_, i) => i !== index);

            const updatedInputs = {
                ...prev,
                time_slots: updatedSlots,
            };

            handleSave(updatedInputs, true);

            return updatedInputs;
        });
    };

    const handleSave = (newList, timeSlot) => {
        setApplyChangesAllDays(null);
        let newWorkingHours = dayInputs.working_hours;
        let updatedTimeSlots = dayInputs.time_slots || [];

        if (timeSlot) {
            updatedTimeSlots = [
                ...(inputs.time_slots || []).filter(
                    ts => ts && ts?.day_of_the_week !== day,
                ),
                ...newList.time_slots,
            ];

            setInputs({
                ...inputs,
                time_slots: updatedTimeSlots,
            });

            return;
        }

        const updatedWorkingHours = inputs.working_hours?.map(wh =>
            wh && wh?.day_of_the_week === day ? newList.working_hours : wh,
        );

        newWorkingHours = !inputs.working_hours?.some(
            wh => wh && wh?.day_of_the_week === day,
        )
            ? [...(inputs.working_hours || []), newList.working_hours]
            : [...updatedWorkingHours];

        setInputs({
            ...inputs,
            working_hours: newWorkingHours,
        });
    };

    const handleApplyChangeAllDays = day => {
        const sourceWHrs = dayInputs.working_hours;
        const sourceTimeSlot = dayInputs.time_slots;

        const updatedWorkingHours = DAYSOFWEEK.map(day => ({
            start: sourceWHrs?.start,
            end: sourceWHrs?.end,
            day_of_the_week: day,
        }));

        const updatedTimeSlots = DAYSOFWEEK.flatMap(currentDay => {
            return sourceTimeSlot.map(slot => ({
                ...slot,
                day_of_the_week: currentDay,
            }));
        });

        setInputs({
            ...inputs,
            working_hours: updatedWorkingHours,
            time_slots: updatedTimeSlots,
        });

        notify(`Changes applied to all days of the week`, "success");
        setApplyChangesAllDays(day);
    };

    return (
        <Section>
            <Row>
                <Label className="row-label" style={{ maxWidth: "320px" }}>
                    Apply this configuration to everyday
                </Label>
                <Col className="ml-2" lg={4}>
                    <span className="k-switch k-switch--md k-switch--icon">
                        <label>
                            <Input
                                checked={applyChangesAllDays === day || false}
                                name="applyAllToday"
                                onChange={() => {
                                    handleApplyChangeAllDays(day);
                                }}
                                type="checkbox"
                            />
                            <span />
                        </label>
                    </span>
                </Col>
            </Row>
            <Col>
                <Label>Working Hours</Label>
                <Row>
                    <Col className="ml-0 pl-0 pr-0" lg={2} md={4}>
                        <FormGroup>
                            <Input
                                className="no-border-right"
                                max="24:00"
                                min="00:00"
                                name="start"
                                onChange={e =>
                                    handleWorkingHourChange(
                                        "start",
                                        e.target.value,
                                    )
                                }
                                placeholder="Start time"
                                type="time"
                                value={dayInputs.working_hours?.start}
                            />
                        </FormGroup>
                    </Col>
                    <div className="working__hours-decorator">
                        <Icon icon={"arrow_next"} />
                    </div>
                    <Col className="pl-0" lg={2} md={4}>
                        <FormGroup>
                            <Input
                                className="no-border-left"
                                max="24:00"
                                min={dayInputs.working_hours?.start}
                                name="end"
                                onChange={e =>
                                    handleWorkingHourChange(
                                        "end",
                                        e.target.value,
                                    )
                                }
                                placeholder="End time"
                                type="time"
                                value={dayInputs.working_hours?.end}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Col>
            <Col className="mb-4">
                <Col lg={inputs.active ? 9 : 8}>
                    <Row
                        style={{
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                        }}
                    >
                        {/* <Col lg={inputs.active ? 7 : 6}></Col> */}
                        <Col className="mr-2 p-0" lg={1}>
                            <Button
                                className="m-0 p-0"
                                color="link"
                                onClick={handleAddTimeSlot}
                                style={{ minWidth: "24px", maxHeight: "24px" }}
                            >
                                <Icon icon="plus" />
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Row className="mb=2">
                    <Col key="disabled" lg={1}>
                        <Label>Disable</Label>
                    </Col>
                    {inputs.active && (
                        <Col key="express" lg={1}>
                            <Label>Express</Label>
                        </Col>
                    )}
                    <Col key="starts at" lg={2}>
                        <Label>Starts At</Label>
                    </Col>
                    <Col key="finish at" lg={2}>
                        <Label>Finish At</Label>
                    </Col>
                    <Col key="Cut off time" lg={2}>
                        <Label>Cut Off Time</Label>
                    </Col>
                    <Col key="delete" lg={1}>
                        <Label>Remove</Label>
                    </Col>
                </Row>
                {dayInputs.time_slots?.map((slot, index) => {
                    const key = `${slot.day_of_the_week}-${slot.start}-${slot.end}-${index}`;
                    return (
                        <Row key={key}>
                            <Col lg={1}>
                                <span className="k-switch k-switch--md k-switch--icon">
                                    <label>
                                        <Input
                                            checked={
                                                slot.disable ? "checked" : ""
                                            }
                                            name={`${key}-disable`}
                                            onChange={e =>
                                                handleTimeSlotChange(
                                                    index,
                                                    "disable",
                                                    e.target.checked,
                                                )
                                            }
                                            type="checkbox"
                                        />
                                        <span />
                                    </label>
                                </span>
                            </Col>
                            {inputs.active && (
                                <Col lg={1}>
                                    <span className="k-switch k-switch--md k-switch--icon">
                                        <label>
                                            <Input
                                                checked={
                                                    slot.is_express
                                                        ? "checked"
                                                        : ""
                                                }
                                                name={`${key}-express`}
                                                onChange={e =>
                                                    handleTimeSlotChange(
                                                        index,
                                                        "is_express",
                                                        e.target.checked,
                                                    )
                                                }
                                                type="checkbox"
                                            />
                                            <span />
                                        </label>
                                    </span>
                                </Col>
                            )}
                            <Col className="ml-0" lg={2}>
                                <FormGroup>
                                    <Input
                                        max="24:00"
                                        min="00:00"
                                        name={`${key}-start`}
                                        onChange={e =>
                                            handleTimeSlotChange(
                                                index,
                                                "start",
                                                e.target.value,
                                            )
                                        }
                                        placeholder="Start time"
                                        type="time"
                                        value={slot.start}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={2}>
                                <FormGroup>
                                    <Input
                                        max="24:00"
                                        min={slot.start}
                                        name={`${key}-end`}
                                        onChange={e =>
                                            handleTimeSlotChange(
                                                index,
                                                "end",
                                                e.target.value,
                                            )
                                        }
                                        placeholder="End time"
                                        type="time"
                                        value={slot.end}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={2}>
                                <FormGroup>
                                    <Input
                                        max="24:00"
                                        min={slot.end}
                                        name={`${key}-cutoff_time`}
                                        onChange={e =>
                                            handleTimeSlotChange(
                                                index,
                                                "cutoff_time",
                                                e.target.value,
                                            )
                                        }
                                        placeholder="Cutoff time"
                                        type="time"
                                        value={slot.cutoff_time}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={1}>
                                <FormGroup>
                                    <Button
                                        className="m-0 p-0"
                                        color="link"
                                        onClick={() =>
                                            handleRemoveTimeSlot(index)
                                        }
                                        style={{
                                            minWidth: "24px",
                                            maxHeight: "42px",
                                        }}
                                    >
                                        <Icon icon="delete" />
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    );
                })}
                {dayInputs.time_slots.length === 0 && (
                    <Col
                        className="bg-light m-2"
                        lg={inputs.active ? 9 : 8}
                        style={{
                            height: "100px",
                            display: "flex",
                            fontWeight: "500",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <p style={{ marginBottom: 0 }}>
                            No time slots are scheduled for this day
                        </p>
                    </Col>
                )}
            </Col>
        </Section>
    );
};

export default WorkingHour;
