import React, { FC, useRef, useState, useEffect } from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import _ from "lodash";
//import StoresList from "./StoresList";
import { Icon, Input, Spinner } from "@/components";
import classNames from "classnames";
import placeholder from "../../../../assets/images/placeholder.png";

interface ISelectProps {
    invalid?: any;
    controlName?: any;
    onChange?: any;
    options?: any;
    value?: any;
    className?: any;
    onFilterChange?: any;
    onFocus?: any;
    loading?: boolean;
}

const FilterSelect: FC<ISelectProps> = ({
    invalid,
    className,
    options,
    onChange,
    value,
    onFilterChange,
    onFocus,
    loading,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [filter, setFilter] = useState("");
    const input = useRef(null);

    useEffect(() => {
        if (isOpen) {
            input.current.focus();
        }
    }, [isOpen]);

    const handleFilter = e => {
        onFilterChange(e.target.value);
        setFilter(e.target.value);
    };

    const toggle = () => {
        const elements = Array.from(
            document.getElementsByClassName(
                "table-responsive",
            ) as HTMLCollectionOf<HTMLElement>,
        );

        if (elements) {
            elements.map(element => {
                element.style.overflow = isOpen ? "auto" : "inherit";
            });
        }
        setIsOpen(isOpen => !isOpen);
    };

    return (
        <div
            className={classNames(
                "k-header__topbar-item k-header__topbar-item--user filter-select-wraper",
                className,
                {
                    "form-control is-invalid": invalid,
                },
            )}
        >
            <Dropdown
                className="k-filter-input"
                direction={"down"}
                isOpen={isOpen}
                right="true"
                toggle={toggle}
            >
                <DropdownToggle
                    role="button"
                    style={{ display: "flex" }}
                    tag="div"
                >
                    <div className="k-input-icon k-input-icon--left ">
                        <span className="k-input-icon__icon k-input-icon__icon--left">
                            <span>
                                {loading ? (
                                    <Spinner color="metal" show sm />
                                ) : (
                                    <Icon icon="search" />
                                )}
                            </span>
                        </span>
                        <Input
                            bsSize="sm"
                            innerRef={input}
                            onChange={handleFilter}
                            onFocus={onFocus}
                            placeholder="Search for a product"
                            value={value || filter}
                        />
                        {/*  <span className="k-input-icon__icon k-input-icon__icon--right separator"></span> */}
                        <span
                            className="k-input-icon__icon k-input-icon__icon--right k-btn-arrow-icon"
                            role="button"
                        >
                            <span>
                                <i className="k-menu__ver-arrow fas fa-chevron-down" />
                            </span>
                        </span>
                    </div>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header key={`header-action-0`}>
                        Products
                    </DropdownItem>
                    {!options.length ? (
                        <DropdownItem
                            className="dropdown-item--no-stores"
                            tag="div"
                        >
                            No products
                        </DropdownItem>
                    ) : (
                        <div className={"dropdown-menu-body"}>
                            {options.map((option, index) => {
                                let imageURL = placeholder;
                                if (option && option?.value?.asset?.image) {
                                    imageURL = option?.value?.asset?.image;
                                }

                                return (
                                    <DropdownItem
                                        className="dropdown-item--search-product"
                                        key={index}
                                        onClick={o => {
                                            setFilter(option.label);
                                            onChange(option.value);
                                            o.stopPropagation();
                                        }}
                                    >
                                        <img
                                            alt={option.name}
                                            key={`${option.id}-${imageURL}`}
                                            onError={event => {
                                                event.currentTarget.src =
                                                    placeholder;
                                            }}
                                            src={imageURL}
                                            style={{
                                                height: "80px",
                                                width: "80px",
                                                background: "#f9fafc",
                                                borderRadius: "4px",
                                            }}
                                        />
                                        {option.label}
                                    </DropdownItem>
                                );
                            })}
                        </div>
                    )}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

export default FilterSelect;
