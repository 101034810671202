import Table from "base/components/table/Table";
import { NOT_AVAILABLE } from "@/config";
import moment from "moment";

import { startCase } from "lodash";

const ActivityLogTable = ({ activityLogs, loading }) => {
    const columns = [
        {
            dataField: "action",
            text: "Action",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "10%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <>
                        <div className="order__table_row_secundary">
                            {row?.action || NOT_AVAILABLE}
                        </div>
                    </>
                );
            },
        },
        {
            dataField: "status",
            text: "Status",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "10%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <>
                        <div className="order__table_row_secundary">
                            {startCase(row?.status) || NOT_AVAILABLE}
                        </div>
                    </>
                );
            },
        },
        {
            dataField: "user",
            text: "Updated By",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "10%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <>
                        <div className="order__table_row_secundary">
                            {row?.user || NOT_AVAILABLE}
                        </div>
                    </>
                );
            },
        },
        {
            dataField: "timestamp",
            text: "Timestamp",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "20%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <>
                        <div className="order__table_row_secundary">
                            {moment(row?.timestamp).format(
                                "ddd, MMM Do, YYYY - h:mm:ss a",
                            ) || NOT_AVAILABLE}
                        </div>
                    </>
                );
            },
        },
    ];

    return (
        <Table
            className="order__detail_items"
            columns={columns}
            data={activityLogs || []}
            keyField="item_id"
            loading={loading}
        />
    );
};

export default ActivityLogTable;
