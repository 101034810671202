import { FC, useEffect, useMemo, useState } from "react";
import { Col, Button, FormGroup, Row } from "reactstrap";

import { LineItem } from "@/types";
import { Icon, Input, Spinner } from "@/components";
import Table from "base/components/table/Table";

import { useCheckBarcodes, useUpdateBarcodes } from "./hooks";

interface ProductBarcodesProps {
    orderId: string;
    lineItems: LineItem[];
    isLoading: boolean;
}

type IBarcodesByProduct = {
    [key in number]: {
        barcodes: string[];
        verifiedStatus: "error" | "success" | "not-verified";
        errorMessage?: string;
    };
};

const ProductBarcodes: FC<ProductBarcodesProps> = ({
    lineItems,
    isLoading,
    orderId,
}) => {
    const productsSize = lineItems?.length ?? 0;
    const [activeProductIndex, setActiveProductIndex] = useState(0);
    const activeProduct = useMemo(
        () => lineItems?.[activeProductIndex],
        [activeProductIndex, lineItems],
    );
    const displayIndex = activeProductIndex + 1;
    const [barcodesByProduct, setBarcodesByProduct] =
        useState<IBarcodesByProduct>({});
    const scannedBarcodes = useMemo(
        () => barcodesByProduct[activeProductIndex]?.barcodes,
        [barcodesByProduct, activeProductIndex],
    );
    const [currentBarcode, setCurrentBarcode] = useState("");
    const readyToVerify = useMemo(() => {
        if (!scannedBarcodes?.length || !activeProduct) {
            return false;
        }
        return scannedBarcodes?.length === activeProduct?.quantity;
    }, [scannedBarcodes, activeProduct]);
    const activeVerifiedStatus =
        barcodesByProduct[activeProductIndex]?.verifiedStatus;
    const allowUpdateBarcodes = useMemo(
        () =>
            Object.entries(barcodesByProduct).length === lineItems?.length &&
            Object.entries(barcodesByProduct)
                .map(item => item[1])
                .every(({ verifiedStatus }) => verifiedStatus === "success"),
        [barcodesByProduct],
    );

    const { checkLineItemBarcodes, isChecking } = useCheckBarcodes({
        onCompleted: itemIndex => {
            setBarcodesByProduct(barcodes => ({
                ...barcodes,
                [itemIndex]: {
                    ...barcodes[itemIndex],
                    verifiedStatus: "success",
                },
            }));
        },
        onError: (itemIndex, errorMessage) => {
            setBarcodesByProduct(barcodes => ({
                ...barcodes,
                [itemIndex]: {
                    ...barcodes[itemIndex],
                    verifiedStatus: "error",
                    errorMessage,
                },
            }));
        },
    });

    const { updateLineItemBarcodes, isUpdating } = useUpdateBarcodes(orderId);

    const handleUpdateBarcodes = () => {
        updateLineItemBarcodes(
            orderId,
            lineItems.map((item, index) => ({
                ...item,
                barcodes: barcodesByProduct[index].barcodes,
            })),
        );
    };

    const handlePrev = () => {
        setActiveProductIndex(index => Math.max(index - 1, 0));
    };

    const handleNext = () => {
        setActiveProductIndex(index => Math.min(index + 1, productsSize - 1));
    };

    const handleBarcodeChange = event => {
        setCurrentBarcode(event.target.value);
    };

    const handleKeyPress = event => {
        if (event.code === "Enter") {
            setBarcodesByProduct(barcodes => ({
                ...barcodes,
                [activeProductIndex]: {
                    barcodes: [
                        ...(barcodes[activeProductIndex]?.barcodes ?? []),
                        event.target.value,
                    ],
                    verifiedStatus:
                        barcodes[activeProductIndex]?.verifiedStatus ??
                        "not-verified",
                },
            }));
            setCurrentBarcode("");
        }
    };

    const handleRemoveBarCode = (index: number) => {
        setBarcodesByProduct(barcodes => ({
            ...barcodes,
            [activeProductIndex]: {
                barcodes: (barcodes[activeProductIndex]?.barcodes ?? []).filter(
                    (_, pos) => pos !== index,
                ),
                verifiedStatus: "not-verified",
            },
        }));
    };

    useEffect(() => {
        if (
            readyToVerify &&
            barcodesByProduct[activeProductIndex]?.verifiedStatus ===
                "not-verified"
        ) {
            checkLineItemBarcodes(
                orderId,
                activeProduct?.id,
                activeProductIndex,
                scannedBarcodes,
                activeProduct?.variants[0]?.type,
            );
        }
    }, [readyToVerify]);

    useEffect(() => {
        setBarcodesByProduct(barcodesFromLineItems(lineItems));
    }, [lineItems]);

    if (isLoading) {
        return null;
    }

    return (
        <>
            <Row className="mt-5 mb-5">
                <Col>
                    <h5>Scan products barcode</h5>
                    <div className="order_data__delivery_scan_header">
                        <div className="d-flex flex-column">
                            <div className="products_slider">
                                <Button
                                    className="products_slider_btn"
                                    disabled={activeProductIndex === 0}
                                    onClick={handlePrev}
                                >
                                    <Icon icon="arrow_left" />
                                </Button>
                                <div className="products_counter">{`${displayIndex}/${productsSize}`}</div>
                                <span className="product_name">
                                    {activeProduct?.name}
                                </span>
                                <Button
                                    className="products_slider_btn"
                                    disabled={
                                        activeProductIndex === productsSize - 1
                                    }
                                    onClick={handleNext}
                                >
                                    <Icon icon="arrow_right" />
                                </Button>
                            </div>
                            <div className="product_details">
                                <span className="scanned_info">
                                    {`${scannedBarcodes?.length ?? 0}/${
                                        activeProduct?.quantity
                                    } scanned`}
                                </span>
                                {isChecking && (
                                    <div className="verification verification_progress">
                                        <i className="fas fa-circle-notch fa-spin"></i>
                                        <span>Verification in progress</span>
                                    </div>
                                )}
                                {activeVerifiedStatus === "success" &&
                                    !isChecking && (
                                        <div className="verification verification_success">
                                            Verification completed
                                        </div>
                                    )}
                                {activeVerifiedStatus === "error" &&
                                    !isChecking && (
                                        <div className="verification verification_error">
                                            {
                                                barcodesByProduct[
                                                    activeProductIndex
                                                ]?.errorMessage
                                            }
                                        </div>
                                    )}
                            </div>
                        </div>
                        <Button
                            className="btn"
                            color="primary"
                            disabled={!allowUpdateBarcodes}
                            onClick={handleUpdateBarcodes}
                        >
                            {isUpdating && <Spinner color="metal" show sm />}
                            <span>Update Barcode</span>
                        </Button>
                    </div>
                    <div className="order_data__delivery_scan_container">
                        <FormGroup className="mb-0 order__detail_select">
                            <Input
                                bsSize="md"
                                className="kanban__search"
                                disabled={readyToVerify}
                                onChange={handleBarcodeChange}
                                onKeyDown={handleKeyPress}
                                placeholder="Scan barcode"
                                value={currentBarcode}
                            />
                        </FormGroup>
                    </div>
                </Col>
            </Row>
            {scannedBarcodes?.length ? (
                <Row className="mt-5 mb-5">
                    <Col>
                        {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                        {/* @ts-ignore */}
                        <Table
                            columns={[
                                {
                                    dataField: "value",
                                    text: "Barcode",
                                    headerAttrs: {
                                        hidden: true,
                                    },
                                },
                                {
                                    formatter: (cell, row, rowIndex) => {
                                        return (
                                            <div className="action_column">
                                                <Button
                                                    disabled={isChecking}
                                                    onClick={() =>
                                                        handleRemoveBarCode(
                                                            rowIndex,
                                                        )
                                                    }
                                                >
                                                    <i className="far fa-trash-alt"></i>
                                                </Button>
                                            </div>
                                        );
                                    },
                                    headerAttrs: {
                                        hidden: true,
                                    },
                                },
                            ]}
                            data={
                                barcodesByProduct[
                                    activeProductIndex
                                ]?.barcodes?.map((item, index) => ({
                                    index,
                                    value: item,
                                })) ?? []
                            }
                            keyField="index"
                            loading={isLoading}
                        />
                    </Col>
                </Row>
            ) : null}
        </>
    );
};

const barcodesFromLineItems = (lineItems: LineItem[]): IBarcodesByProduct =>
    (lineItems?.reduce(
        (acc, item, index) => ({
            ...acc,
            [index]: {
                barcodes: item.barcodes ?? [],
                verifiedStatus: item.barcodes?.length
                    ? "success"
                    : "not-verified",
            },
        }),
        {},
    ) ?? {}) as IBarcodesByProduct;

export { ProductBarcodes };
