import moment from "moment";
import { Badge, Section, SectionTitle, Table } from "@/components";
import { Row, Col, TabPane } from "reactstrap";

const ActivityLogs = ({ tabId, inputs, loading }) => {
    const columns = [
        {
            dataField: "key",
            text: "Key",
            hidden: true,
        },
        {
            dataField: "action",
            text: "Action",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "30%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <>
                        <div className="costumers__table_row_primary">
                            {row.action}
                        </div>
                    </>
                );
            },
        },
        {
            dataField: "user",
            text: "User",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "25%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <>
                        <div className="costumers__table_row_primary">
                            {row.user}
                        </div>
                    </>
                );
            },
        },
        {
            dataField: "status",
            text: "Status",
            headerStyle: (colum, colIndex) => {
                return { width: "15%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Badge className={`status__metalic`} label={row.status} />
                );
            },
            sort: true,
        },
        {
            dataField: "timestamp",
            text: "Date and hours",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "30%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                let date = cell ? moment(cell).format("MMMM Do YYYY") : "";
                let time = cell ? moment(cell).format("HH:mm:ss") : "";
                return (
                    <>
                        <div className="costumers__table_row_primary">
                            {date}
                        </div>
                        <div className="costumers__table_row_secondary">
                            {time}
                        </div>
                    </>
                );
            },
        },
    ];

    const _loading = loading || false;

    const _logs = inputs?.activity_logs || [];

    const _lastLog = _logs.length
        ? ` (Last Updated ${moment(_logs[0]?.updated).format("MMMM Do YYYY")})`
        : " (No recent activity)";

    return (
        <TabPane className="tap__panel_activity_logs" tabId={tabId}>
            <Section>
                <Row>
                    <Col>
                        <SectionTitle md>
                            Activity log <span>{_lastLog}</span>
                        </SectionTitle>
                    </Col>
                </Row>
                <Row className="row-content-table">
                    <Table
                        className="table table-hover"
                        columns={columns}
                        data={_logs}
                        keyField="entity_id"
                        loading={_loading}
                    />
                </Row>
            </Section>
        </TabPane>
    );
};

export default ActivityLogs;
