import React, { useState } from "react";
import { Dropdown, DropdownToggle } from "reactstrap";
import UserMenuItems from "./UserMenuItems";
import useMeta from "../../../hooks/useMeta.js";
import _ from "lodash";

const UserMenu = () => {
    const [open, isOpen] = useState(false);
    const { user } = useMeta();

    let name;
    let initial;

    if (!user.loading) {
        name = _.trim(user.email);
        initial = name.split(" ");
        initial = _.toUpper(initial[0][0]) || "U";
    }

    const toggle = () => {
        isOpen(open => !open);
    };

    return (
        <div className="k-header__topbar-item k-header__topbar-item--user">
            <Dropdown
                className="k-header__topbar-wrapper"
                isOpen={open}
                toggle={toggle}
            >
                <DropdownToggle
                    className="k-header__topbar-user"
                    role="button"
                    tag="div"
                >
                    {/*   <span className="k-header__topbar-welcome k-hidden-mobile">
                        Hi,
                    </span>
                    <span
                        className="k-header__topbar-username k-hidden-mobile"
                        data-to-skip
                    >
                        {name}
                    </span> */}
                    <span
                        className="k-badge k-badge--username k-badge--lg k-badge--brand"
                        data-to-skip
                    >
                        {initial}
                    </span>
                </DropdownToggle>
                <UserMenuItems toggle={toggle} user={user} />
            </Dropdown>
        </div>
    );
};

export default UserMenu;
