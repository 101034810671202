import {
    Badge,
    Filters,
    Icon,
    SectionTitle,
    Spinner,
    Table,
} from "@/components";
import SearchBar from "./SearchBar";

import { Row, Col, Button } from "reactstrap";
import routes from "config/routes/routes";
import { useHistory } from "react-router-dom";
import { replace } from "lodash";
import moment from "moment";
import {
    useInfiniteHits,
    useInstantSearch,
} from "react-instantsearch-hooks-web";
import { KANBAN_LANES } from "config/constant";
import { useTableActions } from "@/hooks";

const OrderHistory = () => {
    const history = useHistory();

    const { status, refresh: refetch } = useInstantSearch();
    const { hits: orders, showMore, isLastPage } = useInfiniteHits();

    function handleDetailOpen(id) {
        const url = replace(routes.ORDER_DETAIL.route, ":id", id);
        history.push(url);
    }

    const getColorStatus = status => {
        const statusOrder = Object.entries(KANBAN_LANES).find(
            ([laneName, laneProps]) => {
                return laneProps.status.includes(status);
            },
        );
        return statusOrder?.[1]?.color;
    };

    const columns = [
        {
            dataField: "key",
            text: "Key",
            hidden: true,
        },
        {
            dataField: "objectID",
            text: "objectID",
            hidden: true,
        },
        {
            dataField: "order_number",
            text: "Order Number",
            headerStyle: (colum, colIndex) => {
                return { width: "6%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return `#${row?.order_number}`;
            },
        },
        {
            dataField: "created_at",
            text: "Created At",
            headerStyle: (colum, colIndex) => {
                return { width: "20%" };
            },
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                let date = cell ? moment(cell).format("MMMM Do YYYY") : "";
                let time = cell ? moment(cell).format("HH:mm:ss") : "";
                return (
                    <>
                        <div className="costumers__table_row_primary">
                            {date}
                        </div>
                        <div className="costumers__table_row_secondary">
                            {time}
                        </div>
                    </>
                );
            },
        },
        {
            dataField: "type",
            text: "Type",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "12%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Badge
                        className={`status__${
                            getColorStatus(row.status) || "info"
                        }`}
                        label={row.type}
                    />
                );
            },
        },
        {
            dataField: "status",
            text: "Status",
            headerStyle: (colum, colIndex) => {
                return { width: "12%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Badge
                        className={`status__${
                            getColorStatus(row.status) || "info"
                        }`}
                        label={row.status}
                    />
                );
            },
            sort: true,
        },
        {
            dataField: "total_price",
            text: "Price",
            headerStyle: (colum, colIndex) => {
                return { width: "12%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <div className="costumers__table_row_primary">
                        {row?.total_price ? `$${row.total_price}` : "$0"}
                    </div>
                );
            },
            sort: true,
        },
        {
            dataField: "jane_store.name",
            text: "Store",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "35%" };
            },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <>
                        <div>{row?.jane_store?.name}</div>
                    </>
                );
            },
        },
    ];

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            handleDetailOpen(row.objectID);
        },
    };

    const actions = [
        {
            label: "Edit",
            action: (cell, row, rowIndex, formatExtraData) => {
                handleDetailOpen(row.objectID);
            },
        },
    ];

    const { refreshButton } = useTableActions(
        refetch,
        "",
        status === "loading",
    );

    const _loading = false;

    return (
        <Row className="mt-5">
            <Col className={"mr-1"}>
                <SectionTitle md>Order History</SectionTitle>
            </Col>

            <Row>
                <Col className={"mb-4"} xs="6">
                    {refreshButton}
                </Col>
            </Row>

            <Row className="row-content-table">
                <Col className="order_history__filters">
                    <Filters />
                </Col>
                <Col className="order_history__table">
                    <SearchBar />
                    <Table
                        actions={actions}
                        className="table table-hover"
                        columns={columns}
                        data={orders}
                        keyField="objectID"
                        loading={_loading}
                        remote={{ filter: true }}
                        rowEvents={rowEvents}
                    />
                    <Button
                        className="mt-3 float-right load_more__button"
                        color="metal"
                        disabled={isLastPage}
                        onClick={() => showMore()}
                    >
                        {status === "loading" ? (
                            <Spinner color="primary" show={true} sm />
                        ) : (
                            <Icon icon={"refresh"} />
                        )}
                        Show more
                    </Button>
                </Col>
            </Row>
        </Row>
    );
};

export default OrderHistory;
