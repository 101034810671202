import gql from "graphql-tag";

let Navigation = {};
Navigation.fragments = {
    attributes: gql`
        fragment NavigationFragment on Navigation {
            id
            title
            slug
            status
            menu_items {
                id
                parent_menu_item_id
                name
                position
                target
                url
            }
        }
    `,
};

export const QUERY_NAVIGATION_LIST = gql`
    query listNavigations($account_id: String!, $store_id: String!) {
        listNavigations(account_id: $account_id, store_id: $store_id) {
            ...NavigationFragment
        }
    }
    ${Navigation.fragments.attributes}
`;

export const QUERY_GET_NAVIGATION = gql`
    query getNavigation(
        $account_id: String!
        $store_id: String!
        $navigation_id: String!
    ) {
        getNavigation(
            account_id: $account_id
            store_id: $store_id
            navigation_id: $navigation_id
        ) {
            ...NavigationFragment
        }
    }
    ${Navigation.fragments.attributes}
`;

export const MUTATION_CREATE_NAVIGATION = gql`
    mutation createNavigation(
        $account_id: String!
        $store_id: String!
        $input: NavigationInput!
    ) {
        createNavigation(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            ...NavigationFragment
        }
    }
    ${Navigation.fragments.attributes}
`;

export const MUTATION_UPDATE_NAVIGATION = gql`
    mutation updateNavigation(
        $account_id: String!
        $store_id: String!
        $input: NavigationInput!
    ) {
        updateNavigation(
            account_id: $account_id
            store_id: $store_id
            input: $input
        ) {
            ...NavigationFragment
        }
    }
    ${Navigation.fragments.attributes}
`;

export const MUTATION_REMOVE_NAVIGATION = gql`
    mutation deleteNavigation(
        $account_id: String!
        $store_id: String!
        $input: NavigationInput!
    ) {
        deleteNavigation(
            account_id: $account_id
            store_id: $store_id
            input: $input
        )
    }
`;
